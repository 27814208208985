import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardHeader, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import { cores } from 'src/modulos/processos/entidades/Etiqueta';

interface ICor {
  background: string;
  color: string;
}

const GerenciarEtiqueta: React.FC = () => {
  const navigate = useNavigate();
  const [salvandoEtiqueta, setSalvandoEtiqueta] =
    React.useState<boolean>(false);
  const [cor, setCor] = React.useState<ICor>({} as ICor);
  const [titulo, setTitulo] = React.useState('');

  const salvar = async () => {
    // setSalvandoEtiqueta(true);
    // const { resultado, erro } = await criarEtiqueta({});
    // setSalvandoEtiqueta(false);
    // if (resultado) {
    //   toast.success('Etiqueta salva com sucesso!');
    //   navigate('/processos/configuracoes/etiquetas');
    // } else {
    //   toast.error(erro?.mensagem);
    // }
  };

  const listarCoresEtiqueta = (): ICor[] => {
    return Object.entries(cores).map((item) => item[1]);
  };

  useEffect(() => {
    setCor(cores['bgm-black']);
  }, []);

  return (
    <Container>
      <Breadcrumb
        links={[
          {
            url: '/',
            texto: 'Controle de Processos',
          },
          {
            url: '/processos/configuracoes/etiquetas',
            texto: 'Configurações de Etiquetas',
          },
          {
            texto: 'Gerenciar Etiqueta',
          },
        ]}
      />
      <Row>
        <Col>
          <Card>
            <CardHeader
              className="rounded-top py-4"
              style={{
                background: cor.background,
              }}
            >
              <div
                className="form-group"
                style={{
                  background: cor.background,
                }}
              >
                <label htmlFor="" style={{ color: cor.color }}>
                  Título da Etiqueta
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setTitulo(e.target.value)}
                  value={titulo}
                />
              </div>
            </CardHeader>
            <div className="card-body">
              <h5 className="my-4">Escolha uma cor</h5>
              <div className="row">
                {listarCoresEtiqueta().map((item) => {
                  return (
                    <div className="col-2 mb-2" key={item.background}>
                      <span
                        className="d-block text-center rounded-lg py-3"
                        style={item}
                        onClick={() => {
                          setCor(item);
                        }}
                      ></span>
                    </div>
                  );
                })}
              </div>
              <hr />
              <div className="d-flex justify-content-end mt-4">
                <div>
                  <Button
                    color="soft-info"
                    className="mr-3"
                    onClick={() => navigate(-1)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    color="primary"
                    onClick={salvar}
                    disabled={salvandoEtiqueta}
                  >
                    <CarregamentoConteudo carregando={salvandoEtiqueta}>
                      Salvar alterações
                    </CarregamentoConteudo>
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default GerenciarEtiqueta;
