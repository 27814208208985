import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Col, Label, Row, Table } from 'reactstrap';
import FormGroup from 'src/components/Form/FormGroup';
import { livros } from 'src/modulos/aplicacao/util/livros';

const LivrosNotas: React.FC = () => {
  const { register } = useFormContext();

  return (
    <Row>
      <Col md={12}>
        <h5>Livro de Protocolo</h5>
        <input
          type="hidden"
          value="Livro de Protocolo"
          {...register(`${livros.NOTAS.LIVRO_PROTOCOLO}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="NOTAS"
          {...register(`${livros.NOTAS.LIVRO_PROTOCOLO}.especialidade`)}
        />
        <input
          type="hidden"
          value={300}
          {...register(`${livros.NOTAS.LIVRO_PROTOCOLO}.folhas_por_livro`)}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.NOTAS.LIVRO_PROTOCOLO}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.NOTAS.LIVRO_PROTOCOLO}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último protocolo</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.NOTAS.LIVRO_PROTOCOLO}.numero_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.NOTAS.LIVRO_PROTOCOLO}.registros_por_folha`
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>Adicionar livro</h5>
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Nome do Livro</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.NOTAS.LIVRO_PROTOCOLO}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.NOTAS.LIVRO_PROTOCOLO}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.NOTAS.LIVRO_PROTOCOLO}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último protocolo</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.NOTAS.LIVRO_PROTOCOLO}.numero_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.NOTAS.LIVRO_PROTOCOLO}.registros_por_folha`
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="py-2">
          <Button color="primary" type="button">
            Adicionar
          </Button>
        </div>
        <Row>
          <Col md="12">
            <Table>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LivrosNotas;
