import clsx from 'clsx';
import React, { useContext } from 'react';
import { FiPlus } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';
import AutenticacaoContext from 'src/contexts/Autenticacao';
import pegarFotoPessoa from 'src/modulos/pessoas/casos-de-uso/pessoa/pegarFotoPessoa';
import adicionarPrepostoProcesso from 'src/modulos/processos/casos-de-uso/processo/adicionarPrepostoProcesso';
import marcarPrepostoComoExecutor from 'src/modulos/processos/casos-de-uso/processo/marcarPrepostoComoExecutor';
import Avatar from 'src/modulos/processos/components/Avatar';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import { Preposto } from 'src/modulos/processos/entidades/Preposto';
import css from './style.module.css';

const AdicionarPreposto: React.FC = () => {
  const { processo, arquivado, onPrepostoAdicionadoNoProcesso } =
    useContext(ProcessoContext);
  const [exibirPopupAdicao, setExibirPopupAdicao] =
    React.useState<boolean>(false);
  const [resultadoBuscaPrepostos, setResultadoBuscaPrepostos] = React.useState<
    Preposto[]
  >([]);
  const { prepostos, encontrarPreposto } = useContext(AutenticacaoContext);

  const abrirPopup = () => setExibirPopupAdicao(true);
  const fecharPopup = () => {
    setResultadoBuscaPrepostos([]);
    setExibirPopupAdicao(false);
  };

  const adicionarAoProcesso = async (preposto: Preposto) => {
    const { resultado, error } = await adicionarPrepostoProcesso(
      processo,
      preposto
    );
    let principal = 0;
    if (resultado) {
      if (processo.prepostos && processo.prepostos.length === 0) {
        principal = 1;
        await marcarPrepostoComoExecutor(processo, preposto);
      }

      onPrepostoAdicionadoNoProcesso(preposto, resultado, principal);

      fecharPopup();
      return;
    }
    if (error) {
      toast.error(error.mensagem);
      return;
    }
  };

  const pesquisarPrepostos = (termo: string) => {
    const listaPrepostos = encontrarPreposto(termo);
    setResultadoBuscaPrepostos(
      listaPrepostos.filter((item) => item.status === 'ATIVO')
    );
  };

  const pegarListaPrepostos = () => {
    if (resultadoBuscaPrepostos.length > 0) {
      return resultadoBuscaPrepostos;
    }
    return prepostos.filter((item) => item.status === 'ATIVO');
  };

  const ehPermitidoAdicionarPreposto = () => {
    return processo && processo.prepostos && processo.prepostos.length < 2;
  };

  if (!ehPermitidoAdicionarPreposto()) {
    return null;
  }

  return (
    <div className="col-4">
      <Button
        color="soft-primary"
        className={clsx('rounded-circle', css['btn-add-preposto'])}
        disabled={arquivado}
        onClick={abrirPopup}
      >
        <FiPlus />
      </Button>
      <Dropdown
        mostrar={exibirPopupAdicao}
        fechar={fecharPopup}
        alinharEsquerda
      >
        <div className="position-relative py-3">
          <div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Pesquise pelo nome ou CPF"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  pesquisarPrepostos(event.target.value)
                }
              />
            </div>
          </div>
          <div className={css['lista-prepostos']}>
            {pegarListaPrepostos().map((item) => {
              return (
                <div
                  className={clsx(
                    'd-flex py-2 px-2 cursor-pointer w-100 align-items-center',
                    css['item-preposto']
                  )}
                  onClick={() => adicionarAoProcesso(item)}
                  key={item.id}
                >
                  <Avatar url={pegarFotoPessoa(item)} tamanho="36px" />
                  <span className="ml-2">{item.nome}</span>
                </div>
              );
            })}
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default AdicionarPreposto;
