import { Pessoa } from './Pessoa';

export interface PessoaFisica extends Omit<Pessoa, 'fisica' | 'juridica'> {
  id: number;
  tipo_pessoa: string;
  cartorio_id: number;
  endereco: string;
  bairro: string;
  cidade_id: number;
  cep: string;
  controle: number;
  nao_possui_cpf_cnpj: string;
  classe: string;
  cpf: string;
  nome: string;
  nome_pai: string;
  nome_mae: string;
  data_nascimento: string;
  rg: string;
  rg_orgao_emissor: string;
  cnh: string;
  profissao: string;
  sexo: string;
  nao_assina: string;
  interdicao_outros: string;
  falecido: string;
  rg_data_emissao: string;
  naturalidade_id: number;
  naturalidade_exterior: string | null;
  nacionalidade_id: number;
  estado_civil: string;
  regime_casamento: string;
  conjuge_cpf: string;
  conjuge_nome: string;
  conjuge_rg: string | null;
  conjuge_rg_orgao_emissor: string | null;
  certificado_validade: string | null;
  cnh_data_emissao: string;
  cnh_orgao_emissor: string;
  certificado_provisorio: string | null;
  email_comercial: string;
  endereco_comercial: string;
  email_comercial_nao_informado: string;
  endereco_comercial_nao_informado: string;
  exposta_politicamente: string | null;
  investigada_ou_acusada_de_terrorismo: string | null;
  pep_cargo: string | null;
  pep_data_inicio_mandato: string | null;
  pep_data_fim_mandato: string | null;
  pep_data_fim_carencia: string | null;
  especie_nacionalidade: string;
  cidade_nome: string;
  nacionalidade_nome: string;
  naturalidade_nome: string;
  historico_exposicao_politica: any[];
  pep_cartorio: string | null;
  tipo: 'FISICA';
  documentos: any[];
}

export const pegarEstadoCivil = (pessoa: PessoaFisica): string => {
  const letra = pessoa.sexo === 'M' ? 'o' : 'a';
  switch (pessoa.estado_civil) {
    case 'SOLTEIRO':
      return `solteir${letra}`;
    case 'CASADO':
      return `casad${letra}`;
    case 'SEPARADO':
      return `separad${letra}`;
    case 'SEPARADO_JUDICIALMENTE':
      return `separad${letra} judicialmente`;
    case 'DESQUITADO':
      return `desquitad${letra}`;
    case 'DIVORCIADO':
      return `divorciad${letra}`;
    case 'VIUVO':
      return `viúv${letra}`;
    case 'UNIAO_ESTAVEL':
      return 'união estável';
  }
  return '';
};

export const pegarModeloCompleto = (pessoa: PessoaFisica): string => {
  const letra = pessoa.sexo === 'M' ? 'o' : 'a';
  const nome = pessoa.nome.toUpperCase();
  const nacionalidade = `${
    pessoa.nacionalidade_nome === 'Brasil' ? 'brasileir' : 'estrangeir'
  }${letra}`;
  const estadoCivil = pegarEstadoCivil(pessoa);
  const profissao = pessoa.profissao.toLowerCase();
  const nascimento = `nascid${letra} em ${pessoa.data_nascimento}`;
  const naturalidade = `natural de ${pessoa.naturalidade_nome}.`;
  const filiacao = `filh${letra} de ${pessoa.nome_pai} e ${pessoa.nome_mae}`;
  const documentoPessoa = pessoa.rg
    ? `portador${pessoa.sexo === 'F' ? 'a' : ''} da CI/RG nº ${pessoa.rg} ${
        pessoa.rg_orgao_emissor
      }${
        pessoa.rg_data_emissao
          ? `, expedida em ${pessoa.rg_data_emissao}, `
          : ''
      }`
    : '';
  const cnh = pessoa.cnh
    ? `CNH nº ${pessoa.cnh} - ${pessoa.cnh_orgao_emissor}${
        pessoa.cnh_data_emissao
          ? `, expedida em ${pessoa.cnh_data_emissao}, `
          : ''
      }`
    : '';
  const inscricao = `inscrit${letra} no CPF sob nº ${pessoa.cpf}`;
  const complemento = pessoa.complemento ? ` ${pessoa.complemento}` : '';
  const endereco = `residente na ${pessoa.endereco}${complemento}, ${pessoa.bairro}, `;
  const cidade = `${pessoa.cidade_nome}., onde é domiciliad${letra}.`;
  const email = pessoa.email ? `e-mail: ${pessoa.email}, ` : '';
  const celular = pessoa.telefone_celular
    ? `Fone/Cel: ${pessoa.telefone_celular}, `
    : '';
  return `${nome}, ${nacionalidade}, ${estadoCivil}, ${profissao}, ${nascimento}, ${naturalidade}, ${filiacao}, ${documentoPessoa}${cnh}${inscricao}, ${endereco}${email}${celular}${cidade}`;
};

export const pegarModeloComum = (pessoa: PessoaFisica): string => {
  const letra = pessoa.sexo === 'M' ? 'o' : 'a';
  const nome = pessoa.nome.toUpperCase();
  const nacionalidade = `${
    pessoa.nacionalidade_nome === 'Brasil' ? 'brasileir' : 'estrangeir'
  }${letra}`;
  const estadoCivil = pegarEstadoCivil(pessoa);
  const profissao = pessoa.profissao.toLowerCase();
  const documentoPessoa = `portador${
    pessoa.sexo === 'F' ? 'a' : ''
  } da CI/RG nº ${pessoa.rg} ${pessoa.rg_orgao_emissor}`;
  const inscricao = `inscrit${letra} no CPF sob nº ${pessoa.cpf}`;
  const complemento = pessoa.complemento ? ` ${pessoa.complemento}` : '';
  const endereco = `residente na ${pessoa.endereco}${complemento}, ${pessoa.bairro}, ${pessoa.cidade_nome}., onde é domiciliad${letra}.`;
  return `${nome}, ${nacionalidade}, ${estadoCivil}, ${profissao}, ${documentoPessoa}, ${inscricao}, ${endereco}`;
};
