import api from '../../api';
import { set } from 'tiny-cookie';
import { ErroRegraNegocio } from '../../../../entidades/ErroRegraNegocio';

type Params = {
  cpf: string;
  senha: string;
  navegador: string;
};

type Autorizacao = {
  token: string;
};

const autenticar = async (
  params: Params
): Promise<{ resultado?: Autorizacao; error?: ErroRegraNegocio }> => {
  try {
    const resultado: Autorizacao = await api().post('/autenticacao', params);
    set('vc_token', resultado.token);

    localStorage.setItem('usuario', JSON.stringify(resultado));
    
    return { resultado };
  } catch (error: ErroRegraNegocio) {
    return { error };
  }
};

export default autenticar;
