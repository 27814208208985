import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { FiCamera, FiUpload } from 'react-icons/fi';
import Select from 'react-select';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';
import css from './style.module.css';

const DadosBiometricos: React.FC = () => {
  const [exibirWebcam, setExibirWebcam] = useState(false);
  const streamWebcam = useRef<MediaStream | null>(null);
  const [previaFoto, setPreviaFoto] = useState<Blob | File | null>(null);

  const abrirSelecaoArquivo = () => {
    document.getElementById('uploadDocumentoCliente')?.click();
  };

  const abrirSelecaoFoto = () => {
    document.getElementById('fotoCliente')?.click();
  };

  const pegarFotoSelecionada = (
    evento: React.ChangeEvent<HTMLInputElement>
  ) => {
    const arquivos = evento.target.files;
    if (arquivos && arquivos.length > 0) {
      setPreviaFoto(arquivos[0]);
      evento.target.value = '';
    }
  };

  const abrirWebcam = async () => {
    setExibirWebcam(true);
    streamWebcam.current = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false,
    });
    const video = document.getElementById('video') as HTMLVideoElement;
    if (video) {
      video.srcObject = streamWebcam.current;
    }
  };

  const fecharWebcam = () => {
    setExibirWebcam(false);
    if (streamWebcam.current) {
      streamWebcam.current.getTracks().forEach((item) => item.stop());
    }
  };

  const capturarFotoWebcam = async () => {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const video = document.getElementById('video') as HTMLVideoElement;
    if (canvas && video) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imagem: Blob | null = await new Promise((resolve) => {
          canvas.toBlob((blob) => {
            resolve(blob);
          });
        });
        if (imagem) {
          setPreviaFoto(imagem);
        }
        fecharWebcam();
      }
    }
  };

  const pegarPreviaFoto = () => {
    if (previaFoto) {
      return URL.createObjectURL(previaFoto);
    }
  };

  return (
    <>
      <Row>
        <Col md={3}>
          <div
            className={clsx(
              'd-flex flex-column position-relative align-items-center justify-content-center',
              css['upload-foto']
            )}
          >
            {previaFoto && <img src={pegarPreviaFoto()} alt="" />}
            <input
              type="file"
              id="fotoCliente"
              className="d-none"
              onChange={pegarFotoSelecionada}
              accept=".jpg, .jpeg, .png"
            />
            <div className={clsx(css['acoes'])}>
              <span className="d-block text-white mb-2">
                <b>Adicionar foto</b>
              </span>
              <div className="d-flex">
                <div className="position-relative">
                  <Button
                    color="primary"
                    className="mr-1 px-3"
                    onClick={() => {
                      if (!exibirWebcam) {
                        abrirWebcam();
                      } else {
                        fecharWebcam();
                      }
                    }}
                  >
                    <FiCamera />
                  </Button>
                  <Dropdown
                    mostrar={exibirWebcam}
                    fechar={fecharWebcam}
                    alinharEsquerda
                  >
                    <canvas
                      id="canvas"
                      width={320}
                      height={240}
                      className="d-none"
                    ></canvas>
                    <video id="video" width={320} height={240} autoPlay></video>
                    <div className="d-flex justify-content-end">
                      <Button
                        color="soft-secondary"
                        onClick={fecharWebcam}
                        className="mr-1"
                      >
                        Cancelar
                      </Button>
                      <Button color="primary" onClick={capturarFotoWebcam}>
                        Capturar
                      </Button>
                    </div>
                  </Dropdown>
                </div>
                <Button
                  color="primary"
                  className="ml-1 px-3"
                  onClick={abrirSelecaoFoto}
                >
                  <FiUpload />
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={12}>
          <h5>Adicionar documentos</h5>
          <FormGroup>
            <Row>
              <Col md={3}>
                <Label>Tipo</Label>
                <Select options={[]} />
              </Col>
            </Row>
          </FormGroup>
          <div
            className={clsx(
              'd-flex flex-column align-items-center',
              css['area-upload']
            )}
          >
            <input
              type="file"
              id="uploadDocumentoCliente"
              className="d-none"
              accept=".doc, .docx, .pdf"
            />
            <span className="d-block mb-1">
              <b>Arraste um arquivo aqui ou</b>
            </span>
            <Button
              color="primary"
              className="d-flex align-items-center"
              onClick={abrirSelecaoArquivo}
            >
              <FiUpload className="mr-2" />
              <span className="mt-1">
                Selecione um arquivo no seu computador
              </span>
            </Button>
            <span className="d-block">
              Apenas no formato DOC ou DOCX de no máximo 50mb.
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DadosBiometricos;
