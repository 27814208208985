import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Processo } from '../../entidades/Processo';

const pegarProcesso = async (
  id: number
): Promise<IResultadoRequisicao<Processo>> => {
  try {
    const resultado: Processo = await api().get(`/processos/${id}`);

    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default pegarProcesso;
