import { useBoolean } from 'ahooks';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import React, { useRef, useState } from 'react';
import BlockUi from 'react-block-ui';
import { useForm } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { IMaskInputProps } from 'react-imask/dist/mixin';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';
import buscarPessoaPorCpfCnpj from 'src/modulos/pessoas/casos-de-uso/pessoa/buscarPessoaPorCpfCnpj';
import cadastrarPessoaSimples from 'src/modulos/pessoas/casos-de-uso/pessoa/cadastrarPessoaSimples';
import { CadastroPessoaSimplesForm } from 'src/modulos/pessoas/entidades/CadastroPessoaSimplesForm';
import { Pessoa } from 'src/modulos/pessoas/entidades/Pessoa';
import constantes from 'src/util/constantes';
import { formatarCNPJ } from 'src/util/formatarCNPJ';
import { formatarCPF } from 'src/util/formatarCPF';

type Props = {
  titulo?: string;
  habilitarCpf?: boolean;
  habilitarCnpj?: boolean;
  buscarPessoa?: boolean;
  valorInicial?: string;
  apenasLeitura?: boolean;
  habilitarCadastroSimples?: boolean;
  limparAposCadastro?: boolean;
  onPessoaEncontrada?(pessoa: Pessoa | null): void;
  onCampoLimpo?(): void;
  onValorAtualizado?(valor: string, tipoDocumento: string): void;
  onAberturaCadastroCliente?(situacao: boolean): void;
};
const CpfCnpjInput = React.forwardRef<
  IMaskInputProps & { element?: HTMLInputElement },
  Props
>(
  (
    {
      titulo = 'CPF ou CNPJ',
      buscarPessoa = true,
      habilitarCpf = true,
      habilitarCnpj = true,
      valorInicial = '',
      apenasLeitura = false,
      habilitarCadastroSimples = false,
      limparAposCadastro = false,
      onPessoaEncontrada,
      onCampoLimpo,
      onValorAtualizado,
      onAberturaCadastroCliente,
    },
    ref
  ) => {
    const { register, getValues } = useForm();
    const [erroValidacao, setErroValidacao] = useState<string | null>(null);
    const [valorCampo, setValorCampo] = useState('');
    const [valorCampoFormatado, setValorCampoFormatado] = useState('');
    const [tipoDocumento, setTipoDocumento] = useState<'CPF' | 'CNPJ'>('CPF');
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [exibirCadastroCliente, { toggle: alternarCadastroCliente }] =
      useBoolean(false);
    const [processando, setProcessando] = useState(false);
    const [
      processandoCadastroCliente,
      { toggle: alternarProcessandoCadastroCliente },
    ] = useBoolean(false);
    const pegarMascaras = () => {
      const mascaras = [];
      if (habilitarCpf) {
        mascaras.push({ mask: constantes.MASCARA_CPF });
      }
      if (habilitarCnpj) {
        mascaras.push({ mask: constantes.MASCARA_CNPJ });
      }
      return mascaras;
    };

    const validarDados = async (valor: string) => {
      if (valor.length === 0 && typeof onCampoLimpo === 'function') {
        onCampoLimpo();
      }
      setErroValidacao(null);
      // CPF
      if (valor.length === 11) {
        if (!cpf.isValid(valor)) {
          setErroValidacao('CPF inválido');
          return;
        }
      }
      // CNPJ
      if (valor.length === 14) {
        if (!cnpj.isValid(valor)) {
          setErroValidacao('CNPJ inválido');
          return;
        }
      }

      if (valor.length === 11) {
        onValorAtualizado?.(valor, 'CPF');
        setValorCampoFormatado(formatarCPF(valor));
        setTipoDocumento('CPF');
      }
      if (valor.length === 14) {
        onValorAtualizado?.(valor, 'CNPJ');
        setValorCampoFormatado(formatarCNPJ(valor));
        setTipoDocumento('CNPJ');
      }

      setValorCampo(valor);

      if ([11, 14].includes(valor.length) && buscarPessoa) {
        setProcessando(true);
        const { resultado } = await buscarPessoaPorCpfCnpj(valor);
        setProcessando(false);
        if (habilitarCadastroSimples && !resultado) {
          alternarCadastroCliente();
          onAberturaCadastroCliente?.(true);
        } else {
          onPessoaEncontrada?.(resultado || null);
        }
      }
    };

    const fecharFormCadastro = () => {
      alternarCadastroCliente();
      onAberturaCadastroCliente?.(false);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };

    const cadastrarCliente = async () => {
      alternarProcessandoCadastroCliente();
      const dados: CadastroPessoaSimplesForm = {
        tipoDocumento: tipoDocumento,
        denominacao: getValues('nome'),
        documento: valorCampo,
      };

      const { resultado, error } = await cadastrarPessoaSimples(dados);
      alternarProcessandoCadastroCliente();
      if (resultado) {
        toast.success('Cadastro realizado');
        alternarCadastroCliente();
        if (limparAposCadastro) {
          if (inputRef.current) {
            inputRef.current.value = '';
          }
        }
        if (typeof onPessoaEncontrada === 'function') {
          onPessoaEncontrada(resultado);
        }
        return;
      }
      if (error) {
        toast.error(error.mensagem);
      }
    };

    return (
      <>
        <Modal isOpen={exibirCadastroCliente} toggle={fecharFormCadastro}>
          <ModalHeader toggle={fecharFormCadastro}>
            Cadastro de cliente
          </ModalHeader>
          <ModalBody>
            <BlockUi blocking={processandoCadastroCliente}>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>{tipoDocumento}</Label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={valorCampoFormatado}
                      readOnly
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Nome</Label>
                    <input
                      type="text"
                      className="form-control text-uppercase"
                      {...register('nome')}
                    />
                    <small>Evite abreviaturas</small>
                  </FormGroup>
                  <div className="d-flex justify-content-end">
                    <Button
                      color="soft-secondary"
                      className="mr-2"
                      onClick={fecharFormCadastro}
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      className="px-4"
                      onClick={cadastrarCliente}
                    >
                      Salvar
                    </Button>
                  </div>
                </Col>
              </Row>
            </BlockUi>
          </ModalBody>
        </Modal>
        <div className="position-relative">
          <label htmlFor="">{titulo}</label>
          <IMaskInput
            ref={ref}
            inputRef={(el) => {
              if (el) {
                inputRef.current = el as HTMLInputElement;
              }
            }}
            mask={pegarMascaras()}
            unmask
            className="form-control"
            readOnly={apenasLeitura}
            onAccept={(valor) => {
              validarDados(String(valor));
            }}
            {...(valorInicial !== '' ? { value: valorInicial } : {})}
          />
          {processando && (
            <Spinner
              size="sm"
              className="position-absolute"
              style={{
                top: '60%',
                right: '16px',
              }}
            />
          )}
          <span>{erroValidacao}</span>
        </div>
      </>
    );
  }
);

export default CpfCnpjInput;
