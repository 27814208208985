import { Row, Col, Card, CardBody, Form, Container } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { Wizard, Steps, Step } from 'react-albus';
import Breadcrumb from 'src/components/Breadcrumb';
import Navegar from './navegar';

import DadosGerais from './Abas/DadosGerais';
import Permissoes from './Abas/Permissoes';

const linksBreadcrumb = [
  {
    url: '/administrativo',
    texto: 'Início ',
  },
  {
    url: '/administrativo/usuarios',
    texto: 'Usuários',
  },
  {
    texto: 'Adicionar Usuário',
  },
];

type CamposFormulario = {
  nomeDoContrato: string;
  valorBase: number;
  ursAutorizadas: string[];
  clausulasDoContrato: string;
};

const menuItens = [
  {
    numero: '01',
    step_id: 'dados-gerais',
    nome: 'Dados Gerais',
    component: DadosGerais,
  },
  {
    numero: '02',
    step_id: 'permissoes',
    nome: 'Permissões',
    component: Permissoes,
  },
];

const salvar = async (dados: any) => {
  console.log(dados);
};

const AdicionarUsuario: React.FC = () => {
  const { getValues, handleSubmit, register } = useForm<CamposFormulario>();

  return (
    <Row className="d-flex justify-content-center">
      <Col className="p-4" sm={10}>
        <Breadcrumb links={linksBreadcrumb} />
        <Card>
          <CardBody>
            <div className="sw-main sw-theme-arrows">
              <Wizard
                render={({ step, steps }) => (
                  <div className="sw-main sw-theme-arrows">
                    <ul className="nav nav-tabs step-anchor text-white">
                      {menuItens.map((item, key) => (
                        <li
                          key={item.numero}
                          className={`nav-item
                                ${steps.indexOf(step) === key && 'active'}
                                ${steps.indexOf(step) > key && 'done'}
                                `}
                        >
                          <a className="nav-link">
                            {item.numero}
                            <small className="d-block">{item.nome}</small>
                          </a>
                        </li>
                      ))}
                    </ul>
                    <Form onSubmit={handleSubmit(salvar)}>
                      <Container className="mt-4 mb-4">
                        <Steps>
                          {menuItens.map((item, index) => {
                            let StepComponent = item.component;
                            return (
                              <Step id={item.step_id} key={index}>
                                <div className="tab-pane step-content d-block">
                                  <StepComponent />
                                </div>
                              </Step>
                            );
                          })}
                        </Steps>
                      </Container>

                      <Navegar />
                    </Form>
                  </div>
                )}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AdicionarUsuario;
