import React from 'react';
import { Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

type props = {
  register: any
}

const CabecalhoRequerimento: React.FC<props> = ({ register }) => {

  return (
    <div>
      <Row form>
        <Col md={4}>
          <FormGroup>
            <Label for='requerimento_cabecalho'>
              Cabeçalho
            </Label>
            <input
              {...register('requerimento_cabecalho')}
              id="requerimento_cabecalho"
              type="text"
              className="form-control"
              placeholder="e.: Senhor tabelião do Cartório de Natividade"
              aria-label="Cabeçalho"
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default CabecalhoRequerimento;