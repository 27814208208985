import React, { useContext } from 'react';
import { BsCameraVideo } from 'react-icons/bs';
import {
  FiCamera,
  FiChevronDown,
  FiChevronUp,
  FiMonitor,
} from 'react-icons/fi';
import { IoLink } from 'react-icons/io5';
import { MdAttachFile } from 'react-icons/md';
import { Button, Col, Row } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import AnexarDoComputador from './AnexarDoComputador';
import AnexarLink from './AnexarLink';

const AnexarDocumento: React.FC = () => {
  const { processo, arquivado } = useContext(ProcessoContext);
  const [mostrar, setMostrar] = React.useState(false);
  const [exibirFormAnexarLink, setExibirFormAnexarLink] = React.useState(false);
  const [tipoAnexo, setTipoAnexo] = React.useState('');

  const tipoAnexoSelecionado = (tipo: string): boolean => {
    return tipoAnexo === tipo;
  };

  React.useEffect(() => {
    setExibirFormAnexarLink(false);
  }, [mostrar]);

  return (
    <div className="relative">
      <Button
        color="soft-secondary"
        block
        className="d-flex align-items-center"
        disabled={arquivado}
        onClick={() => setMostrar(true)}
      >
        <MdAttachFile className="mr-2" />
        <span>Anexos</span>
      </Button>
      <Dropdown
        mostrar={mostrar}
        fechar={() => {
          setMostrar(false);
        }}
        titulo={'Anexar arquivos'}
      >
        {!exibirFormAnexarLink && (
          <>
            <h6>Escolha o tipo de anexo</h6>

            <div className="p-2">
              <div className="row border rounded">
                <div className="col-12">
                  <div className="d-flex align-items-center py-2">
                    <span
                      className="flex-fill cursor-pointer"
                      onClick={() => {
                        if (!tipoAnexoSelecionado('computador')) {
                          setTipoAnexo('computador');
                        } else {
                          setTipoAnexo('');
                        }
                      }}
                    >
                      <FiMonitor className="mr-3" />
                      Computador
                    </span>
                    {tipoAnexoSelecionado('computador') && <FiChevronUp />}
                    {!tipoAnexoSelecionado('computador') && <FiChevronDown />}
                  </div>
                  <AnexarDoComputador
                    mostrar={tipoAnexoSelecionado('computador')}
                  />
                </div>
              </div>

              <div className="row border rounded py-2 mt-3">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <span
                      className="flex-fill cursor-pointer"
                      onClick={() => setExibirFormAnexarLink(true)}
                    >
                      <IoLink className="mr-3" />
                      Link
                    </span>
                  </div>
                </div>
              </div>

              <div className="row border rounded py-2 mt-3">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <span
                      className="flex-fill cursor-pointer"
                      onClick={() => {
                        if (!tipoAnexoSelecionado('foto')) {
                          setTipoAnexo('foto');
                        } else {
                          setTipoAnexo('');
                        }
                      }}
                    >
                      <FiCamera className="mr-3" />
                      Tirar Foto
                    </span>
                    {tipoAnexoSelecionado('foto') && <FiChevronUp />}
                    {!tipoAnexoSelecionado('foto') && <FiChevronDown />}
                  </div>
                  <AnexarDoComputador mostrar={tipoAnexoSelecionado('foto')} />
                </div>
              </div>

              <div className="row border rounded py-2 mt-3">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <span
                      className="flex-fill cursor-pointer"
                      onClick={() => {
                        if (!tipoAnexoSelecionado('video')) {
                          setTipoAnexo('video');
                        } else {
                          setTipoAnexo('');
                        }
                      }}
                    >
                      <BsCameraVideo className="mr-3" />
                      Gravar vídeo
                    </span>
                    {tipoAnexoSelecionado('video') && <FiChevronUp />}
                    {!tipoAnexoSelecionado('video') && <FiChevronDown />}
                  </div>
                  <AnexarDoComputador mostrar={tipoAnexoSelecionado('video')} />
                </div>
              </div>
            </div>
          </>
        )}
        {exibirFormAnexarLink && (
          <Row className="mt-3">
            <Col md={12}>
              <AnexarLink
                processo={processo}
                onSalvar={() => {
                  setMostrar(false);
                }}
                onCancelar={() => {
                  setExibirFormAnexarLink(false);
                }}
              />
            </Col>
          </Row>
        )}
      </Dropdown>
    </div>
  );
};

export default AnexarDocumento;
