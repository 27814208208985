import React from "react";
import {
  BsChatRightText,
  BsFillGrid3X3GapFill,
  BsSearch,
} from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { IoFlashOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import IconeProcessos from "../../assets/icon-processos.png";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

const Header: React.FC = () => {
  const [mostrarModulos, setMostrarModulos] = React.useState(false);

  const alternarMostrarModulos = () => {
    setMostrarModulos((mostrar) => !mostrar);
  };

  return (
    <div className="navbar navbar-expand flex-column flex-md-row navbar-custom">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand mr-0 mr-md-2 logo">
          <span className="logo-lg">
            <img src={IconeProcessos} alt="" className="w-100" />
          </span>
        </Link>
        <ul className="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0">
          <li className="">
            <button className="button-menu-mobile open-left disable-btn">
              <i data-feather="menu" className="menu-icon"></i>
              <i data-feather="x" className="close-icon"></i>
            </button>
          </li>
          <li className="">
            <div className="app-search">
              <div className="btn-group dropdown">
                <button
                  className="btn btn-soft-primary dropdown-toggle"
                  type="button"
                  id="dropdownMenutext"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  CARTÓRIO TESTE - CNS 22.222-2
                </button>
                <div
                  className="dropdown-menu dropdown-lg p-3"
                  aria-labelledby="dropdownMenutext"
                >
                  <div className="text-muted">
                    <p>
                      Some example text that's free-flowing within the dropdown
                      menu.
                    </p>
                    <p className="mb-0">And this is more example text.</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="d-none d-sm-block">
            <div className="app-search">
              <form>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Pesquisar por processo"
                  />
                  <BsSearch />
                </div>
              </form>
            </div>
          </li>
        </ul>

        <ul className="navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0">
          <li
            className="dropdown notification-list"
            data-toggle="tooltip"
            data-placement="left"
            title="Settings"
          >
            <span className="nav-link right-bar-toggle">
              <IoFlashOutline />
            </span>
          </li>
          <li
            className="dropdown notification-list"
            data-toggle="tooltip"
            data-placement="left"
            title="Settings"
          >
            <span className="nav-link right-bar-toggle">
              <FiSettings />
            </span>
          </li>

          <Dropdown
            isOpen={mostrarModulos}
            toggle={alternarMostrarModulos}
            size="lg"
            className="nav-link right-bar-toggle"
            tag="li"
          >
            <DropdownToggle data-toggle="dropdown" tag="a">
              <BsFillGrid3X3GapFill />
            </DropdownToggle>
            <DropdownMenu right style={{ width: "400px" }}>
              <div className="container">
                <div>
                  <h5>Ir para o módulo...</h5>
                </div>
                <div className="row">
                  <div className="col-4">
                    <Link to="/">
                      <img
                        src="https://dummyimage.com/100x100/9c9c9c/fff"
                        alt=""
                      />
                      <span>Processos</span>
                    </Link>
                  </div>
                  <div className="col-4">
                    <Link to="/financeiro">
                      <img
                        src="https://dummyimage.com/100x100/9c9c9c/fff"
                        alt=""
                      />
                      <span>Financeiro</span>
                    </Link>
                  </div>
                  <div className="col-4">
                    <Link to="/acervo">
                      <img
                        src="https://dummyimage.com/100x100/9c9c9c/fff"
                        alt=""
                      />
                      <span>Acervo</span>
                    </Link>
                  </div>
                  <div className="col-4">
                    <Link to="/pessoas">
                      <img
                        src="https://dummyimage.com/100x100/9c9c9c/fff"
                        alt=""
                      />
                      <span>Pessoas</span>
                    </Link>
                  </div>
                  <div className="col-4">
                    <Link to="/mosaico">
                      <img
                        src="https://dummyimage.com/100x100/9c9c9c/fff"
                        alt=""
                      />
                      <span>Mosaico</span>
                    </Link>
                  </div>
                  <div className="col-4">
                    <Link to="/comunicacoes">
                      <img
                        src="https://dummyimage.com/100x100/9c9c9c/fff"
                        alt=""
                      />
                      <span>Comunicações</span>
                    </Link>
                  </div>
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
          <li
            className="dropdown"
            data-toggle="tooltip"
            data-placement="left"
            title="Settings"
          >
            <span className="nav-link right-bar-toggle">
              <BsChatRightText />
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
