import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import { Row, Col, Table, Card, CardBody } from 'reactstrap';
import { Cartorio } from 'src/modulos/aplicacao/entidades/Cartorio';

import { BiDotsVerticalRounded } from 'react-icons/bi';

import visualizarCartorio from '../../casos-de-uso/cartorio/visualizarCartorio';
import formatarCNSCartorio from 'src/modulos/aplicacao/casos-de-uso/cartorio/formatarCNSCartorio';

interface cartorioDetalhamento {
  data_inicio: string;
  email: string;
  cidade_uf: string;
  tipo_de_emissao: string;
  tel_whats: string;
  dados_titular: string;
  data_pagamento: number;
  validade_gise: string;
}

const DetalhesDoCartorio: React.FC = () => {
  const { id } = useParams();

  const [cartorio, setCartorio] = React.useState<Cartorio>({} as Cartorio);

  const linksBreadcrumb = [
    {
      url: '/administrativo',
      texto: 'Início ',
    },
    {
      url: '/administrativo/cartorios',
      texto: 'Cartórios',
    },
    {
      texto: `${cartorio.cns}`,
    },
  ];

  React.useEffect(() => {
    if (id) {
      visualizarCartorio(Number(id)).then(({ resultado }) => {
        if (resultado) {
          setCartorio(resultado);
        }
      });
    }
  }, [id]);

  return (
    <Row>
      <Col>
        <Breadcrumb links={linksBreadcrumb} />
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between">
              <div>
                <b>
                  <h4>{cartorio.denominacao}</h4>
                </b>

                <span>{formatarCNSCartorio(cartorio)}</span>
              </div>

              <button className="btn btn-soft-primary h-25">
                <BiDotsVerticalRounded />
                Opções
              </button>
            </div>

            <Row className="mt-5">
              <Col>
                <Table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td className="bg-light font-weight-bold">
                        Data de Início de Contrato
                      </td>
                      <td>{cartorio.created_at}</td>
                    </tr>
                    <tr>
                      <td className="bg-light font-weight-bold">E-mail</td>
                      <td>{cartorio.email}</td>
                    </tr>
                    <tr>
                      <td className="bg-light font-weight-bold">
                        Cidade - Estado
                      </td>
                      <td>
                        {cartorio.cidade} - {cartorio.uf}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-light font-weight-bold">
                        Tipo de Emissão de NF
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-light font-weight-bold">
                        Telefone / Whatsapp
                      </td>
                      <td>
                        {cartorio.telefone}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-light font-weight-bold">
                        Dados do Titular
                      </td>
                      <td>
                        {cartorio.titular}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-light font-weight-bold">
                        Data de Pagamento
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-light font-weight-bold">
                        Validade do Hash do GISE
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            <hr />

            <b>
              <h4>Lotes de Selos</h4>
            </b>

            <Row className="mt-5">
              <Col>
                <Table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>
                        <div className="d-flex justify-content-between">
                          Especialidade
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between">
                          Usados
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between">
                          Livres
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between">
                          Recebidos
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between">
                          Erros
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between">
                          Paralisados
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between">
                          Inicial
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-between">
                          Final
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="bg-light font-weight-bold">Notas</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                    </tr>

                    <tr>
                      <td className="bg-light font-weight-bold">
                        Reconhecimento
                      </td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                    </tr>

                    <tr>
                      <td className="bg-light font-weight-bold">
                        Autenticação
                      </td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                    </tr>

                    <tr>
                      <td className="bg-light font-weight-bold">Protestos</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                    </tr>

                    <tr>
                      <td className="bg-light font-weight-bold">RI</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                    </tr>

                    <tr>
                      <td className="bg-light font-weight-bold">RTD</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                    </tr>

                    <tr>
                      <td className="bg-light font-weight-bold">RCPJ</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                    </tr>

                    <tr>
                      <td className="bg-light font-weight-bold">RCPN</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default DetalhesDoCartorio;
