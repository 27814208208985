import React, { useContext } from 'react';
import BreadcrumbApp from 'src/components/Breadcrumb';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';

const Breadcrumb: React.FC = () => {
  const { processo } = useContext(ProcessoContext);

  return (
    <BreadcrumbApp
      links={[
        {
          url: '/',
          texto: 'Controle de Processos',
        },
        {
          texto: `PROCESSO Nº ${processo.numero}`,
        },
      ]}
    />
  );
};

export default Breadcrumb;
