import { WithWizard } from 'react-albus';

const Navegar: React.FC = () => {
  return (
    <WithWizard
      render={({ next, previous, step, steps }) => (
        <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
          <button
            onClick={() => alert('Adicionar ação')}
            className="btn btn-soft-success mr-3 w-auto h-auto"
          >
            Cancelar
          </button>

          {steps.indexOf(step) > 0 && (
            <button
              type="button"
              className="btn btn-secondary rounded-none w-auto h-auto"
              onClick={previous}
            >
              Voltar
            </button>
          )}

          {steps.indexOf(step) < steps.length - 1 && (
            <button
              type="button"
              className="btn btn-primary w-auto h-auto"
              onClick={next}
            >
              Salvar e prosseguir
            </button>
          )}

          {steps.indexOf(step) === steps.length - 1 && (
            <button className="btn btn-primary w-auto h-auto">Concluir</button>
          )}
        </div>
      )}
    />
  );
};

export default Navegar;
