import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { Etiqueta } from 'src/modulos/processos/entidades/Etiqueta';
import { FiTag } from 'react-icons/fi';
import adicionarEtiquetaProcesso from 'src/modulos/processos/casos-de-uso/processo/adicionarEtiquetaProcesso';
import { toast } from 'react-toastify';
import excluirEtiquetaProcesso from 'src/modulos/processos/casos-de-uso/processo/excluirEtiquetaProcesso';
import Dropdown from 'src/components/Dropdown';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import GerenciarEtiquetas from 'src/modulos/processos/components/Etiquetas';
import { etiquetaEstahNoProcesso } from 'src/modulos/processos/entidades/Processo';

const AdicionarEtiqueta: React.FC = () => {
  const { processo, arquivado, onAdicaoEtiqueta, onExclusaoEtiqueta } =
    useContext(ProcessoContext);
  const [exibirPopup, setExibirPopup] = React.useState<boolean>(false);

  const alternarEtiqueta = async (etiqueta: Etiqueta) => {
    if (etiquetaEstahNoProcesso(processo, etiqueta.id)) {
      const { resultado, error } = await excluirEtiquetaProcesso(
        processo,
        etiqueta
      );
      if (resultado) {
        onExclusaoEtiqueta(etiqueta, resultado);
      }
      if (error) {
        toast.error(error.mensagem);
      }
    } else {
      const { resultado, error } = await adicionarEtiquetaProcesso(
        processo,
        etiqueta
      );
      if (resultado) {
        onAdicaoEtiqueta(etiqueta, resultado);
      }
      if (error) {
        toast.error(error.mensagem);
      }
    }
  };

  const abrirPopup = () => setExibirPopup(true);
  const fecharPopup = () => setExibirPopup(false);

  return (
    <>
      <Button
        color="soft-secondary"
        block
        className="d-flex align-items-center"
        onClick={abrirPopup}
        disabled={arquivado}
      >
        <FiTag className="mr-2" />
        <span>Etiquetas</span>
      </Button>
      <Dropdown mostrar={exibirPopup} fechar={fecharPopup} titulo="Etiquetas">
        <GerenciarEtiquetas
          onEtiquetaSelecionada={alternarEtiqueta}
          onEtiquetaDesmarcada={alternarEtiqueta}
          etiquetasMarcadas={processo.etiquetas.map((item) => item.id)}
        />
      </Dropdown>
    </>
  );
};

export default AdicionarEtiqueta;
