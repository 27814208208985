import React from 'react';
import { useForm } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import constantes from 'src/util/constantes';
import Logo from '../../assets/logo-completa.svg';
import autenticar from '../../casos-de-uso/usuario/autenticar';
import RecuperarSenha from './RecuperarSenha';

type CamposFormulario = {
  cpf: string;
  senha: string;
};

const Entrar: React.FC = () => {
  const { register, setValue, handleSubmit } = useForm<CamposFormulario>();
  const [carregando, setCarregando] = React.useState(false);
  const navigate = useNavigate();

  const login = async (dados: CamposFormulario) => {
    setCarregando(true);
    const { resultado, error } = await autenticar({
      cpf: dados.cpf.replace(/\D/g, ''),
      senha: dados.senha,
      navegador: 'hash123', // todo: adicionar hash do navegador
    });

    setCarregando(false);

    if (resultado) {
      navigate('/');
    } else {
      toast.error(error.mensagem);
    }
  };

  return (
    <div className="container d-flex justify-content-center">
      <div className="w-75">
        <div className="d-flex justify-content-center mb-4 mt-5">
          <img src={Logo} alt="Logo VirtualCart" className="w-50" />
        </div>
        <div className="card w-100">
          <div className="card-body px-5">
            <div className="row pb-4">
              <div className="col-6">
                <h3>Entrar</h3>
                <form onSubmit={handleSubmit(login)}>
                  <div className="form-group">
                    <label htmlFor="">CPF</label>
                    <IMaskInput
                      mask={constantes.MASCARA_CPF}
                      unmask
                      className="form-control"
                      onAccept={(valor) => {
                        setValue('cpf', String(valor));
                      }}
                      data-testid="cpf"
                    />
                  </div>
                  <div className="form-group">
                    <div className="d-flex justify-content-between">
                      <label htmlFor="">Senha</label>
                      <RecuperarSenha />
                    </div>
                    <input
                      type="password"
                      data-testid="senha"
                      className="form-control"
                      disabled={carregando}
                      {...register('senha', { required: 'Informe a senha' })}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={carregando}
                    data-testid="entrar"
                  >
                    <CarregamentoConteudo carregando={carregando}>
                      ENTRAR
                    </CarregamentoConteudo>
                  </button>
                </form>
                <span className="d-block text-center py-2">ou</span>
                <button className="btn btn-soft-primary btn-block" disabled>
                  ENTRAR COM CERTIFICADO DIGITAL
                </button>
              </div>
              <div className="col-5 offset-1">
                <h3>Não possui cadastro?</h3>
                <Link to={'/registro'} className="btn btn-primary btn-block">
                  CADASTRE-SE AGORA!
                </Link>
                <p className="mt-4">
                  Clique aqui para assistir tutorial com o passo a passo do
                  cadastro e, em caso de dúvidas, clique aqui para contatar
                  nosso suporte, pelo telegram.
                </p>
                <p>Atendimento de Segunda à Sexta das 12h00 às 18h00</p>
                <a
                  href="https://get.teamviewer.com/6vz8i23"
                  className="btn btn-soft-secondary btn-block mt-4"
                  target={'_blank'}
                  rel="noreferrer noopener"
                >
                  BAIXAR ACESSO REMOTO
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <small>Produzido por:</small>
          </div>
          <div className="d-flex justify-content-between">
            <img
              src="https://beta.virtualcart.app/dist/img/nato_digital_footer.png?v1"
              alt=""
            />

            <img
              src="https://beta.virtualcart.app/dist/img/site_seguro.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entrar;
