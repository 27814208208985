import api from 'src/modulos/processos/api';
import { Modelo } from 'src/modulos/processos/entidades/Modelo';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';

export default async function cadastrarModelo(
  modelo: Modelo
): Promise<IResultadoRequisicao<Modelo>> {
  try {
    const resultado = await api().post('modelos', modelo);
    return { resultado };
  } catch (error) {
    return { error };
  }
}
