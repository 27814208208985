import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';

const recuperarSenhaUsuario = async (
  cpf: string
): Promise<IResultadoRequisicao<string>> => {
  try {
    const resultado: string = await api().post('autenticacao/recuperar', {
      cpf,
    });
    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default recuperarSenhaUsuario;
