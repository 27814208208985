import React from 'react';
import { Row, Col, CustomInput, Label, FormGroup } from 'reactstrap';
import InputMask from 'react-input-mask';

type Props = {
  register: any;
  getValues?: any;
  setValue?: any;
  watch?: any;
};

const DadosDeContato: React.FC<Props> = ({
  register,
  getValues,
  setValue,
  watch,
}) => {
  return (
    <>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="ddi">
              DDI
              <span className="font-weight-normal text-danger">
                (obrigatório *)
              </span>
            </Label>
            <input
              id="ddi"
              type="text"
              className="form-control"
              placeholder="ex.: +55 (Brasil)"
              {...register('ddi')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="whatsapp">
              DDD + Whatsapp
              <span className="font-weight-normal text-danger">
                (obrigatório *)
              </span>
            </Label>
            <Row className="align-items-center">
              <Col md={3} xs={8}>
                <InputMask
                  mask="(99) 9 9999-9999"
                  id="whatsapp"
                  type="text"
                  className="form-control"
                  placeholder="ex.: (00) 0000-0000"
                  {...register('whatsapp')}
                />
              </Col>
              <Col md={3} xs={4}>
                <CustomInput
                  type="checkbox"
                  id="nao_possui_whatsapp"
                  label="Não possui Whatsapp"
                  inline
                  {...register('nao_possui_whatsapp')}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="telefone">DDD + Telefone fixo</Label>
            <InputMask
              mask="(99) 9 9999-9999"
              id="telefone"
              type="text"
              className="form-control"
              placeholder="ex.: (00) 0000-0000"
              {...register('telefone')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup>
            <Label for="email">E-mail</Label>
            <input
              id="email"
              type="email"
              className="form-control"
              placeholder="ex.: exemplo@gmail.com"
              {...register('email')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <FormGroup>
            <Label for="cep">CEP</Label>
            <InputMask
              mask="99999-999"
              id="cep"
              type="text"
              className="form-control"
              placeholder="ex.: 00000-000"
              {...register('cep', {
                setValueAs: (v: String) => v.replace(/\D/g, ''),
              })}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label for="bairro">Bairro</Label>
            <input
              id="bairro"
              type="text"
              className="form-control"
              placeholder="ex.: Jardim Aureny IV"
              {...register('bairro')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup>
            <Label for="endereco">Endereço</Label>
            <input
              id="endereco"
              type="text"
              className="form-control"
              placeholder="ex.: Rua 01, quadra 01, S/N"
              {...register('endereco')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup>
            <Label for="complemento">Complemento</Label>
            <input
              id="complemento"
              type="text"
              className="form-control"
              placeholder="obs.: Insira pontos de referência"
              {...register('complemento')}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default DadosDeContato;
