import React, { useState } from 'react';
import {
  FiCalendar,
  FiChevronDown,
  FiDollarSign,
  FiFilter,
  FiChevronRight,
} from 'react-icons/fi';
import { Button, Collapse, ListGroup, ListGroupItem } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';
import Flatpickr from 'react-flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import PluginSelecaoMes from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import 'flatpickr/dist/themes/material_blue.css';
import Select from 'react-select';

const tiposContas = [
  {
    value: 'executado',
    label: 'Executado',
  },
  {
    value: 'deposito',
    label: 'Depósito Prévio',
  },
];

export type FiltroLivroDiario = {
  competencia: Date;
  tipo: string;
};

type Props = {
  onFiltroAplicado(filtro: FiltroLivroDiario): void;
  filtroInicial: FiltroLivroDiario;
};

const Filtro: React.FC<Props> = ({ onFiltroAplicado, filtroInicial }) => {
  const [opcoesExpandidas, setOpcoesExpandidas] = useState<string[]>([]);
  const [exibirFiltro, setExibirFiltro] = useState(false);
  const [filtro, setFiltro] = useState<FiltroLivroDiario>(filtroInicial);

  const estahExpandido = (item: string) => opcoesExpandidas.includes(item);

  const alternarExpansao = (item: string) => {
    if (estahExpandido(item)) {
      setOpcoesExpandidas((atual) => atual.filter((opcao) => opcao !== item));
    } else {
      setOpcoesExpandidas((atual) => [...atual, item]);
    }
  };

  const fecharFiltro = () => setExibirFiltro(false);

  const aplicarFiltro = () => {
    fecharFiltro();
    onFiltroAplicado(filtro);
  };

  return (
    <div className="position-relative">
      <Button
        color="soft-primary"
        className="ml-2"
        onClick={() => setExibirFiltro(true)}
      >
        <FiFilter />
      </Button>
      <Dropdown
        mostrar={exibirFiltro}
        fechar={fecharFiltro}
        titulo="Filtrar por..."
        fecharAoClicarExterno={false}
      >
        <ListGroup className="border-0">
          <ListGroupItem
            tag="button"
            action
            className="border-0"
            onClick={() => alternarExpansao('competencia')}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <FiCalendar />
                <span className="ml-2">Competência</span>
              </div>
              {estahExpandido('competencia') ? (
                <FiChevronDown />
              ) : (
                <FiChevronRight />
              )}
            </div>
          </ListGroupItem>
          <Collapse isOpen={estahExpandido('competencia')} className="py-2">
            <Flatpickr
              value={filtro.competencia}
              className="form-control"
              onChange={(data) => {
                if (data && data.length > 0) {
                  setFiltro((atual) => ({
                    ...atual,
                    competencia: data[0],
                  }));
                }
              }}
              options={{
                locale: Portuguese,
                maxDate: new Date(),
                plugins: [new (PluginSelecaoMes as any)({})],
              }}
            />
          </Collapse>
          <ListGroupItem
            tag="button"
            action
            className="border-0"
            onClick={() => alternarExpansao('tipo')}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <FiDollarSign />
                <span className="ml-2">Tipo</span>
              </div>
              {estahExpandido('tipo') ? <FiChevronDown /> : <FiChevronRight />}
            </div>
          </ListGroupItem>
          <Collapse isOpen={estahExpandido('tipo')} className="py-2">
            <Select
              options={tiposContas}
              placeholder="Selecione"
              onChange={(valor) => {
                if (valor) {
                  setFiltro((atual) => ({
                    ...atual,
                    tipo: valor.value,
                  }));
                }
              }}
            />
          </Collapse>
        </ListGroup>
        <div className="d-flex justify-content-end border-top pt-2">
          <Button color="soft-success" className="mr-2" onClick={fecharFiltro}>
            Cancelar
          </Button>
          <Button color="primary" className="mr-2" onClick={aplicarFiltro}>
            Aplicar filtros
          </Button>
        </div>
      </Dropdown>
    </div>
  );
};

export default Filtro;
