import React from "react";

const ListagemProcessos: React.FC = () => {
  return (
    <section>
      <div className="card">
        <div className="card-body"></div>
      </div>
    </section>
  );
};

export default ListagemProcessos;
