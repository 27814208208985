import React from 'react';

import { Row, Col, Label, FormGroup } from 'reactstrap';
import Data from 'src/components/Form/Data';
import { useGerenciarPessoaContext } from '../GerenciarPessoaContext';

const DadosPLDFT: React.FC = () => {
  const { form } = useGerenciarPessoaContext();

  const exibirEnvioArquivoPLDFT =
    form.watch('nao_assina') ||
    form.watch('interdicao_outros') ||
    form.watch('falecido');
  const ehPessoaExpostaPoliticamente = form.watch('exposta_politicamente');

  return (
    <>
      <Row>
        <Col md={4}>
          <div className="checkbox checkbox-primary ml-1">
            <input
              type="checkbox"
              id="checkboxNaoSabeAssinar"
              {...form.register('nao_assina')}
            />
            <label htmlFor="checkboxNaoSabeAssinar">
              Não sabe (ou não pode) assinar
            </label>
          </div>
        </Col>
        <Col md={4}>
          <div className="checkbox checkbox-primary ml-1">
            <input
              type="checkbox"
              id="checkboxInterdicaoOutros"
              {...form.register('interdicao_outros')}
            />
            <label htmlFor="checkboxInterdicaoOutros">
              Interdição e outros
            </label>
          </div>
        </Col>
        <Col md={4}>
          <div className="checkbox checkbox-primary ml-1">
            <input
              type="checkbox"
              id="checkboxFalecido"
              {...form.register('falecido')}
            />
            <label htmlFor="checkboxFalecido">Falecido</label>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={4}>
          <div className="checkbox checkbox-primary ml-1">
            <input
              type="checkbox"
              id="checkboxAcusadaTerrorismo"
              {...form.register('investigada_ou_acusada_de_terrorismo')}
            />
            <label htmlFor="checkboxAcusadaTerrorismo">
              Pessoa investigada ou acusada de terrorismo
            </label>
          </div>
        </Col>
        <Col md={4}>
          <div className="checkbox checkbox-primary ml-1">
            <input
              type="checkbox"
              id="checkboxPep"
              {...form.register('exposta_politicamente')}
            />
            <label htmlFor="checkboxPep">Pessoa exposta politicamente</label>
          </div>
        </Col>
      </Row>
      {exibirEnvioArquivoPLDFT && (
        <>
          <hr />
          <h5>Arquivo</h5>
          <Row className="mt-2">
            <Col md={6}>
              <FormGroup>
                <Label htmlFor="pldftArquivo">Arquivo</Label>
                <input
                  type="file"
                  name="arquivo"
                  id="pldftArquivo"
                  accept=".jpg, .png, .jpeg, .pdf"
                  className="form-control"
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      {ehPessoaExpostaPoliticamente && (
        <>
          <hr />
          <h5>Pessoa exposta politicamente</h5>
          <Row className="mt-2">
            <Col md={3}>
              <FormGroup>
                <Label className="font-weight-bold">
                  Data de início do mandato
                </Label>
                <Data
                  valorInicial={form.getValues('pep_data_inicio_mandato')!}
                  onDataAtualizada={(data) => {
                    form.setValue('pep_data_fim_mandato', data);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label className="font-weight-bold">
                  Data de fim do mandato
                </Label>
                <Data
                  valorInicial={form.getValues('pep_data_fim_mandato')!}
                  onDataAtualizada={(data) =>
                    form.setValue('pep_data_fim_mandato', data)
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label className="font-weight-bold">Data de carência</Label>
                <Data
                  valorInicial={form.getValues('pep_data_fim_carencia')!}
                  onDataAtualizada={(data) =>
                    form.setValue('pep_data_fim_carencia', data)
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label className="font-weight-bold">Cargo</Label>
                <input
                  type="text"
                  className="form-control"
                  {...form.register('pep_cargo')}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      <hr />
      <Row className="mt-2">
        <Col>
          <FormGroup>
            <Label className="font-weight-bold">Nome do Pai</Label>
            <input
              type="text"
              className="form-control"
              {...form.register('nome_pai')}
              placeholder="obs.: Insira o nome completo, sem abreviações"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label className="font-weight-bold">Nome da Mãe</Label>
            <input
              type="text"
              {...form.register('nome_mae')}
              className="form-control"
              placeholder="obs.: Insira o nome completo, sem abreviações"
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default DadosPLDFT;
