import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import { Processo } from '../../entidades/Processo';

export default async function excluirAnexoProcesso(
  processo: Processo,
  anexoId: number
): Promise<IResultadoRequisicao<Andamento>> {
  try {
    const resultado: Andamento = await api().delete(
      `/processos/${processo.id}/anexo/${anexoId}`
    );
    return { resultado };
  } catch (error) {
    return { error };
  }
}
