import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import {
  pegarDenominacaoPessoa,
  Pessoa,
} from 'src/modulos/pessoas/entidades/Pessoa';
import {
  pegarEstadoCivil,
  PessoaFisica as EntidadePessoaFisica,
} from 'src/modulos/pessoas/entidades/PessoaFisica';

type Props = {
  pessoa: EntidadePessoaFisica;
};

const PessoaFisica: React.FC<Props> = ({ pessoa }) => {
  return (
    <Row className="mt-5">
      <Col xs={6}>
        <Table className="table table-bordered">
          <tbody>
            <tr>
              <td className="bg-light font-weight-bold">Controle</td>
              <td>{pessoa.controle}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">Nome</td>
              <td>
                {pegarDenominacaoPessoa(pessoa)} ({pessoa.profissao})
              </td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">Data de Nascimento</td>
              <td>{pessoa.data_nascimento}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">RG</td>
              <td>
                {pessoa.rg} - {pessoa.rg_orgao_emissor}
              </td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">CPF</td>
              <td>{pessoa.cpf}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">
                Nacionalidade (País)
              </td>
              <td>{pessoa.nacionalidade_nome}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">Naturalidade</td>
              <td>{pessoa.naturalidade_nome}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">Pai</td>
              <td>{pessoa.nome_pai}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">Mãe</td>
              <td>{pessoa.nome_mae}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">Estado Civil</td>
              <td>{pegarEstadoCivil(pessoa)}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">Cônjuge</td>
              <td>{pessoa.conjuge_nome}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Col xs={6}>
        <Table className="table table-bordered">
          <tbody>
            <tr>
              <td className="bg-light font-weight-bold">Cidade</td>
              <td>{pessoa.cidade_nome}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">Endereço</td>
              <td>{pessoa.endereco}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">Endereço comercial</td>
              <td>{pessoa.endereco_comercial}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">Bairro</td>
              <td>{pessoa.bairro}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">CEP</td>
              <td>{pessoa.cep}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">E-mail</td>
              <td>{pessoa.email}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">E-mail comercial</td>
              <td>{pessoa.email_comercial}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">Telefone fixo</td>
              <td>{pessoa.telefone_fixo}</td>
            </tr>
            <tr>
              <td className="bg-light font-weight-bold">WhatsApp</td>
              <td>{pessoa.whatsapp}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default PessoaFisica;
