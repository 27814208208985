import { ErroRegraNegocio } from "src/entidades/ErroRegraNegocio";
import api from "../../api";
import { FinanceiroConfiguracao } from "../../entidades/FinanceiroConfiguracao";

const editarConfiguracao = async (
  params: Partial<FinanceiroConfiguracao>
): Promise<{
  resultado?: FinanceiroConfiguracao;
  erro?: ErroRegraNegocio;
}> => {
  try {
    const resultado: FinanceiroConfiguracao = await api().post("/configuracao/editar", params);
    return { resultado };
  } catch (error) {
    const erro = {
      mensagem: "Falha ao editar configuração",
    };
    return { erro };
  }
};

export default editarConfiguracao;
