import React, { useContext, useState } from 'react';
import AutenticacaoContext from 'src/contexts/Autenticacao';
import { FiltroProcessos } from '../entidades/FiltroProcessos';

interface ListagemProcessosContextProps {
  filtro: FiltroProcessos;
  setFiltro: React.Dispatch<React.SetStateAction<FiltroProcessos>>;
}

const ListagemProcessosContext =
  React.createContext<ListagemProcessosContextProps>(
    {} as ListagemProcessosContextProps
  );

export const ListagemProcessosProvider: React.FC = ({ children }) => {
  const { usuario } = useContext(AutenticacaoContext);
  const [filtro, setFiltro] = useState<FiltroProcessos>({
    preposto: [usuario],
    funcao: ['EXECUTOR'],
  } as FiltroProcessos);
  return (
    <ListagemProcessosContext.Provider
      value={{
        filtro,
        setFiltro,
      }}
    >
      {children}
    </ListagemProcessosContext.Provider>
  );
};

export default ListagemProcessosContext;
