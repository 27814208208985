import { Route } from 'react-router-dom';
import { AutenticacaoProvider } from 'src/contexts/Autenticacao';
import Layout from '../layout';
import FinalizarSelo from '../paginas/FinalizarSelo';
import GerarSelo from '../paginas/GerarSelo';
import ListagemSelos from '../paginas/ListagemSelos';
import VisualizarSelo from '../paginas/VisualizarSelo';

export default (
  <Route
    path="/selos"
    element={
      <AutenticacaoProvider>
        <Layout />
      </AutenticacaoProvider>
    }
  >
    <Route index element={<ListagemSelos />} />
    <Route path=":idProtocolo" element={<GerarSelo />} />
    <Route path="visualizar/:hash" element={<VisualizarSelo />} />
    <Route path="finalizar-selo/:hash" element={<FinalizarSelo />} />
  </Route>
);
