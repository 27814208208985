import clsx from 'clsx';
import React from 'react';
import { RecolhidoOrcamento } from 'src/modulos/financeiro/entidades/RecolhidoOrcamento';
import formatarDinheiro from 'src/util/formatarDinheiro';

type Props = {
  recolhido: RecolhidoOrcamento;
};
const DetalheRecolhido: React.FC<Props> = ({ recolhido }) => {
  const descricao =
    recolhido.tipo === 'DEVOLVER' ? 'Recolhido a maior' : 'Recolhido a menor';
  return (
    <div className="d-flex justify-content-between py-2">
      <span className="flex-fill">Saldo devedor ({descricao})</span>
      <span
        className={clsx({
          'text-danger': recolhido.tipo === 'RECEBER',
          'text-info': recolhido.tipo === 'DEVOLVER',
        })}
      >
        <b>
          {formatarDinheiro(
            recolhido.tipo === 'DEVOLVER' ? recolhido.valor : -recolhido.valor
          )}
        </b>
      </span>
    </div>
  );
};

export default DetalheRecolhido;
