import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../api';
import { Pasta } from '../entidades/Pasta';

export default async function listarPastasAcervo(
  caminho?: string
): Promise<IResultadoRequisicao<Pasta[]>> {
  try {
    const resultado: Pasta[] = await api()
      .get(`pasta${caminho ? `/${caminho}` : ''}`)
      .then((data: { data: Pasta[] }) => data.data);
    return { resultado };
  } catch (error) {
    return { error };
  }
}
