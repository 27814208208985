import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Collapse, Table } from 'reactstrap';
import { ServicoAto } from 'src/modulos/financeiro/entidades/ServicoAto';
import formatarDinheiro from 'src/util/formatarDinheiro';

type Props = {
  servico: ServicoAto;
  aberturaExterna: boolean;
};

const DetalheServico: React.FC<Props> = ({
  servico,
  aberturaExterna = false,
}) => {
  const [mostrar, setMostrar] = useState(false);

  const alternarDetalhes = () => {
    setMostrar((atual) => !atual);
  };

  useEffect(() => {
    setMostrar(aberturaExterna);
  }, [aberturaExterna]);

  return (
    <>
      <div
        className="d-flex align-items-center border-bottom py-2 cursor-pointer"
        onClick={alternarDetalhes}
      >
        {mostrar ? (
          <FiChevronUp className="mr-2" />
        ) : (
          <FiChevronDown className="mr-2" />
        )}
        <span className="flex-fill">
          <b>{servico.descricao}</b>
        </span>
        <span className="mr-3">
          <b>{formatarDinheiro(servico.valor)}</b>
        </span>
      </div>
      <Collapse isOpen={mostrar}>
        <Table>
          <tbody>
            <tr>
              <td className="w-100">Emolumentos</td>
              <td></td>
              <td></td>
              <td>{formatarDinheiro(servico.valor_cartorio)}</td>
            </tr>
            <tr>
              <td className="w-100">Taxa FSE</td>
              <td></td>
              <td></td>
              <td>{formatarDinheiro(servico.fundo_eletronizacao)}</td>
            </tr>
            <tr>
              <td className="w-100">TFJ</td>
              <td></td>
              <td></td>
              <td>{formatarDinheiro(servico.tfj)}</td>
            </tr>
            <tr>
              <td className="w-100">Funcivil</td>
              <td></td>
              <td></td>
              <td>{formatarDinheiro(servico.funcivil)}</td>
            </tr>
            <tr>
              <td className="w-100">ISS</td>
              <td></td>
              <td></td>
              <td>{formatarDinheiro(servico.iss)}</td>
            </tr>
          </tbody>
        </Table>
      </Collapse>
    </>
  );
};

export default DetalheServico;
