import React from 'react';
import { BsSearch } from 'react-icons/bs';
import buscarPessoas from 'src/modulos/pessoas/casos-de-uso/pessoa/buscarPessoaPorCpfCnpj';
import { Pessoa } from 'src/modulos/pessoas/entidades/Pessoa';
import ItemResultadoBusca from './ItemResultadoBusca';
import css from './style.module.css';
import { Card } from 'reactstrap';

const Buscar: React.FC = () => {
  const [processando, setProcessando] = React.useState(false);
  const [resultadoBusca, setResultadoBusca] = React.useState<Pessoa[] | any>(
    []
  );

  const pesquisarPessoas = async (
    evento: React.ChangeEvent<HTMLInputElement>
  ) => {
    const termo: string = evento.target.value;

    setProcessando(true);
    const { resultado } = await buscarPessoas(termo);
    if (resultado) {
      setResultadoBusca(resultado);
    }
    setProcessando(false);
  };

  return (
    <div className="app-search position-relative">
      <form>
        <div className="input-group">
          <input
            type="text"
            className="form-control w-100"
            placeholder="Pesquisar pessoas cadastradas"
            onChange={pesquisarPessoas}
          />
          {!processando ? <BsSearch /> : 'Processando'}
        </div>
      </form>
      <div className={css.busca}>
        {/* {resultadoBusca.map((item: Pessoa) => {
          return <ItemResultadoBusca pesssoa={item} />;
        })} */}
      </div>
    </div>
  );
};

export default Buscar;
