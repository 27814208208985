import { useBoolean } from 'ahooks';
import React, { useContext } from 'react';
import BlockUi from 'react-block-ui';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Alert, Button, FormGroup, Label } from 'reactstrap';
import { gerarProtocolo } from 'src/modulos/aplicacao/casos-de-uso/protocolo';
import { pegarDenominacaoPessoa } from 'src/modulos/pessoas/entidades/Pessoa';
import adicionarProtocoloProcesso from 'src/modulos/processos/casos-de-uso/processo/adicionarProtocoloProcesso';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import { pessoaEhApresentante } from 'src/modulos/processos/entidades/PessoaProcesso';

const naturezaTitulo = [
  {
    value: 1,
    label: 'Registro',
  },
  {
    value: 2,
    label: 'Averbação',
  },
];

type Props = {
  onSalvar(): void;
  onCancelar(): void;
};

const FormularioRCPN: React.FC<Props> = ({ onSalvar, onCancelar }) => {
  const { processo, onProtocoloAdicionado } = useContext(ProcessoContext);
  const [processando, { toggle }] = useBoolean(false);

  const { getValues, setValue } = useForm();

  const apresentanteProcesso = processo.pessoas.find(pessoaEhApresentante);

  const salvar = async () => {
    toggle();
    const dados = getValues();
    const protocolo = await gerarProtocolo({
      especialidade: 'RCPN',
      pessoa_id: apresentanteProcesso?.id,
      ...dados,
    });
    if (protocolo.resultado) {
      const protocoloProcesso = await adicionarProtocoloProcesso(processo, {
        especialidade: protocolo.resultado.especialidade,
        data: protocolo.resultado?.created_at,
        codigo: `${protocolo.resultado.especialidade}-${protocolo.resultado?.numero}`,
        numero: protocolo.resultado?.numero,
        selo_anoreg_id: protocolo.resultado?.selo_anoreg?.id,
      });
      if (protocoloProcesso.resultado) {
        onSalvar();
        onProtocoloAdicionado(
          protocoloProcesso.resultado.protocolo,
          protocoloProcesso.resultado.andamento
        );
        toggle();

        return;
      }
      if (protocoloProcesso.error) {
        toast.error(protocoloProcesso.error.mensagem);
      }
    } else {
      toast.error(protocolo.error.mensagem);
    }
    toggle();
  };

  return (
    <div className="py-2">
      <BlockUi blocking={processando}>
        <FormGroup>
          <Label>Natureza</Label>
          <Select
            options={naturezaTitulo}
            placeholder="Selecione"
            onChange={(valor) =>
              valor && setValue('natureza_titulo', valor.value)
            }
          />
        </FormGroup>
        {apresentanteProcesso && (
          <FormGroup>
            <Label>Apresentante</Label>
            <span className="d-block">
              {pegarDenominacaoPessoa(apresentanteProcesso)}
            </span>
          </FormGroup>
        )}
        {!apresentanteProcesso && (
          <Alert color="warning">
            Adicione o apresentante para gerar o protocolo.
          </Alert>
        )}
        <div className="d-flex justify-content-end">
          <Button color="soft-success" className="mr-2" onClick={onCancelar}>
            Cancelar
          </Button>
          <Button
            color="primary"
            className="px-3"
            onClick={salvar}
            disabled={!apresentanteProcesso}
          >
            Gerar
          </Button>
        </div>
      </BlockUi>
    </div>
  );
};

export default FormularioRCPN;
