import dayjs from 'dayjs';
import { ChecklistItem } from './ChecklistItem';
import { Processo } from './Processo';

interface ProcessoItemChecklistPivot extends Processo {
  pivot: {
    item_checklist_id: number;
    processo_id: number;
    concluido: number;
    usuario_id: number;
    prazo: string;
    created_at: string;
    updated_at: string;
  };
}

export interface ChecklistItemProcesso extends ChecklistItem {
  processos: ProcessoItemChecklistPivot[];
}

export const itemChecklistEstahConcluido = (
  itemChecklist: ChecklistItemProcesso
) => {
  return itemChecklist.processos[0].pivot.concluido === 1;
};

export const pegarPrazoItemChecklist = (
  itemChecklist: ChecklistItemProcesso
) => {
  return dayjs(itemChecklist.processos[0].pivot.prazo).format('DD/MM/YYYY');
};

export const pegarCorPrazoItemChecklist = (
  itemChecklist: ChecklistItemProcesso
) => {
  if (itemChecklistEstahConcluido(itemChecklist)) {
    return 'success';
  }
  if (
    dayjs(itemChecklist.processos[0].pivot.prazo).isBefore(
      dayjs().subtract(1, 'day')
    )
  ) {
    return 'danger';
  }
  return 'soft-secondary';
};
