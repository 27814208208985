import { ErroRegraNegocio } from "src/entidades/ErroRegraNegocio";
import api from "../../api";
import { Taxa } from "../../entidades/Taxa";

const cadastrarTaxa = async (
  params: Partial<Taxa>
): Promise<{
  resultado?: Taxa;
  erro?: ErroRegraNegocio;
}> => {
  try {
    const resultado: Taxa = await api().post("/taxa/cadastrar", params);
    return { resultado };
  } catch (error) {
    const erro = {
      mensagem: "Falha ao cadastrar taxa",
    };
    return { erro };
  }
};

export default cadastrarTaxa;
