import React from 'react';
import Breadcrumb from '../../../../../components/Breadcrumb';
import {
  InputGroup,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import { FiPlus, FiEye, FiX } from 'react-icons/fi';

interface atividadeInterface {
  titulo: string;
  prazo: number;
}

const linksBreadcrumb = [
  {
    url: '/',
    texto: 'Controle de Processos',
  },
  {
    url: '/processos/configuracoes/checklists',
    texto: 'Checklists',
  },
  {
    texto: 'Editar checklist',
  },
];

const GerenciarChecklist: React.FC = () => {
  const [atividades, setAtividades] = React.useState<atividadeInterface[]>([]);

  const criarNovaAtividade = () => {
    setAtividades((atividades) => [
      ...atividades,
      {
        titulo: `Atividade ${atividades.length + 1}`,
        prazo: 1,
      },
    ]);
  };

  return (
    <Row className="d-flex justify-content-center">
      <Col className="p-4" sm={8}>
        <Breadcrumb links={linksBreadcrumb} />
        <Card>
          <CardBody>
            <Col className="p-4">
              <Row className="justify-content-between">
                <Row>
                  <h4>Especialidades</h4>
                  <select className="custom-select">
                    <option value="Notas">Notas</option>
                  </select>
                </Row>

                <Col className="ml-5">
                  <Row>
                    <h4>Título da Checklist</h4>
                    <InputGroup>
                      <Input placeholder="ex.: Serviços gerais"></Input>
                    </InputGroup>
                  </Row>
                </Col>
              </Row>
            </Col>

            <hr />

            <Col>
              <Row className="justify-content-between">
                <h4>Atividades</h4>

                <div className="d-flex">
                  <Button color="soft-primary" className="mr-2">
                    <FiEye className="mr-2" />
                    Ver todas as subatividades
                  </Button>

                  <Button color="soft-primary" onClick={criarNovaAtividade}>
                    <FiPlus className="mr-2" />
                    Adicionar atividade
                  </Button>
                </div>
              </Row>
            </Col>

            <Col>
              <Card>
                <CardBody>
                  {atividades.map((atividade) => (
                    <Row className="justify-content-between mt-3">
                      <Col sm={8}>
                        <b>
                          <p>{atividade.titulo}</p>
                        </b>
                        <InputGroup>
                          <Input placeholder="Descrição da atividade"></Input>
                        </InputGroup>
                      </Col>

                      <Col className="ml-2" sm={3}>
                        <Row className="h-75">
                          <b>
                            <p>Prazo em dias</p>
                          </b>
                          <div className="input-busca-div d-flex">
                            <input
                              type="text"
                              className="form-control"
                              value={atividade.prazo}
                            />

                            <div className="d-flex">
                              <Button color="primary" className="mr-2">
                                -
                              </Button>
                              <Button color="primary">+</Button>
                            </div>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                </CardBody>
              </Card>
            </Col>

            <hr />

            <Col>
              <Row>
                <h5>Observações:</h5>
              </Row>

              <div className="d-flex justify-content-between">
                <Col sm={6} className="d-flex justify-content-between">
                  <Row>
                    <p className="text-left">
                      - As rotinas serão exibidas no processo na sequência em
                      que estão acima listadas; - Os prazos de cada atividade
                      contam a partir do prazo da atividade anterior (ex:
                      Atividade 02 começa a contar o prazo a partir do prazo da
                      Atividade 01).
                    </p>
                  </Row>
                </Col>

                <Row>
                  <div>
                    <Button color="soft-success" className="mr-2">
                      Cancelar
                    </Button>

                    <Button color="primary">Criar checklist</Button>
                  </div>
                </Row>
              </div>
            </Col>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default GerenciarChecklist;
