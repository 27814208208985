import {
  Etiqueta,
  pegarCorEtiqueta,
} from 'src/modulos/processos/entidades/Etiqueta';
import css from './style.module.css';

type Props = {
  etiquetas?: Etiqueta[];
};

const Etiquetas: React.FC<Props> = ({ etiquetas }) => {
  return (
    <div className="w-100 d-block mb-2">
      {etiquetas?.map((item) => {
        return (
          <div
            className={css.etiqueta}
            key={item.id}
            style={pegarCorEtiqueta(item)}
          ></div>
        );
      })}
    </div>
  );
};

export default Etiquetas;
