import React, { useContext, useState } from 'react';
import { Preposto } from 'src/modulos/processos/entidades/Preposto';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { Row } from 'reactstrap';
import AdicionarPreposto from './AdicionarPreposto';
import excluirPrepostoProcesso from 'src/modulos/processos/casos-de-uso/processo/excluirPrepostoProcesso';
import Dropdown from 'src/components/Dropdown';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import Avatar from 'src/modulos/processos/components/Avatar';
import { toast } from 'react-toastify';
import marcarPrepostoComoExecutor from 'src/modulos/processos/casos-de-uso/processo/marcarPrepostoComoExecutor';
import {
  pegarFuncaoPreposto,
  prepostoEhExecutor,
  prepostoEhRevisor,
  PrepostoProcesso,
} from 'src/modulos/processos/entidades/PrepostoProcesso';
import pegarFotoPessoa from 'src/modulos/pessoas/casos-de-uso/pessoa/pegarFotoPessoa';

const Prepostos: React.FC = () => {
  const {
    processo,
    arquivado,
    onPrepostoRemovidoDoProcesso,
    onPrepostoExecutorAtualizado,
  } = useContext(ProcessoContext);
  const [exibirPopupPreposto, setExibirPopupPreposto] = useState<number | null>(
    null
  );

  const pegarNomePreposto = (preposto: Preposto) => {
    return preposto.pessoa?.fisica?.nome || preposto.nome || '';
  };

  const atualizarExecutorDoProcesso = async (preposto: Preposto) => {
    const { resultado, error } = await marcarPrepostoComoExecutor(
      processo,
      preposto
    );
    if (resultado) {
      onPrepostoExecutorAtualizado(preposto, resultado);
      return;
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  const excluirDoProcesso = async (preposto: PrepostoProcesso) => {
    const { resultado, error } = await excluirPrepostoProcesso(
      processo,
      preposto
    );

    if (resultado) {
      if (prepostoEhExecutor(preposto)) {
        if (processo.prepostos && processo.prepostos.length === 2) {
          await atualizarExecutorDoProcesso(processo.prepostos[1]);
        }
      }

      onPrepostoRemovidoDoProcesso(preposto, resultado);

      return;
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  return (
    <>
      <h5>Prepostos</h5>
      <Row noGutters>
        {processo.prepostos.map((item) => {
          return (
            <div key={item.id}>
              <div
                className="d-flex flex-column mr-3 align-items-center cursor-pointer"
                onClick={() => setExibirPopupPreposto(item.id!)}
              >
                <Avatar
                  url={pegarFotoPessoa(item)}
                  borda={prepostoEhExecutor(item)}
                />
                {pegarFuncaoPreposto(item)}
              </div>
              <Dropdown
                mostrar={exibirPopupPreposto === item.id}
                fechar={() => setExibirPopupPreposto(null)}
              >
                <div className="position-relative">
                  <div className="d-flex px-4 py-2 align-items-center">
                    <Avatar url={pegarFotoPessoa(item)} />
                    <span className="mx-2">
                      {pegarNomePreposto(item)} - {pegarFuncaoPreposto(item)}
                    </span>
                  </div>
                  {!arquivado && (
                    <>
                      {prepostoEhRevisor(item) && (
                        <>
                          <hr />
                          <div
                            className="d-flex align-items-center px-4 cursor-pointer"
                            onClick={() => atualizarExecutorDoProcesso(item)}
                          >
                            <FiEdit className="mr-2" />
                            <span>Marcar como Executor</span>
                          </div>
                        </>
                      )}
                      {prepostoEhExecutor(item) && (
                        <>
                          <hr />
                          <div
                            className="d-flex align-items-center px-4 cursor-pointer"
                            onClick={() => atualizarExecutorDoProcesso(item)}
                          >
                            <FiEdit className="mr-2" />
                            <span>Marcar como Revisor</span>
                          </div>
                        </>
                      )}
                      <hr />
                      <div
                        className="d-flex px-4 align-items-center text-danger cursor-pointer"
                        onClick={() => excluirDoProcesso(item)}
                      >
                        <FiTrash />
                        <span className="ml-2">Remover deste processo</span>
                      </div>
                    </>
                  )}
                </div>
              </Dropdown>
            </div>
          );
        })}

        <AdicionarPreposto />
      </Row>
    </>
  );
};

export default Prepostos;
