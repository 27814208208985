import React from 'react';
import { Outlet } from 'react-router-dom';
import AutenticacaoContext from '../../../contexts/Autenticacao';
import Footer from '../../../layout/Footer';
import Header from './Header';
import MenuLateral from './MenuLateral';

const Layout: React.FC = () => {
  const { carregando } = React.useContext(AutenticacaoContext);

  if (carregando) {
    return <>Carregando...</>;
  }

  return (
    <div id="wrapper">
      <Header />
      <MenuLateral />
      <div className="content-page pb-5">
        <div className="content py-2">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
