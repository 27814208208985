import React from 'react';
import { Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

type props = {
  register: any
}

const ISS: React.FC<props> = ({ register }) => {

  return (
    <div>
      <Row form>
        <Col md={2}>
          <FormGroup>
            <Label for='iss_percentual'>
              Valor
            </Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
              <InputGroupText>%</InputGroupText>
              </InputGroupAddon>
              <input
                {...register('iss_percentual')}
                id="iss_percentual"
                type="text"
                className="form-control"
                placeholder="0,00"
                aria-label="Valor ISS"
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={4}>
          <FormGroup>
            <Label for='iss_calculado_sobre'>
              Calculado sobre
            </Label>
            <InputGroup>
            <input
              {...register('iss_calculado_sobre')}
              id="iss_calculado_sobre"
              type="text"
              className="form-control"
              placeholder="ex.: Emolumentos (-TFJ/FUNCIVIL)"
              aria-label="ISS Calculado sobre"
            />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default ISS;