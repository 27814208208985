import React from 'react';
import { FiLock, FiLogOut, FiUser } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { removeCookie } from 'tiny-cookie';
import Avatar from 'src/modulos/processos/components/Avatar';
import style from './style.module.css';
import AutenticacaoContext from 'src/contexts/Autenticacao';
import pegarFotoPessoa from 'src/modulos/pessoas/casos-de-uso/pessoa/pegarFotoPessoa';
import Dropdown from 'src/components/Dropdown';
import clsx from 'clsx';

const Usuario: React.FC = () => {
  const { usuario } = React.useContext(AutenticacaoContext);

  const [mostrarOpcoesUsuario, setMostrarOpcoesUsuario] = React.useState(false);
  const alternarMostrarOpcoesUsuario = () => {
    setMostrarOpcoesUsuario((mostrar) => !mostrar);
  };

  const bloquearSessao = () => {};

  const sairDaSessao = () => {
    localStorage.clear();
    removeCookie('vc_token');
  };

  return (
    <>
      <li
        className={clsx(
          'position-relative right-bar-toggle nav-link btn-group-lg',
          style['line-height-0']
        )}
        onClick={alternarMostrarOpcoesUsuario}
      >
        <Avatar url={pegarFotoPessoa(usuario)} />
        <Dropdown
          mostrar={mostrarOpcoesUsuario}
          fechar={alternarMostrarOpcoesUsuario}
          titulo="Minha conta"
        >
          <div>
            <div className="px-3 mt-2">
              <Link
                className="d-flex align-items-center py-3"
                to={'/usuarios/perfil'}
              >
                <FiUser />
                <span className="ml-3">Gerenciar minha conta</span>
              </Link>
            </div>
            <div className={style['divisor']}></div>
            <div className="px-3" onClick={bloquearSessao}>
              <span className="d-flex align-items-center py-3">
                <FiLock />
                <span className="ml-3">Bloquear sessão</span>
              </span>
            </div>
            <div className="px-3">
              <Link
                className="d-flex align-items-center py-3"
                to={'/entrar'}
                onClick={sairDaSessao}
              >
                <FiLogOut />
                <span className="ml-3">Sair</span>
              </Link>
            </div>
          </div>
        </Dropdown>
      </li>
    </>
  );
};

export default Usuario;
