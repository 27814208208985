import clsx from 'clsx';
import React from 'react';
import { Badge } from 'reactstrap';

type Props = {
  situacao: string;
};

const SituacaoOrcamento: React.FC<Props> = ({ situacao }) => {
  const pegarTitulo = () => {
    switch (situacao) {
      case 'NENHUM':
        return 'Não recolhido';
      case 'TOTAL':
        return 'Recolhido';
      case 'PARCIAL':
        return 'Recolhido a menor';
      case 'MAIOR':
        return 'Recolhido a maior';
    }
  };

  const pegarCor = () => {
    switch (situacao) {
      case 'NENHUM':
        return 'soft-danger';
      case 'TOTAL':
        return 'soft-success';
      case 'PARCIAL':
        return 'soft-warning';
      case 'MAIOR':
        return 'soft-info';
    }
  };

  const pegarCorBorda = () => {
    switch (situacao) {
      case 'NENHUM':
        return 'border-danger';
      case 'TOTAL':
        return 'border-success';
      case 'PARCIAL':
        return 'border-warning';
      case 'MAIOR':
        return 'border-info';
    }
  };

  return (
    <Badge
      color={pegarCor()}
      className={clsx('border py-1 px-3', pegarCorBorda())}
    >
      {pegarTitulo()}
    </Badge>
  );
};

export default SituacaoOrcamento;
