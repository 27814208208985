import React from "react";

type Props = {
  carregando: boolean;
};

const CarregamentoConteudo: React.FC<Props> = ({ children, carregando }) => {
  return carregando ? <div>Carregando...</div> : <>{children}</>;
};

export default CarregamentoConteudo;
