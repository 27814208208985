import { Row, Col, Card, CardBody, Button, Table, Badge } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import { BsArrowDownUp } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { FiPlus, FiCheckSquare, FiXSquare } from 'react-icons/fi';
import { HiOutlinePencilAlt } from 'react-icons/hi';

import avatar from 'src/assets/images/users/avatar-1.jpg';

const linksBreadcrumb = [
  {
    url: '/administrativo',
    texto: 'Início ',
  },
  {
    texto: 'Contratos',
  },
];

const PapeisEPermissoes: React.FC = () => {
  return (
    <Row className="d-flex justify-content-center">
      <Col className="p-4" sm={10}>
        <Breadcrumb links={linksBreadcrumb} />
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between">
              <div>
                <b>
                  <h4 className="mb-4">Papéis e Permissões</h4>
                </b>
              </div>

              <div className="d-flex">
                <Link to="/administrativo/usuarios/adicionar">
                  <Button color="primary" className="h-75">
                    <FiPlus className="mr-2" />
                    Adicionar Papel
                  </Button>
                </Link>
              </div>
            </div>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>
                    <div className="d-flex justify-content-between">
                      Nome
                      <BsArrowDownUp />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex justify-content-between">
                      QTD Permissões
                    </div>
                  </th>
                  <th>
                    <div className="d-flex justify-content-between">
                      Criado por
                    </div>
                  </th>
                  <th>Data / Hora</th>
                  <th>Situação</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">Papel XYZ</td>
                  <td className="text-center">30</td>
                  <td className="d-flex justify-content-center align-items-center h-50 w-50">
                    <img
                      src={avatar}
                      alt="imagem de usuário"
                      className="mr-2 card-img h-25 w-25 img-rounded"
                    />
                  </td>
                  <td>16/01/2022 08h 40min</td>
                  <td>
                    <Badge color="soft-success">Ativo</Badge>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Link to="/administrativo/contratos/adicionar">
                        <HiOutlinePencilAlt className="mr-2 text-primary" />
                      </Link>

                      <FiXSquare
                        className="text-danger"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="text-center">Papel XYZ</td>
                  <td className="text-center">30</td>
                  <td className="d-flex justify-content-center align-items-center h-50 w-50">
                    <img
                      src={avatar}
                      alt="imagem de usuário"
                      className="mr-2 card-img h-25 w-25 img-rounded"
                    />
                  </td>
                  <td>16/01/2022 08h 40min</td>
                  <td>
                    <Badge color="soft-danger">Desativado</Badge>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Link to="/administrativo/contratos/adicionar">
                        <HiOutlinePencilAlt className="mr-2 text-primary" />
                      </Link>

                      <FiCheckSquare
                        className="text-success"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default PapeisEPermissoes;
