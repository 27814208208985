import { Andamento } from './Andamento';
import { PessoaProcesso } from './PessoaProcesso';
import { EtiquetaProcesso } from './EtiquetaProcesso';
import { PrepostoProcesso } from './PrepostoProcesso';
import { SeloProcesso } from './SeloProcesso';
import { ChecklistProcesso } from './ChecklistProcesso';
import { ProcessoRelacionado } from './ProcessoRelacionado';
import { Prazo } from './Prazo';
import { ProtocoloProcesso } from './ProtocoloProcesso';

export interface Processo {
  id: number;
  numero: string;
  etapa: string;
  usuario_id: number;
  cartorio_id: number;
  created_at: Date;
  updated_at: Date;
  descricao: string | null;
  prepostos: PrepostoProcesso[];
  pessoas: PessoaProcesso[];
  andamentos: Andamento[];
  selos: SeloProcesso[];
  orcamentos: any[];
  prazos: Prazo[];
  etiquetas: EtiquetaProcesso[];
  anexos: any[];
  protocolos: ProtocoloProcesso[];
  checklists: ChecklistProcesso[];
  processos_relacionados: ProcessoRelacionado[];
  // cartorio:               Cartorio;
}

export const gerarUrlProcesso = (processo: Processo): string => {
  const numero = processo.numero.replace('/', '-');

  return `/${processo.id}/processo/${numero}`;
};

export const pegarApresentante = (
  processo: Processo
): PessoaProcesso | null => {
  if (processo.pessoas.length > 0) {
    const apresentante = processo.pessoas.find(
      (item) => item.pivot.principal === 1
    );
    return apresentante || null;
  }
  return null;
};

export const etiquetaEstahNoProcesso = (
  processo: Processo,
  idEtiqueta: number
): boolean => {
  if (processo.etiquetas.length === 0) return false;
  return processo.etiquetas.filter((item) => item.id === idEtiqueta).length > 0;
};

export const checklistEstahNoProcesso = (
  processo: Processo,
  idChecklist: number
): boolean => {
  if (processo.checklists.length === 0) return false;
  return (
    processo.checklists.filter((item) => item.id === idChecklist).length > 0
  );
};

export const processoEstahRelacionadoAoProcesso = (
  processo: Processo,
  processoRelacionado: Processo
) => {
  return (
    processo.processos_relacionados.findIndex(
      (item) => item.id === processoRelacionado.id
    ) >= 0
  );
};
