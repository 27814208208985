import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import {
  Row,
  Col,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  CustomInput,
  FormGroup,
} from 'reactstrap';

type Props = {
  register: any;
  getValues?: any;
  setValue?: any;
  watch?: any;
};

type SociosArray = {
  nome?: string;
  cpf?: string;
};

const SociosAdministradores: React.FC<Props> = ({
  register,
  getValues,
  setValue,
  watch,
}) => {
  const [modal, setModal] = useState(false);
  const [socios, setSocios] = useState<SociosArray[]>([]);

  const sociosWatch = watch('cpf_socio_valor');

  useEffect(() => {
    setValue('cpf_socio', sociosWatch);
  }, [sociosWatch]);

  const toggle = () => {
    setModal(!modal);
  };

  const adicionarPessoas = () => {
    let cpf = getValues('cpf_socio');
    let nome = getValues('nome_socio');
    setSocios([...socios, { nome, cpf }]);
    console.log('teste');
    setValue('socios', socios);
    toggle();
  };

  return (
    <>
      <p className="font-weight-bold">Ainda em desenvolvimento</p>
      {/*<Row>
        <Col>
          <Label for="cpf_socio_valor">CPF do Sócio/Administrador</Label>
          <Row>
            <Col xs={4}>
              <InputMask
                mask="999.999.999-99"
                id="cpf_socio_valor"
                type="text"
                className="form-control"
                placeholder="Ex.: 000.000.000-00"
                {...register('cpf_socio_valor')}
              />
            </Col>
            <Col xs={4}>
              <Button
                onClick={toggle}
                data-clipboard-action="copy"
                data-clipboard-target="#modelo-completo"
                className="btn btn-soft-primary button"
              >
                + Adicionar
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Table className="table table-striped table-bordered">
            <thead>
              <tr className="font-weight-bold">
                <td>Nome</td>
                <td>CPF</td>
                <td>Função</td>
                <td>Beneficiário Final</td>
                <td>Ações</td>
              </tr>
            </thead>
            <tbody>
              {socios.map((item: any, index: any) => (
                <tr key={index}>
                  <td>{item.nome}</td>
                  <td>{item.cpf}</td>
                  <td>
                    <select
                      id={`titular-proprietatio-${index}`}
                      className="form-control"
                      {...register('genero')}
                    >
                      <option value="Titular/Proprietário">
                        Titular/Proprietário
                      </option>
                    </select>
                  </td>
                  <td>
                    <CustomInput
                      type="checkbox"
                      id={`titular-nao_sabe_assinar-${index}`}
                      label="Sim"
                      inline
                      {...register('nao_sabe_assinar')}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Modal title</ModalHeader>
          <ModalBody>
            <Row form>
              <Col xs={6}>
                <FormGroup>
                  <Label for="cpf_socio" className="font-weight-bold">
                    CPF do Sócio/Administrador
                  </Label>
                  <InputMask
                    readOnly
                    mask="999.999.999-99"
                    id="cpf_socio"
                    type="text"
                    className="form-control"
                    placeholder="Ex.: 000.000.000-00"
                    value={getValues('cpf_socio')}
                    {...register('cpf_socio', {
                      required: 'Obrigatório',
                      setValueAs: (v: String) => v.replace(/\D/g, ''),
                    })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="nome_socio" className="font-weight-bold">
                    Nome completo
                  </Label>
                  <input
                    id="nome_socio"
                    type="text"
                    className="form-control"
                    placeholder="obs.: Insira o nome completo e evite abreviaturas"
                    {...register('nome_socio')}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button className="btn btn-soft-success " onClick={toggle}>
              Cancelar
            </Button>
            <Button color="primary" onClick={adicionarPessoas}>
              Adicionar pessoa
            </Button>{' '}
          </ModalFooter>
        </Modal>
                  </div>*/}
    </>
  );
};

export default SociosAdministradores;
