import React from 'react';
import formatarData from 'src/casos-de-uso/formatacao/formatarData';
import { PagamentoOrcamento } from 'src/modulos/financeiro/entidades/PagamentoOrcamento';
import formatarDinheiro from 'src/util/formatarDinheiro';

type Props = {
  pagamento: PagamentoOrcamento;
};

const DetalhePagamento: React.FC<Props> = ({ pagamento }) => {
  const pegarDescricao = () => {
    let prazo = 'à vista';
    let conector = 'em';
    let forma = 'dinheiro';

    switch (pagamento.prazo) {
      case 'DEPOSITO_CONTA':
        break;
    }
    switch (pagamento.forma) {
      case 'DEPOSITO_CONTA':
        conector = 'via';
        forma = 'Transf./Depósito';
        break;

      default:
        break;
    }
    return `${prazo} ${conector} ${forma}, por ${pagamento.usuario.pessoa.especializacao.nome}`;
  };

  return (
    <div className="d-flex justify-content-between py-2">
      <span className="flex-fill">
        {formatarData(pagamento.data_base)}, {pegarDescricao()}
      </span>
      <span>{formatarDinheiro(pagamento.valor)}</span>
    </div>
  );
};

export default DetalhePagamento;
