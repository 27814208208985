import React, { useContext, useState } from 'react';
import {
  FiChevronDown,
  FiChevronUp,
  FiEdit,
  FiMessageSquare,
  FiTrash,
} from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Button, Collapse } from 'reactstrap';
import formatarDatahora from 'src/casos-de-uso/formatacao/formatarDatahora';
import PopupConfirmacao from 'src/components/PopupConfirmacao';
import pegarTipoArquivo from 'src/modulos/acervo/casos-de-uso/arquivos/pegarTipoArquivo';
import excluirAnexoProcesso from 'src/modulos/processos/casos-de-uso/processo/excluirAnexoProcesso';
import visualizarArquivoProcesso from 'src/modulos/processos/casos-de-uso/processo/visualizarArquivoProcesso';
import IconeTipoArquivo from 'src/modulos/processos/components/IconeTipoArquivo';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import AnexoProcesso from 'src/modulos/processos/entidades/AnexoProcesso';

const ConteudoDoAto: React.FC = () => {
  const { processo, onArquivoExcluido } = useContext(ProcessoContext);
  const [exibirPopupExclusao, setExibirPopupExclusao] = useState<number | null>(
    null
  );
  const [expandido, setExpandido] = React.useState(false);

  const pegarListaDocumentos = () => {
    if (!processo.anexos) {
      return [];
    }
    return processo.anexos?.filter((item) => item.tipo === 'CONTEUDO_DO_ATO');
  };

  const fecharPopupExclusao = () => {
    setExibirPopupExclusao(null);
  };

  const abrirPopupExclusao = (id: number) => {
    setExibirPopupExclusao(id);
  };

  const excluirArquivoProcesso = async (arquivo: AnexoProcesso) => {
    const { resultado, error } = await excluirAnexoProcesso(
      processo,
      arquivo.id!
    );

    if (resultado) {
      toast.success('Arquivo removido do processo');
      onArquivoExcluido(arquivo, resultado);
      setExibirPopupExclusao(null);
      return;
    }

    if (error) {
      toast.error(error.mensagem);
    }
  };

  if (pegarListaDocumentos().length === 0) {
    return <></>;
  }

  return (
    <div className="border rounded p-3">
      <div
        className="d-flex flex-row cursor-pointer"
        onClick={() => setExpandido((atual) => !atual)}
      >
        <span className="flex-fill">Conteúdo do Ato</span>
        {expandido && <FiChevronDown />}
        {!expandido && <FiChevronUp />}
      </div>
      <Collapse isOpen={expandido}>
        {pegarListaDocumentos().map((item) => {
          return (
            <div className="d-flex flex-row my-3" key={item.id}>
              <div
                className="cursor-pointer w-25"
                onClick={() => visualizarArquivoProcesso(item.acervo_id)}
              >
                <IconeTipoArquivo tipo={pegarTipoArquivo(item?.acervo?.mime)} />
              </div>
              <div className="w-75 d-flex flex-column px-3">
                <h5
                  className="cursor-pointer"
                  onClick={() => visualizarArquivoProcesso(item.acervo_id)}
                >
                  {item.titulo}
                </h5>
                <span className="d-block font-size-14">
                  Adicionado em {formatarDatahora(item.criadoEm!)} | Atualizado
                  em {formatarDatahora(item.atualizadoEm!)}
                </span>
                <div className="d-flex flex-row mt-3">
                  <Button color="soft-primary" className="mr-2" disabled>
                    <FiMessageSquare />
                    <span className="ml-2">Comentar</span>
                  </Button>
                  <Button color="soft-primary" className="mr-2" disabled>
                    <FiEdit />
                    <span className="ml-2">Metadados</span>
                  </Button>
                  <div className="position-relative">
                    <Button
                      color="soft-danger"
                      className="mr-2"
                      onClick={() => abrirPopupExclusao(item.id!)}
                    >
                      <FiTrash />
                      <span className="ml-2">Excluir</span>
                    </Button>
                    <PopupConfirmacao
                      mostrar={exibirPopupExclusao === item.id}
                      fechar={fecharPopupExclusao}
                      aoConfirmar={() => {
                        excluirArquivoProcesso(item);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Collapse>
    </div>
  );
};

export default ConteudoDoAto;
