import React, { useContext, useState } from 'react';
import Dropdown from 'src/components/Dropdown';
import { FiDollarSign } from 'react-icons/fi';
import { Button, FormGroup, Label } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';

const AdicionarOrcamento: React.FC = () => {
  const { arquivado } = useContext(ProcessoContext);
  const [mostrarFormulario, setMostrarFormulario] = useState<boolean>(false);

  return (
    <div className="relative">
      <Button
        color="soft-secondary"
        block
        className="d-flex align-items-center"
        disabled={arquivado}
        onClick={() => setMostrarFormulario((mostrar) => !mostrar)}
      >
        <FiDollarSign className="mr-2" />
        <span className="text-azul-secundario">Orçamentos</span>
      </Button>

      <Dropdown
        mostrar={mostrarFormulario}
        fechar={() => setMostrarFormulario((mostrar) => !mostrar)}
        fecharAoClicarExterno={false}
        titulo="Orçamentos"
      >
        <FormGroup>
          <Label>Selecione ou pesquise pelo orçamento</Label>
          <AsyncSelect
            placeholder="Pesquise pelo número Ex.: 000020/2020"
            options={[]}
          />
        </FormGroup>
      </Dropdown>
    </div>
  );
};

export default AdicionarOrcamento;
