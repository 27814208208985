import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FiPlus, FiEdit, FiTrash } from 'react-icons/fi';
import {
  Card,
  CardBody,
  Table,
  ButtonGroup,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';

import { Servico } from 'src/modulos/financeiro/entidades/Servico';
import listarServicos from 'src/modulos/financeiro/casos-de-uso/servico/listarServicos';
import cadastrarServico from 'src/modulos/financeiro/casos-de-uso/servico/cadastrarServico';
import atualizarServico from 'src/modulos/financeiro/casos-de-uso/servico/atualizarServico';
import excluirServico from '../../casos-de-uso/servico/excluirServico';

const ListagemServicos: React.FC = () => {
  const [carregando, setCarregando] = React.useState(false);
  const [atualizando, setAtualizando] = React.useState(false);
  const [mostrarModalAlerta, setMostrarModalAlerta] = React.useState(false);
  const [mostrarModalFormulario, setMostrarModalFormulario] =
    React.useState(false);
  const [mostrarModalExcluir, setMostrarModalExcluir] = React.useState(false);
  const [servicos, setServicos] = React.useState<Servico[]>([]);
  const [servicoExcluir, setServicoExcluir] = React.useState<Servico>();

  const { reset, setValue, register, handleSubmit } = useForm<Servico>();

  useEffect(() => {
    carregarInformacoes();
  }, []);

  const carregarInformacoes = async () => {
    setCarregando(true);
    await carregarServicos();
    setCarregando(false);
  };

  const carregarServicos = async () => {
    await listarServicos().then(({ resultado }) => {
      if (resultado) {
        setServicos(resultado.data);
      }
    });
  };

  const ocultarModalAlerta = () => {
    setMostrarModalAlerta(false);
  };

  const confirmaCadastrarServico = async () => {
    ocultarModalAlerta();
    setMostrarModalFormulario(true);
  };

  const ocultarModalFormulario = () => {
    setMostrarModalFormulario(false);
    setAtualizando(false);
    reset();
  };

  const mostrarModalFormularioEditando = (data: Servico) => {
    setValue('id', data.id);
    setValue('descricao', data.descricao);
    setAtualizando(true);
    setMostrarModalFormulario(true);
  };

  const confirmaSalvarServico: SubmitHandler<Servico> = async (data, e) => {
    e?.preventDefault();

    const { resultado, erro } = atualizando
      ? await atualizarServico(data)
      : await cadastrarServico(data);

    if (resultado) {
      toast.success('Serviço salvo com sucesso!');
      ocultarModalFormulario();
      carregarInformacoes();
    } else {
      toast.error(erro?.mensagem);
    }
  };

  const ocultarModalExcluir = () => {
    setMostrarModalExcluir(false);
    setServicoExcluir(undefined);
  };

  const mostrarModalExcluirServico = async (data: Servico) => {
    setServicoExcluir(data);
    setMostrarModalExcluir(true);
  };

  const confirmaExcluirServico = async () => {
    if (servicoExcluir) {
      const { resultado, erro } = await excluirServico(servicoExcluir);

      if (resultado) {
        toast.success('Serviço excluído com sucesso!');
        ocultarModalExcluir();
        carregarInformacoes();
      } else {
        toast.error(erro?.mensagem);
      }
    }
    setServicoExcluir(undefined);
    setMostrarModalExcluir(false);
  };

  return (
    <>
      <Breadcrumb
        links={[
          { url: '/financeiro', texto: 'Financeiro' },
          { texto: 'Gerenciar Adicionais' },
        ]}
      />
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <h2 className="mb-3 mt-0">Gerenciar Serviços</h2>
            </Col>
            <Col md={6}>
              <Button
                color="primary"
                className="float-right ml-2 mr-2"
                onClick={() => {
                  setMostrarModalAlerta(true);
                }}
              >
                <FiPlus></FiPlus> Cadastrar Adicional
              </Button>
              <Button
                color="soft-primary"
                className="float-right ml-2 mr-2"
                onClick={() => {
                  alert('TODO');
                }}
              >
                <FiPlus></FiPlus> Filtrar por Tipos
              </Button>
            </Col>
          </Row>
          <CarregamentoConteudo carregando={carregando}></CarregamentoConteudo>
          <Table
            className="mb-0"
            disabled={atualizando ? true : false}
            bordered
            striped
          >
            <thead>
              <tr>
                <th className="text-left col-md-7">Descrição</th>
                <th className="text-left col-md-2">Tipo</th>
                <th className="text-left col-md-2">Valor Unitário (R$)</th>
                <th className="text-center col-md-1">Ações</th>
              </tr>
            </thead>
            <tbody>
              {servicos.map((servico, index) => {
                return (
                  <tr key={index}>
                    <td className="text-left col-md-7">{servico.descricao}</td>
                    <td className="text-left col-md-2">R$ 0,00</td>
                    <td className="text-center col-md-1">
                      <ButtonGroup>
                        <Button
                          onClick={() => {
                            mostrarModalFormularioEditando(servico);
                          }}
                          color={'soft-light'}
                          size={'lg'}
                          className="pt-0 pb-0"
                        >
                          <FiEdit className="text-primary" />
                        </Button>
                        <Button
                          onClick={() => {
                            mostrarModalExcluirServico(servico);
                          }}
                          color={'soft-light'}
                          size={'lg'}
                          className="pt-0 pb-0"
                        >
                          <FiTrash className="text-danger" />
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Modal className="modal-dialog-centered" isOpen={mostrarModalAlerta}>
        <form onSubmit={handleSubmit(confirmaCadastrarServico)}>
          <ModalHeader toggle={ocultarModalAlerta}>Atenção!</ModalHeader>
          <ModalBody>
            <h6>
              Antes de cadastrar novos serviços, lembre-se de verificar as
              taxas.
            </h6>
          </ModalBody>
          <ModalFooter>
            <Button color="soft-info" onClick={ocultarModalAlerta}>
              Cancelar
            </Button>
            <Button color="primary" type="submit">
              Cadastrar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      <Modal className="modal-dialog-centered" isOpen={mostrarModalFormulario}>
        <form onSubmit={handleSubmit(confirmaSalvarServico)}>
          <ModalHeader toggle={ocultarModalFormulario}>
            {atualizando ? 'Editar' : 'Cadastrar'} Serviço
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="descricao">Descrição</Label>
                  <input
                    {...register('descricao')}
                    id="descricao"
                    type="text"
                    className="form-control"
                    placeholder="ex.: Servico de impressão"
                    aria-label="Nome da Servico"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="especialidade">Especialidade</Label>
                  <select
                    id="especialidade"
                    className="form-control"
                    aria-label="Especialidade"
                  >
                    <option value="IMOVEIS">Imóveis</option>
                    <option value="PROTESTO">Protesto</option>
                    <option value="RTD">RTD</option>
                    <option value="RCPJ">RCPJ</option>
                    <option value="RCPN">RCPN</option>
                    <option value="EXTRA_NOTARIAL_REGISTRAL">
                      Extra Notarial / Registral
                    </option>
                    <option value="OUTROS">Outros</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="soft-info" onClick={ocultarModalFormulario}>
              Cancelar
            </Button>
            <Button color="primary" type="submit">
              Cadastrar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      <Modal className="modal-dialog-centered" isOpen={mostrarModalExcluir}>
        <form onSubmit={handleSubmit(confirmaExcluirServico)}>
          <ModalHeader toggle={ocultarModalExcluir}>
            Excluir Servico
          </ModalHeader>
          <ModalBody>
            <h2>Confirma excluir {servicoExcluir?.descricao}?</h2>
          </ModalBody>
          <ModalFooter>
            <Button color="soft-info" onClick={ocultarModalExcluir}>
              Cancelar
            </Button>
            <Button color="soft-danger" type="submit">
              Excluir
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default ListagemServicos;
