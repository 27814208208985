import { ErroRegraNegocio } from "src/entidades/ErroRegraNegocio";
import api from "../../api";
import { Grupo } from "../../entidades/Grupo";

const atualizarGrupo = async (
  params: Partial<Grupo>
): Promise<{
  resultado?: Grupo;
  erro?: ErroRegraNegocio;
}> => {
  try {
    const resultado: Grupo = await api().put("/grupo/atualizar", params);
    return { resultado };
  } catch (error) {
    const erro = {
      mensagem: "Falha ao atualizar grupo",
    };
    return { erro };
  }
};

export default atualizarGrupo;
