import currency from 'currency.js';

export default function formatarDinheiro(valor: number | string) {
  return currency(Number(valor)).format({
    decimal: ',',
    precision: 2,
    separator: '.',
    symbol: 'R$',
  });
}
