import api from 'src/modulos/processos/api';
import { Processo } from 'src/modulos/processos/entidades/Processo';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';

export default async function pegarProcessosOrcamento(
  id: number
): Promise<IResultadoRequisicao<Processo[]>> {
  try {
    const resultado: Processo[] = await api().get(`/processos/orcamento/${id}`);
    return { resultado };
  } catch (error) {
    return { error };
  }
}
