import React from 'react';
import IconeFinanceiro from '../../assets/icon-financeiro.png';
import Buscar from './Buscar';
import HeaderTema from 'src/layout/Header';

const Header: React.FC = () => {
  return (
    <HeaderTema
      icone={IconeFinanceiro}
      urlIcone="/"
      componentBusca={<Buscar />}
    />
  );
};

export default Header;
