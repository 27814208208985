import React from 'react';
import { BsSearch } from 'react-icons/bs';
import ItemResultadoBusca from './ItemResultadoBusca';
import css from './style.module.css';

const Buscar: React.FC = () => {
  const [processando, setProcessando] = React.useState(false);
  const [resultadoBusca, setResultadoBusca] = React.useState<[]>([]);

  const pesquisarOrcamentos = async (
    evento: React.ChangeEvent<HTMLInputElement>
  ) => {
    const termo: string = evento.target.value;

    setProcessando(true);
    // const { resultado } = await buscarOrcamentos(termo);
    // if (resultado) {
    //   setResultadoBusca(resultado);
    // }
    setProcessando(false);
  };

  return (
    <div className="app-search position-relative">
      <form>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            style={{ width: '500px' }}
            placeholder="Pesquisar por orçamento. Ex: 000011/2021"
            onChange={pesquisarOrcamentos}
          />
          {!processando ? <BsSearch /> : 'Processando'}
        </div>
      </form>
      <div className={css.busca}>
        {resultadoBusca.map((item) => {
          return <ItemResultadoBusca processo={item} />;
        })}
      </div>
    </div>
  );
};

export default Buscar;
