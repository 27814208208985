import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from 'src/modulos/aplicacao/api';
import { PessoaFisica } from '../../entidades/PessoaFisica';
import { PessoaJuridica } from '../../entidades/PessoaJuridica';

const buscarPessoaPorCpfCnpj = async (
  cpfCnpj: string
): Promise<IResultadoRequisicao<PessoaFisica | PessoaJuridica>> => {
  try {
    const resultado: PessoaFisica | PessoaJuridica = await api().get(
      `/gerencia/pessoa/existe/${cpfCnpj}`
    );
    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default buscarPessoaPorCpfCnpj;
