import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import CpfCnpjInput from 'src/components/Form/CpfCnpjInput';
import {
  pegarDenominacaoPessoa,
  pegarDocumentoPessoa,
  Pessoa,
} from 'src/modulos/pessoas/entidades/Pessoa';
import { useGerenciarPessoaContext } from '../GerenciarPessoaContext';

const VerificacaoCadastro: React.FC = () => {
  const [pessoaEncontrada, setPessoaEncontrada] = useState<Pessoa | null>(null);
  const { edicao, setExibirEtapas, form } = useGerenciarPessoaContext();

  useEffect(() => {});

  return (
    <>
      <Row>
        <Col md={4}>
          <CpfCnpjInput
            valorInicial={pegarDocumentoPessoa(form.getValues())}
            apenasLeitura={edicao}
            buscarPessoa={!edicao}
            onPessoaEncontrada={(pessoa) => {
              if (pessoa === null) {
                setExibirEtapas(true);
              }
              setPessoaEncontrada(pessoa);
            }}
            onValorAtualizado={(valor, tipoCadastro) => {
              setExibirEtapas(false);
            }}
            onCampoLimpo={() => {}}
          />
        </Col>
        <Col md={12} className="mt-2">
          {pessoaEncontrada && (
            <div className="bg-soft-warning rounded text-center p-2">
              <p>
                O cliente <b>{pegarDenominacaoPessoa(pessoaEncontrada)}</b> já
                possui cadastro no cartório.
              </p>
              <Link to={`/pessoas/visualizar/${pessoaEncontrada.id}`}>
                <Button color="warning">Visualizar cadastro do cliente</Button>
              </Link>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default VerificacaoCadastro;
