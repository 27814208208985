import clsx from 'clsx';
import React, { useContext } from 'react';
import { FiArchive } from 'react-icons/fi';
import formatarDatahora from 'src/casos-de-uso/formatacao/formatarDatahora';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import css from './style.module.css';

const Arquivado: React.FC = () => {
  const { processo, arquivado } = useContext(ProcessoContext);

  const pegarArquivamento = () => {
    const andamento = processo.andamentos.find(
      (item) => item.descricao!.indexOf('FINALIZADO') >= 0
    );

    if (andamento) {
      return `Processo arquivado em ${formatarDatahora(
        andamento.updated_at!
      )} por ${andamento.usuario?.pessoa?.fisica?.nome}`;
    }
    return '';
  };

  if (!arquivado) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        'd-flex w-100 rounded align-items-center p-3',
        css.arquivado
      )}
    >
      <FiArchive />
      <span className="ml-3">{pegarArquivamento()}</span>
    </div>
  );
};

export default Arquivado;
