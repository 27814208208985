import React from 'react';
import { Link } from 'react-router-dom';

const getVersao = () => {
  let versao = '0.0.0';

  try {
    versao = require('../../../package.json').version;
  } catch (error) {
    versao = '1.0.0';
  }

  return versao;
};

const Footer: React.FC = () => {
  const versao = getVersao();

  return (
    <footer className="footer mt-5 pt-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            &copy; 2012 - 2022 NATO-DIGITAL. Todos os direitos reservados
            <span className="mx-2">|</span>
            <Link to="/politica-privacidade">
              Política de privacidade - LGPD
            </Link>
            <span className="mx-2">|</span>
            <Link to="/politica-privacidade">Termos de uso</Link>
            <span className="mx-2">|</span>
            <small>Versão: {versao}</small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
