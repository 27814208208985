import pegarFotoPessoa from 'src/modulos/pessoas/casos-de-uso/pessoa/pegarFotoPessoa';
import Avatar from 'src/modulos/processos/components/Avatar';
import { Preposto } from 'src/modulos/processos/entidades/Preposto';

type Props = {
  prepostos: Preposto[];
};

const Prepostos: React.FC<Props> = ({ prepostos }) => {
  return (
    <div className="d-flex">
      {prepostos.map((item) => {
        return (
          <div className="mr-2" key={item.id}>
            <Avatar url={pegarFotoPessoa(item)} tamanho="32px" />
          </div>
        );
      })}
    </div>
  );
};

export default Prepostos;
