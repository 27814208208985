import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import { Checklist } from '../../entidades/Checklist';
import { Processo } from '../../entidades/Processo';

type ResultadoApi = {
  andamento: Andamento;
  checklist: Checklist;
};

export default async function excluirChecklistProcesso(
  processo: Processo,
  idChecklist: number
): Promise<IResultadoRequisicao<ResultadoApi>> {
  try {
    const resultado: ResultadoApi = await api().delete(
      `/processos/${processo.id}/checklist/${idChecklist}`
    );
    return { resultado };
  } catch (error) {
    return { error };
  }
}
