import { Route } from 'react-router-dom';
import { AutenticacaoProvider } from 'src/contexts/Autenticacao';
import Layout from '../layout';
import Automatizacao from '../paginas/Configuracoes/Automatizacao';
import Checklist from '../paginas/Configuracoes/Checklist';
import Etiquetas from '../paginas/Configuracoes/Etiquetas';
import GerenciarChecklist from '../paginas/Configuracoes/GerenciarChecklist';
import GerenciarEtiqueta from '../paginas/Configuracoes/GerenciarEtiqueta';
import GerenciarModelo from '../paginas/Configuracoes/GerenciarModelo';
import ModelosDocumentos from '../paginas/Configuracoes/ModelosDocumentos';
import Inicio from '../paginas/Inicio';
import Processo from '../paginas/Processo';
import Relatorios from '../paginas/Relatorios';

export default (
  <Route
    path="/"
    element={
      <AutenticacaoProvider>
        <Layout />
      </AutenticacaoProvider>
    }
  >
    <Route index element={<Inicio />} />
    <Route path=":id/processo/:numero" element={<Processo />} />
    <Route path="processos/configuracoes/checklists" element={<Checklist />} />
    <Route
      path="processos/configuracoes/checklists/adicionar"
      element={<GerenciarChecklist />}
    />
    <Route
      path="processos/configuracoes/checklists/editar/:id"
      element={<GerenciarChecklist />}
    />
    <Route path="processos/configuracoes/etiquetas" element={<Etiquetas />} />
    <Route
      path="processos/configuracoes/etiqueta"
      element={<GerenciarEtiqueta />}
    />
    <Route
      path="processos/configuracoes/etiqueta/:id"
      element={<GerenciarEtiqueta />}
    />
    <Route
      path="processos/configuracoes/modelos"
      element={<ModelosDocumentos />}
    />
    <Route
      path="processos/configuracoes/modelos/adicionar"
      element={<GerenciarModelo />}
    />
    <Route
      path="processos/configuracoes/modelos/editar/:id"
      element={<GerenciarModelo />}
    />
    <Route path="processos/configuracoes/relatorios" element={<Relatorios />} />
    <Route
      path="processos/configuracoes/automatizacao"
      element={<Automatizacao />}
    />
  </Route>
);
