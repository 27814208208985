import { ErroRegraNegocio } from "src/entidades/ErroRegraNegocio";
import api from "../../api";

const listarContas = async (
  params?: any
): Promise<{
  resultado?: any;
  erro?: ErroRegraNegocio;
}> => {
  try {
    const resultado = await api().get("/conta/listar", {
      params
    });

    return { resultado };
  } catch (error) {
    const erro = {
      mensagem: "Falha ao listar contas",
    };
    return { erro };
  }
};

export default listarContas;
