import { ErroRegraNegocio } from "src/entidades/ErroRegraNegocio";
import api from "../../api";
import { Taxa } from "../../entidades/Taxa";

const excluirTaxa = async (
  taxa: Partial<Taxa>
): Promise<{
  resultado?: Taxa;
  erro?: ErroRegraNegocio;
}> => {
  try {
    const resultado: Taxa = await api().delete("/taxa/excluir", { params: {id : taxa.id}});
    return { resultado };
  } catch (error) {
    const erro = {
      mensagem: "Falha ao excluir taxa",
    };
    return { erro };
  }
};

export default excluirTaxa;
