import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import { Processo } from '../../entidades/Processo';
import { ProtocoloProcesso } from '../../entidades/ProtocoloProcesso';

interface IResultadoAdicaoProtocolo {
  andamento: Andamento;
  protocolo: ProtocoloProcesso;
}
export default async function adicionarProtocoloProcesso(
  processo: Processo,
  dados: any
): Promise<IResultadoRequisicao<IResultadoAdicaoProtocolo>> {
  try {
    const resultado: IResultadoAdicaoProtocolo = await api().post(
      `/processos/${processo.id}/protocolo`,
      { protocolo: dados }
    );
    return { resultado };
  } catch (error) {
    return { error };
  }
}
