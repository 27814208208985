import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import { Processo } from '../../entidades/Processo';

const editarDescricaoProcesso = async (
  processo: Processo,
  descricao: string
): Promise<IResultadoRequisicao<Andamento>> => {
  try {
    const resultado: Andamento = await api().put(`/processos/${processo.id}`, {
      descricao,
    });

    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default editarDescricaoProcesso;
