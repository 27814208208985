import React, { useRef, useState } from 'react';
import { FiFolder, FiTrash2 } from 'react-icons/fi';
import { Button } from 'reactstrap';
import formatarDatahora from 'src/casos-de-uso/formatacao/formatarDatahora';
import { Pasta } from 'src/modulos/acervo/entidades/Pasta';

type Props = {
  pasta: Pasta;
  abrirPasta(pasta: Pasta): void;
  onPastaRenomeada?(pasta: Pasta): void;
  onPastaExcluida?(pasta: Pasta): void;
};

const PastaItem: React.FC<Props> = ({
  pasta,
  abrirPasta,
  onPastaExcluida,
  onPastaRenomeada,
}) => {
  const [edicao, setEdicao] = useState(false);
  const renomearRef = useRef<HTMLInputElement>(null);

  const renomearPasta = async () => {
    console.log(renomearRef.current?.value, pasta);
    setEdicao(false);
  };

  if (edicao) {
    return (
      <tr>
        <td colSpan={4}>
          <div className="d-flex align-items-start justify-content-between">
            <FiFolder className="text-primary h3" />
            <div className="d-flex flex-column flex-fill mx-3">
              <input
                ref={renomearRef}
                type="text"
                className="form-control"
                defaultValue={pasta.nome}
              />
              <div className="d-flex justify-content-end mt-2">
                <Button
                  color="soft-success mr-3"
                  onClick={() => setEdicao(false)}
                >
                  Cancelar
                </Button>
                <Button color="primary" onClick={renomearPasta}>
                  Salvar alterações
                </Button>
              </div>
            </div>
            <FiTrash2 className="text-danger h4 mt-2 cursor-pointer" />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <>
      <tr>
        <td>
          <div className="d-flex align-items-center cursor-pointer" onClick={() => {
          abrirPasta(pasta);
        }}>
            <div className="mr-3">
              <FiFolder className="text-danger h3" />
            </div>
            <div>
              <span className="d-block">
                <b>{pasta.nome}</b>
              </span>
            </div>
          </div>
        </td>
        <td>
          <span>{formatarDatahora(pasta.created_at)}</span>
        </td>
        <td>-</td>
        <td>-</td>
      </tr>
    </>
  );
};

export default PastaItem;
