import React, { useState } from 'react';
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Row,
  Col,
  CardBody,
} from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import classnames from 'classnames';
import Comparativo from './Comparativo';
import Individual from './Individual';
import Prepostos from './Prepostos';
import Processos from './Processos';
import '../../assets/styles/processos.css';

const linksBreadcrumb = [
  {
    url: '/',
    texto: 'Controle de Processos ',
  },
  {
    texto: 'Relatórios',
  },
];

const abas = [
  {
    id: 'comparativo',
    titulo: 'Comparativo',
    componente: Comparativo,
  },
  {
    id: 'individugal',
    titulo: 'Individual',
    componente: Individual,
  },
  {
    id: 'prepostos',
    titulo: 'Prepostos',
    componente: Prepostos,
  },
  {
    id: 'processos',
    titulo: 'Processos',
    componente: Processos,
  },
];

const Relatorios: React.FC = () => {
  const [abaAtiva, setAbaAtiva] = useState(abas[0].id);
  const trocarAba = (id: string) => setAbaAtiva(id);

  const abaEstahAtiva = (id: string) => abaAtiva === id;

  return (
    <Container>
      <Row>
        <Col>
          <Breadcrumb links={linksBreadcrumb} />
          <Card>
            <CardBody>
              <Col className="justify-content-between">
                <Row className="justify-content-between">
                  <b>
                    <h4 className="mb-4">Relatórios</h4>
                  </b>
                </Row>
              </Col>

              <Col>
                <div>
                  <Nav tabs>
                    {abas.map((item) => (
                      <NavItem key={item.id}>
                        <NavLink
                          className={classnames('cursor-pointer', {
                            active: abaEstahAtiva(item.id),
                          })}
                          onClick={() => {
                            trocarAba(item.id);
                          }}
                        >
                          {item.titulo}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={abaAtiva}>
                    {abas.map((item) => {
                      const Componente = item.componente;
                      return (
                        <TabPane tabId={item.id}>
                          {abaEstahAtiva(item.id) && <Componente />}
                        </TabPane>
                      );
                    })}
                  </TabContent>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Relatorios;
