import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import { Checklist } from '../../entidades/Checklist';
import { ChecklistProcesso } from '../../entidades/ChecklistProcesso';
import { Processo } from '../../entidades/Processo';

interface IResultadoAdicaoChecklist {
  andamento: Andamento;
  checklist: ChecklistProcesso;
}

export default async function adicionarChecklistProcesso(
  processo: Processo,
  checklist: Checklist
): Promise<
  IResultadoRequisicao<{ andamento: Andamento; checklist: ChecklistProcesso }>
> {
  try {
    const resultado: IResultadoAdicaoChecklist = await api().post(
      `/processos/${processo.id}/checklist/${checklist.id}`
    );

    return {
      resultado,
    };
  } catch (error) {
    return { error };
  }
}
