import api from '../../api';
import { IResultadoRequisicao } from '../../entidades/IResultadoRequisicao';
import Selo, { ISeloApi } from '../../entidades/Selo';

export default async function pegarDadosSelo(
  hash: string
): Promise<IResultadoRequisicao<Selo[]>> {
  try {
    const resultado: ISeloApi[] = await api().get(`selo/${hash}`);
    return { resultado: resultado.map((item) => new Selo(item)) };
  } catch (error) {
    return { error };
  }
}
