import React from 'react';
import { Input, InputGroup, Row, Col, Badge, Table } from 'reactstrap';
import { BsSearch, BsChevronDown, BsArrowDownUp } from 'react-icons/bs';
import { ImFilesEmpty } from 'react-icons/im';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { IoCloseOutline } from 'react-icons/io5';
import { BiPieChartAlt } from 'react-icons/bi';
import { MdOutlineIncompleteCircle } from 'react-icons/md';
import { RiBattery2ChargeFill } from 'react-icons/ri';
import { Chart } from 'react-google-charts';
import avatar from 'src/assets/images/users/avatar-1.jpg';
import '../../../assets/styles/comparativo.css';

export const data = [
  ['Pontuação', 'Gustavo Aires', 'Ludimila Cantarelli'],
  ['Jan', 37.8, 80.8],
  ['Fev', 30.9, 69.5],
  ['Mar', 25.4, 57],
  ['Abr', 11.7, 18.8],
  ['Mai', 11.9, 17.6],
  ['Jun', 8.8, 13.6],
  ['Jul', 7.6, 12.3],
];

const Comparativo: React.FC = () => {
  const [pesquisa, setPesquisa] = React.useState('');

  return (
    <div className="my-8">
      <Row className="align-items-center">
        <Col sm={10}>
          <div className="input-busca-div d-flex">
            <div className="p-2">
              <BsSearch className="font-size-16" />
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Pesquisar por nome ou CPF do preposto"
              value={pesquisa}
              onChange={(e) => {
                setPesquisa(e.target.value);
              }}
            />
          </div>
        </Col>

        <Col>
          <InputGroup className="align-items-center">
            <Input
              addon
              aria-label="Checkbox"
              type="checkbox"
              className="mr-2"
            />
            Selecionar todos
          </InputGroup>
        </Col>
      </Row>

      <div className="mt-2 d-flex">
        <Badge color="primary" className="p-2 mr-2" pill>
          Ludmila Cantarelli <IoCloseOutline className="ml-2" />
        </Badge>

        <Badge color="success" className="p-2 mr-2" pill>
          Gustavo Aires <IoCloseOutline className="ml-2" />
        </Badge>
      </div>

      <hr />

      <div className="d-flex align-items-center">
        Pontuação de
        <Badge color="soft-primary" className="ml-2 p-2 mr-2">
          Janeiro / 2020
          <BsChevronDown className="ml-2" />
        </Badge>
        à
        <Badge color="soft-primary" className="ml-2 p-2">
          Julho / 2020
          <BsChevronDown className="ml-2" />
        </Badge>
      </div>

      <div className="mt-5">
        <Chart chartType="Line" width="100%" height="400px" data={data} />
      </div>

      <hr />

      <div className="d-flex align-items-center">
        Processos de
        <Badge color="soft-primary" className="ml-2 p-2 mr-2">
          Agosto / 2020
          <BsChevronDown className="ml-2" />
        </Badge>
      </div>

      <Table>
        <thead>
          <tr>
            <th className="d-flex justify-content-between align-items-center">
              Preposto <BsArrowDownUp />
            </th>

            <th>
              <ImFilesEmpty /> <BsArrowDownUp className="ml-3" />
            </th>

            <th>
              <RiBattery2ChargeFill className="text-danger" />{' '}
              <BsArrowDownUp className="ml-3" />
            </th>

            <th>
              <MdOutlineIncompleteCircle className="text-warning" />{' '}
              <BsArrowDownUp className="ml-3" />
            </th>

            <th>
              <IoMdCheckmarkCircleOutline className="text-success" />{' '}
              <BsArrowDownUp className="ml-3" />
            </th>

            <th>
              <BiPieChartAlt className="text-primary" />{' '}
              <BsArrowDownUp className="ml-3" />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div>
                <img
                  src={avatar}
                  alt="imagem de usuário"
                  className="card-img img-rounded mr-2 table-img"
                />
                Gustavo Aires
              </div>
            </td>

            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>

          <tr>
            <td>
              <div>
                <img
                  src={avatar}
                  alt="imagem de usuário"
                  className="card-img img-rounded mr-2 table-img"
                />
                Ludimila Cantarelli
              </div>
            </td>

            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default Comparativo;
