import { Row, Col, Card, CardBody, FormGroup, Label, Button } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import Flatpickr from 'react-flatpickr';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/i18n/pt-br';
import { useRef } from 'react';

const linksBreadcrumb = [
  {
    url: '/administrativo',
    texto: 'Início ',
  },
  {
    url: '/administrativo/comunicados',
    texto: 'Comunicados',
  },
  {
    texto: 'Adicionar Comunicado',
  },
];

const AdicionarComunicado: React.FC = () => {
  const editorRef = useRef<Editor>(null);

  return (
    <Row>
      <Col md={12}>
        <Breadcrumb links={linksBreadcrumb} />
        <Card>
          <CardBody>
            <b>
              <h4 className="mb-4">Adicionar Comunicado</h4>
            </b>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Assunto</Label>
                  <input type="text" className="form-control" />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormGroup>
                  <span className="d-block">
                    <i>
                      Obs.: O envio pode ser agendado ao selecionar uma data
                      futura.
                    </i>
                  </span>
                  <Label>Data de envio</Label>
                  <Flatpickr
                    data-enable-time
                    className="form-control"
                    options={{
                      minDate: new Date(),
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h6>Enviar para:</h6>
                <div className="radio radio-primary ml-1">
                  <input
                    type="radio"
                    name="envio"
                    id="radioTitularesSubstitutos"
                  />
                  <label htmlFor="radioTitularesSubstitutos">
                    apenas para titulares e substitutos
                  </label>
                </div>
                <div className="radio radio-primary ml-1 mt-2">
                  <input type="radio" name="envio" id="radioTodos" />
                  <label htmlFor="radioTodos">
                    para todos os usuários ativos
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={12}>
                <h6>Conteúdo</h6>
                <Editor
                  height="500px"
                  initialEditType="wysiwyg"
                  placeholder="Escreva o conteúdo aqui..."
                  ref={editorRef}
                  language="pt-BR"
                  initialValue={''}
                />
              </Col>
            </Row>

            <div className="d-flex justify-content-end mt-3">
              <Button color="soft-success" className="mr-3">
                Cancelar
              </Button>

              <Button color="primary">Adicionar Comunicado</Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AdicionarComunicado;
