import React from "react";
import { Route } from "react-router-dom";
import { AutenticacaoProvider } from "src/contexts/Autenticacao";
import Layout from "../layout";
import Dashboard from "../paginas/Dashboard";

export default (
  <Route
    path="/mosaico"
    element={
      <AutenticacaoProvider>
        <Layout />
      </AutenticacaoProvider>
    }
  >
    <Route index element={<Dashboard />} />
  </Route>
);
