import { useEffect, useContext, useState } from 'react';
import {
  FiArchive,
  FiArrowRight,
  FiMoreVertical,
  FiPlus,
} from 'react-icons/fi';
import { ListGroup, ListGroupItem } from 'reactstrap';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import Dropdown from 'src/components/Dropdown';
import formatarEtapaProcesso from 'src/modulos/processos/casos-de-uso/processo/formatarEtapaProcesso';
import listarProcessosQuadro, {
  IQuadroProcessos,
} from 'src/modulos/processos/casos-de-uso/processos/listarProcessosQuadro';
import ListagemProcessosContext from 'src/modulos/processos/contexts/ListagemProcessosContext';
import { Processo } from 'src/modulos/processos/entidades/Processo';
import Cartao from './Cartao';
import style from './style.module.css';
import { ReactSortable, Sortable, SortableEvent } from 'react-sortablejs';
import atualizarEtapaProcesso from 'src/modulos/processos/casos-de-uso/atualizarEtapaProcesso';

const regrasMoverCartoes: { [key: string]: string[] } = {
  'NOVO/A SER DISTRIBUÍDO': ['NOVO/A SER DISTRIBUÍDO', 'EM ANÁLISE'],
  'EM ANÁLISE': [
    'EM ANÁLISE',
    'NOVO/A SER DISTRIBUÍDO',
    'PROVIDÊNCIAS DO CLIENTE',
  ],
  'PROVIDÊNCIAS DO CLIENTE': [
    'PROVIDÊNCIAS DO CLIENTE',
    'EM ANÁLISE',
    'EM EXECUÇÃO',
  ],
  'EM EXECUÇÃO': [
    'EM EXECUÇÃO',
    'FEITO: A SER ENTREGUE',
    'PROVIDÊNCIAS DO CLIENTE',
  ],
  'FEITO: A SER ENTREGUE': [
    'FEITO: A SER ENTREGUE',
    'EM EXECUÇÃO',
    'FEITO: A SER ARQUIVADO',
  ],
  'FEITO: A SER ARQUIVADO': ['FEITO: A SER ARQUIVADO', 'FEITO: A SER ENTREGUE'],
};

const ListagemQuadro: React.FC = () => {
  const { filtro } = useContext(ListagemProcessosContext);
  const [exibirOpcoesLista, setExibirOpcoesLista] = useState<string | null>(
    null
  );
  const [processando, setProcessando] = useState(true);
  const [quadro, setQuadro] = useState<IQuadroProcessos>({});

  const moverCartao = (processos: Processo[], sortable: Sortable | null) => {
    if (sortable !== null) {
      const etapa = sortable.el.id;
      setQuadro((atual) => {
        atual[etapa] = [...processos];
        return { ...atual };
      });
    }
  };

  const verificarMoverCartao = (
    sortable: Sortable,
    activeSortable: Sortable
  ) => {
    const etapaDe = activeSortable.el.id;
    const etapaPara = sortable.el.id;
    return regrasMoverCartoes[etapaDe].includes(etapaPara);
  };

  const atualizarEtapa = (evento: SortableEvent) => {
    const novaEtapa = evento.to.id;
    const etapaAtual = evento.from.id;
    const processo = quadro[etapaAtual].find(
      (item) => item.id === Number(evento.item.id)
    );
    if (processo) {
      atualizarEtapaProcesso(processo, novaEtapa);
    }
  };

  useEffect(() => {
    setProcessando(true);
    listarProcessosQuadro(filtro).then(({ resultado }) => {
      setProcessando(false);
      if (resultado) {
        setQuadro(resultado);
      }
    });
  }, [filtro]);

  return (
    <section id="quadro-processos" className={style['quadro']}>
      <CarregamentoConteudo carregando={processando}>
        {Object.keys(quadro).map((etapa) => {
          const processos = quadro[etapa];
          return (
            <div className={style['lista']} key={etapa}>
              <div className={style['cabecalho-lista']}>
                <span>
                  {formatarEtapaProcesso({ etapa } as Processo)} (
                  {processos.length})
                </span>
                <div className="position-relative">
                  <FiMoreVertical
                    className="cursor-pointer"
                    onClick={() => setExibirOpcoesLista(etapa)}
                  />
                  <Dropdown
                    mostrar={exibirOpcoesLista === etapa}
                    fechar={() => {
                      setExibirOpcoesLista(null);
                    }}
                    titulo="Ações da lista"
                    alinharEsquerda
                  >
                    <ListGroup className="border-0">
                      <ListGroupItem tag="button" action className="border-0">
                        <FiPlus />
                        <span className="ml-2">Adicionar processo</span>
                      </ListGroupItem>
                      <ListGroupItem tag="button" action className="border-0">
                        <FiArrowRight />
                        <span className="ml-2">
                          Mover todos os processos desta lista para...
                        </span>
                      </ListGroupItem>
                      <ListGroupItem tag="button" action className="border-0">
                        <FiArchive />
                        <span className="ml-2">
                          Arquivar todos processos desta lista
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </Dropdown>
                </div>
              </div>
              <div className={style['container-cartoes']}>
                <div className={style['cartoes']}>
                  <ReactSortable
                    list={quadro[etapa]}
                    setList={moverCartao}
                    onAdd={atualizarEtapa}
                    group={{
                      name: 'processos',
                      put: verificarMoverCartao,
                    }}
                    id={etapa}
                  >
                    {processos.map((item) => {
                      return <Cartao processo={item} key={item.id} />;
                    })}
                  </ReactSortable>
                </div>
              </div>
            </div>
          );
        })}
      </CarregamentoConteudo>
    </section>
  );
};

export default ListagemQuadro;
