import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { FiltroProcessos } from '../../entidades/FiltroProcessos';
import { Processo } from '../../entidades/Processo';

export interface IQuadroProcessos {
  [key: string]: Processo[];
}

const listarProcessosQuadro = async (
  filtro?: FiltroProcessos
): Promise<IResultadoRequisicao<IQuadroProcessos>> => {
  try {
    const params: any = {};
    if (filtro) {
      if (filtro.funcao) {
        params.tipo = filtro.funcao[0];
      }
      if (filtro.preposto) {
        params.usuarios = filtro.preposto[0].id;
      }
    }
    const resultado: IQuadroProcessos = await api().get('/processos', {
      params,
    });

    if ('FINALIZADO' in resultado) {
      delete resultado.FINALIZADO;
    }

    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default listarProcessosQuadro;
