import React, { useContext, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Button, Col, Row } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';
import TemModulo from 'src/components/TemModulo';
import { modulos } from 'src/modulos/aplicacao/util/modulos';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import FormularioImoveis from './FormularioImoveis';
import FormularioNotas from './FormularioNotas';
import FormularioRCPJ from './FormularioRCPJ';
import FormularioRCPN from './FormularioRCPN';

const CriarProtocolo: React.FC = () => {
  const { arquivado } = useContext(ProcessoContext);
  const [exibirForms, setExibirForms] = useState(false);
  const [especialidade, setEspecialidade] = useState<string | null>(null);

  const selecionarEspecialidade = (nome: string) => setEspecialidade(nome);

  const fechar = () => {
    setEspecialidade(null);
    setExibirForms(false);
  };

  const abrir = () => setExibirForms(true);

  const voltar = () => {
    setEspecialidade(null);
  };

  return (
    <div className="position-relative">
      <Button color="soft-primary" onClick={abrir} disabled={arquivado}>
        <FiPlus />
      </Button>
      <Dropdown
        mostrar={exibirForms}
        fechar={fechar}
        voltar={especialidade !== null ? voltar : undefined}
        titulo="Criar um novo protocolo"
        alinharEsquerda
      >
        {especialidade === modulos.NOTAS && (
          <FormularioNotas onSalvar={fechar} onCancelar={voltar} />
        )}
        {especialidade === modulos.IMOVEIS && (
          <FormularioImoveis onSalvar={fechar} onCancelar={voltar} />
        )}
        {especialidade === modulos.RCPJ && (
          <FormularioRCPJ onSalvar={fechar} onCancelar={voltar} />
        )}
        {especialidade === modulos.RCPN && (
          <FormularioRCPN onSalvar={fechar} onCancelar={voltar} />
        )}
        {!especialidade && (
          <>
            <span className="d-block my-2">Escolha uma especialidade</span>
            <Row>
              <TemModulo modulo={modulos.NOTAS}>
                <Col md={4} className="mb-3">
                  <div
                    className="d-flex flex-column align-items-center cursor-pointer"
                    onClick={() => selecionarEspecialidade(modulos.NOTAS)}
                  >
                    <img src="/icones/icone-notas.png" alt="" width={64} />
                    <span className="d-block mt-2">Notas</span>
                  </div>
                </Col>
              </TemModulo>
              <TemModulo modulo={modulos.IMOVEIS}>
                <Col md={4} className="mb-3">
                  <div
                    className="d-flex flex-column align-items-center cursor-pointer"
                    onClick={() => selecionarEspecialidade(modulos.IMOVEIS)}
                  >
                    <img src="/icones/icone-imoveis.png" alt="" width={64} />
                    <span className="d-block mt-2">Imóveis</span>
                  </div>
                </Col>
              </TemModulo>

              <TemModulo modulo={modulos.PROTESTO}>
                <Col md={4} className="mb-3">
                  <div
                    className="d-flex flex-column align-items-center cursor-pointer"
                    onClick={() => selecionarEspecialidade(modulos.PROTESTO)}
                  >
                    <img src="/icones/icone-protesto.png" alt="" width={64} />
                    <span className="d-block mt-2">Protesto</span>
                  </div>
                </Col>
              </TemModulo>

              {/* <TemModulo modulo={modulos.RTD}>
                <Col md={4} className="mb-3">
                  <div
                    className="d-flex flex-column align-items-center cursor-pointer"
                    onClick={() => selecionarEspecialidade(modulos.RTD)}
                  >
                    <img src="/icones/icone-rtd.png" alt="" width={64} />
                    <span className="d-block mt-2">RTD</span>
                  </div>
                </Col>
              </TemModulo> */}

              <TemModulo modulo={modulos.RCPJ}>
                <Col md={4} className="mb-3">
                  <div
                    className="d-flex flex-column align-items-center cursor-pointer"
                    onClick={() => selecionarEspecialidade(modulos.RCPJ)}
                  >
                    <img src="/icones/icone-rcpj.png" alt="" width={64} />
                    <span className="d-block mt-2">RCPJ</span>
                  </div>
                </Col>
              </TemModulo>

              <TemModulo modulo={modulos.RCPN}>
                <Col md={4} className="mb-3">
                  <div
                    className="d-flex flex-column align-items-center cursor-pointer"
                    onClick={() => selecionarEspecialidade(modulos.RCPN)}
                  >
                    <img src="/icones/icone-rcpn.png" alt="" width={64} />
                    <span className="d-block mt-2">RCPN</span>
                  </div>
                </Col>
              </TemModulo>
            </Row>
          </>
        )}
      </Dropdown>
    </div>
  );
};

export default CriarProtocolo;
