import clsx from 'clsx';
import React from 'react';
import { FiExternalLink } from 'react-icons/fi';

import {
  BsFileEarmarkPdfFill,
  BsFileEarmarkWordFill,
  BsFileEarmarkImage,
} from 'react-icons/bs';
import css from './style.module.css';

type Props = {
  tipo: 'pdf' | 'docx' | 'imagem' | 'url';
};

const IconeTipoArquivo: React.FC<Props> = ({ tipo }) => {
  return (
    <div className={clsx(css.container, css.fundo, css[tipo])}>
      {tipo === 'pdf' && <BsFileEarmarkPdfFill className={css['icone']} />}
      {tipo === 'docx' && <BsFileEarmarkWordFill className={css['icone']} />}
      {tipo === 'imagem' && <BsFileEarmarkImage className={css['icone']} />}
      {tipo === 'url' && <FiExternalLink className={css['icone']} />}
    </div>
  );
};

export default IconeTipoArquivo;
