import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { livros } from 'src/modulos/aplicacao/util/livros';
const LivrosRI: React.FC = () => {
  const { register } = useFormContext();
  return (
    <Row>
      <Col md={12}>
        <h5>Termo Inicial dos Livros 1, 2, 3, 4 e 5</h5>

        <span className="text-danger d-block mb-2">
          <b>
            Obs.: O número a ser informado é o correspondente ao número do
            próximo ato a ser realizado.
          </b>
        </span>
        <h5>Livro 1 - Protocolo</h5>
        <input
          type="hidden"
          value="Livro 1 - Protocolo"
          {...register(`${livros.IMOVEIS.LIVRO_1_PROTOCOLO}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="IMOVEIS"
          {...register(`${livros.IMOVEIS.LIVRO_1_PROTOCOLO}.especialidade`)}
        />
        <input
          type="hidden"
          value={300}
          {...register(`${livros.IMOVEIS.LIVRO_1_PROTOCOLO}.folhas_por_livro`)}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_1_PROTOCOLO}.livro_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_1_PROTOCOLO}.folha_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último protocolo</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_1_PROTOCOLO}.numero_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_1_PROTOCOLO}.registros_por_folha`
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>Livro 2 - Registro geral</h5>
        <input
          type="hidden"
          value="Livro 2 - Registro geral"
          {...register(`${livros.IMOVEIS.LIVRO_2_GERAL}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="IMOVEIS"
          {...register(`${livros.IMOVEIS.LIVRO_2_GERAL}.especialidade`)}
        />
        <input
          type="hidden"
          value={300}
          {...register(`${livros.IMOVEIS.LIVRO_2_GERAL}.folhas_por_livro`)}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.IMOVEIS.LIVRO_2_GERAL}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.IMOVEIS.LIVRO_2_GERAL}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último protocolo</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.IMOVEIS.LIVRO_2_GERAL}.numero_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_2_GERAL}.registros_por_folha`
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>Livro 3 - Registro auxiliar</h5>
        <input
          type="hidden"
          value="Livro 3 - Registro auxiliar"
          {...register(
            `${livros.IMOVEIS.LIVRO_3_REGISTRO_AUXILIAR}.nome_exibicao`
          )}
        />
        <input
          type="hidden"
          value="IMOVEIS"
          {...register(
            `${livros.IMOVEIS.LIVRO_3_REGISTRO_AUXILIAR}.especialidade`
          )}
        />
        <input
          type="hidden"
          value={300}
          {...register(
            `${livros.IMOVEIS.LIVRO_3_REGISTRO_AUXILIAR}.folhas_por_livro`
          )}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_3_REGISTRO_AUXILIAR}.livro_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_3_REGISTRO_AUXILIAR}.folha_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último protocolo</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_3_REGISTRO_AUXILIAR}.numero_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_3_REGISTRO_AUXILIAR}.registros_por_folha`
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>Livro 3 - Transcrição das Transmissões</h5>
        <input
          type="hidden"
          value="Livro 3 - Transcrição das Transmissões"
          {...register(`${livros.IMOVEIS.LIVRO_3_TRANSCRICAO}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="IMOVEIS"
          {...register(`${livros.IMOVEIS.LIVRO_3_TRANSCRICAO}.especialidade`)}
        />
        <input
          type="hidden"
          value={300}
          {...register(
            `${livros.IMOVEIS.LIVRO_3_TRANSCRICAO}.folhas_por_livro`
          )}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_3_TRANSCRICAO}.livro_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_3_TRANSCRICAO}.folha_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último protocolo</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_3_TRANSCRICAO}.numero_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_3_TRANSCRICAO}.registros_por_folha`
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>Livro 4 - Indicador real</h5>
        <input
          type="hidden"
          value="Livro 4 - Indicador real"
          {...register(
            `${livros.IMOVEIS.LIVRO_4_INDICADOR_REAL}.nome_exibicao`
          )}
        />
        <input
          type="hidden"
          value="IMOVEIS"
          {...register(
            `${livros.IMOVEIS.LIVRO_4_INDICADOR_REAL}.especialidade`
          )}
        />
        <input
          type="hidden"
          value={300}
          {...register(
            `${livros.IMOVEIS.LIVRO_4_INDICADOR_REAL}.folhas_por_livro`
          )}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_4_INDICADOR_REAL}.livro_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_4_INDICADOR_REAL}.folha_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último protocolo</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_4_INDICADOR_REAL}.numero_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_4_INDICADOR_REAL}.registros_por_folha`
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>Livro 5 - Indicador pessoal</h5>
        <input
          type="hidden"
          value="Livro 5 - Indicador pessoal"
          {...register(
            `${livros.IMOVEIS.LIVRO_5_INDICADOR_PESSOAL}.nome_exibicao`
          )}
        />
        <input
          type="hidden"
          value="IMOVEIS"
          {...register(
            `${livros.IMOVEIS.LIVRO_5_INDICADOR_PESSOAL}.especialidade`
          )}
        />
        <input
          type="hidden"
          value={300}
          {...register(
            `${livros.IMOVEIS.LIVRO_5_INDICADOR_PESSOAL}.folhas_por_livro`
          )}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_5_INDICADOR_PESSOAL}.livro_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_5_INDICADOR_PESSOAL}.folha_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último protocolo</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_5_INDICADOR_PESSOAL}.numero_inicial`
                )}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.IMOVEIS.LIVRO_5_INDICADOR_PESSOAL}.registros_por_folha`
                )}
              />
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LivrosRI;
