import { ErroRegraNegocio } from 'src/entidades/ErroRegraNegocio';
import { Cartorio } from 'src/modulos/aplicacao/entidades/Cartorio';
import api from '../../api';

const listarCartorios = async (): Promise<{
  resultado?: Cartorio[];
  error?: ErroRegraNegocio;
}> => {
  try {
    const resultado: Cartorio[] = await api().get('/cartorio');

    return { resultado };
  } catch (error: ErroRegraNegocio) {
    return { error };
  }
};

export default listarCartorios;
