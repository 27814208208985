export interface IResultadoPaginado<T> {
  current_page: number;
  data: T[];
  first_page_url?: string;
  from?: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string | null;
  to: number;
  total: number;
}

export default class ResultadoPaginado<T> {
  dados: T[];
  paginaAtual: number;
  total: number;

  constructor(resultado: IResultadoPaginado<T>) {
    this.dados = resultado.data;
    this.paginaAtual = resultado.current_page;
    this.total = resultado.total;
  }
}
