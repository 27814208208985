import { Emolumento, IEmolumento } from './Emolumento';

export interface IAto {
  cartorio_id: number;
  codigo: number;
  dados: any[];
  data: string;
  emolumento: Emolumento;
  emolumento_id: number;
  emolumentos: number;
  funcivil: number;
  fundo_eletronizacao: number;
  iss: number;
  tfj: number;
  tipo: string;
  total: number;
  usuario_id: number;
}

export interface ISelo {
  atos: IAto[];
  cartorio_id: number;
  codigo: string;
  codigo_validacao: string;
  created_at: string;
  diferido: boolean;
  enviado_central: boolean;
  gratuito: boolean;
  grupo_impressao: string;
  isento: boolean;
  lote_selo_id: number;
  pago: boolean;
  relatorio: string;
  status: string;
  status_informacoes_central: string;
  tipo: string;
  updated_at: string;
  usuario_id: number;
  _id: string;
}

export interface ISeloApi {
  cartorio_id: number;
  codigo: string;
  codigo_emolumento: string;
  codigo_validacao: string;
  created_at: Date;
  dados: any[];
  deleted_at: null | Date;
  diferido: boolean;
  emolumento: IEmolumento;
  emolumento_id: number;
  emolumentos: number;
  erro_malote: boolean;
  funcivil: number;
  fundo_eletronizacao: number;
  gratuito: boolean;
  grupo_impressao: string;
  hash_malote: null | string;
  id: number;
  isento: boolean;
  iss: number;
  pago: boolean;
  relatorio: null | string;
  relatorio_trace: null | string;
  status: string;
  tfj: number;
  tipo: string;
  total: number;
  updated_at: Date;
}

export default class Selo {
  id: number;
  ato: any[];
  codigo: string;
  codigoValidacao: string;
  emolumentos: number;
  emolumento: Emolumento;
  funcivil: number;
  tfj: number;
  fundo_eletronizacao: number;
  total: number;

  constructor(selo: ISeloApi) {
    this.id = selo.id;
    this.ato = selo.dados;
    this.codigo = selo.codigo;
    this.codigoValidacao = selo.codigo_validacao;
    this.emolumento = new Emolumento(selo.emolumento);
    this.emolumentos = selo.emolumentos;
    this.funcivil = selo.funcivil;
    this.tfj = selo.tfj;
    this.fundo_eletronizacao = selo.fundo_eletronizacao;
    this.total = selo.total;
  }
}
