import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import { Cartorio } from 'src/modulos/aplicacao/entidades/Cartorio';

import listarCartorios from '../../casos-de-uso/cartorio/listarCartorios';
import formatarCNSCartorio from 'src/modulos/aplicacao/casos-de-uso/cartorio/formatarCNSCartorio';

const Cartorios: React.FC = () => {
  const [cartorios, setCartorios] = React.useState<Cartorio[]>([]);

  React.useEffect(() => {
    listarCartorios().then(({ resultado }) => {
      if (resultado) {
        setCartorios(resultado);
      }
    });
  }, []);

  const linksBreadcrumb = [
    {
      url: '/administrativo',
      texto: 'Início ',
    },
    {
      texto: 'Cartórios',
    },
  ];

  return (
    <Row>
      <Col>
        <Breadcrumb links={linksBreadcrumb} />
        <Card>
          <CardBody>
            <b>
              <h4 className="mb-4">Cartórios</h4>
            </b>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th className="col-6">Denominacação</th>
                  <th>CNS</th>
                  <th>Situação</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {cartorios.map((item) => {
                  return (
                    <tr>
                      <td>{item.id}</td>
                      <td>
                        <Link
                          to={`/administrativo/cartorios/${item.id}`}
                          className="text-primary"
                        >
                          <u>{item.nome_fantasia}</u>
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/administrativo/cartorios/${item.id}`}
                          className="text-primary"
                        >
                          <u>{formatarCNSCartorio(item)}</u>
                        </Link>
                      </td>
                      <td>{item.situacao}</td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Cartorios;
