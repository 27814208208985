import { Row, Col, Card, CardBody, Button, Table, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import { FiFilter, FiChevronDown, FiPlus, FiTrash } from 'react-icons/fi';
import { BsArrowDownUp } from 'react-icons/bs';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import avatar from 'src/assets/images/users/avatar-1.jpg';

import '../../assets/styles/contratos.css';

const linksBreadcrumb = [
  {
    url: '/administrativo',
    texto: 'Início ',
  },
  {
    texto: 'Contratos',
  },
];

const Contratos: React.FC = () => {
  return (
    <Row className="d-flex justify-content-center">
      <Col className="p-4" sm={10}>
        <Breadcrumb links={linksBreadcrumb} />
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between">
              <div>
                <b>
                  <h4 className="mb-4">Contratos</h4>
                </b>
              </div>

              <div className="d-flex h-75">
                <Button color="soft-primary" className="h-75 mr-3">
                  <FiFilter className="mr-2" />
                  Filtrar
                  <FiChevronDown className="ml-2" />
                </Button>

                <Button color="primary" className="h-75">
                  <FiPlus className="mr-2" />
                  Adicionar contrato
                </Button>
              </div>
            </div>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Válido em</th>
                  <th>
                    <div className="d-flex justify-content-between">
                      Nome
                      <BsArrowDownUp />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex justify-content-between">
                      Validade
                      <BsArrowDownUp />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex justify-content-between">
                      Valor base
                      <BsArrowDownUp />
                    </div>
                  </th>
                  <th>Criado por</th>
                  <th>Data / Hora</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="d-flex justify-content-center align-items-center">
                    AM, DF...{' '}
                    <Badge color="soft-primary" className="ml-2 rounded-badge">
                      +10
                    </Badge>
                  </td>
                  <td>Contrato AB</td>
                  <td>13/02/2023</td>
                  <td>R$ 100,00</td>
                  <td>
                    <img
                      src={avatar}
                      alt="imagem de usuário"
                      className="ml-2 card-img h-25 w-25 img-rounded"
                    />
                  </td>
                  <td>16/01/2022 08h 40min</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Link to="/administrativo/contratos/adicionar">
                        <HiOutlinePencilAlt
                          className="text-primary mr-2"
                          style={{ cursor: 'pointer' }}
                        />
                      </Link>

                      <FiTrash
                        className="text-danger"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex justify-content-center align-items-center">
                    AM, DF...{' '}
                    <Badge color="soft-primary" className="ml-2 rounded-badge">
                      +10
                    </Badge>
                  </td>
                  <td>Contrato AB</td>
                  <td>13/02/2023</td>
                  <td>R$ 100,00</td>
                  <td>
                    <img
                      src={avatar}
                      alt="imagem de usuário"
                      className="ml-2 card-img h-25 w-25 img-rounded"
                    />
                  </td>
                  <td>16/01/2022 08h 40min</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Link to="/administrativo/contratos/adicionar">
                        <HiOutlinePencilAlt
                          className="text-primary mr-2"
                          style={{ cursor: 'pointer' }}
                        />
                      </Link>

                      <FiTrash
                        className="text-danger"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex justify-content-center align-items-center">
                    AM, DF...{' '}
                    <Badge color="soft-primary" className="ml-2 rounded-badge">
                      +10
                    </Badge>
                  </td>
                  <td>Contrato AB</td>
                  <td>13/02/2023</td>
                  <td>R$ 100,00</td>
                  <td>
                    <img
                      src={avatar}
                      alt="imagem de usuário"
                      className="ml-2 card-img h-25 w-25 img-rounded"
                    />
                  </td>
                  <td>16/01/2022 08h 40min</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Link to="/administrativo/contratos/adicionar">
                        <HiOutlinePencilAlt
                          className="text-primary mr-2"
                          style={{ cursor: 'pointer' }}
                        />
                      </Link>

                      <FiTrash
                        className="text-danger"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex justify-content-center align-items-center">
                    AM, DF...{' '}
                    <Badge color="soft-primary" className="ml-2 rounded-badge">
                      +10
                    </Badge>
                  </td>
                  <td>Contrato AB</td>
                  <td>13/02/2023</td>
                  <td>R$ 100,00</td>
                  <td>
                    <img
                      src={avatar}
                      alt="imagem de usuário"
                      className="ml-2 card-img h-25 w-25 img-rounded"
                    />
                  </td>
                  <td>16/01/2022 08h 40min</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Link to="/administrativo/contratos/adicionar">
                        <HiOutlinePencilAlt
                          className="text-primary mr-2"
                          style={{ cursor: 'pointer' }}
                        />
                      </Link>

                      <FiTrash
                        className="text-danger"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex justify-content-center align-items-center">
                    AM, DF...{' '}
                    <Badge color="soft-primary" className="ml-2 rounded-badge">
                      +10
                    </Badge>
                  </td>
                  <td>Contrato AB</td>
                  <td>13/02/2023</td>
                  <td>R$ 100,00</td>
                  <td>
                    <img
                      src={avatar}
                      alt="imagem de usuário"
                      className="ml-2 card-img h-25 w-25 img-rounded"
                    />
                  </td>
                  <td>16/01/2022 08h 40min</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Link to="/administrativo/contratos/adicionar">
                        <HiOutlinePencilAlt
                          className="text-primary mr-2"
                          style={{ cursor: 'pointer' }}
                        />
                      </Link>

                      <FiTrash
                        className="text-danger"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Contratos;
