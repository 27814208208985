import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import ResultadoPaginado from 'src/entidades/ResultadoPaginado';
import listarOrcamentosPaginado from 'src/modulos/financeiro/casos-de-uso/orcamento/listarOrcamentosPaginado';
import {
  formatarNumeroOrcamento,
  Orcamento,
  pegarTotalServicos,
} from '../../entidades/Orcamento';
import { AiOutlinePlus } from 'react-icons/ai';
import Paginacao from 'src/entidades/Paginacao';
import formatarDatahora from 'src/casos-de-uso/formatacao/formatarDatahora';
import Avatar from 'src/modulos/processos/components/Avatar';
import pegarFotoPessoa from 'src/modulos/pessoas/casos-de-uso/pessoa/pegarFotoPessoa';
import SituacaoOrcamento from '../../components/SituacaoOrcamento';

const ListagemOrcamentos: React.FC = () => {
  const [orcamentos, setOrcamentos] = useState<ResultadoPaginado<Orcamento>>();
  const [, setPaginacao] = useState<Paginacao>();

  useEffect(() => {
    listarOrcamentosPaginado().then(
      ({ resultado, paginacao: dadosPaginacao }) => {
        if (resultado) {
          setOrcamentos(resultado);
        }
        if (dadosPaginacao) {
          setPaginacao(dadosPaginacao);
        }
      }
    );
  }, []);
  return (
    <>
      <Breadcrumb
        links={[
          {
            url: '/financeiro',
            texto: 'Financeiro',
          },
          {
            texto: 'Orçamentos',
          },
        ]}
      />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>Orçamentos</h4>
                <Button color="primary" disabled>
                  <AiOutlinePlus className="mr-2" />
                  Cadastrar orçamento
                </Button>
              </div>
              <Table striped>
                <thead>
                  <tr>
                    <th>Número</th>
                    <th>Data</th>
                    <th>Prepostos</th>
                    <th>Apresentante</th>
                    <th>Total</th>
                    <th>Pagamento</th>
                  </tr>
                </thead>
                <tbody>
                  {orcamentos?.dados.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Link
                            to={`/financeiro/orcamento/visualizar/${item.id}`}
                          >
                            {formatarNumeroOrcamento(item)}
                          </Link>
                        </td>
                        <td>{formatarDatahora(item.created_at)}</td>
                        <td>
                          <Avatar
                            url={pegarFotoPessoa(item.usuario)}
                            tamanho="32px"
                          />
                        </td>
                        <td>
                          <Avatar
                            url={pegarFotoPessoa(item.pessoa)}
                            tamanho="32px"
                          />
                        </td>
                        <td>{pegarTotalServicos(item, true)}</td>
                        <td>
                          <SituacaoOrcamento situacao={item.recolhido} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ListagemOrcamentos;
