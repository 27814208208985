import clsx from 'clsx';
import React, { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import AutenticacaoContext from 'src/contexts/Autenticacao';
import pegarFotoPreposto from 'src/modulos/processos/casos-de-uso/preposto/pegarFotoPreposto';
import Avatar from 'src/modulos/processos/components/Avatar';
import ListagemProcessosContext from 'src/modulos/processos/contexts/ListagemProcessosContext';
import { FiltroProcessos } from 'src/modulos/processos/entidades/FiltroProcessos';
import { Preposto } from 'src/modulos/processos/entidades/Preposto';
import css from './style.module.css';

const Prepostos: React.FC = () => {
  const { prepostos, encontrarPreposto } = useContext(AutenticacaoContext);
  const { filtro, setFiltro } = useContext(ListagemProcessosContext);
  const [resultadoBuscaPrepostos, setResultadoBuscaPrepostos] = React.useState<
    Preposto[]
  >([]);

  const funcaoEstahNoFiltro = (funcao: 'REVISOR' | 'EXECUTOR') => {
    return filtro.funcao && filtro.funcao.indexOf(funcao) >= 0;
  };

  const prepostoEstahNoFiltro = (preposto: Preposto) => {
    return false;
    // return (
    //   filtro.preposto &&
    //   filtro.preposto.filter((item) => item.id === preposto.id).length > 0
    // );
  };

  const aplicarFiltroExecutorRevisor = (
    funcao: 'REVISOR' | 'EXECUTOR',
    selecionado: boolean
  ) => {
    setFiltro((atual: FiltroProcessos) => {
      if (selecionado) {
        atual.funcao = [...(atual.funcao || []), funcao];
      } else {
        atual.funcao = [...(atual.funcao || [])].filter(
          (item) => item !== funcao
        );
      }
      return { ...atual };
    });
  };

  const aplicarFiltroPreposto = (preposto: Preposto, selecionado: boolean) => {
    setFiltro((atual: FiltroProcessos) => {
      // if (selecionado) {
      //   atual.preposto = [...(atual.preposto || []), preposto];
      // } else {
      //   atual.preposto = [...(atual.preposto || [])].filter((item) => {
      //     console.log(item.id !== preposto.id, item.id, preposto.id);
      //     return item.id !== preposto.id;
      //   });
      // }
      return { ...atual };
    });
  };

  const pesquisarPrepostos = (termo: string) => {
    const listaPrepostos = encontrarPreposto(termo);
    setResultadoBuscaPrepostos(
      listaPrepostos.filter((item) => item.status === 'ATIVO')
    );
  };

  const pegarListaPrepostos = () => {
    if (resultadoBuscaPrepostos.length > 0) {
      return resultadoBuscaPrepostos;
    }
    return prepostos;
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <p>
              <b>Função do(s) Preposto(s)</b>
            </p>
            <div className="checkbox checkbox-primary mt-2">
              <input
                type="checkbox"
                id="checkboxExecutor"
                onChange={(evento: React.ChangeEvent<HTMLInputElement>) => {
                  aplicarFiltroExecutorRevisor(
                    'EXECUTOR',
                    evento.target.checked
                  );
                }}
                checked={funcaoEstahNoFiltro('EXECUTOR')}
              />
              <label htmlFor="checkboxExecutor">Executor</label>
            </div>
            <div className="checkbox checkbox-primary mt-2">
              <input
                type="checkbox"
                id="checkboxRevisor"
                onChange={(evento: React.ChangeEvent<HTMLInputElement>) => {
                  aplicarFiltroExecutorRevisor(
                    'REVISOR',
                    evento.target.checked
                  );
                }}
                checked={funcaoEstahNoFiltro('REVISOR')}
              />
              <label htmlFor="checkboxRevisor">Revisor</label>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <p>
              <b>Preposto</b>
            </p>
            <input
              type="text"
              placeholder="ex.: Fulano da Silva"
              className="form-control"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                pesquisarPrepostos(event.target.value)
              }
            />
            <div className={clsx('py-2', css['lista-prepostos'])}>
              {pegarListaPrepostos().map((item: Preposto) => {
                return (
                  <div
                    className="checkbox checkbox-primary d-flex flex-row align-items-center py-2 w-100"
                    key={item.id}
                  >
                    <input
                      type="checkbox"
                      id={`preposto_${item.id}`}
                      onChange={(
                        evento: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        aplicarFiltroPreposto(item, evento.target.checked);
                      }}
                      checked={prepostoEstahNoFiltro(item)}
                    />
                    <label
                      htmlFor={`preposto_${item.id}`}
                      className="flex-fill"
                    >
                      <div className="d-flex flex-row flex-fill align-items-center">
                        <Avatar url={pegarFotoPreposto(item)} tamanho="32px" />
                        <span className="ml-3">{item.nome}</span>
                      </div>
                    </label>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Prepostos;
