import Helmet from 'react-helmet';

type props = {
  titulo: string;
};

const Titulo: React.FC<props> = ({ children, titulo }) => {
  return (
    <div>
      <Helmet>
        <title>{titulo}</title>
      </Helmet>
    </div>
  );
};

export default Titulo;
