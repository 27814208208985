import {
  Col,
  Card,
  CardBody,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Button,
} from 'reactstrap';
import { FaChevronDown } from 'react-icons/fa';
import avatar from 'src/assets/images/users/avatar-1.jpg';
import { UsuarioCaixaType } from 'src/modulos/financeiro/entidades/UsuarioCaixa';

type CardUsuarioCaixaProps = {
  pessoa: UsuarioCaixaType;
};

const CardUsuarioCaixa: React.FC<CardUsuarioCaixaProps> = ({ pessoa }) => {
  function formatarValor(numero?: number) {
    if (numero) {
      return numero.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    } else return 'R$ 0,00';
  }

  type valorPessoaProps = {
    valor: number;
  };

  const RetornarValorPessoa: React.FC<valorPessoaProps> = ({ valor }) => {
    if (valor === 0) {
      return (
        <b>
          <h4>+ {formatarValor(valor)}</h4>
        </b>
      );
    } else {
      return <h4 className="text-success">+ {formatarValor(valor)}</h4>;
    }
  };

  return (
    <Col>
      <Card className={'pagamento-card'} style={{ width: 350 }}>
        <CardBody>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img
                src={avatar}
                alt="imagem de usuário"
                className="card-img h-25 w-25 img-rounded"
              />

              <div className="ml-2">
                <p>{pessoa.nome}</p>
                <b>
                  <RetornarValorPessoa valor={pessoa.valor} />
                </b>
              </div>
            </div>

            <div>
              <FaChevronDown style={{ cursor: 'pointer' }} />
            </div>
          </div>

          <div hidden={pessoa.detalhesExibidos}>
            <hr />

            <p>Entrada</p>

            <Row>
              <Col md={6}>
                <InputGroup>
                  <InputGroupText>R$</InputGroupText>
                  <Input placeholder="0,00" />
                </InputGroup>
              </Col>

              <Col>
                <Button color="soft-primary" className="ml-1">
                  Salvar Alterações
                </Button>
              </Col>
            </Row>

            <hr />

            {pessoa.valores.map((valorUsuario) => (
              <div>
                <p style={{ maxWidth: 250 }}>{valorUsuario.nome}</p>
                <b>
                  <p>{formatarValor(valorUsuario.valor)}</p>
                </b>
                <hr />
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CardUsuarioCaixa;
