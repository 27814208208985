import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';

export const salvarLivros = async (
  dados: any
): Promise<IResultadoRequisicao<any>> => {
  try {
    const resultado = await api().post('/gerencia/livros', dados);
    return { resultado };
  } catch (error) {
    return { error };
  }
};

export const listarLivros = async (): Promise<any> => {
  return await api().get('gerencia/livros');
};
