import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FiPlus, FiPrinter } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import Breadcrumb from '../../../../components/Breadcrumb';
import listarTransacoesLivroDiario from '../../casos-de-uso/livroDiario/listarTransacoesLivroDiario';
import {
  LivroDiarioTotais,
  pegarTotalDespesas,
  pegarTotalRepasse,
} from '../../entidades/LivroDiarioTotais';
import {
  ehDespesa,
  TransacaoLivroDiario,
} from '../../entidades/TransacaoLivroDiario';
import qs from 'qs';
import Filtro, { FiltroLivroDiario } from './Filtro';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import formatarDinheiro from 'src/util/formatarDinheiro';
import formatarData from 'src/casos-de-uso/formatacao/formatarData';
import clsx from 'clsx';

const linksBreadcrumb = [
  {
    url: '/financeiro',
    texto: 'Financeiro',
  },
  {
    texto: 'Livro Diário Auxiliar',
  },
];

const queryParamsParaFiltro = (query: string): FiltroLivroDiario => {
  const q = qs.parse(query.replace('?', ''));
  return {
    competencia:
      'competencia' in q ? dayjs(String(q.competencia)).toDate() : new Date(),
    tipo: 'tipo' in q ? String(q.tipo) : 'executado',
  };
};

const LivroDiarioAuxiliar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [carregando, setCarregando] = useState(false);
  const [totais, setTotais] = useState<LivroDiarioTotais>(
    {} as LivroDiarioTotais
  );
  const [filtroLocal, setFiltroLocal] = useState<FiltroLivroDiario>(
    queryParamsParaFiltro(location.search)
  );
  const [transacoes, setTransacoes] = useState<TransacaoLivroDiario[]>([]);

  const visualizarComprovante = (item: TransacaoLivroDiario) => {
    console.log(
      '🚀 ~ file: index.tsx ~ line 66 ~ visualizarComprovante ~ item',
      item
    );
  };

  useEffect(() => {
    navigate(
      `/financeiro/livro-diario-auxiliar?competencia=${dayjs(
        filtroLocal.competencia
      ).format('YYYY-MM-DD')}&tipo=${filtroLocal.tipo}`,
      { replace: true }
    );

    setCarregando(true);

    listarTransacoesLivroDiario(filtroLocal.competencia, filtroLocal.tipo).then(
      ({ resultado }) => {
        setCarregando(false);

        if (resultado) {
          setTotais(resultado.totais);
          setTransacoes(resultado.transacoes);
        }
      }
    );
  }, [filtroLocal.competencia, filtroLocal.tipo, navigate]);

  return (
    <>
      <Row>
        <Col md={12}>
          <Breadcrumb links={linksBreadcrumb} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Row className="justify-content-between">
                <Col>
                  <h4 className="font-weight-bold">Livro Diário Auxiliar</h4>
                  <span className="text-capitalize">
                    {dayjs(filtroLocal.competencia).format('MMMM [de] YYYY')}
                  </span>
                </Col>

                <div className="d-flex align-items-center">
                  <Button color="soft-primary" className="ml-2">
                    <FiPrinter />
                  </Button>
                  <Filtro
                    onFiltroAplicado={(filtro: FiltroLivroDiario) => {
                      setFiltroLocal(filtro);
                    }}
                    filtroInicial={filtroLocal}
                  />
                  <Button color="primary" className="ml-2">
                    <FiPlus /> Cadastrar Despesa
                  </Button>
                </div>
              </Row>

              <CarregamentoConteudo carregando={carregando}>
                <Row className="justify-content-center mb-4">
                  <Col md={3} className="text-center">
                    <h5>Receitas</h5>
                    <h4 className="font-weight-light text-success">
                      {formatarDinheiro(totais?.receitas || 0)}
                    </h4>
                  </Col>
                  <Col md={3} className="text-center">
                    <h5>Despesas</h5>
                    <h4 className="font-weight-light text-danger">
                      {formatarDinheiro(pegarTotalDespesas(totais))}
                    </h4>
                    <small>
                      Dedutíveis {formatarDinheiro(totais.despesas_dedutiveis)}{' '}
                      e não dedutíveis{' '}
                      {formatarDinheiro(totais.despesas_nao_dedutiveis)}
                    </small>
                  </Col>
                  <Col md={3} className="text-center">
                    <h5>Repasses</h5>
                    <h4 className="font-weight-light text-primary">
                      {formatarDinheiro(pegarTotalRepasse(totais))}
                    </h4>
                    <small>
                      Funcivil {formatarDinheiro(totais.repasses_funcivil)}, TFJ{' '}
                      {formatarDinheiro(totais.repasses_tfj)} e ISS{' '}
                      {formatarDinheiro(totais.repasses_iss)}
                    </small>
                  </Col>
                </Row>
                <Table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Qtd.</th>
                      <th>Entrada / Saída</th>
                      <th>Ato / Histórico</th>
                      <th>Valor</th>
                      <th>Repasse</th>
                    </tr>
                  </thead>

                  <tbody>
                    {transacoes.length === 0 && (
                      <tr>
                        <td colSpan={6}>Nenhuma transação encontrada.</td>
                      </tr>
                    )}
                    {transacoes.map((item) => (
                      <tr key={item.id}>
                        <td>{formatarData(item.data)}</td>
                        <td>{item.quantidade}</td>
                        <td>{item.conta}</td>
                        <td>
                          <span className="d-block">{item.historico}</span>
                          {item.comprovante && (
                            <span
                              className="d-block cursor-pointer text-primary"
                              onClick={() => visualizarComprovante(item)}
                            >
                              Visualizar comprovante
                            </span>
                          )}
                        </td>
                        <td
                          className={clsx({
                            'text-danger': ehDespesa(item),
                            'text-success': !ehDespesa(item),
                          })}
                        >
                          {formatarDinheiro(
                            ehDespesa(item) ? -item.valor : item.valor
                          )}
                        </td>
                        <td>{formatarDinheiro(item.repasse)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CarregamentoConteudo>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LivroDiarioAuxiliar;
