import React from 'react';
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Table,
  Input,
  InputGroup,
} from 'reactstrap';
import { AiOutlineEye } from 'react-icons/ai';

type props = {
  nome: string;
};

const RetornarCampoTabela: React.FC<props> = ({ nome }) => {
  return (
    <tr>
      <td>{nome}</td>

      <td>
        <InputGroup>
          <Input addon aria-label="Checkbox" type="checkbox" />
        </InputGroup>
      </td>
      <td>
        <InputGroup>
          <Input addon aria-label="Checkbox" type="checkbox" />
        </InputGroup>
      </td>
      <td>
        <InputGroup>
          <Input addon aria-label="Checkbox" type="checkbox" />
        </InputGroup>
      </td>
      <td>
        <InputGroup>
          <Input addon aria-label="Checkbox" type="checkbox" />
        </InputGroup>
      </td>
    </tr>
  );
};

const Permissoes: React.FC = () => {
  return (
    <Col>
      <Row className="mb-4">
        <Col xs={6}>
          <b>
            <span>Função</span>
          </b>

          <div className="mt-1">
            <ButtonGroup>
              <Button color="soft-primary" onClick={function noRefCheck() {}}>
                Admin
              </Button>
              <Button color="soft-primary" onClick={function noRefCheck() {}}>
                Suporte
              </Button>
              <Button color="soft-primary" onClick={function noRefCheck() {}}>
                Desenvolvimento
              </Button>
            </ButtonGroup>
          </div>
        </Col>
      </Row>

      <Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>
                <div className="d-flex justify-content-between">Módulo</div>
              </th>
              <th>
                <div className="d-flex justify-content-between">Ver</div>
              </th>
              <th>
                <div className="d-flex justify-content-between">Inserir</div>
              </th>
              <th>Editar</th>
              <th>Excluir</th>
            </tr>
          </thead>
          <tbody>
            <RetornarCampoTabela nome="Controle de processos" />
            <RetornarCampoTabela nome="Acervo" />
            <RetornarCampoTabela nome="Mosaico" />
            <RetornarCampoTabela nome="Pessoas" />
            <RetornarCampoTabela nome="Chat" />
            <RetornarCampoTabela nome="Financeiro" />
          </tbody>
        </Table>
      </Row>

      <hr />
    </Col>
  );
};

export default Permissoes;
