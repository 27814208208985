import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, FormGroup, Label } from 'reactstrap';
import { Processo } from 'src/modulos/processos/entidades/Processo';

type Props = {
  processo: Processo;
  onCancelar(): void;
  onSalvar(): void;
};

type CamposFormulario = {
  titulo: string;
  url: string;
};

const AnexarLink: React.FC<Props> = ({ processo, onCancelar, onSalvar }) => {
  const { register, handleSubmit } = useForm<CamposFormulario>();
  const [procesando, setProcessando] = useState(false);

  const salvar = async (dados: CamposFormulario) => {
    // setProcessando(true);
    // const { resultado, error } = await adicionarLinkProcesso(processo, {
    //   titulo: dados.titulo,
    //   url: dados.url,
    // });
    // setProcessando(false);
    // if (resultado) {
    //   toast.success('Link adicionado ao processo');
    //   onSalvar();
    // }
    // if (error) {
    //   toast.error('Falha ao adicionar o link ao processo');
    // }
  };

  return (
    <>
      <form onSubmit={handleSubmit(salvar)}>
        <FormGroup>
          <Label>Título</Label>
          <input
            type="text"
            className="form-control"
            placeholder="Ex.: Consulta de Selo Pública"
            {...register('titulo')}
          />
        </FormGroup>
        <FormGroup>
          <Label>Endereço do link</Label>
          <input
            type="text"
            className="form-control"
            placeholder="Ex.: www.exemplo-site.com"
            {...register('url')}
          />
        </FormGroup>
        <div className="d-flex justify-content-end">
          <Button
            type="button"
            color="soft-info"
            className="mr-3"
            onClick={onCancelar}
            disabled={procesando}
          >
            Cancelar
          </Button>
          <Button type="submit" color="primary" disabled={procesando}>
            Adicionar
          </Button>
        </div>
      </form>
    </>
  );
};

export default AnexarLink;
