import { ErroRegraNegocio } from "src/entidades/ErroRegraNegocio";
import api from "../../api";
import { Servico } from "../../entidades/Servico";

const cadastrarServico = async (
  params: Partial<Servico>
): Promise<{
  resultado?: Servico;
  erro?: ErroRegraNegocio;
}> => {
  try {
    const resultado: Servico = await api().post("/servico/cadastrar", params);
    return { resultado };
  } catch (error) {
    const erro = {
      mensagem: "Falha ao cadastrar serviço",
    };
    return { erro };
  }
};

export default cadastrarServico;
