import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import visualizarPessoa from '../../casos-de-uso/pessoa/visualizarPessoa';
import { PessoaFisica } from '../../entidades/PessoaFisica';
import { PessoaJuridica } from '../../entidades/PessoaJuridica';
import { useForm, UseFormReturn } from 'react-hook-form';
interface GerenciarPessoaContextProps {
  edicao: boolean;
  exibirEtapas: boolean;
  setExibirEtapas(exibir: boolean): void;
  form: UseFormReturn<PessoaFisica | PessoaJuridica>;
  salvar(): void;
}

const GerenciarPessoaContext = React.createContext<GerenciarPessoaContextProps>(
  {} as GerenciarPessoaContextProps
);

export const GerenciarPessoaProvider: React.FC = ({ children }) => {
  const [edicao, setEdicao] = useState<boolean>(false);
  const [exibirEtapas, setExibirEtapas] = useState(false);
  const form = useForm<PessoaFisica | PessoaJuridica>();
  const params = useParams();

  const salvar = async () => {};

  useEffect(() => {
    if (params.id) {
      setEdicao(true);
      visualizarPessoa(Number(params.id)).then(({ resultado }) => {
        if (resultado) {
          form.reset(resultado);
          setExibirEtapas(true);
        }
      });
    }
  }, [form, params.id]);

  return (
    <GerenciarPessoaContext.Provider
      value={{
        edicao,
        exibirEtapas,
        setExibirEtapas,
        form,
        salvar,
      }}
    >
      {children}
    </GerenciarPessoaContext.Provider>
  );
};

export const useGerenciarPessoaContext = (): GerenciarPessoaContextProps => {
  return useContext(GerenciarPessoaContext);
};

export default GerenciarPessoaContext;
