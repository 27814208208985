import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Row, Col, Card, CardBody, CardFooter, TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import classnames from 'classnames';
import Breadcrumb from 'src/components/Breadcrumb';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';

import Iss from './ISS';
import ControleDeOrcamento from './ControleDeOrcamento';
import DadosBancarios from './DadosBancarios';
import ObservacoesDoOrcamento from './ObservacoesDoOrcamento';
import CabecalhoRequerimento from './CabecalhoRequerimento';
import editarConfiguracao from 'src/modulos/financeiro/casos-de-uso/configuracao/editarConfiguracao';
import visualizarConfiguracao from 'src/modulos/financeiro/casos-de-uso/configuracao/visualizarConfiguracao';
import visualizarDadoBancario from 'src/modulos/financeiro/casos-de-uso/configuracao/visualizarDadoBancario';
import { FinanceiroConfiguracao } from "../../entidades/FinanceiroConfiguracao";

const Configuracoes: React.FC = () => {
  const [carregando, setCarregando] = React.useState(false);
  const {
    setValue,
    register,
    handleSubmit,
  } = useForm<FinanceiroConfiguracao>();

  useEffect(() => {
    carregarInformacoes();
  }, []);

  const carregarInformacoes = async () => {
    setCarregando(true);
    await carregarConfiguracao();
    await carregarDadoBancario();
    setCarregando(false);
  }

  const carregarConfiguracao = async () => {
    await visualizarConfiguracao().then(({ resultado, error }) => {
      if (resultado && resultado.length > 0) {
        const configuracao = resultado[0];

        setValue('iss_percentual', configuracao.iss_percentual);
        setValue('iss_calculado_sobre', configuracao.iss_calculado_sobre);
        setValue('orcamento_numero_inicial', configuracao.orcamento_numero_inicial);
        setValue('orcamento_observacao', configuracao.orcamento_observacao);
        setValue('requerimento_cabecalho', configuracao.requerimento_cabecalho);
      }
    });
  };

  const carregarDadoBancario = async () => {
    await visualizarDadoBancario().then(({ resultado, error }) => {
      setCarregando(false);
      if (resultado && resultado.length > 0) {
        const dadoBancario = resultado[0];
        setValue('dados_bancarios', dadoBancario);
        // setValue('dados_bancarios', {
        //   instituicao_financeira_id: dadoBancario.instituicao_financeira_id,
        //   agencia: dadoBancario.agencia,
        //   conta: dadoBancario.conta,
        //   chave_pix_tipo: dadoBancario.chave_pix_tipo,
        //   chave_pix_descricao: dadoBancario.chave_pix_descricao
        // });
        return;
      }
    });
  };

  const onSubmit: SubmitHandler<FinanceiroConfiguracao> = async (data, e) => {
    e?.preventDefault();
    console.log(data);

    const { resultado, erro } = await editarConfiguracao(data);

    if (resultado) {
      toast.success('Configuração salva com sucesso!');
    } else {
      toast.error(erro?.mensagem);
    }
  };

  const [guiaAtiva, setGuiaAtiva] = React.useState('1');
  const alterarGuiaAtiva = (guia: string) => {
    if (guiaAtiva !== guia) {
      setGuiaAtiva(guia);
    }
  };

  const ListaDeGuias = [
    {
        id: '1',
        titulo: 'ISS',
        componente: Iss
    },
    {
        id: '2',
        titulo: 'Controle de Orçamento',
        componente: ControleDeOrcamento
    },
    {
        id: '3',
        titulo: 'Dados Bancários',
        componente: DadosBancarios
    },
    {
        id: '4',
        titulo: 'Observações do Orçamento',
        componente: ObservacoesDoOrcamento
    },
    {
        id: '5',
        titulo: 'Cabeçalho do Requerimento',
        componente: CabecalhoRequerimento
    }
  ];

  return (
    <>
      <Breadcrumb links={[{ url: '/financeiro', texto: 'Financeiro' }, { texto: 'Configurações Gerais' }]}/>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardBody>
            <h2 className="mb-3 mt-0">Configurações Gerais</h2>
            <CarregamentoConteudo carregando={carregando}></CarregamentoConteudo>
              <Nav tabs>
                {ListaDeGuias.map((guia, index) => {
                  return (
                    <NavItem key={index}>
                      <NavLink href="#" className={classnames({ active: guiaAtiva === guia.id })} onClick={() => { alterarGuiaAtiva(guia.id); }}>
                        <span className="d-none d-sm-block">{guia.titulo}</span>
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
              <TabContent activeTab={guiaAtiva}>
                {ListaDeGuias.map((guia, index) => {
                  const GuiaComponente = guia.componente;
                  return (
                    <TabPane tabId={guia.id} key={index}>
                      <Row>
                        <Col sm="12">
                          <GuiaComponente register={register} />
                        </Col>
                      </Row>
                    </TabPane>
                  );
                })}
              </TabContent>
          </CardBody>
          <CardFooter>
            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">Salvar</Button>
            </div>
          </CardFooter>
        </form>
      </Card>
    </>
  );
}

export default Configuracoes;