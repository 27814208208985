import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import {
  FiEye,
  FiFilter,
  FiCalendar,
  FiChevronDown,
  FiUser,
} from 'react-icons/fi';
import { FaChevronDown } from 'react-icons/fa';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import mockUsuariosCaixa from 'src/modulos/financeiro/casos-de-uso/caixa/usuarios/mockUsuarios';
import { UsuarioCaixaType } from 'src/modulos/financeiro/entidades/UsuarioCaixa';
import CardUsuarioCaixa from 'src/modulos/financeiro/components/CardUsuarioCaixa';
import { BsEyeSlash } from 'react-icons/bs';

const CaixaPorUsuario: React.FC = () => {
  const [seletorDePaginas, setValorSeletor] = useState(1);
  const [dropdownAberto, setDropdownAberto] = useState(false);
  const [usuariosCaixa, setUsuariosCaixa] = useState<UsuarioCaixaType[]>([]);
  const [estadoAlternarTodos, setEstadoAlternarTodos] = useState(false);

  const novoValorSeletorDePaginas = (pagina: number) => {
    setValorSeletor(pagina);
  };

  const alternarEstadoDropdown = () =>
    setDropdownAberto((prevState) => !prevState);

  const statusSeletorDePaginas = () => {
    if (seletorDePaginas === 1) return true;
    else return false;
  };

  const RetornarBotaoExpandirTudo: React.FC = () => {
    if (estadoAlternarTodos) {
      return (
        <Button
          color="soft-primary"
          className="mr-2"
          onClick={alternarEstadoDetalhesTodosUsuarios}
        >
          <BsEyeSlash className="mr-1" /> Contrair tudo
        </Button>
      );
    } else {
      return (
        <Button
          color="soft-primary"
          className="mr-2"
          onClick={alternarEstadoDetalhesTodosUsuarios}
        >
          <FiEye className="mr-1" /> Expandir tudo
        </Button>
      );
    }
  };

  useEffect(() => {
    var usuariosCaixa = mockUsuariosCaixa();

    usuariosCaixa.forEach((usuario) => {
      setUsuariosCaixa((usuariosCaixa) => [
        ...usuariosCaixa,
        {
          nome: usuario.nome,
          valor: usuario.valor,
          valores: usuario.valores,
          detalhesExibidos: usuario.detalhesExibidos,
        },
      ]);
    });
  }, []);

  function alternarEstadoDetalhesTodosUsuarios() {
    setEstadoAlternarTodos(!estadoAlternarTodos);

    var novaListaUsuarios: UsuarioCaixaType[] = [];

    usuariosCaixa.forEach((usuario) => {
      novaListaUsuarios.push({
        nome: usuario.nome,
        valor: usuario.valor,
        valores: usuario.valores,
        detalhesExibidos: !usuario.detalhesExibidos,
      });
    });

    while (usuariosCaixa.length > 0) usuariosCaixa.pop();

    return novaListaUsuarios.forEach((usuario) => {
      setUsuariosCaixa((usuariosCaixa) => [
        ...usuariosCaixa,
        {
          nome: usuario.nome,
          valor: usuario.valor,
          valores: usuario.valores,
          detalhesExibidos: usuario.detalhesExibidos,
        },
      ]);
    });
  }

  function alternarEstadoDetalhesUsuario(pessoa: UsuarioCaixaType) {
    var novaListaUsuarios: UsuarioCaixaType[] = [];

    usuariosCaixa.forEach((usuario) => {
      if (usuario.nome === pessoa.nome) {
        novaListaUsuarios.push({
          nome: usuario.nome,
          valor: usuario.valor,
          valores: usuario.valores,
          detalhesExibidos: !usuario.detalhesExibidos,
        });
      } else {
        novaListaUsuarios.push({
          nome: usuario.nome,
          valor: usuario.valor,
          valores: usuario.valores,
          detalhesExibidos: usuario.detalhesExibidos,
        });
      }
    });

    while (usuariosCaixa.length > 0) usuariosCaixa.pop();

    return novaListaUsuarios.forEach((usuario) => {
      setUsuariosCaixa((usuariosCaixa) => [
        ...usuariosCaixa,
        {
          nome: usuario.nome,
          valor: usuario.valor,
          valores: usuario.valores,
          detalhesExibidos: usuario.detalhesExibidos,
        },
      ]);
    });
  }

  return (
    <>
      <Row className="d-flex justify-content-center">
        <Col md={8}>
          <Breadcrumb
            links={[
              {
                url: '/financeiro',
                texto: 'Financeiro',
              },
              {
                url: '/financeiro/caixa',
                texto: 'Resumo do Caixa',
              },
              {
                texto: 'Caixa por Usuário',
              },
            ]}
          />
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <b>
                        <h3>Caixa por Usuário</h3>
                      </b>
                      <p>16 / 02 / 2022</p>
                    </div>

                    <div className="d-flex align-items-center">
                      <RetornarBotaoExpandirTudo />

                      <Dropdown
                        isOpen={dropdownAberto}
                        toggle={alternarEstadoDropdown}
                        direction={'left'}
                      >
                        <DropdownToggle caret color="soft-primary">
                          <FiFilter className="mr-1" /> Filtrar{' '}
                          <FaChevronDown className="ml-1" />
                        </DropdownToggle>

                        <DropdownMenu style={{ width: 400 }}>
                          <DropdownItem
                            header
                            className="d-flex align-items-center justify-content-between"
                          >
                            <h6>Filtrar por...</h6>

                            <IoClose
                              className="ml-5 font-size-16"
                              style={{ cursor: 'pointer' }}
                              onClick={alternarEstadoDropdown}
                            />
                          </DropdownItem>

                          <hr />

                          <DropdownItem className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <FiCalendar className="mr-2" />
                              Data
                            </div>

                            <FiChevronDown />
                          </DropdownItem>

                          <hr />

                          <DropdownItem className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <FiUser className="mr-2" />
                              Prepostos
                            </div>

                            <FiChevronDown />
                          </DropdownItem>

                          <hr />

                          <DropdownItem>
                            <div className="d-flex align-items-center justify-content-end">
                              <Button
                                color="soft-success"
                                className="w-75 mr-2"
                              >
                                Cancelar
                              </Button>
                              <Button color="primary" className="w-75">
                                Aplicar Filtro
                              </Button>
                            </div>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                {usuariosCaixa.map((pessoa) => (
                  <a
                    onClick={() => {
                      alternarEstadoDetalhesUsuario(pessoa);
                    }}
                  >
                    <CardUsuarioCaixa pessoa={pessoa} />
                  </a>
                ))}
              </Row>

              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-soft-primary"
                  onClick={() => {
                    if (seletorDePaginas > 1) {
                      novoValorSeletorDePaginas(seletorDePaginas - 1);
                    }
                  }}
                  disabled={statusSeletorDePaginas()}
                >
                  <BsChevronLeft />
                </button>

                <InputGroup style={{ width: 80 }} className="ml-2 mr-2">
                  <Input
                    value={seletorDePaginas}
                    type="text"
                    onChange={(e) => {
                      novoValorSeletorDePaginas(parseInt(e.target.value));
                    }}
                    className="text-center"
                  />
                </InputGroup>

                <button
                  className="btn btn-soft-primary"
                  onClick={() => {
                    novoValorSeletorDePaginas(seletorDePaginas + 1);
                  }}
                >
                  <BsChevronRight />
                </button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CaixaPorUsuario;
