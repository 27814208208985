import React from 'react';
import { useFormContext } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { Cartorio } from 'src/modulos/aplicacao/entidades/Cartorio';
import Constantes from 'src/util/constantes';

const Identificacao: React.FC = () => {
  const { register, getValues, setValue } = useFormContext<Cartorio>();

  return (
    <>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>CNPJ</Label>
            <IMaskInput
              mask={Constantes.MASCARA_CNPJ}
              unmask
              className="form-control"
              defaultValue={getValues('cnpj') || ''}
              onAccept={(valor) => {
                setValue('cnpj', String(valor));
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Nome Fantasia</Label>
            <input
              type="text"
              className="form-control"
              {...register('nome_fantasia')}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default Identificacao;
