import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import {
  Col,
  Card,
  CardBody,
  Row,
  InputGroup,
  FormGroup,
  Input,
  ButtonGroup,
  Button,
  Badge,
} from 'reactstrap';

import '../../assets/styles/editarmodulo.css';
import { Modulo } from '../../entidades/Modulo';

import { BsSearch } from 'react-icons/bs';
import { FiCamera } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';

import buscarDetalhesDoModulo from '../../casos-de-uso/modulos/buscarDetalhesDoModulo';
import iconeProcessos from '../../../processos/assets/icon-processos.png';

type UFBadgesProps = {
  badgesDeEstado: string[];
  setBadgesDeEstado: React.Dispatch<React.SetStateAction<string[]>>;
};

const ListarBadgesDeEstado: React.FC<UFBadgesProps> = ({
  badgesDeEstado,
  setBadgesDeEstado,
}) => {
  return (
    <div>
      {badgesDeEstado.map((estado) => (
        <Badge
          color="soft-primary"
          className="p-2 badge-uf font-size-14 mr-2 mb-2"
          pill
        >
          {estado}
          <IoClose
            className="ml-3 font-size-15 font-weight-bold"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              removerEstado(estado, badgesDeEstado, setBadgesDeEstado);
            }}
          />
        </Badge>
      ))}
    </div>
  );
};

const removerEstado = (
  id: string,
  badgesDeEstado: string[],
  setBadgesDeEstado: React.Dispatch<React.SetStateAction<string[]>>
) => {
  var novosEstados: string[] = [];

  badgesDeEstado.map((estado) => {
    if (estado === id) return false;
    else novosEstados.push(estado);
  });

  while (badgesDeEstado.length > 0) {
    badgesDeEstado.pop();
  }

  novosEstados.map((estado) => {
    setBadgesDeEstado((badgesDeEstado) => [...badgesDeEstado, estado]);
  });
};

const EditarModulo: React.FC = () => {
  const { id } = useParams();

  const [badgesDeEstado, setBadgesDeEstado] = React.useState<string[]>([]);
  const [UFSFiltrados, setUFSFiltrados] = React.useState<string[]>([]);

  const [modulo, setModulo] = React.useState<Modulo>();
  const [pesquisaUF, setPesquisaUF] = React.useState('');

  const filtrarUFS = (input: string) => {
    var UFS = badgesDeEstado.map((estado) => {
      if (estado.startsWith(input.toUpperCase())) return estado;
    });

    while (UFSFiltrados.length > 0) {
      UFSFiltrados.pop();
    }

    UFS.map((estado) => {
      if (estado) {
        setUFSFiltrados((UFSFiltrados) => [...UFSFiltrados, estado]);
      }
    });
  };

  const RetornarBadgesDeEstados: React.FC = () => {
    if (UFSFiltrados.length > 0) {
      return (
        <ListarBadgesDeEstado
          badgesDeEstado={UFSFiltrados}
          setBadgesDeEstado={setUFSFiltrados}
        />
      );
    }

    return (
      <ListarBadgesDeEstado
        badgesDeEstado={badgesDeEstado}
        setBadgesDeEstado={setBadgesDeEstado}
      />
    );
  };

  const linksBreadcrumb = [
    {
      url: '/administrativo',
      texto: 'Início ',
    },
    {
      url: '/administrativo/modulos',
      texto: 'Módulos',
    },
    {
      texto: 'Editar Módulo',
    },
  ];

  const estados: string[] = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  React.useEffect(() => {
    setModulo(buscarDetalhesDoModulo(id));

    estados.map((estado) => {
      setBadgesDeEstado((badgesDeEstado) => [...badgesDeEstado, estado]);
    });
  }, []);

  return (
    <div>
      <Row className="d-flex justify-content-center">
        <Col className="p-4" sm={8}>
          <Breadcrumb links={linksBreadcrumb} />
          <Card>
            <CardBody>
              <b>
                <h4 className="mb-4">Editar Módulo</h4>
              </b>

              <Row>
                <Col xs={1}>
                  <img
                    src={iconeProcessos}
                    alt="Icone do modulo"
                    className="card-img"
                  />

                  <div className="modulo-image-overlay text-center">
                    <div className="modulo-image-overlay-container bg-secondary">
                      <FiCamera className="font-size-16" />
                    </div>
                  </div>
                </Col>

                <Col xs={4}>
                  <b>
                    <span>Nome</span>
                  </b>

                  <InputGroup className="mt-1">
                    <Input placeholder={modulo?.nome} />
                  </InputGroup>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xs={12}>
                  <b>
                    <span>Descrição</span>
                  </b>
                  <FormGroup row className="mt-2">
                    <Col sm={10}>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        value={`[ Descrição do módulo aqui Descrição do módulo aqui Descrição do módulo aqui
Descrição do módulo aqui]`}
                        style={{ overflow: 'scroll' }}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <b>
                    <span>Situação</span>
                  </b>

                  <Col className="mt-2">
                    <ButtonGroup>
                      <Button
                        color="soft-primary"
                        onClick={function noRefCheck() {}}
                      >
                        Ativo
                      </Button>
                      <Button color="light" onClick={function noRefCheck() {}}>
                        Inativo
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col>
                  <b>
                    <span>UFs Autorizadas</span>
                  </b>
                  <br />

                  <i>
                    <span>Pesquise pela sigla do estado, ex.: TO</span>
                  </i>

                  <Row>
                    <Col xs={3} className="mt-2">
                      <form action="">
                        <div className="input-busca-div d-flex">
                          <div className="p-2">
                            <BsSearch className="font-size-16" />
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              setPesquisaUF(e.target.value);
                              filtrarUFS(e.target.value);
                            }}
                            value={pesquisaUF}
                          />
                        </div>
                      </form>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="container mt-3">
                <div className="row">
                  <RetornarBadgesDeEstados />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditarModulo;
