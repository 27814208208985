import React, { useContext, useState } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import Dropdown from 'src/components/Dropdown';
import {
  Processo,
  processoEstahRelacionadoAoProcesso,
} from 'src/modulos/processos/entidades/Processo';
import relacionarProcessos from 'src/modulos/processos/casos-de-uso/processo/relacionarProcessos';
import { toast } from 'react-toastify';
import { FiLayers } from 'react-icons/fi';
import buscarProcessos from 'src/modulos/processos/casos-de-uso/processos/buscarProcessos';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';

const RelacionarProcessos: React.FC = () => {
  const { processo, arquivado, onProcessoRelacionado } =
    useContext(ProcessoContext);
  const [mostrarFormulario, setMostrarFormulario] = useState<boolean>(false);
  const [carregando, setCarregando] = useState(false);

  const criarRelacionamentoProcessos = async (
    processoRelacionado: Processo
  ) => {
    if (processo.id === processoRelacionado.id) {
      toast.error('Você não pode relacionar o processo com ele mesmo.');
      return;
    }
    if (processoEstahRelacionadoAoProcesso(processo, processoRelacionado)) {
      toast.error(
        `O procesos ${processoRelacionado.numero} já está relacionado ao processo ${processo.numero}`
      );
      return;
    }
    const { resultado, error } = await relacionarProcessos(
      processo,
      processoRelacionado.id!
    );

    if (resultado) {
      toast.success('Processos relacionados');
      setMostrarFormulario(false);
      onProcessoRelacionado(processoRelacionado, resultado);
      return;
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  const pesquisarProcessos = async (termo: string) => {
    setCarregando(true);
    const { resultado } = await buscarProcessos(termo);
    setCarregando(false);
    if (resultado) {
      return resultado.map((item) => ({
        value: item.id,
        label: item.numero,
        processo: item,
      }));
    }
    return [];
  };

  return (
    <div className="relative">
      <Button
        color="soft-secondary"
        block
        className="d-flex align-items-center"
        disabled={arquivado}
        onClick={() => setMostrarFormulario((mostrar) => !mostrar)}
      >
        <FiLayers className="mr-2" />
        <span>Processos relacionados</span>
      </Button>
      <Dropdown
        mostrar={mostrarFormulario}
        fechar={() => setMostrarFormulario((mostrar) => !mostrar)}
        fecharAoClicarExterno={false}
        titulo="Processos relacionados"
      >
        <FormGroup>
          <Label>Selecione ou pesquise pelo processo</Label>
          <AsyncSelect
            placeholder="Pesquise pelo número Ex.: 000155/2022"
            isLoading={carregando}
            loadOptions={pesquisarProcessos}
            onChange={(selecionado) => {
              if (selecionado) {
                criarRelacionamentoProcessos(selecionado.processo);
              }
            }}
          />
        </FormGroup>
      </Dropdown>
    </div>
  );
};

export default RelacionarProcessos;
