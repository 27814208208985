import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import listarEtiquetas from '../../casos-de-uso/configuracoes/etiquetas/listarEtiquetas';
import { Etiqueta, pegarCorEtiqueta } from '../../entidades/Etiqueta';
import css from './style.module.css';

type Props = {
  onEtiquetaSelecionada?(etiqueta: Etiqueta): void;
  onEtiquetaDesmarcada?(etiqueta: Etiqueta): void;
  etiquetasMarcadas?: number[];
  exibirPesquisa?: boolean;
};

const Etiquetas: React.FC<Props> = ({
  exibirPesquisa = true,
  onEtiquetaSelecionada,
  onEtiquetaDesmarcada,
  etiquetasMarcadas = [],
}) => {
  const [etiquetas, setEtiquetas] = React.useState<Etiqueta[]>([]);
  const [termoBusca, setTermoBusca] = useState('');

  const pegarListaEtiquetas = () => {
    return etiquetas.filter((item) => {
      if (termoBusca.length > 0) {
        return (
          item.titulo!.toLowerCase().indexOf(termoBusca.toLowerCase()) >= 0
        );
      }
      return true;
    });
  };

  const selecionarEtiqueta = (etiqueta: Etiqueta) => {
    if (etiquetasMarcadas.indexOf(etiqueta.id!) >= 0) {
      if (typeof onEtiquetaDesmarcada === 'function') {
        onEtiquetaDesmarcada(etiqueta);
      }
    } else {
      if (typeof onEtiquetaSelecionada === 'function') {
        onEtiquetaSelecionada(etiqueta);
      }
    }
  };

  const estahMarcada = (etiqueta: Etiqueta) =>
    etiquetasMarcadas.indexOf(etiqueta.id!) >= 0;

  useEffect(() => {
    listarEtiquetas().then(({ resultado }) => {
      if (resultado) {
        setEtiquetas(resultado);
      }
    });
  }, []);

  return (
    <>
      {exibirPesquisa && (
        <div className="position-relative px-2">
          <div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Pesquise pelo nome da etiqueta"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTermoBusca(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className={clsx(css['lista-etiquetas'])}>
        {pegarListaEtiquetas().map((item) => {
          return (
            <div
              className="d-flex w-100 cursor-pointer border-bottom"
              key={item.id}
              style={pegarCorEtiqueta(item)}
              onClick={() => selecionarEtiqueta(item)}
            >
              <div className="d-flex flex-fill px-3 py-2 rounded">
                <span className="d-inline-block flex-fill">{item.titulo}</span>
                {estahMarcada(item) && (
                  <span>
                    <FiCheck style={{ color: item.cor }} />
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Etiquetas;
