import { useRequest } from 'ahooks';
import React from 'react';
import { FiEye } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { pegarCodigoProtocolo } from 'src/modulos/aplicacao/entidades/Protocolo';
import visualizarArquivoProcesso from 'src/modulos/processos/casos-de-uso/processo/visualizarArquivoProcesso';
import { pegarCodigoSelo } from 'src/modulos/processos/entidades/SeloAnoregProcesso';
import { pegarLivroCompleto } from '../../casos-de-uso/livro';

const Livro: React.FC = () => {
  const { nome } = useParams();
  const { data: registros, loading: carregando } = useRequest(
    () => pegarLivroCompleto(nome!),
    {
      refreshDeps: [nome],
    }
  );

  if (carregando) {
    return <></>;
  }

  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <h5>Livro</h5>
              <Table>
                <thead>
                  <tr>
                    <th>Protocolo</th>
                    <th>Livro</th>
                    <th>Folha</th>
                    <th>Registro</th>
                    <th>Selo</th>
                    <th>Ato</th>
                  </tr>
                </thead>
                <tbody>
                  {registros.data.map((item: any) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          {pegarCodigoProtocolo(item.protocolo || item)}{' '}
                          {item.protocolo?.selo_anoreg
                            ? `(${pegarCodigoSelo(
                                item.protocolo?.selo_anoreg
                              )})`
                            : ''}
                        </td>
                        <td>{item.livro}</td>
                        <td>{item.folha}</td>
                        <td>{item.numero}</td>
                        <td>{pegarCodigoSelo(item?.selo_anoreg)}</td>
                        <td>
                          {item.acervo && (
                            <Button
                              color="primary"
                              className="d-flex align-items-center"
                              onClick={() =>
                                visualizarArquivoProcesso(item?.acervo?.id)
                              }
                            >
                              <FiEye className="mr-2" /> Ver ato
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Livro;
