import React from 'react';

import { Table } from 'reactstrap';
import { BsArrowDownUp } from 'react-icons/bs';
import { BiPencil, BiSearch } from 'react-icons/bi';
import { AiOutlineEye } from 'react-icons/ai';

import avatar from 'src/assets/images/users/avatar-1.jpg';

const Prepostos: React.FC = () => {
  return (
    <div className="my-8">
      <div className="mt-8">
        <div className="tabela fundo-alternado">
          <div className="d-flex justify-content-center">
            <Table>
              <thead>
                <tr>
                  <th className="col-4">
                    Preposto <BsArrowDownUp className="ml-2" />
                  </th>
                  <th>Atrasados</th>
                  <th>Semana atual</th>
                  <th>2 próximas semanas</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>-</td>
                  <td>
                    <td>
                      <BiPencil />
                    </td>
                    <td>
                      <BiSearch />
                    </td>
                    <td>
                      <AiOutlineEye />
                    </td>
                  </td>
                  <td>
                    <td>
                      <BiPencil />
                    </td>
                    <td>
                      <BiSearch />
                    </td>
                    <td>
                      <AiOutlineEye />
                    </td>
                  </td>
                  <td>
                    <td>
                      <BiPencil />
                    </td>
                    <td>
                      <BiSearch />
                    </td>
                    <td>
                      <AiOutlineEye />
                    </td>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div>
                      <img
                        src={avatar}
                        alt="imagem de usuário"
                        className="card-img img-rounded mr-2 table-img"
                      />
                      Gustavo Aires
                    </div>
                  </td>

                  <td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </td>

                  <td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </td>

                  <td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div>
                      <img
                        src={avatar}
                        alt="imagem de usuário"
                        className="card-img img-rounded mr-2 table-img"
                      />
                      Ludimila Cantarelli
                    </div>
                  </td>

                  <td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </td>

                  <td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </td>

                  <td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>TOTAL</b>
                  </td>

                  <td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </td>

                  <td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </td>

                  <td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </td>
                </tr>

                <tr>
                  <td>Média</td>

                  <td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </td>

                  <td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </td>

                  <td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="d-flex mt-1 align-items-center">
            <div className="mr-3">
              <BiPencil className="mr-1" /> Executor
            </div>

            <div className="mr-3">
              <BiSearch className="mr-1" /> Revisor
            </div>

            <div className="mr-3">
              <AiOutlineEye className="mr-1" /> Analista
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prepostos;
