import React from 'react';
import { Link } from 'react-router-dom';

type LinkType = {
  url?: string;
  texto: string;
};

type Props = {
  links: Array<LinkType>;
};

const Breadcrumb: React.FC<Props> = ({ links }) => {
  return (
    <div className="py-3 d-flex flex-row">
      {links.map((link, index) => {
        if (link.url) {
          return (
            <span key={index}>
              <span>
                <Link to={link.url}>
                  <b className="text-primary">{link.texto}</b>
                </Link>
              </span>
              {links.length > 1 && (
                <span className="text-secondary mx-1">/</span>
              )}
            </span>
          );
        } else {
          return <span key={index} className="text-cinza-normal">{link.texto}</span>;
        }
      })}
    </div>
  );
};

export default Breadcrumb;
