import React from 'react';
import { BsChatRightText } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Usuario from './Usuario';
import Modulos from './Modulos';
import SelecionarCartorio from './SelecionarCartorio';

type Props = {
  icone: string;
  exibirSelecaoCartorios?: boolean;
  urlIcone: string;
  componentBusca?: React.ReactNode;
  acoesRapidas?: React.ReactNode;
};

const Header: React.FC<Props> = ({
  icone,
  exibirSelecaoCartorios = true,
  urlIcone = '',
  componentBusca,
  acoesRapidas,
}) => {
  return (
    <div className="navbar navbar-expand flex-column flex-md-row navbar-custom">
      <div className="container-fluid">
        <Link to={urlIcone} className="navbar-brand mr-0 mr-md-2 logo">
          <span className="logo-lg">
            <img src={icone} alt="" className="w-100" />
          </span>
        </Link>
        <div className="d-flex align-items-center h-100 flex-fill mx-4">
          {exibirSelecaoCartorios && <SelecionarCartorio />}
          {componentBusca || null}
        </div>

        <ul className="navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0 align-items-center">
          {acoesRapidas || null}
          <Modulos />
          <li
            className="dropdown"
            data-toggle="tooltip"
            data-placement="left"
            title="Settings"
          >
            <span className="nav-link right-bar-toggle">
              <BsChatRightText />
            </span>
          </li>
          <Usuario />
        </ul>
      </div>
    </div>
  );
};

export default Header;
