import React from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';

type props = {
  register: any
}

const ControleDeOrcamento: React.FC<props> = ({ register }) => {
  return (
    <div>
      <Row form>
        <Col md={3}>
          <FormGroup>
            <Label for='orcamento_numero_inicial'>Número Inicial do Orçamento</Label>
            <input
                {...register('orcamento_numero_inicial')}
                id="orcamento_numero_inicial"
                type="text"
                className="form-control"
                placeholder=""
                aria-label="Número Inicial do Orçamento"
              />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default ControleDeOrcamento;