import React from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';

type props = {
  register: any
}

const DadosBancarios: React.FC<props> = ({ register }) => {

  return (
    <div>
      <Row form>
        <Col md={4}>
        <FormGroup>
            <Label for='instituicao_financeira_id'>
              Banco
            </Label>
            <select
              {...register('dados_bancarios.instituicao_financeira_id')}
              id="instituicao_financeira_id"
              className="form-control"
              aria-label="Banco"
            >
              {/* <option value="">Selecione ou pesquise pelo nome ou código</option> */}
              <option value="1">Itaú Unibanco S.A.</option>
            </select>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={2}>
          <FormGroup>
            <Label for='agencia'>
              Agência
            </Label>
            <input
              {...register('dados_bancarios.agencia')}
              id="agencia"
              type="text"
              className="form-control"
              placeholder="ex.: 0000"
              aria-label="Agência"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={2}>
        <FormGroup>
            <Label for='conta'>
              Conta
            </Label>
            <input
              {...register('dados_bancarios.conta')}
              id="conta"
              type="text"
              className="form-control"
              placeholder="ex.: 000000-0"
              aria-label="Conta"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={2}>
        <FormGroup>
            <Label for='chave_pix_tipo'>
              Tipo de Chave Pix
            </Label>
            <select
              {...register('dados_bancarios.chave_pix_tipo')}
              id="chave_pix_tipo"
              className="form-control"
              aria-label="Tipo de Chave Pix"
            >
              <option value="CPF_CNPJ">CPF/CNPJ</option>
              <option value="EMAIL">E-mail</option>
            </select>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={2}>
        <FormGroup>
            <Label for='chave_pix_descricao'>
              Chave Pix
            </Label>
            <input
              {...register('dados_bancarios.chave_pix_descricao')}
              id="chave_pix_descricao"
              type="text"
              className="form-control"
              placeholder="ex.: 000000-0"
              aria-label="Chave Pix"
            />
          </FormGroup>
        </Col>
      </Row>
      <hr/>
      <Row form>
        <Col md={4}>
        <FormGroup>
            <Label>
              Titularidade da Conta
            </Label>
            <div>
              <div className="custom-control custom-radio">
                <input type="radio" id="titularidadeCPF" value="CPF" {...register('dados_bancarios.titularidade')} className="custom-control-input"/>
                <Label className="custom-control-label" for="titularidadeCPF">CPF</Label>
              </div>
              <div className="custom-radio custom-control">
                <input type="radio" id="titularidadeCNPJ" value="CNPJ" {...register('dados_bancarios.titularidade')} className="custom-control-input"/>
                <Label className="custom-control-label" for="titularidadeCNPJ">CNPJ</Label>
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default DadosBancarios;