import React from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import { Container, Row, Col } from 'reactstrap';
import { GerenciarPessoaProvider } from './GerenciarPessoaContext';
import Etapas from './Etapas';

const AdicionarPessoa: React.FC = () => {
  return (
    <Container className="mt-3">
      <Breadcrumb
        links={[
          {
            url: '/pessoas',
            texto: 'Pessoas',
          },
          {
            texto: 'Adicionar Pessoa',
          },
        ]}
      />
      <Row>
        <Col md={12}>
          <GerenciarPessoaProvider>
            <Etapas />
          </GerenciarPessoaProvider>
        </Col>
      </Row>
    </Container>
  );
};

export default AdicionarPessoa;
