import React from 'react';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import Footer from '../../../../layout/Footer';
import MenuLateral from './MenuLateral';
import { Container } from 'reactstrap';

const UsuariosLayout: React.FC = () => {
  return (
    <div id="wrapper">
      <Header />
      <MenuLateral />
      <div className="content-page pb-5">
        <div className="content">
          <Container fluid>
            <Outlet />
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UsuariosLayout;
