import { ErroRegraNegocio } from "src/entidades/ErroRegraNegocio";
import api from "../../api";

const listarGrupos = async (): Promise<{
  resultado?: any;
  erro?: ErroRegraNegocio;
}> => {
  try {
    const resultado = await api().get("/grupo/listar");

    return { resultado };
  } catch (error) {
    const erro = {
      mensagem: "Falha ao listar grupos",
    };
    return { erro };
  }
};

export default listarGrupos;
