import React, { useState } from 'react';

type Props = {
  onFasesAtualizadas(fases: string[]): void;
};

const Fases: React.FC<Props> = ({ onFasesAtualizadas }) => {
  const [, setFases] = useState<string[]>([]);

  const atualizarFase = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFases((atual) => {
      console.log('🚀 ~ file: index.tsx ~ line 12 ~ setFases ~ atual', atual);
      let atualizado = [];
      console.log(
        '🚀 ~ file: index.tsx ~ line 15 ~ setFases ~ event.target.checked',
        event.target.checked
      );
      if (event.target.checked) {
        atualizado = [...atual, event.target.value];
      } else {
        atualizado = atual.filter((item) => item !== event.target.value);
      }
      onFasesAtualizadas(atualizado);
      return atualizado;
    });
  };
  return (
    <div>
      <div className="checkbox checkbox-primary ml-1 my-2">
        <input
          type="checkbox"
          onChange={atualizarFase}
          id="checkboxFaseNovo"
          value="NOVO/A SER DISTRIBUÍDO"
        />
        <label htmlFor="checkboxFaseNovo">NOVO/A SER DISTRIBUÍDO</label>
      </div>
      <div className="checkbox checkbox-primary ml-1 mb-2">
        <input
          type="checkbox"
          onChange={atualizarFase}
          id="checkboxEmAnalise"
          value="EM ANÁLISE"
        />
        <label htmlFor="checkboxEmAnalise">EM ANÁLISE</label>
      </div>
      <div className="checkbox checkbox-primary ml-1 mb-2">
        <input
          type="checkbox"
          onChange={atualizarFase}
          id="checkboxProvidenciaCliente"
          value="PROVIDÊNCIAS DO CLIENTE"
        />
        <label htmlFor="checkboxProvidenciaCliente">
          PROVIDÊNCIA DO CLIENTE
        </label>
      </div>
      <div className="checkbox checkbox-primary ml-1 mb-2">
        <input
          type="checkbox"
          onChange={atualizarFase}
          id="checkboxEmExecucao"
          value="EM EXECUÇÃO"
        />
        <label htmlFor="checkboxEmExecucao">EM EXECUÇÃO</label>
      </div>
      <div className="checkbox checkbox-primary ml-1 mb-2">
        <input
          type="checkbox"
          onChange={atualizarFase}
          id="checkboxFeitoASerEntregue"
          value="FEITO: A SER ENTREGUE"
        />
        <label htmlFor="checkboxFeitoASerEntregue">FEITO: A SER ENTREGUE</label>
      </div>
      <div className="checkbox checkbox-primary ml-1 mb-2">
        <input
          type="checkbox"
          onChange={atualizarFase}
          id="checkboxFeitoASerArquivado"
          value="FEITO: A SER ARQUIVADO"
        />
        <label htmlFor="checkboxFeitoASerArquivado">
          FEITO: A SER ARQUIVADO
        </label>
      </div>
      <div className="checkbox checkbox-primary ml-1 mb-2">
        <input
          type="checkbox"
          onChange={atualizarFase}
          id="checkboxFeitoASerArquivado"
          value="FINALIZADO"
        />
        <label htmlFor="checkboxFeitoASerArquivado">FINALIZADO</label>
      </div>
    </div>
  );
};

export default Fases;
