import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import listarPastasAcervo from '../../casos-de-uso/listarPastasAcervo';
import { Acervo } from '../../entidades/Acervo';
import { Pasta } from '../../entidades/Pasta';
import ArquivoItem from './ArquivoItem';
import MenuLateral from './MenuLateral';
import PastaItem from './PastaItem';

const Inicio: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [carregando, setCarregando] = useState(false);
  const [pastaAtual, setPastaAtual] = useState<Pasta | null>(null);
  const [pastas, setPastas] = useState<Pasta[]>([]);
  const [arquivos, setArquivos] = useState<Acervo[]>([]);

  const temPastasOuArquivos = pastas.length > 0 || arquivos.length > 0;
  const tituloPastaAtual = pastaAtual === null ? 'Acervo' : pastaAtual.nome;

  const abrirPasta = (pasta: Pasta) => {
    setPastaAtual(pasta);
    navigate(`/acervo/${pasta.uri}?incluir=subpastas,acervos`);
  };

  const onPastaRenomeada = (pasta: Pasta) => {};

  const onPastaExcluida = (pasta: Pasta) => {};

  const pegarListaPastas = (lista: Pasta[]): Pasta[] => {
    return lista.reduce<Pasta[]>((prev, atual) => {
      const pastaRoot = atual.subpastas === undefined ? [atual] : [];
      return [...prev, ...pastaRoot, ...(atual.subpastas || [])];
    }, []);
  };

  const pegarListaArquivos = (lista: Pasta[]): Acervo[] => {
    return lista.reduce<Acervo[]>((prev, atual) => {
      return [...prev, ...(atual.acervos || [])];
    }, []);
  };

  const gerarBreadcrumb = () => {
    const base = [
      {
        url: '/acervo',
        texto: 'Acervo ',
      },
    ];
    return base;
  };

  useEffect(() => {
    setCarregando(true);
    const path = location.pathname.replace(/\/acervo\/?/g, '');
    listarPastasAcervo(`${path}${location.search}`).then(({ resultado }) => {
      setCarregando(false);
      if (resultado) {
        setPastas(pegarListaPastas(resultado));
        setArquivos(pegarListaArquivos(resultado));
      }
    });
  }, [location.pathname, location.search]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12}>
            <Breadcrumb links={gerarBreadcrumb()} />
            <Card className="mt-3">
              <CardBody>
                <h4 className="mb-3">{tituloPastaAtual}</h4>
                <CarregamentoConteudo carregando={carregando}>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Data de Criação</th>
                        <th>Tamanho</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!temPastasOuArquivos && (
                        <tr>
                          <td colSpan={4}>
                            Não há resultados a serem exibidos.
                          </td>
                        </tr>
                      )}
                      {pastas.map((item) => (
                        <PastaItem
                          key={item.id}
                          pasta={item}
                          abrirPasta={abrirPasta}
                          onPastaExcluida={onPastaExcluida}
                          onPastaRenomeada={onPastaRenomeada}
                        />
                      ))}
                      {arquivos.map((item) => (
                        <ArquivoItem key={item.id} acervo={item} />
                      ))}
                    </tbody>
                  </Table>
                </CarregamentoConteudo>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Inicio;
