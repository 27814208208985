import React, { useContext, useState } from 'react';
import { FiBriefcase, FiTrash } from 'react-icons/fi';
import { Row } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';
import pegarFotoPessoa from 'src/modulos/pessoas/casos-de-uso/pessoa/pegarFotoPessoa';
import {
  pegarDenominacaoPessoa,
  pegarDocumentoPessoa,
} from 'src/modulos/pessoas/entidades/Pessoa';
import excluirClienteNoProcesso from 'src/modulos/processos/casos-de-uso/processo/excluirClienteNoProcesso';
import marcarApresentanteProcesso from 'src/modulos/processos/casos-de-uso/processo/marcarApresentanteProcesso';
import Avatar from 'src/modulos/processos/components/Avatar';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import {
  pessoaEhApresentante,
  PessoaProcesso,
} from 'src/modulos/processos/entidades/PessoaProcesso';
import AdicionarCliente from './AdicionarCliente';

const Clientes: React.FC = () => {
  const {
    processo,
    arquivado,
    onClienteRemovido,
    onClienteMarcadoComoApresentante,
  } = useContext(ProcessoContext);
  const [exibirPopupCliente, setExibirPopupCliente] = useState<number | null>(
    null
  );

  const marcarComoApresentante = async (cliente: PessoaProcesso) => {
    const { resultado } = await marcarApresentanteProcesso(processo, cliente);
    if (resultado) {
      onClienteMarcadoComoApresentante(cliente, resultado);
    }
  };

  const excluirDoProcesso = async (cliente: PessoaProcesso) => {
    const { resultado } = await excluirClienteNoProcesso(processo, cliente.id!);
    if (resultado) {
      setExibirPopupCliente(null);
      onClienteRemovido(cliente, resultado);
    }
  };

  return (
    <>
      <h5>Clientes</h5>
      <Row noGutters>
        {processo.pessoas.map((item) => {
          return (
            <div key={item.id}>
              <div
                className="d-flex flex-column mr-2 mb-2 align-items-center"
                onClick={() => setExibirPopupCliente(item.id!)}
              >
                <Avatar
                  url={pegarFotoPessoa(item)}
                  borda={pessoaEhApresentante(item)}
                />
              </div>
              <Dropdown
                mostrar={exibirPopupCliente === item.id}
                fechar={() => setExibirPopupCliente(null)}
              >
                <div className="position-relative">
                  <div className="py-2 d-flex flex-row align-items-center">
                    <Avatar
                      url={pegarFotoPessoa(item)}
                      borda={pessoaEhApresentante(item)}
                    />
                    <div>
                      <span className="d-block ml-3">
                        {pegarDenominacaoPessoa(item)}
                      </span>
                      <small className="d-block ml-3">
                        {pegarDocumentoPessoa(item, true)}
                      </small>
                    </div>
                  </div>
                  {!pessoaEhApresentante(item) && (
                    <>
                      <hr />
                      <div
                        className="d-flex flex-row text-primary cursor-pointer"
                        onClick={() => marcarComoApresentante(item)}
                      >
                        <FiBriefcase />
                        <span className="d-inline-block ml-3">
                          Marcar como apresentante
                        </span>
                      </div>
                    </>
                  )}
                  <hr />
                  {!arquivado && (
                    <div
                      className="d-flex flex-row text-danger cursor-pointer"
                      onClick={() => {
                        excluirDoProcesso(item);
                      }}
                    >
                      <FiTrash />
                      <span className="d-inline-block ml-3">
                        Remover do processo
                      </span>
                    </div>
                  )}
                </div>
              </Dropdown>
            </div>
          );
        })}
        <AdicionarCliente />
      </Row>
    </>
  );
};

export default Clientes;
