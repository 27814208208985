import React from "react";

type Props = {
  semDadosParaExibir: boolean;
};

const SemDadosParaExibir: React.FC<Props> = ({ children, semDadosParaExibir }) => {
  return semDadosParaExibir ? <div>Não há dados para exibir...</div> : <>{children}</>;
};

export default SemDadosParaExibir;
