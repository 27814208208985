import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import gerarDescricaoSelo from '../../casos-de-uso/selo/gerarDescricaoSelo';
import pegarDadosSelo from '../../casos-de-uso/selo/pegarDadosSelo';
import Selo from '../../entidades/Selo';

const FinalizarSelo: React.FC = () => {
  const { hash } = useParams();
  const [selos, setSelos] = useState<Selo[]>([]);

  useEffect(() => {
    pegarDadosSelo(hash!).then(({ resultado }) => {
      if (resultado) {
        setSelos(resultado);
      }
    });
  }, [hash]);

  if (!selos) {
    return <></>;
  }

  return (
    <>
      <Container>
        {selos.map((item) => {
          return (
            <Row key={item.id}>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <h4>
                      Selo: {item.codigo}-{item.codigoValidacao}
                    </h4>
                    {gerarDescricaoSelo([item])}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          );
        })}
      </Container>
    </>
  );
};

export default FinalizarSelo;
