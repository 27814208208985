import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Row, Col, Card, CardBody } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import BlocoDoModulo from './BlocoDoModulo';

import iconProcessos from '../../../processos/assets/icon-processos.png';
import iconFinanceiro from '../../../financeiro/assets/icon-financeiro.png';
import iconAcervo from '../../../acervo/assets/icon-acervo.png';
import iconPessoas from '../../../pessoas/assets/icon-pessoas.png';

const Modulos: React.FC = () => {
  const linksBreadcrumb = [
    {
      url: '/administrativo',
      texto: 'Início ',
    },
    {
      texto: 'Módulos',
    },
  ];

  return (
    <Row className="d-flex justify-content-center">
      <Col className="p-4" sm={10}>
        <Breadcrumb links={linksBreadcrumb} />
        <Card>
          <CardBody>
            <b>
              <h4 className="mb-4">Módulos</h4>
            </b>

            <Row>
              <BlocoDoModulo
                nome="Procesos"
                imagem={iconProcessos}
                descricao="Módulo de processos"
                ativo
              />

              <BlocoDoModulo
                nome="Financeiro"
                imagem={iconFinanceiro}
                descricao="Módulo do financeiro"
              />
            </Row>

            <Row>
              <BlocoDoModulo
                nome="Acervo"
                imagem={iconAcervo}
                descricao="Módulo do acervo"
                ativo
              />

              <BlocoDoModulo
                nome="Pessoas"
                imagem={iconPessoas}
                descricao="Módulo de pessoas"
                ativo
              />
            </Row>

            <Row>
              <BlocoDoModulo
                nome="Mosaico"
                descricao="Módulo do mosaico"
                ativo
              />

              <BlocoDoModulo
                nome="Comunicações"
                descricao="Módulo de comunicações"
                ativo
              />
            </Row>

            <Row>
              <BlocoDoModulo
                nome="Usuários"
                descricao="Módulo de usuários"
                ativo
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Modulos;
