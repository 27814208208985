import { Route } from 'react-router-dom';
import { AutenticacaoProvider } from 'src/contexts/Autenticacao';
import Layout from '../Layout';
import Inicio from '../paginas/Inicio';
import Livro from '../paginas/Livro';

export default (
  <Route
    path="/acervo"
    element={
      <AutenticacaoProvider>
        <Layout />
      </AutenticacaoProvider>
    }
  >
    <Route index element={<Inicio />} />
    <Route path="livro/:nome" element={<Livro />} />
    <Route path="*" element={<Inicio />} />
  </Route>
);
