import React, { useContext, useEffect, useState } from 'react';
import { FiMinus, FiPlus, FiStar } from 'react-icons/fi';
import { Button } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';
import pegarFotoPreposto from 'src/modulos/processos/casos-de-uso/preposto/pegarFotoPreposto';
import Avatar from 'src/modulos/processos/components/Avatar';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import { pegarFuncaoPreposto } from 'src/modulos/processos/entidades/PrepostoProcesso';

const AdicionarPontuacaoPrepostos: React.FC = () => {
  const { processo, arquivado } = useContext(ProcessoContext);
  const [exibirPopup, setExibirPopup] = useState(false);
  const [pontuacao, setPontuacao] = useState(
    new Array(processo.prepostos?.length).fill(0)
  );

  const adicionarPontos = (indice: number) => {
    setPontuacao((atual) => {
      const copia = [...atual];
      copia[indice] += 0.5;
      return [...copia];
    });
  };

  const removerPontos = (indice: number) => {
    setPontuacao((atual) => {
      const copia = [...atual];
      copia[indice] -= 0.5;
      return [...copia];
    });
  };

  const abrirPopup = () => {
    setExibirPopup(true);
  };

  const fecharPopup = () => {
    setExibirPopup(false);
  };

  useEffect(() => {
    if (processo.prepostos) {
      setPontuacao(
        processo.prepostos.map((item) => Number(item.pivot.pontuacao))
      );
    }
  }, [processo.prepostos]);

  return (
    <div className="relative">
      <Button
        color="soft-secondary"
        block
        className="d-flex align-items-center"
        onClick={abrirPopup}
        disabled={processo.prepostos.length === 0 || arquivado}
      >
        <FiStar className="mr-2" />
        <span>Atribuir pontuação</span>
      </Button>
      <Dropdown
        mostrar={exibirPopup}
        fechar={fecharPopup}
        titulo="Atribuir ponutação"
      >
        {processo.prepostos?.map((item, idx) => {
          return (
            <div className="d-flex justify-content-between py-2" key={idx}>
              <div className="d-flex align-items-center">
                <Avatar url={pegarFotoPreposto(item)} />
                <div className="ml-3">
                  <span className="d-block">
                    <b>{item.pessoa?.fisica?.nome}</b>
                  </span>
                  <span>{pegarFuncaoPreposto(item)}</span>
                </div>
              </div>
              <div>
                <div className="d-flex align-items-center border p-1 rounded">
                  <span className="ml-2 mr-5">{pontuacao[idx]}</span>
                  <Button
                    color="primary"
                    size="xs"
                    className="mr-2"
                    onClick={() => removerPontos(idx)}
                  >
                    <FiMinus />
                  </Button>
                  <Button
                    color="primary"
                    size="xs"
                    onClick={() => adicionarPontos(idx)}
                  >
                    <FiPlus />
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
        <hr />
        <div className="d-flex justify-content-end">
          <Button color="primary">Salvar alterações</Button>
        </div>
      </Dropdown>
    </div>
  );
};

export default AdicionarPontuacaoPrepostos;
