import React from 'react';
import { WithWizard } from 'react-albus';

const Navigation = () => (
  <WithWizard
    render={({ next, previous, step, steps }) => (
      <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
        <button
          onClick={() => alert('Adicionar ação')}
          className="btn btn-soft-success mr-3"
        >
          Cancelar
        </button>

        {steps.indexOf(step) > 0 && (
          <button
            type="button"
            className="btn btn-secondary rounded-none"
            onClick={previous}
          >
            Anterior
          </button>
        )}

        {steps.indexOf(step) < steps.length - 1 && (
          <button type="button" className="btn btn-primary" onClick={next}>
            Próximo
          </button>
        )}

        {steps.indexOf(step) === steps.length - 1 && (
          <button type="submit" className="btn btn-primary">
            Concluir o registro
          </button>
        )}
      </div>
    )}
  />
);

export default Navigation;
