import React from 'react';
import ListagemGant from './ListagemGant';
import ListagemProcessos from './ListagemProcessos';
import ListagemQuadro from './ListagemQuadro';
import { FaRegListAlt } from 'react-icons/fa';
import { BsBarChartSteps } from 'react-icons/bs';
import { MdInsertChartOutlined } from 'react-icons/md';
import { Helmet } from 'react-helmet';
import AdicionarProcesso from './AdicionarProcesso';
import Filtro from './Filtro';
import { ListagemProcessosProvider } from '../../contexts/ListagemProcessosContext';

const Inicio: React.FC = () => {
  const [tipoVisualizacao] = React.useState<'lista' | 'gant' | 'quadro'>(
    'quadro'
  );

  return (
    <>
      <Helmet>
        <title>Controle de Processos</title>
      </Helmet>
      <ListagemProcessosProvider>
        <section className="container-fluid">
          <div className="d-flex justify-content-between my-2">
            <div>
              <h4>Controle de Processos</h4>
            </div>
            <div className="d-flex align-items-center">
              <AdicionarProcesso />
              <Filtro />
              <div className="bg-white p-2 rounded" hidden>
                <button className="btn btn-soft-primary btn-xs mr-2">
                  <FaRegListAlt />
                </button>
                <button className="btn btn-soft-primary btn-xs mr-2">
                  <BsBarChartSteps />
                </button>
                <button className="btn btn-soft-primary btn-xs">
                  <MdInsertChartOutlined />
                </button>
              </div>
            </div>
          </div>
          {tipoVisualizacao === 'lista' && <ListagemProcessos />}
          {tipoVisualizacao === 'quadro' && <ListagemQuadro />}
          {tipoVisualizacao === 'gant' && <ListagemGant />}
        </section>
      </ListagemProcessosProvider>
    </>
  );
};

export default Inicio;
