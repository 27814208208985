import { Etiqueta } from 'src/modulos/processos/entidades/Etiqueta';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../../api';

const listarEtiquetas = async (): Promise<IResultadoRequisicao<Etiqueta[]>> => {
  try {
    const resultado: Etiqueta[] = await api().get('/etiquetas');
    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default listarEtiquetas;
