import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Processo } from '../../entidades/Processo';

export default async function atualizarItemChecklist(
  processo: Processo,
  idChecklist: number,
  idItemChecklist: number,
  concluido: boolean
): Promise<IResultadoRequisicao<number>> {
  try {
    const resultado: number = await api().post(
      `processos/${processo.id}/checklist/${idChecklist}/item/${idItemChecklist}`,
      {
        concluido: concluido ? 1 : 0,
      }
    );
    return { resultado };
  } catch (error) {
    return { error };
  }
}
