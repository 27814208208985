import axios from 'axios';
import { ErroRegraNegocio } from 'src/entidades/ErroRegraNegocio';
import { get } from 'tiny-cookie';

const api = (): any => {
  const instancia = axios.create({
    baseURL: `${process.env.REACT_APP_SELOS_API}/`,
    headers: {
      ...(get('vc_token')
        ? {
            Authorization: `KFt8LRe53EKAn4OriwPtgBt6BEE6KWUxsOwgaZ0J5sAbdYi7rgAe3jrGp1UKQoxhkZCFRpMv3g6buOrSow1yfNeSt6ifEmZiq7c51T3wWlZi8wGhspdcpEqsQvYYn2D2QGvG11bc8zdPMbPFmfoCtiQ4rta2YeMVeizDTEs8T5erRCoq5teUx5iOnIigifIwhxgTEJciLzhSdfAn3VIMm7Mc1wyO21OZySkZZjJRe3OLqG3ljShF96cV197A1R71`,
          }
        : {}),
    },
  });

  instancia.interceptors.response.use(
    (res) => {
      if (
        res.headers['content-type'] &&
        res.headers['content-type'].indexOf('application/json') >= 0
      ) {
        return res.data;
      }
      return res;
    },
    (err) => {
      // if ([null, undefined, 401, 403].includes(err.status)) {
      //   window.location.href = '/entrar';
      //   return Promise.reject(err);
      // }
      if (err && err.response.data && err.response.data.erro) {
        const erro: ErroRegraNegocio = {
          mensagem: err.response.data.erro,
        };
        return Promise.reject(erro);
      }
      return Promise.reject(err);
    }
  );

  return instancia;
};

export default api;
