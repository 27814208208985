import React from 'react';
import { FiArchive, FiChevronDown } from 'react-icons/fi';
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';

const Automatizacao: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <h4>Automatizações</h4>
              <div className="border rounded">
                <div className="d-flex align-items-center justify-content-between p-3">
                  <div className="d-flex align-items-center">
                    <FiArchive />
                    <span className="mx-3">Arquivamento automático</span>
                    <FiChevronDown />
                  </div>
                  <div className="checkbox checkbox-primary ml-1 mt-0">
                    <input
                      type="checkbox"
                      id="checkboxArquivamento"
                      value="true"
                    />
                    <label htmlFor="checkboxArquivamento"></label>
                  </div>
                </div>
                <Collapse isOpen className="mt-2 bg-light p-3">
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <Label>Arquivar após</Label>
                        <input type="text" className="form-control" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <span>
                    <i>
                      Obs.: A contagem de dias para ser arquivado começará após
                      o processo ser feito e entregue.
                    </i>
                  </span>
                </Collapse>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Automatizacao;
