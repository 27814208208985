import React from 'react';
import { ServicoAto } from 'src/modulos/financeiro/entidades/ServicoAto';
import formatarDinheiro from 'src/util/formatarDinheiro';

type Props = {
  servico: ServicoAto;
};

const DetalheServicoExtra: React.FC<Props> = ({ servico }) => {
  return (
    <>
      <div className="d-flex align-items-center border-bottom py-2">
        <span className="ml-2 flex-fill">
          <b>{servico.servico?.nome}</b>
        </span>
        <span className="mr-3">
          <b>{formatarDinheiro(servico.valor)}</b>
        </span>
      </div>
    </>
  );
};

export default DetalheServicoExtra;
