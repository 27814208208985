import React from 'react';
import { IMaskInput } from 'react-imask';
import { FormGroup, Label, Row, Col } from 'reactstrap';
import constantes from 'src/util/constantes';

const Contato: React.FC = () => {
  return (
    <>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>Telefone</Label>
            <IMaskInput
              mask={[
                { mask: constantes.MASCARA_TELEFONE },
                { mask: constantes.MASCARA_CELULAR },
              ]}
              unmask
              className="form-control"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>E-mail</Label>
            <input type="email" className="form-control" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>Site do cartório</Label>
            <input type="text" className="form-control" />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default Contato;
