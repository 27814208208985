import React from 'react';
import PessoaJuridica from './PessoaJuridica';

import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Input,
  Label,
  FormGroup,
  InputGroup,
} from 'reactstrap';
import InputMask from 'react-input-mask';

type Props = {
  register: any;
  getValues?: any;
  setValue?: any;
  watch?: any;
};

const DadosBasicos: React.FC<Props> = ({
  register,
  getValues,
  setValue,
  watch,
}) => {
  const [tipoCadastro, setTipoCadastro] = React.useState<
    'fisica' | 'juridica' | null
  >(null);

  React.useEffect(() => {
    if (tipoCadastro === 'fisica') {
    }
  }, [tipoCadastro]);

  return (
    <>
      <Row form>
        <Col md={3}>
          <FormGroup>
            <Label className="font-weight-bold" for="cnpj">
              CNPJ{' '}
              <span className="font-weight-normal text-danger">
                (obrigatório *)
              </span>
            </Label>
            <InputMask
              mask="99.999.999/9999-99"
              id="cnpj"
              type="text"
              className="form-control"
              placeholder="Ex.: 00.000.000/0000-00"
              {...register('cnpj', {
                setValueAs: (v: String) => v.replace(/\D/g, ''),
              })}
            />
          </FormGroup>
        </Col>
      </Row>
      <PessoaJuridica register={register} />
    </>
  );
};

export default DadosBasicos;
