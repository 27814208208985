import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Processo } from '../../entidades/Processo';

const criarProcesso = async (): Promise<IResultadoRequisicao<Processo>> => {
  try {
    const resultado: Processo = await api().post('/processos');
    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default criarProcesso;
