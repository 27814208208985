const constantes = {
  MASCARA_CPF: '000.000.000-00',
  MASCARA_CNPJ: '00.000.000/0000-00',
  MASCARA_CEP: '00000-000',
  MASCARA_DATA: '00/00/0000',
  MASCARA_CELULAR: '(00) 00000-0000',
  MASCARA_TELEFONE: '(00) 0000-0000',
};

export default constantes;
