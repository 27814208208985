export interface LivroDiarioTotais {
  receitas: number;
  despesas_dedutiveis: number;
  despesas_nao_dedutiveis: number;
  repasses_iss: number;
  repasses_tfj: number;
  repasses_funcivil: number;
}

export const pegarTotalDespesas = (dados: LivroDiarioTotais) => {
  return dados.despesas_dedutiveis + dados.despesas_nao_dedutiveis;
};

export const pegarTotalRepasse = (dados: LivroDiarioTotais) => {
  return dados.repasses_funcivil + dados.repasses_iss + dados.repasses_iss;
};
