import { SeloAnoreg } from './SeloAnoreg';

export interface Protocolo {
  id: number;
  usuario_id: number;
  livro_id: number;
  cartorio_id: number;
  especialidade: string;
  nome_livro: string;
  livro: number;
  folha: number;
  numero: number;
  deleted_at?: any;
  created_at: string;
  updated_at: string;
  selo_anoreg?: SeloAnoreg;
  classe: string;
}

export const pegarCodigoProtocolo = (protocolo?: Protocolo) => {
  if (!protocolo) {
    return '';
  }
  return `${protocolo.especialidade}-${protocolo.numero}`;
};
