import { toast } from 'react-toastify';
import pegarUrlArquivoProcesso from './pegarUrlArquivoProcesso';

export default async function visualizarArquivoProcesso(arquivoId: number) {
  const { resultado, error } = await pegarUrlArquivoProcesso(arquivoId);
  if (resultado) {
    window.open(resultado, '_blank', 'noreferrer noopener');
    return;
  }
  if (error) {
    toast.error(error.mensagem);
  }
}
