import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Breadcrumb from '../../../../components/Breadcrumb/index';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import salvarPerfilUsuario from '../../casos-de-uso/usuario/salvarPerfilUsuario';
import { toast } from 'react-toastify';
import { Usuario } from '../../entidades/Usuario';
import pegarPerfilUsuario from '../../casos-de-uso/usuario/pegarPerfilUsuario';
import Avatar from 'src/modulos/processos/components/Avatar';
import { formatarCPF } from 'src/util/formatarCPF';

type CamposFormulario = {
  nome: string;
  nomeUsuario: string;
  email: string;
};

const MeuPerfil: React.FC = () => {
  const [perfil, setPerfil] = useState<Usuario>({} as Usuario);
  const { register, handleSubmit, setValue } = useForm<CamposFormulario>({
    defaultValues: {
      nome: perfil.nome,
      email: perfil.email,
      nomeUsuario: perfil.nome_usuario,
    },
  });
  const [carregandoPerfil, setCarregandoPerfil] = useState(true);
  const [processando, setProcessando] = useState(false);

  const salvarPerfil = async (dados: CamposFormulario) => {
    setProcessando(true);
    const dadosPerfil = {
      ...perfil,
      nome: dados.nome,
      nome_usuario: dados.nomeUsuario,
      email: dados.email,
    };
    const { resultado, error } = await salvarPerfilUsuario(dadosPerfil);
    setPerfil(dadosPerfil);
    setProcessando(false);
    if (resultado) {
      toast.success('Perfil salvo com sucesso.');
      return;
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  useEffect(() => {
    pegarPerfilUsuario().then(({ resultado }) => {
      if (resultado) {
        setPerfil(resultado);
        setValue('nome', resultado.nome);
        setValue('nomeUsuario', resultado.nome_usuario || '');
        setValue('email', resultado.email || '');
        setCarregandoPerfil(false);
      }
    });
  }, [setValue]);

  if (carregandoPerfil) {
    return <></>;
  }

  return (
    <Row>
      <Col md={12}>
        <Breadcrumb
          links={[
            { url: '/usuarios', texto: 'Inicio ' },
            { texto: ' Meu Perfil' },
          ]}
        />
      </Col>
      <Col md={12}>
        <form onSubmit={handleSubmit(salvarPerfil)}>
          <Card>
            <CardBody>
              <h3 className="font-weight-bold">Meu Perfil</h3>
              <div className="d-flex align-items-center">
                <Avatar tamanho="48px" />
                <div className="ml-4">
                  <h3>{perfil.nome}</h3>
                  <span>{formatarCPF(perfil.cpf)}</span>
                </div>
              </div>
              <Row className="mt-4">
                <Col md={4}>
                  <FormGroup>
                    <Label>Nome de exibição</Label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ex.: Fulana da Silva"
                      {...register('nome')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Nome para menções</Label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="@fulanadasilva"
                      {...register('nomeUsuario')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>E-mail</Label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="meu@email.com"
                      {...register('email')}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <div className="d-flex justify-content-end">
                <Button color="primary">
                  <CarregamentoConteudo carregando={processando}>
                    Salvar alterações
                  </CarregamentoConteudo>
                </Button>
              </div>
            </CardFooter>
          </Card>
        </form>
      </Col>
    </Row>
  );
};

export default MeuPerfil;
