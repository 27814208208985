import Paginacao from 'src/entidades/Paginacao';
import ResultadoPaginado, {
  IResultadoPaginado,
} from 'src/entidades/ResultadoPaginado';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../../aplicacao/api';
import { Orcamento } from '../../entidades/Orcamento';

export default async function listarOrcamentosPaginado(
  filtro?: any
): Promise<IResultadoRequisicao<ResultadoPaginado<Orcamento>>> {
  try {
    const resultado: IResultadoPaginado<Orcamento> = await api().get(
      'gerencia/orcamento?id=TODOS&page=1&pagina=1&limite=20'
    );
    return {
      resultado: new ResultadoPaginado<Orcamento>(resultado),
      paginacao: new Paginacao(resultado),
    };
  } catch (error) {
    return { error };
  }
}
