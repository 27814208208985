import Breadcrumb from 'src/components/Breadcrumb';
import { FiEdit, FiLock, FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import listarPrepostos from '../../casos-de-uso/cartorio/listarPrepostos';
import { Preposto } from 'src/modulos/processos/entidades/Preposto';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import AutenticacaoContext from 'src/contexts/Autenticacao';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { formatarCPF } from 'src/util/formatarCPF';

const MembrosDaEquipe: React.FC = () => {
  const { usuario } = useContext(AutenticacaoContext);
  const [processando, setProcessando] = useState(false);
  const [prepostos, setPrepostos] = useState<Preposto[]>([]);
  const [termoBusca, setTermoBusca] = useState<string>();

  const podeEditar = (preposto: Preposto): boolean => {
    if (usuario.id !== preposto.id) {
      return true; // todo: verificar permissões
    }
    return false;
  };

  const podeBloquear = (preposto: Preposto): boolean => {
    if (usuario.id !== preposto.id) {
      return true; // todo: verificar permissões
    }
    return false;
  };

  const pegarClasseStatusPreposto = (status?: string) =>
    status === 'ATIVO' ? 'badge badge-success' : 'badge badge-danger';

  const pegarPrepostos = () => {
    if (termoBusca && termoBusca?.length > 0) {
      return prepostos.filter((item) => {
        const termoCpf = termoBusca.replace(/\D/g, '');
        if (termoCpf.length > 0) {
          return item.cpf!.indexOf(termoCpf) >= 0;
        }
        const nome = item.nome!.toLowerCase();
        return nome.indexOf(termoBusca.toLowerCase()) >= 0;
      });
    }
    return prepostos;
  };

  useEffect(() => {
    setProcessando(true);
    listarPrepostos().then(({ resultado }) => {
      setProcessando(false);
      if (resultado) {
        setPrepostos(resultado);
      }
    });
  }, []);

  return (
    <div>
      <Breadcrumb
        links={[
          { url: '/usuarios', texto: 'Inicio ' },
          { texto: ' Membros da equipe' },
        ]}
      />
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <h4 className="flex-fill">Membros da Equipe</h4>
            <Link
              to={'/usuarios/membros/adicionar'}
              className="btn btn-primary"
            >
              <FiPlus className="mr-2" />
              <span>Adicionar Membro</span>
            </Link>
          </div>
          <Row className="mt-4">
            <Col md={6}>
              <input
                type="text"
                className="form-control"
                placeholder="Pesquise pelo nome ou CPF"
                onChange={(event) => setTermoBusca(event.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <CarregamentoConteudo carregando={processando}>
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>CPF</th>
                      <th>Nível</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pegarPrepostos().map((item) => {
                      return (
                        <tr>
                          <td className="w-50">{item.nome}</td>
                          <td>{formatarCPF(item.cpf!)}</td>
                          <td>-</td>
                          <td>
                            <div
                              className={pegarClasseStatusPreposto(item.status)}
                            >
                              {item.status}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-row">
                              <button
                                className="btn btn-link p-0 mr-3"
                                disabled={!podeEditar(item)}
                              >
                                <FiEdit />
                              </button>
                              <button
                                className="btn btn-link p-0 text-danger"
                                disabled={!podeBloquear(item)}
                              >
                                <FiLock />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </CarregamentoConteudo>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default MembrosDaEquipe;
