import { Cartorio } from '../../entidades/Cartorio';

const formatarCNSCartorio = (cartorio: Cartorio) => {
  if (cartorio.cns) {
    return cartorio.cns.replace(/(\d{2})(\d{3})(\d{1})/g, '$1.$2-$3');
  }
  return cartorio.cns;
};

export default formatarCNSCartorio;
