import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  FiBookOpen,
  FiPieChart,
  FiHome,
  FiTrendingUp,
  FiDollarSign,
  FiTag,
  FiFilePlus,
} from 'react-icons/fi';

import { HiOutlineNewspaper } from 'react-icons/hi';
import clsx from 'clsx';

const MenuLateral: React.FC = () => {
  const location = useLocation();
  const paginaAtual = location.pathname;

  const ehPaginaAtual = (url: string | string[]): boolean => {
    let local = url;
    if (!Array.isArray(url)) {
      local = url;
    }
    return local.includes(paginaAtual);
  };

  useEffect(() => {}, [location.pathname]);

  return (
    <div className="left-side-menu">
      <div className="sidebar-content">
        <div id="sidebar-menu" className="slimscroll-menu">
          <ul className="metismenu" id="menu-bar">
            {/* <li>
              <Link
                to={'/financeiro'}
                className={clsx({ active: ehPaginaAtual('/financeiro') })}
              >
                <FiHome />
                <span> Início </span>
              </Link>
            </li> */}

            <li>
              <Link
                to={'/financeiro'}
                className={clsx({ active: ehPaginaAtual('/financeiro') })}
              >
                <FiPieChart />
                <span> Resumo do Caixa </span>
              </Link>
            </li>

            <li>
              <Link
                to={'/financeiro/orcamentos'}
                className={clsx({
                  active: ehPaginaAtual('/financeiro/orcamentos'),
                })}
              >
                <HiOutlineNewspaper />
                <span> Orçamentos </span>
              </Link>
            </li>

            <li>
              <Link
                to={'/financeiro/livro-diario-auxiliar'}
                className={clsx({
                  active: ehPaginaAtual('/financeiro/livro-diario-auxiliar'),
                })}
              >
                <FiBookOpen />
                <span> Livro Diário Auxiliar </span>
              </Link>
            </li>

            <li>
              <Link
                to={'/financeiro/recibos'}
                className={clsx({
                  active: ehPaginaAtual('/financeiro/recibos'),
                })}
              >
                <FiFilePlus />
                <span> Recibos </span>
              </Link>
            </li>

            <li>
              <Link
                to={'/financeiro/informacoes-repasse'}
                className={clsx({
                  active: ehPaginaAtual('/financeiro/informacoes-repasse'),
                })}
              >
                <FiDollarSign />
                <span> Informações de Repasse </span>
              </Link>
            </li>

            <li>
              <Link
                to={'/financeiro/infomoney'}
                className={clsx({
                  active: ehPaginaAtual('/financeiro/infomoney'),
                })}
              >
                <FiTrendingUp />
                <span> InfoMoney </span>
              </Link>
            </li>
            <li>
              <Link
                to={'/financeiro/selos'}
                className={clsx({ active: ehPaginaAtual('/financeiro/selos') })}
              >
                <FiTag />
                <span> Selos utilizados </span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
};

export default MenuLateral;
