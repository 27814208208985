import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Protocolo } from '../../entidades/Protocolo';

export const pegarProtocolo = async (id: number): Promise<Protocolo> => {
  return await api().get(`/protocolos/${id}`);
};

export const gerarProtocolo = async (
  dados: any
): Promise<IResultadoRequisicao<Protocolo>> => {
  try {
    const resultado: Protocolo = await api().post('/protocolos', dados);
    return {
      resultado,
    };
  } catch (error) {
    return { error };
  }
};
