import React from 'react';
import { Card, CardBody, Col, Row, Container, Button } from 'reactstrap';
import Breadcrumb from '../../../../../components/Breadcrumb';
import { useRequest } from 'ahooks';
import listarModelos from 'src/modulos/processos/casos-de-uso/configuracoes/modelos/listarModelos';
import CardModelo from './CardModelo';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const links = [
  {
    url: '/',
    texto: 'Controle de Processos',
  },
  {
    texto: 'Modelos',
  },
];

const ModelosDocumentos: React.FC = () => {
  const { data: modelos } = useRequest(listarModelos);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Breadcrumb links={links} />
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between">
                <h4>Modelos de documentos</h4>
                <Link to="/processos/configuracoes/modelos/adicionar">
                  <Button color="primary">
                    <FiPlus />
                    <span className="ml-2">Adicionar</span>
                  </Button>
                </Link>
              </div>
              {modelos
                ?.filter((item) => item.emolumento_codigo)
                .map((item) => {
                  return <CardModelo modelo={item} key={item.id} />;
                })}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ModelosDocumentos;
