import React from 'react';
import { Col, Row } from 'reactstrap';
import formatarData from 'src/casos-de-uso/formatacao/formatarData';
import { pegarCodigoProtocolo } from 'src/modulos/aplicacao/entidades/Protocolo';
import { pegarCodigoSelo } from 'src/modulos/processos/entidades/SeloAnoregProcesso';
import { useGerarSeloContext } from '../GerarSeloContext';

const Protocolo: React.FC = () => {
  const { protocolo } = useGerarSeloContext();
  return (
    <Row>
      <Col md={4}>
        <h5>
          Protocolo: {pegarCodigoProtocolo(protocolo)} de{' '}
          {formatarData(protocolo?.created_at!)}
        </h5>
        <span className="d-block">
          Selo de protocolo: {pegarCodigoSelo(protocolo?.selo_anoreg!)}
        </span>
      </Col>
    </Row>
  );
};

export default Protocolo;
