import React from 'react';
import { Routes } from 'react-router-dom';
import AdministrativoRoutes from '../modulos/administrativo/routes';
import AcervoRoutes from '../modulos/acervo/routes';
import AplicacaoRoutes from '../modulos/aplicacao/routes';
import FinanceiroRoutes from '../modulos/financeiro/routes';
import MosaicoRoutes from '../modulos/mosaico/routes';
import PessoasRoutes from '../modulos/pessoas/routes';
import ProcessosRoutes from '../modulos/processos/routes';
import SelosRoutes from '../modulos/selos/routes';

const routes: React.FC = () => {
  return (
    <Routes>
      {AdministrativoRoutes}
      {AcervoRoutes}
      {AplicacaoRoutes}
      {FinanceiroRoutes}
      {MosaicoRoutes}
      {PessoasRoutes}
      {ProcessosRoutes}
      {SelosRoutes}
    </Routes>
  );
};

export default routes;
