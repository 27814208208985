import React from 'react';
import Flatpickr from 'react-flatpickr';
import { FiAlertCircle } from 'react-icons/fi';
import { Alert, Button, Col, FormGroup, Label, Row } from 'reactstrap';

const Gise: React.FC = () => {
  return (
    <Row>
      <Col md={12}>
        <form>
          <Alert color="info" className="d-flex align-items-center">
            <FiAlertCircle />
            <span className="ml-2">
              Para a geração de selos é necessário a configuração dos dados de
              comunicação com a ANOREG TO.
            </span>
          </Alert>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>HASH fornecido pelo GISE</Label>
                <input type="text" className="form-control" />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Data de validade</Label>
                <Flatpickr className="form-control" />
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary" className="px-3">
            Salvar
          </Button>
        </form>
      </Col>
    </Row>
  );
};

export default Gise;
