import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';

export default async function pegarUrlArquivoProcesso(
  arquivoId: number
): Promise<IResultadoRequisicao<string>> {
  try {
    const resultado: { url: string } = await api().get(
      `/processos/acervo/${arquivoId}`
    );
    return { resultado: resultado.url };
  } catch (error) {
    return { error };
  }
}
