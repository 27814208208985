import React, { useContext } from 'react';
import { FiFileText } from 'react-icons/fi';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import css from '../style.module.css';

const Titulo: React.FC = () => {
  const { processo, arquivado } = useContext(ProcessoContext);

  return (
    <h3 className={css.titulo}>
      <FiFileText className={css['icone-titulo']} />{' '}
      {arquivado ? '[ARQUIVADO]' : ''} PROCESSO Nº {processo.numero}
    </h3>
  );
};

export default Titulo;
