import api from '../../api';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import { PessoaFisica } from '../../entidades/PessoaFisica';
import { PessoaJuridica } from '../../entidades/PessoaJuridica';

const visualizarPessoa = async (
  id: number
): Promise<IResultadoRequisicao<PessoaFisica | PessoaJuridica>> => {
  try {
    const resultado: PessoaFisica | PessoaJuridica = await api().get(
      `/gerencia/pessoa/${id}`
    );

    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default visualizarPessoa;
