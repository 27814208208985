import Paginacao from 'src/entidades/Paginacao';
import ResultadoPaginado, {
  IResultadoPaginado,
} from 'src/entidades/ResultadoPaginado';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { PessoaFisica } from '../../entidades/PessoaFisica';
import { PessoaJuridica } from '../../entidades/PessoaJuridica';

export default async function buscarPessoas(
  termo: string = '',
  pagina: number = 1
): Promise<
  IResultadoRequisicao<ResultadoPaginado<PessoaFisica | PessoaJuridica>>
> {
  try {
    const resultado: IResultadoPaginado<PessoaFisica | PessoaJuridica> =
      await api().get(`/gerencia/pessoa?busca=${termo}&pagina=${pagina}`);

    const lista = new ResultadoPaginado<PessoaFisica | PessoaJuridica>(
      resultado
    );
    const paginacao = new Paginacao(resultado);
    return { resultado: lista, paginacao };
  } catch (error) {
    return { error };
  }
}
