import React, { useContext } from 'react';
import { Button, Col, Row } from 'reactstrap';
import formatarData from 'src/casos-de-uso/formatacao/formatarData';
import Dropdown from 'src/components/Dropdown';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import Flatpickr from 'react-flatpickr';
import BlockUi from 'react-block-ui';
import dayjs from 'dayjs';
import { useBoolean } from 'ahooks';
import adicionarDataEntregaProcesso from 'src/modulos/processos/casos-de-uso/adicionarDataEntregaProcesso';
import { toast } from 'react-toastify';

const ListagemDataEntrega: React.FC = () => {
  const { processo, arquivado, onAdicionadoDataEntrega } =
    useContext(ProcessoContext);
  const [exibirPopup, setExibirPopup] = React.useState<boolean>(false);
  const [processando, { toggle }] = useBoolean(false);

  const fecharPopup = () => setExibirPopup(false);
  const abrirPopup = () => setExibirPopup(true);

  const salvarDataEntrega = async (data: Date) => {
    toggle();
    const { resultado, error } = await adicionarDataEntregaProcesso(
      processo,
      dayjs(data).format('YYYY-MM-DD')
    );
    toggle();

    if (resultado) {
      toast.success('Data de entrega atualizada');
      fecharPopup();
      onAdicionadoDataEntrega(resultado.prazo);
      return;
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  if (!processo.prazos || processo.prazos.length === 0) {
    return <></>;
  }

  return (
    <div className="pt-5 position-relative">
      <h5 className="mb-2">Data de entrega</h5>
      <div className="d-flex flex-row align-items-start">
        <div className="checkbox checkbox-primary">
          <input
            type="checkbox"
            className="mr-3"
            id="checkboxDataEntrega"
            disabled={arquivado}
          />
          <label htmlFor="checkboxDataEntrega"></label>
        </div>
        <Button
          color="soft-primary"
          onClick={abrirPopup}
          className="mt-2"
          disabled={arquivado}
        >
          {formatarData(processo.prazos[0].data)}
        </Button>
      </div>
      <Dropdown
        mostrar={exibirPopup}
        fechar={fecharPopup}
        titulo="Escolha uma data de entrega"
        alinharEsquerda
      >
        <BlockUi blocking={processando}>
          <Row>
            <Col
              md={12}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <Flatpickr
                options={{
                  minDate: new Date(),
                  inline: true,
                  locale: 'pt',
                  dateFormat: 'd/m/Y',
                  defaultDate: dayjs(processo.prazos[0].data).toDate(),
                }}
                onChange={(datas: Date[]) => {
                  if (datas.length > 0) {
                    salvarDataEntrega(datas[0]);
                  }
                }}
                className="d-none"
              />
            </Col>
          </Row>
        </BlockUi>
      </Dropdown>
    </div>
  );
};

export default ListagemDataEntrega;
