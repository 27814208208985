import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import { Preposto } from '../../entidades/Preposto';
import { Processo } from '../../entidades/Processo';

const marcarPrepostoComoExecutor = async (
  processo: Processo,
  preposto: Preposto
): Promise<IResultadoRequisicao<Andamento>> => {
  try {
    const resultado: Andamento = await api().post(
      `processos/${processo.id}/preposto/principal/${preposto.id}`
    );

    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default marcarPrepostoComoExecutor;
