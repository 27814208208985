import { Pessoa } from 'src/modulos/pessoas/entidades/Pessoa';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import { Processo } from '../../entidades/Processo';

const marcarApresentanteProcesso = async (
  processo: Processo,
  pessoa: Pessoa
): Promise<IResultadoRequisicao<Andamento>> => {
  try {
    const resultado: Andamento = await api().post(
      `processos/${processo.id}/pessoa/principal/${pessoa.id}`
    );

    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default marcarApresentanteProcesso;
