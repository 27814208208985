export type ErroRegraNegocio = {
  mensagem: string;
} & any &
  unknown;
export class RegraNegocioError {
  mensagem: string;

  constructor(mensagem: string) {
    this.mensagem = mensagem;
  }
}
