import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Breadcrumb from 'src/components/Breadcrumb';
import { Container, Row, Col, Card, Form } from 'reactstrap';
import { Wizard, Steps, Step } from 'react-albus';

import AutenticacaoContext from '../../../../contexts/Autenticacao';
import Permissao from '../../../../entidades/Permissao';
import DadosBasicos from './DadosBasicos';
import SociosAdministrdores from './SociosAdministradores';
import DadosDeContato from './DadosDeContato';
import DadosBiometricos from './DadosBiometricos';
import Navegacao from './navegacao';

type CamposFormulario = {
  id: number;
  cnpj: string;
  razao_social: string;
};

const AdicionarPessoa: React.FC = () => {
  const { getValues, setValue, handleSubmit, register, watch } =
    useForm<CamposFormulario>();

  const { temPermissao } = React.useContext(AutenticacaoContext);
  const navigate = useNavigate();

  /*if (!temPermissao(Permissao.PESSOAS_ADICIONAR_PESSOA)) {
    navigate('/pessoas', {
      replace: true,
    });
    return <></>;
  }*/

  const salvar = async (dados: any) => {};

  const menuItens = [
    {
      numero: '01',
      step_id: 'dados-basicos',
      nome: 'Dados Básicos',
      component: DadosBasicos,
    },
    {
      numero: '02',
      step_id: 'socios-administradores',
      nome: 'Sócios Administradores',
      component: SociosAdministrdores,
    },
    {
      numero: '03',
      step_id: 'dados-de-contratos',
      nome: 'Dados de Contatos *',
      component: DadosDeContato,
    },
    {
      numero: '04',
      step_id: 'dados-biometricos',
      nome: 'Dados biométricos',
      component: DadosBiometricos,
    },
  ];

  return (
    <Container className="mt-3">
      <Breadcrumb
        links={[
          {
            url: '/pessoas',
            texto: 'Pessoas',
          },
          {
            texto: 'Adicionar Pessoa',
          },
        ]}
      />
      <Row>
        <Col>
          <Card className="rounded">
            <div className="sw-main sw-theme-arrows">
              <Wizard
                render={({ step, steps }) => (
                  <div className="sw-main sw-theme-arrows">
                    <ul className="nav nav-tabs step-anchor text-white">
                      {menuItens.map((item, key) => (
                        <li
                          key={item.numero}
                          className={`nav-item
                                ${steps.indexOf(step) === key && 'active'}
                                ${steps.indexOf(step) > key && 'done'}
                                `}
                        >
                          <a className="nav-link">
                            {item.numero}
                            <small className="d-block">{item.nome}</small>
                          </a>
                        </li>
                      ))}
                    </ul>
                    <Form onSubmit={handleSubmit(salvar)}>
                      <Container className="mt-4 mb-4">
                        <Steps>
                          {menuItens.map((item, index) => {
                            let StepComponent = item.component;
                            return (
                              <Step id={item.step_id} key={index}>
                                <div className="tab-pane step-content d-block">
                                  <StepComponent
                                    register={register}
                                    getValues={getValues}
                                    setValue={setValue}
                                    watch={watch}
                                  />
                                </div>
                              </Step>
                            );
                          })}
                        </Steps>
                      </Container>
                      <Navegacao />
                    </Form>
                  </div>
                )}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdicionarPessoa;
