import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { livros } from 'src/modulos/aplicacao/util/livros';

const LivrosRCPJ: React.FC = () => {
  const { register } = useFormContext();

  return (
    <Row>
      <Col md={12}>
        <h5>Termo Inicial dos Livros A e B</h5>

        <span className="text-danger d-block mb-2">
          <b>
            Obs.: O número a ser informado é o correspondente ao número do
            próximo ato a ser realizado.
          </b>
        </span>
        <h5>Livro de Protocolo</h5>
        <input
          type="hidden"
          value="Livro de Protocolo"
          {...register(`${livros.RCPJ.LIVRO_PROTOCOLO}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="RCPJ"
          {...register(`${livros.RCPJ.LIVRO_PROTOCOLO}.especialidade`)}
        />
        <input
          type="hidden"
          value={300}
          {...register(`${livros.RCPJ.LIVRO_PROTOCOLO}.folhas_por_livro`)}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.RCPJ.LIVRO_PROTOCOLO}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.RCPJ.LIVRO_PROTOCOLO}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último protocolo</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.RCPJ.LIVRO_PROTOCOLO}.numero_inicial`)}
              />
            </FormGroup>
          </Col>

          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="text"
                className="form-control"
                {...register(
                  `${livros.RCPJ.LIVRO_PROTOCOLO}.registros_por_folha`
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>
          Livro A - Registro geral (art. 114, I e II, da Lei Federal nº
          6.015/73)
        </h5>
        <input
          type="hidden"
          value="Livro A - Registro geral"
          {...register(`${livros.RCPJ.LIVRO_A}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="RCPJ"
          {...register(`${livros.RCPJ.LIVRO_A}.especialidade`)}
        />
        <input
          type="hidden"
          value={300}
          {...register(`${livros.RCPJ.LIVRO_A}.folhas_por_livro`)}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.RCPJ.LIVRO_A}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.RCPJ.LIVRO_A}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último protocolo</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.RCPJ.LIVRO_A}.numero_inicial`)}
              />
            </FormGroup>
          </Col>

          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.RCPJ.LIVRO_A}.registros_por_folha`)}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>
          Livro B - Registros especiais (art. 116, II, da Lei Federal nº
          6.015/73)
        </h5>
        <input
          type="hidden"
          value="Livro B - Registros especiais"
          {...register(`${livros.RCPJ.LIVRO_B}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="RCPJ"
          {...register(`${livros.RCPJ.LIVRO_B}.especialidade`)}
        />
        <input
          type="hidden"
          value={300}
          {...register(`${livros.RCPJ.LIVRO_B}.folhas_por_livro`)}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.RCPJ.LIVRO_B}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.RCPJ.LIVRO_B}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último protocolo</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.RCPJ.LIVRO_B}.numero_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="text"
                className="form-control"
                {...register(`${livros.RCPJ.LIVRO_B}.registros_por_folha`)}
              />
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LivrosRCPJ;
