import React from 'react';
import IconeProcessos from '../../assets/icon-processos.png';
import HeaderTema from 'src/layout/Header';

const Header: React.FC = () => {
  return (
    <HeaderTema
      icone={IconeProcessos}
      urlIcone="/"
      exibirSelecaoCartorios={false}
    />
  );
};

export default Header;
