import React from 'react';
import { Col, Row } from 'reactstrap';
import { PessoaOrcamento } from 'src/modulos/financeiro/entidades/PessoaOrcamento';
import { pegarDenominacaoPessoa } from 'src/modulos/pessoas/entidades/Pessoa';

type Props = {
  pessoa: PessoaOrcamento;
};

const Apresentante: React.FC<Props> = ({ pessoa }) => {
  return (
    <>
      <Row>
        <Col md={7}>
          <span className="d-block">Apresentante</span>
          <b>
            <span>{pegarDenominacaoPessoa(pessoa.especializacao)}</span>
          </b>
        </Col>
        <Col md={5}>
          <span className="d-block">Telefone / WhatsApp</span>
          <b>
            <span>{pessoa.whatsapp}</span>
          </b>
        </Col>
      </Row>
    </>
  );
};

export default Apresentante;
