import React, { useEffect, useState } from 'react';
import { FiArrowUpLeft, FiDownload, FiPauseCircle } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import pegarDadosSelo from '../../casos-de-uso/selo/pegarDadosSelo';
import Selo from '../../entidades/Selo';

const VisualizarSelo: React.FC = () => {
  const { hash } = useParams<{ hash: string }>();
  const [selo] = useState<Selo>();

  useEffect(() => {
    pegarDadosSelo(hash!).then(({ resultado }) => {
      if (resultado) {
        // setSelo(resultado);
      }
    });
  }, [hash]);

  if (!selo) {
    return <>1</>;
  }

  return (
    <>
      <Breadcrumb
        links={[
          {
            url: '/financeiro',
            texto: 'Financeiro',
          },
          {
            url: '/selos',
            texto: 'Selos utilizados',
          },
          {
            texto: `Selo ${selo.codigo}-${selo.codigoValidacao}`,
          },
        ]}
      />
      <Row>
        <Col md={12}>
          <Card>
            <div className="d-flex justify-content-between p-3">
              <h4>
                Selo {selo.codigo}-{selo.codigoValidacao}
              </h4>
              <div>
                <Button
                  color="soft-primary"
                  className="mx-2 align-items-center"
                >
                  <FiArrowUpLeft />
                  <span> Estornar</span>
                </Button>
                <Button
                  color="soft-primary"
                  className="mx-2 align-items-center"
                >
                  <FiPauseCircle />
                  <span>Paralisar envio</span>
                </Button>
                <Button
                  color="soft-primary"
                  className="mx-2 align-items-center"
                >
                  <FiDownload />
                  <span>Baixar XML</span>
                </Button>
              </div>
            </div>
            <CardBody>
              <Table bordered>
                <tbody>
                  <tr>
                    <td className="bg-light col-4">Ato</td>
                    {/* <td>{selo.ato.emolumento.apelido}</td> */}
                  </tr>
                  {/* {selo.ato.dados.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="bg-light">{item.rotulo}</td>
                        <td>{pegarValorAto(item)}</td>
                      </tr>
                    );
                  })} */}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default VisualizarSelo;
