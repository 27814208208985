import formatarDinheiro from 'src/util/formatarDinheiro';
import Selo from '../../entidades/Selo';

export default function gerarDescricaoSelo(selos: Selo[]): string {
  const selo = selos[0];

  const ato = `ATO: ${selo.emolumento}`;
  const emolumentos = `Emolumentos (Cartório): ${formatarDinheiro(
    selo.emolumentos
  )};`;
  const tfj = `TFJ (Tribunal de Justiça): ${formatarDinheiro(selo.tfj)};`;
  const funcivil = `Funcivil (Tribunal de Justiça): ${formatarDinheiro(
    selo.funcivil
  )};`;
  const fse = `FSE (Tribunal de Justiça): ${formatarDinheiro(
    selo.fundo_eletronizacao
  )};`;
  const issCartorio = `ISS {{ cartorioAtual.iss * 100 }}%`;
  const issMunicipio = `(Município): {{ emolumentos.iss | currency }};`;
  const total = `TOTAL: {{ emolumentos.total | currency }}`;
  const seloFiscalizacao = `[SELO DE FISCALIZAÇÃO: ${selo.codigo}-${selo.codigoValidacao} - Valide este selo em: www.selodigital.tjto.org].`;

  return `${ato}[${emolumentos}${tfj}${funcivil}${fse} e ${issCartorio}${issMunicipio}${total}]. ${seloFiscalizacao}`;
}
