import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import Select from 'react-select';
import {
  CKEditor,
  CKEditorEventPayload,
  CKEditorInstance,
} from 'ckeditor4-react';
import { Modelo } from 'src/modulos/processos/entidades/Modelo';
import cadastrarModelo from 'src/modulos/processos/casos-de-uso/configuracoes/modelos/cadastrarModelo';
import { useNavigate, useParams } from 'react-router-dom';
import { useBoolean, useRequest } from 'ahooks';
import pegarModelo from 'src/modulos/processos/casos-de-uso/configuracoes/modelos/pegarModelo';
import atualizarModelo from 'src/modulos/processos/casos-de-uso/configuracoes/modelos/atualizarModelo';
import { toast } from 'react-toastify';
import listarEmolumentos from 'src/modulos/selos/casos-de-uso/emolumentos/listarEmolumentos';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormGroup from 'src/components/Form/FormGroup';
import FeedbackErro from 'src/components/Form/FeedbackErro';
import BlockUi from 'react-block-ui';

const schemaValidacao = yup.object({
  titulo: yup.string().required(),
  especialidade: yup.string().required(),
  conteudo: yup.string().required(),
  emolumento_codigo: yup.number().required(),
});

const especialidades = [
  {
    value: 'RCPN',
    label: 'Registro Civil de Pessoas Naturais',
    especialidade: 'rcpn',
  },
  {
    value: 'NOTAS',
    label: 'Tabelionato de Notas',
    especialidade: 'notas',
  },
];

const links = [
  {
    url: '/',
    texto: 'Controle de Processos',
  },
  {
    url: '/processos/configuracoes/modelos',
    texto: 'Modelos',
  },
];

const GerenciarModelo: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const tituloPagina = id
    ? 'Editar modelo de documento'
    : 'Adicionar modelo de documento';
  const breadcrumb = id ? 'Editar modelo' : 'Adicionar modelo';

  const {
    register,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Modelo>({
    resolver: yupResolver(schemaValidacao),
  });
  const { run, loading: carregandoModelo } = useRequest(
    (id: number) => pegarModelo(Number(id)),
    {
      manual: true,
      onSuccess(modelo) {
        reset(modelo);
      },
    }
  );
  const [processando, { toggle: alternarSalvamento }] = useBoolean(false);

  const [atos, setAtos] = useState<any[]>([]);
  const [, setAtoSelecionado] = useState<any>(null);
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState<
    string | null
  >(null);
  const editorRef = useRef<CKEditorInstance>(null);

  const salvarModelo = async (dados: Modelo) => {
    alternarSalvamento();
    let erro;
    if (id) {
      const { resultado, error } = await atualizarModelo(Number(id), dados);
      alternarSalvamento();

      erro = error;
      if (resultado) {
        toast.success('Modelo atualizado com sucesso.');
        navigate('/processos/configuracoes/modelos');
        return;
      }
    } else {
      const { resultado, error } = await cadastrarModelo(dados);
      alternarSalvamento();

      erro = error;
      if (resultado) {
        toast.success('Modelo cadastrado com sucesso');
        navigate('/processos/configuracoes/modelos');
      }
    }

    if (erro) {
      toast.error(erro.mensagem);
    }
  };

  useEffect(() => {
    if (id) {
      run(Number(id));
    }
  }, [run, id]);

  useEffect(() => {
    if (especialidadeSelecionada !== null) {
      listarEmolumentos(especialidadeSelecionada, '').then(({ resultado }) => {
        if (resultado) {
          setAtos(resultado);
        }
      });
    }
  }, [especialidadeSelecionada]);

  if (carregandoModelo) {
    return <>Carregando...</>;
  }

  return (
    <Container>
      <BlockUi blocking={processando}>
        <Breadcrumb links={[...links, { texto: breadcrumb }]} />
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <h4 className="mb-4">{tituloPagina}</h4>
                <form onSubmit={handleSubmit(salvarModelo)}>
                  <Row>
                    <Col md={12}>
                      <FormGroup erro={errors.titulo?.message}>
                        <Label for="titulo">Título do modelo</Label>
                        <input
                          type="text"
                          id="titulo"
                          className="form-control"
                          placeholder="Ex.: Registro de Nascimento"
                          {...register('titulo')}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <FormGroup erro={errors.especialidade?.message}>
                        <Label for="especialidade">Especialidade</Label>
                        <Select
                          options={especialidades}
                          placeholder="Selecione"
                          onChange={(valor) => {
                            if (valor) {
                              setValue('especialidade', valor.value);
                              setEspecialidadeSelecionada(valor.especialidade);
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={8}>
                      <FormGroup erro={errors.emolumento_codigo?.message}>
                        <Label for="titulo">Ato</Label>
                        <Select
                          options={atos.map((item) => ({
                            value: item.codigo,
                            label: item.titulo,
                            ato: item,
                          }))}
                          placeholder="Selecione o ato"
                          isDisabled={!especialidadeSelecionada}
                          onChange={(valor) => {
                            if (valor) {
                              setValue(
                                'emolumento_codigo',
                                Number(valor.value)
                              );
                              setAtoSelecionado(valor.ato);
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h6>Conteúdo do modelo</h6>
                      <FeedbackErro>{errors.conteudo?.message}</FeedbackErro>
                      <CKEditor
                        editorUrl="https://cdn.ckeditor.com/4.20.0/full-all/ckeditor.js"
                        onInstanceReady={(editor) => {
                          if (editor.editor) {
                            editorRef.current = editor.editor;
                            editor.editor.setData(getValues('conteudo'));
                          }
                        }}
                        config={{
                          height: '70vh',
                        }}
                        initData={getValues('conteudo')}
                        onChange={(evento: CKEditorEventPayload<'change'>) => {
                          if (evento.editor) {
                            setValue('conteudo', evento.editor.getData());
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <div className="d-flex justify-content-end mt-4">
                    <Button color="soft-info" className="mr-3">
                      Cancelar
                    </Button>
                    <Button color="primary">Salvar modelo</Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </BlockUi>
    </Container>
  );
};

export default GerenciarModelo;
