import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';

const AlterarSenha: React.FC = () => {
  return (
    <>
      <Breadcrumb
        links={[
          { url: '/', texto: 'Inicio ' },
          { url: '/usuarios/seguranca', texto: ' Segurança' },
          { texto: ' Alterar senha' },
        ]}
      />
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <Card>
              <CardBody>
                <h3>Alterar senha</h3>
                <form>
                  <FormGroup>
                    <Label>Senha atual</Label>
                    <input type="password" className="form-control" />
                  </FormGroup>
                  <FormGroup>
                    <Label>Nova senha</Label>
                    <input type="password" className="form-control" />
                  </FormGroup>
                  <FormGroup>
                    <Label>Confirmar nova senha</Label>
                    <input type="password" className="form-control" />
                  </FormGroup>
                  <div className="d-flex justify-content-end">
                    <Button color="outline-dark" className="mr-3">
                      Cancelar
                    </Button>
                    <Button color="primary">Salvar alterações</Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AlterarSenha;
