import React from 'react';
import style from './style.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import clsx from 'clsx';

type Props = {
  url?: string | 'default';
  tamanho?: string;
  borda?: boolean;
};

const Avatar: React.FC<Props> = ({
  url = 'default',
  tamanho = '42px',
  borda = false,
}) => {
  return (
    <div className="d-flex">
      <div
        className={clsx('rounded-circle position-relative bg-white', {
          [style.borda]: borda,
          [style['borda-invisivel']]: !borda,
        })}
      >
        <LazyLoadImage
          src={url}
          className={style['avatar']}
          style={{ width: tamanho, height: tamanho }}
          alt=""
          effect="opacity"
          visibleByDefault={url === 'default'}
          onError={(e) => {
            (e.target as HTMLImageElement).src = '/icones/default-avatar.jpg';
          }}
        />
      </div>
    </div>
  );
};

export default Avatar;
