import React, { useContext, useState } from 'react';
import { FiChevronsLeft, FiChevronsRight, FiEdit2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Button, FormGroup, Label } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';
import atualizarPrazoChecklist from 'src/modulos/processos/casos-de-uso/processo/atualizarPrazoChecklist';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import {
  AcaoPrazoChecklist,
  ChecklistProcesso,
} from 'src/modulos/processos/entidades/ChecklistProcesso';

type Props = {
  checklist: ChecklistProcesso;
};

const EditarPrazo: React.FC<Props> = ({ checklist }) => {
  const { processo, arquivado, onPrazoChecklistAtualizado } =
    useContext(ProcessoContext);
  const [dias, setDias] = useState<number>(1);
  const [exibir, setExibir] = useState(false);

  const alternarExibicao = () => setExibir((atual) => !atual);

  const fechar = () => setExibir(false);

  const atualizar = async (acao: AcaoPrazoChecklist) => {
    onPrazoChecklistAtualizado(checklist, acao, dias);
    const { resultado, error } = await atualizarPrazoChecklist(
      processo,
      checklist.id,
      acao,
      dias
    );
    if (resultado) {
      fechar();
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  return (
    <div className="position-relative">
      <Button
        color="soft-primary"
        className="mr-1 d-flex align-items-center"
        size="sm"
        disabled={arquivado}
        onClick={alternarExibicao}
      >
        <FiEdit2 />
        <span className="ml-1">Editar Prazos</span>
      </Button>
      <Dropdown
        mostrar={exibir}
        fechar={fechar}
        titulo="Editar prazos dessa checklist"
      >
        <FormGroup className="mt-3">
          <Label>Reduzir ou prorrogar prazo em...</Label>
          <input
            type="number"
            defaultValue={dias}
            className="form-control"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setDias(Number(event.target.value))
            }
          />
          <div className="d-flex justify-content-end py-2">
            <Button color="warning" onClick={() => atualizar('reduzir')}>
              <FiChevronsLeft />
              <span>Reduzir</span>
            </Button>
            <Button
              color="primary"
              className="ml-2"
              onClick={() => atualizar('prorrogar')}
            >
              <span>Prorrogar</span>
              <FiChevronsRight />
            </Button>
          </div>
        </FormGroup>
      </Dropdown>
    </div>
  );
};

export default EditarPrazo;
