import { useContext, useState } from 'react';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import AutenticacaoContext from 'src/contexts/Autenticacao';
import pegarUrlModulo from 'src/modulos/aplicacao/casos-de-uso/pegarUrlModulo';
import pegarIconeModulo from 'src/modulos/aplicacao/casos-de-uso/pegarIconeModulo';
import Dropdown from 'src/components/Dropdown';
import { Col, Row } from 'reactstrap';

const modulosVisiveis = ['financeiro', 'acervo', 'clientes', 'mosaico'];

const Modulos: React.FC = () => {
  const { modulos } = useContext(AutenticacaoContext);
  const [mostrarModulos, setMostrarModulos] = useState(false);

  const alternarMostrarModulos = () => {
    setMostrarModulos((mostrar) => !mostrar);
  };

  const listarModulos = () => {
    return modulos.filter((item) => modulosVisiveis.includes(item.nome));
  };

  if (!modulos) {
    return <></>;
  }

  return (
    <>
      <li
        className="position-relative right-bar-toggle nav-link btn-group-lg"
        onClick={alternarMostrarModulos}
      >
        <BsFillGrid3X3GapFill />
        <Dropdown
          mostrar={mostrarModulos}
          fechar={() => {
            setMostrarModulos(false);
          }}
          titulo="Ir para o módulo..."
        >
          <div className="container">
            <Row>
              {listarModulos().map((item) => {
                return (
                  <Col md={4} key={item.id} className="py-3">
                    <Link
                      to={pegarUrlModulo(item)}
                      className="d-flex flex-column align-items-center"
                    >
                      <img
                        src={pegarIconeModulo(item)}
                        alt={`Módulo ${item.titulo}`}
                      />
                      <span>{item.titulo}</span>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Dropdown>
      </li>
    </>
  );
};

export default Modulos;
