import React, { useContext } from 'react';
import { FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';
import excluirEtiquetaProcesso from 'src/modulos/processos/casos-de-uso/processo/excluirEtiquetaProcesso';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import { pegarCorEtiqueta } from 'src/modulos/processos/entidades/Etiqueta';
import { EtiquetaProcesso } from 'src/modulos/processos/entidades/EtiquetaProcesso';

const ListagemEtiquetas: React.FC = () => {
  const { processo, arquivado, onExclusaoEtiqueta } =
    useContext(ProcessoContext);

  const excluir = async (etiqueta: EtiquetaProcesso) => {
    const confirmacao = window.confirm('Remover etiqueta do processo?'); // todo: mudar para modal

    if (confirmacao) {
      const { resultado, error } = await excluirEtiquetaProcesso(
        processo,
        etiqueta
      );
      if (resultado) {
        onExclusaoEtiqueta(etiqueta, resultado);
        return;
      }
      if (error) {
        toast.error(error.mensagem);
      }
    }
  };

  if (processo.etiquetas.length === 0) {
    return <></>;
  }

  return (
    <div className="py-5">
      <h5 className="mb-3">Etiquetas</h5>
      <div className="d-flex flex-row flex-wrap">
        {processo.etiquetas.map((item: EtiquetaProcesso) => {
          return (
            <div
              key={item.id}
              className="d-flex flex-row align-items-center py-2 pl-3 mr-2 mt-2 rounded shadow-sm"
              style={pegarCorEtiqueta(item)}
            >
              <span className="flex-fill">{item.titulo}</span>
              {!arquivado && (
                <FiX className="mx-3" onClick={() => excluir(item)} />
              )}
            </div>
          );
        })}
        {/* <button className="btn btn-soft-primary text-primary px-3 d-flex align-items-center">
          <FiPlus />
        </button> */}
      </div>
    </div>
  );
};

export default ListagemEtiquetas;
