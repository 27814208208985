import { ErroRegraNegocio } from "src/entidades/ErroRegraNegocio";
import api from "../../api";
import { DadoBancario } from "../../entidades/DadoBancario";

const visualizarDadoBancario = async (): Promise<{
  resultado?: DadoBancario[];
  error?: ErroRegraNegocio;
}> => {
  try {
    const resultado: DadoBancario[] = await api().get(
      '/dado-bancario/visualizar'
    );
    return { resultado };
  } catch (error: ErroRegraNegocio | any) {
    return { error };
  }
};

export default visualizarDadoBancario;
