import Titulo from '../../../../components/Titulo';
import Breadcrumb from '../../../../components/Breadcrumb';
import { CgArrowsExchangeV, CgDanger } from 'react-icons/cg';
import { FiTrash } from 'react-icons/fi';
import { BsCheck2Circle } from 'react-icons/bs';

const NavegadoresConfirmados: React.FC = () => {
  return (
    <div className="container p-3 d-flex justify-content-center">
      <div>
        <Titulo titulo="Navegadores Confirmados" />
        <Breadcrumb
          links={[
            { url: '/usuarios', texto: 'Inicio ' },
            { url: '/usuarios/seguranca', texto: ' Segurança ' },
            { texto: ' Navegadores Confirmados' },
          ]}
        />
        <div className="card" style={{ minWidth: 850 }}>
          <div className="card-content p-5">
            <h2>Navegadores Confirmados</h2>

            <div className="mt-4">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>
                      Última Tentativa de Acesso {''} <CgArrowsExchangeV />
                    </th>
                    <th>Navegador</th>
                    <th>IP</th>
                    <th>Local</th>
                    <th>Status</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="d-flex justify-items-start">
                        <div className="mr-3"></div>
                        <div>
                          <span className="d-block">
                            <b>23/05/2021 às 16:13</b>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span>Firefox (Windows)</span>
                    </td>
                    <td>
                      <span>192.168.0.2</span>
                    </td>
                    <td>
                      <span>Palmas - TO (Brasil)</span>
                    </td>
                    <td
                      style={{
                        color: '#de8f07',
                      }}
                      className="text-center"
                    >
                      <span>
                        <CgDanger />
                      </span>
                    </td>
                    <td className="text-danger text-center">
                      <span>
                        <FiTrash />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex justify-items-start">
                        <div className="mr-3"></div>
                        <div>
                          <span className="d-block">
                            <b>23/05/2021 às 16:13</b>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span>Chrome (Windows)</span>
                    </td>
                    <td>
                      <span>192.168.0.1</span>
                    </td>
                    <td>
                      <span>Palmas - TO (Brasil)</span>
                    </td>
                    <td
                      style={{
                        color: '#14b562',
                      }}
                      className="text-center"
                    >
                      <span>
                        <BsCheck2Circle />
                      </span>
                    </td>
                    <td className="text-danger text-center">
                      <span>
                        <FiTrash />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavegadoresConfirmados;
