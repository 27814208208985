import React from 'react';
import {
  FiBarChart,
  FiCheckSquare,
  FiGrid,
  FiSettings,
  FiTag,
} from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';

const Configuracoes: React.FC = () => {
  const [mostrarConfiguracoes, setMostrarConfiguracoes] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    setMostrarConfiguracoes(false);
  }, [location]);

  return (
    <li className="position-relative right-bar-toggle nav-link btn-group-lg">
      <FiSettings
        className="font-size-20 cursor-pointer"
        onClick={() => setMostrarConfiguracoes((atual) => !atual)}
      />
      <Dropdown
        mostrar={mostrarConfiguracoes}
        fechar={() => {
          setMostrarConfiguracoes(false);
        }}
        titulo="Configurações desse módulo"
      >
        <div className="text-left">
          <Row>
            <Col md={12}>
              <ul className="list-unstyled">
                <li>
                  <Link
                    to="/processos/configuracoes/checklists"
                    className="d-flex flex-row py-3 align-items-center"
                  >
                    <FiCheckSquare />
                    <span className="flex-fill ml-3">Checklists</span>
                  </Link>
                </li>
                <li className="">
                  <Link
                    to="/processos/configuracoes/etiquetas"
                    className="d-flex flex-row py-3 align-items-center"
                  >
                    <FiTag />
                    <span className="flex-fill ml-3">Etiquetas</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/processos/configuracoes/modelos"
                    className="d-flex flex-row py-3 align-items-center"
                  >
                    <FiGrid />
                    <span className="flex-fill ml-3">Modelos</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/processos/configuracoes/relatorios"
                    className="d-flex flex-row py-3 align-items-center"
                  >
                    <FiBarChart />
                    <span className="flex-fill ml-3">Relatórios</span>
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Dropdown>
    </li>
  );
};

export default Configuracoes;
