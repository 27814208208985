export default function pegarTipoArquivo(nome: string) {
  if (!nome) {
    return 'url';
  }
  const tipo = nome.split('.').pop() || '';

  if (['doc', 'docx'].includes(tipo)) {
    return 'docx';
  }

  if (['pdf', 'application/pdf'].includes(tipo)) {
    return 'pdf';
  }

  if (['jpg', 'jpeg', 'png', 'gif'].includes(tipo)) {
    return 'imagem';
  }

  return 'url';
}
