import { Preposto } from './Preposto';

export interface PrepostoProcesso extends Preposto {
  pivot: {
    processo_id: number;
    usuario_id: number;
    principal: number;
    pontuacao: number;
    pontuacao_final: number;
    justificativa: string | null;
    data_arquivamento: string | null;
    created_at: Date | string;
    updated_at: Date | string;
  };
}

export const prepostoEhExecutor = (preposto: PrepostoProcesso): boolean => {
  return preposto.pivot.principal === 1;
};

export const prepostoEhRevisor = (preposto: PrepostoProcesso): boolean => {
  return preposto.pivot.principal !== 1;
};

export const pegarFuncaoPreposto = (preposto: PrepostoProcesso) => {
  if (prepostoEhExecutor(preposto)) {
    return 'Executor';
  }
  return 'Revisor';
};
