import React from 'react';
import { pegarDenominacaoPessoa } from 'src/modulos/pessoas/entidades/Pessoa';
import { PessoaProcesso } from 'src/modulos/processos/entidades/PessoaProcesso';

type Props = {
  apresentante: PessoaProcesso | null;
};

const Pessoas: React.FC<Props> = ({ apresentante }) => {
  if (apresentante === null) return <></>;
  return (
    <div>
      <small className="">
        {pegarDenominacaoPessoa(apresentante).toUpperCase()}
      </small>
    </div>
  );
};

export default Pessoas;
