import clsx from 'clsx';
import React, { useState } from 'react';
import Dropdown from 'src/components/Dropdown';
import AutenticacaoContext from 'src/contexts/Autenticacao';
import formatarCNSCartorio from 'src/modulos/aplicacao/casos-de-uso/cartorio/formatarCNSCartorio';
import { Cartorio } from 'src/modulos/aplicacao/entidades/Cartorio';
import css from './style.module.css';

const SelecionarCartorio: React.FC = () => {
  const { cartorio, cartorios, fazerTrocaCartorio } =
    React.useContext(AutenticacaoContext);
  const [resultadoBusca, setResultadoBusca] = useState<Cartorio[]>([]);
  const [exibirSelecaoCartorio, setExibirSelecaoCartorio] =
    React.useState(false);

  const pegarCartorios = () => {
    let resultado = [];
    if (resultadoBusca && resultadoBusca.length > 0) {
      resultado = resultadoBusca;
    } else {
      resultado = cartorios;
    }
    return resultado.filter((item) => item.id !== cartorio.id);
  };

  const pesquisarCartorio = (termo: string) => {
    termo = termo.toLowerCase();
    const resultado = cartorios.filter((item) => {
      const cns = (item.cns || '').toLocaleLowerCase();
      const denominacao = (item.denominacao || '').toLocaleLowerCase();
      const nomeFantasia = (item.nome_fantasia || '').toLocaleLowerCase();

      return (
        cns.indexOf(termo) >= 0 ||
        denominacao.indexOf(termo) >= 0 ||
        nomeFantasia.indexOf(termo) >= 0
      );
    });
    setResultadoBusca(resultado);
  };

  const selecionarCartorio = async (cartorio: Cartorio) => {
    await fazerTrocaCartorio(cartorio);
    setExibirSelecaoCartorio(false);
    setResultadoBusca([]);
  };

  const formatarNomeCartorio = (cartorio: Cartorio) => {
    return `${cartorio.nome_fantasia || ''}${
      cartorio.nome_fantasia ? ' - ' : ''
    }CNS ${formatarCNSCartorio(cartorio)}`;
  };

  return (
    <div className="position-relative">
      <button
        className="btn btn-soft-primary"
        type="button"
        onClick={() => setExibirSelecaoCartorio((atual) => !atual)}
      >
        {cartorio.nome_fantasia} - {formatarCNSCartorio(cartorio)}
      </button>
      <Dropdown
        mostrar={exibirSelecaoCartorio}
        fechar={() => setExibirSelecaoCartorio(false)}
        alinharEsquerda
      >
        <div>
          {cartorios.length > 3 && (
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Pesquise pelo nome ou cns do cartório"
                autoFocus
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  pesquisarCartorio(event.target.value);
                }}
              />
            </div>
          )}
          <div className={clsx(css['lista-cartorios'], 'mt-3')}>
            <div>
              {pegarCartorios().map((item) => {
                return (
                  <span
                    className={clsx(
                      'd-block p-2 cursor-pointer',
                      css['item-cartorio']
                    )}
                    onClick={() => selecionarCartorio(item)}
                    key={item.id}
                  >
                    {formatarNomeCartorio(item)}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default SelecionarCartorio;
