import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import AnexoProcesso from '../../entidades/AnexoProcesso';
import { Processo } from '../../entidades/Processo';

interface IRespostaAnexoProcesso {
  andamento: Andamento;
  anexo: AnexoProcesso;
}

const adicionarArquivoProcesso = async (
  processo: Processo,
  anexo: FormData,
  onProgressoEnvio?: (progressEvent: any) => void
): Promise<IResultadoRequisicao<IRespostaAnexoProcesso>> => {
  try {
    const resultado: IRespostaAnexoProcesso = await api().post(
      `/processos/${processo.id}/anexo`,
      anexo,
      {
        ...(onProgressoEnvio ? { onUploadProgress: onProgressoEnvio } : {}),
      }
    );

    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default adicionarArquivoProcesso;
