import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { AcaoPrazoChecklist } from '../../entidades/ChecklistProcesso';
import { Processo } from '../../entidades/Processo';

export default async function atualizarPrazoChecklist(
  processo: Processo,
  idChecklist: number,
  acao: AcaoPrazoChecklist,
  dias = 1
): Promise<IResultadoRequisicao<number>> {
  try {
    const resultado: number = await api().post(
      `processos/${processo.id}/checklist/${idChecklist}/${acao}`,
      {
        dias,
      }
    );
    return { resultado };
  } catch (error) {
    return { error };
  }
}
