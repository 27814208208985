export const livros = {
  RCPN: {
    LIVRO_PROTOCOLO: 'RCPN_LIVRO_PROTOCOLO',
    LIVRO_A: 'RCPN_LIVRO_A',
    LIVRO_B: 'RCPN_LIVRO_B',
    LIVRO_B_AUXILIAR: 'RCPN_LIVRO_B_AUXILIAR',
    LIVRO_C: 'RCPN_LIVRO_C',
    LIVRO_C_AUXILIAR: 'RCPN_LIVRO_C_AUXILIAR',
    LIVRO_D: 'RCPN_LIVRO_D',
    LIVRO_E: 'RCPN_LIVRO_E',
  },
  RTD: {
    LIVRO_A_PROTOCOLO: 'RTD_LIVRO_A_PROTOCOLO',
    LIVRO_B_TRANSLADACAO: 'RTD_LIVRO_B_TRANSLADACAO',
    LIVRO_C_INSCRICAO: 'RTD_LIVRO_C_INSCRICAO',
    LIVRO_C_INDICADOR_PESSOAL: 'RTD_LIVRO_C_INDICADOR_PESSOAL',
  },
  RCPJ: {
    LIVRO_PROTOCOLO: 'RCPJ_LIVRO_PROTOCOLO',
    LIVRO_A: 'RCPJ_LIVRO_A',
    LIVRO_B: 'RCPJ_LIVRO_B',
  },
  IMOVEIS: {
    LIVRO_1_PROTOCOLO: 'IMOVEIS_LIVRO_1_PROTOCOLO',
    LIVRO_2_GERAL: 'IMOVEIS_LIVRO_2_GERAL',
    LIVRO_3_REGISTRO_AUXILIAR: 'IMOVEIS_LIVRO_3_REGISTRO_AUXILIAR',
    LIVRO_3_TRANSCRICAO: 'IMOVEIS_LIVRO_3_TRANSCRICAO',
    LIVRO_4_INDICADOR_REAL: 'IMOVEIS_LIVRO_3_INDICADOR_REAL',
    LIVRO_5_INDICADOR_PESSOAL: 'IMOVEIS_LIVRO_3_INDICADOR_PESSOAL',
  },
  NOTAS: {
    LIVRO_PROTOCOLO: 'NOTAS_LIVRO_PROTOCOLO',
  },
};
