import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import pegarProcessosOrcamento from 'src/modulos/financeiro/casos-de-uso/orcamento/pegarProcessosOrcamento';
import {
  gerarUrlProcesso,
  Processo,
} from 'src/modulos/processos/entidades/Processo';

type Props = {
  idOrcamento: number;
};

const Processos: React.FC<Props> = ({ idOrcamento }) => {
  const [processos, setProcessos] = useState<Processo[]>([]);

  useEffect(() => {
    pegarProcessosOrcamento(idOrcamento).then(({ resultado }) => {
      if (resultado) {
        setProcessos(resultado);
      }
    });
  }, [idOrcamento]);

  if (processos.length === 0) {
    return null;
  }
  return (
    <Row>
      <Col md={12}>
        <h6>Processos vinculados</h6>
        {processos.map((item, idx) => (
          <>
            <Link
              key={item.id}
              to={gerarUrlProcesso(item)}
              target="_blank"
              className="text-primary"
            >
              <span>PROCESSO Nº {item.numero}</span>
            </Link>
            {idx !== processos.length - 1 && <span className="mr-1">,</span>}
          </>
        ))}
      </Col>
    </Row>
  );
};

export default Processos;
