import { useRequest } from 'ahooks';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { listarLivros, salvarLivros } from '../../casos-de-uso/livros';
import LivrosNotas from './LivrosNotas';
import LivrosProtesto from './LivrosProtesto';
import LivrosRCPJ from './LivrosRCPJ';
import LivrosRCPN from './LivrosRCPN';
import LivrosRI from './LivrosRI';
import LivrosRTD from './LivrosRTD';

const tabs = [
  {
    id: 'rcpn',
    titulo: 'Registro Civil de Pessoas Naturais',
    componente: <LivrosRCPN />,
  },
  {
    id: 'rcpj',
    titulo: 'Registro Civil de Pessoas Jurídicas',
    componente: <LivrosRCPJ />,
  },
  {
    id: 'rtd',
    titulo: 'Registro de Títulos e Documentos',
    componente: <LivrosRTD />,
  },
  {
    id: 'ri',
    titulo: 'Registro de Imóveis',
    componente: <LivrosRI />,
  },
  {
    id: 'notas',
    titulo: 'Tabelionato de Notas',
    componente: <LivrosNotas />,
  },
  {
    id: 'protesto',
    titulo: 'Tabelionato de Protesto de Títulos',
    componente: <LivrosProtesto />,
  },
];

const TermoInicial: React.FC = () => {
  const form = useForm();
  const { loading: processando } = useRequest(listarLivros, {
    onSuccess(dados) {
      form.reset(dados);
    },
  });
  console.log(form.getValues());
  const [tabSelecionada, setTabSelecionada] = useState<string>('rcpn');

  const salvar = form.handleSubmit(async (dados) => {
    await salvarLivros(dados);
  });

  if (processando) {
    return <></>;
  }

  return (
    <>
      <Breadcrumb
        links={[
          { url: '/', texto: 'Inicio ' },
          { texto: ' Controle de Termo Inicial (Livros)' },
        ]}
      />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <FormProvider {...form}>
                <form onSubmit={salvar}>
                  <h4>Controle de termo incial - Sequência dos livros</h4>
                  <div className="mt-3">
                    <Nav tabs>
                      {tabs.map((item) => (
                        <NavItem
                          className="cursor-pointer"
                          key={item.id}
                          onClick={() => setTabSelecionada(item.id)}
                        >
                          <NavLink active={item.id === tabSelecionada}>
                            {item.titulo}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                    <Alert color="warning" className="mt-3">
                      Após salvar, não será possível editar. Por favor,
                      certifique-se de ter informado os dados corretamente. Caso
                      seja necessário correções, contate nosso suporte.
                    </Alert>
                    <TabContent activeTab={tabSelecionada}>
                      {tabs.map((item) => (
                        <TabPane id={item.id} tabId={item.id}>
                          {item.componente}
                        </TabPane>
                      ))}
                    </TabContent>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                  </div>
                </form>
              </FormProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TermoInicial;
