import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Usuario } from '../../entidades/Usuario';

const pegarPerfilUsuario = async (
): Promise<IResultadoRequisicao<Usuario>> => {
  try {
    const resultado: Usuario = await api().get('perfil');
    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default pegarPerfilUsuario;
