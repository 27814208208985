import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { FormProvider, useForm } from 'react-hook-form';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import listarEmolumentos from '../../casos-de-uso/emolumentos/listarEmolumentos';
import { IFormularioSelo } from '../../entidades/IFormularioSelo';
import { GerarSeloProvider, useGerarSeloContext } from './GerarSeloContext';
import Protocolo from './Protocolo';

const GerarSelo: React.FC = () => {
  const { protocolo, atoSelecionado, setAtoSelecionado, salvandoAto } =
    useGerarSeloContext();
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [carregando, setCarregando] = useState(true);
  const [formulario, setFormulario] = useState<IFormularioSelo | null>(null);
  const [emolumentos, setEmolumentos] = useState<any[]>();

  const pegarFormulario = async () => {
    setCarregando(true);
    if (protocolo.especialidade) {
      const pastaComponente = protocolo.especialidade;
      const { default: form }: { default: IFormularioSelo } = await import(
        `../../components/Formularios/${pastaComponente}/${atoSelecionado.emolumento.formulario}`
      );
      setFormulario(form);
    }

    setCarregando(false);
  };

  const formatarListaEmolumentos = () => {
    if (!emolumentos) return [];
    return emolumentos.map((item) => ({
      value: item.codigo,
      label: item.titulo,
      emolumento: item,
    }));
  };

  const atoEstahSelecionado = atoSelecionado !== null;

  useEffect(() => {
    listarEmolumentos(protocolo.especialidade.toLowerCase(), '2022-06-06').then(
      ({ resultado }) => {
        setEmolumentos(resultado);
      }
    );
  }, [protocolo.especialidade]);

  useEffect(() => {
    if (atoSelecionado !== null) {
      pegarFormulario();
      methods.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atoSelecionado]);

  return (
    <>
      <FormProvider {...methods}>
        <BlockUi blocking={salvandoAto}>
          <Container>
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <Protocolo />
                    <hr />
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Qual ato será realizado?</Label>
                          <Select
                            options={formatarListaEmolumentos()}
                            onChange={(valor) => {
                              setAtoSelecionado(valor);
                            }}
                            placeholder="Selecione ou busque pelo ato"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {atoEstahSelecionado && (
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <React.Suspense fallback="Loading views...">
                        {!carregando &&
                          formulario !== null &&
                          formulario.formulario}
                      </React.Suspense>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        </BlockUi>
      </FormProvider>
    </>
  );
};

const Wrapper: React.FC = () => (
  <GerarSeloProvider>
    <GerarSelo />
  </GerarSeloProvider>
);
export default Wrapper;
