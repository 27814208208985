import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../../aplicacao/api';
import { OrcamentoCompleto } from '../../entidades/OrcamentoCompleto';

export default async function pegarOrcamentoCompleto(
  id: number
): Promise<IResultadoRequisicao<OrcamentoCompleto>> {
  try {
    const resultado: OrcamentoCompleto = await api().get(
      `gerencia/orcamento/${id}/completo`
    );
    return {
      resultado,
    };
  } catch (error) {
    return { error };
  }
}
