import axios from 'axios';
import { ErroRegraNegocio } from 'src/entidades/ErroRegraNegocio';
import { get } from 'tiny-cookie';

const api = () => {
  const http = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      ...(get('vc_token')
        ? { Authorization: `Bearer ${get('vc_token')}` }
        : {}),
    },
  });

  http.interceptors.response.use(
    (res) => {
      if (
        res.headers['content-type'] &&
        res.headers['content-type'].indexOf('application/json') >= 0
      ) {
        return res.data;
      }
      return res;
    },
    (err) => {
      if (err && [401, 403, null, undefined].includes(err.status)) {
        window.location.href = '/entrar';
        return Promise.reject(err);
      }
      if (err && err.response.data && err.response.data.erro) {
        const erro: ErroRegraNegocio = {
          mensagem: err.response.data.erro,
        };
        return Promise.reject(erro);
      }
      return Promise.reject(err);
    }
  );
  return http;
};

export default api;
