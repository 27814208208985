export type CidadeType = {
  id: number;
  nome: string;
};

export default class Cidade {
  id: number;
  nome: string;
  constructor(cidade: CidadeType) {
    this.id = cidade.id;
    this.nome = cidade.nome;
  }
}
