import iconProcessos from '../../processos/assets/icon-processos.png';
import iconFinanceiro from '../../financeiro/assets/icon-financeiro.png';
import iconAcervo from '../../acervo/assets/icon-acervo.png';
import iconPessoas from '../../pessoas/assets/icon-pessoas.png';

import { Modulo } from '../../entidades/Modulo';

var modulos: Modulo[] = [
  {
    nome: 'Processos',
  },
  {
    nome: 'Financeiro',
  },
  {
    nome: 'Acervo',
  },
  {
    nome: 'Pessoas',
  },
  {
    nome: 'Mosaico',
  },
  {
    nome: 'Usuários',
  },
];

export default function buscarDetalhesDoModulo(id: string | undefined) {
  var moduloAlvo: Modulo | undefined = undefined;

  modulos.map((modulo) => {
    if (modulo.nome.toLowerCase() === id) moduloAlvo = modulo;
  });

  return moduloAlvo;
}
