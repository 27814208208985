import React, { useContext, useState } from 'react';
import { FiPaperclip, FiPlus } from 'react-icons/fi';
import { Col, Row } from 'reactstrap';

import ConteudoDoAto from './ConteudoDoAto';
import DocumentosCadastrais from './DocumentosCadastrais';
import DocumentosDoProcesso from './DocumentosDoProcesso';
// import MinutasDoAto from './MinutasDoAto';
import css from '../style.module.css';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import DropdownAnexarArquivo from './DropdownAnexar';

const ListagemAnexos: React.FC = () => {
  const { processo } = useContext(ProcessoContext);
  const [mostrar, setMostrar] = useState(false);

  if (processo.anexos && processo.anexos.length === 0) {
    return <></>;
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className={css.titulo}>
          <FiPaperclip className={css['icone-titulo']} />
          <h5>Anexos</h5>
        </h3>

        <button
          className="btn btn-soft-primary"
          onClick={() => setMostrar(true)}
        >
          <FiPlus />
          <span>Adicionar anexo</span>
        </button>

        <DropdownAnexarArquivo mostrar={mostrar} setMostrar={setMostrar} />
      </div>
      <Row>
        <Col md={12} className="mb-3">
          <ConteudoDoAto />
        </Col>
        <Col md={12}>
          <DocumentosDoProcesso />
          <DocumentosCadastrais />
          {/* <div className="space-y-5 pl-10">
        <MinutasDoAto />
      </div> */}
        </Col>
      </Row>
    </div>
  );
};

export default ListagemAnexos;
