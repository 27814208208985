import { useBoolean } from 'ahooks';
import React, { useContext } from 'react';
import BlockUi from 'react-block-ui';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Alert, Button, Col, FormGroup, Label, Row } from 'reactstrap';
import AutenticacaoContext from 'src/contexts/Autenticacao';
import { salvarCredenciaisAnoreg } from 'src/modulos/aplicacao/casos-de-uso/integracao';
import { IntegracaoAnoreg } from 'src/modulos/aplicacao/entidades/IntegracaoAnoreg';

const Anoreg: React.FC = () => {
  const { cartorio } = useContext(AutenticacaoContext);
  const { register, handleSubmit } = useForm<IntegracaoAnoreg>();
  const [processando, { toggle }] = useBoolean(false);

  const salvar = async (dados: IntegracaoAnoreg) => {
    toggle();
    const { resultado, error } = await salvarCredenciaisAnoreg(dados);
    toggle();
    if (resultado) {
      toast.success('Credenciais atualizadas com sucesso.');
      return;
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };
  return (
    <Row>
      <Col md={12}>
        <BlockUi blocking={processando}>
          <form onSubmit={handleSubmit(salvar)}>
            <Alert color="info">
              Para a utilização dos serviços da ANOREG é necessário informar as
              credenciais de acesso.
            </Alert>
            <Alert color="info">
              O certificado será utilizado para assinatura digital de atos
              realizados.
            </Alert>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Client ID</Label>
                  <input
                    type="text"
                    className="form-control"
                    disabled={cartorio?.integracao_anoreg?.client_id}
                    {...register('client_id')}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Client Secret</Label>
                  <input
                    type="text"
                    className="form-control"
                    disabled={cartorio?.integracao_anoreg?.client_secret}
                    {...register('client_secret')}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Certificado A1</Label>
                  <input
                    type="file"
                    className="form-control"
                    disabled={cartorio?.integracao_anoreg?.certificado_a1}
                    {...register('certificado_a1')}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" className="px-3" type="submit">
              Salvar
            </Button>
          </form>
        </BlockUi>
      </Col>
    </Row>
  );
};

export default Anoreg;
