interface ICor {
  background: string;
  color: string;
}

export interface Etiqueta {
  id: number;
  titulo: string;
  cor: string;
  usuario_id: number;
  cartorio_id: null;
  created_at: Date;
  updated_at: Date;
}

export const cores: { [key: string]: ICor } = {
  'bgm-black': {
    background: '#000',
    color: '#fff',
  },
  'bgm-gray': {
    background: '#9e9e9e',
    color: '#000',
  },
  'bgm-lightgreen': {
    background: '#8bc34a',
    color: '#000',
  },
  'bgm-green': {
    background: '#4caf50',
    color: '#fff',
  },
  'bgm-red': {
    background: '#f44336',
    color: '#fff',
  },
  'bgm-brown': {
    background: '#795548',
    color: '#fff',
  },
  'bgm-teal': {
    background: '#009688',
    color: '#fff',
  },
  'bgm-deeppurple': {
    background: '#673ab7',
    color: '#fff',
  },
  'bgm-cyan': {
    background: '#00bcd4',
    color: '#000',
  },
  'bgm-blue': {
    background: '#2196f3',
    color: '#fff',
  },
  'bgm-bluegray': {
    background: '#607d8b',
    color: '#fff',
  },
  'bgm-indigo': {
    background: '#3f51b5',
    color: '#fff',
  },
  'bgm-deeporange': {
    background: '#ff5722',
    color: '#fff',
  },
  'bgm-orange': {
    background: '#ff9800',
    color: '#000',
  },
  'bgm-amber ': {
    background: '#ffc107',
    color: '#000',
  },
};

export const pegarCorEtiqueta = (etiqueta: Etiqueta) => {
  return cores[etiqueta.cor!] || cores['bgm-black'];
};
