import React, { useState } from 'react';
import { FiPrinter } from 'react-icons/fi';
import { Button } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';

const Imprimir: React.FC = () => {
  const [exibirPopup, setExibirPopup] = useState(false);

  const abrirPopup = () => {
    setExibirPopup(true);
  };

  const fecharPopup = () => {
    setExibirPopup(false);
  };

  return (
    <div className="relative">
      <Button
        color="soft-secondary"
        block
        className="d-flex align-items-center"
        onClick={abrirPopup}
      >
        <FiPrinter className="mr-2" />
        <span className="text-azul-secundario">Imprimir</span>
      </Button>
      <Dropdown
        mostrar={exibirPopup}
        fechar={fecharPopup}
        titulo="Imprimir"
        largura="300px"
      >
        <span className="d-block py-2 cursor-pointer">Completo</span>
        <span className="d-block py-2 cursor-pointer">Resumo</span>
        <span className="d-block py-2 cursor-pointer">Capa</span>
      </Dropdown>
    </div>
  );
};

export default Imprimir;
