export interface TransacaoLivroDiario {
  id: number;
  data: Date;
  quantidade: number;
  historico: string;
  valor: number;
  repasse: number;
  tipo: string;
  conta: string;
  ordem: string;
  comprovante: string;
  editavel: boolean;
}

export const ehDespesa = (dados: TransacaoLivroDiario) => {
  return dados.tipo === 'despesa';
};
