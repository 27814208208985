import React, { useState } from 'react';
import { Etiqueta } from 'src/modulos/processos/entidades/Etiqueta';
import GerenciaEtiqueta from 'src/modulos/processos/components/Etiquetas';

type Props = {
  onEtiquetasAtualizadas(ids: number[]): void;
};

const Etiquetas: React.FC<Props> = ({ onEtiquetasAtualizadas }) => {
  const [etiquetasMarcadas, setEtiquetasMarcadas] = useState<number[]>([]);

  const onEtiquetaSelecionada = (etiqueta: Etiqueta) => {
    setEtiquetasMarcadas((atual) => {
      const atualizado = [...atual, etiqueta.id!];
      onEtiquetasAtualizadas(atualizado);
      return atualizado;
    });
  };

  const onEtiquetaDesmarcada = (etiqueta: Etiqueta) => {
    setEtiquetasMarcadas((atual) => {
      const atualizado = atual.filter((item) => item !== etiqueta.id!);
      onEtiquetasAtualizadas(atualizado);
      return atualizado;
    });
  };

  return (
    <>
      <GerenciaEtiqueta
        onEtiquetaSelecionada={onEtiquetaSelecionada}
        onEtiquetaDesmarcada={onEtiquetaDesmarcada}
        etiquetasMarcadas={etiquetasMarcadas}
      />
    </>
  );
};

export default Etiquetas;
