import React from 'react';
import { useFormContext } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import BuscaCidade from 'src/components/Form/BuscaCidade';
import { Cartorio } from 'src/modulos/aplicacao/entidades/Cartorio';
import constantes from 'src/util/constantes';

const Endereco: React.FC = () => {
  const { register, setValue } = useFormContext<Cartorio>();

  return (
    <>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label>CEP</Label>
            <IMaskInput mask={constantes.MASCARA_CEP} className="form-control" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label>Endereço</Label>
            <input
              type="text"
              className="form-control"
              {...register('endereco')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label>Complemento</Label>
            <input
              type="text"
              className="form-control"
              {...register('complemento')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label>Bairro</Label>
            <input
              type="text"
              className="form-control"
              {...register('bairro')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label>Cidade, UF</Label>
            <BuscaCidade onCidadeSelecionada={(cidade) => {
              setValue('cidade_id', String(cidade.id));
              setValue('cidade_nome', cidade.nome);
            }} />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default Endereco;
