import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  FiUsers,
  FiHome,
  FiDollarSign,
  // FiBookOpen,
  // FiShield,
} from 'react-icons/fi';
// import { CgMenuGridR } from 'react-icons/cg';
import { HiOutlineSpeakerphone } from 'react-icons/hi';

const MenuLateral: React.FC = () => {
  return (
    <div className="left-side-menu">
      <div className="sidebar-content">
        <div id="sidebar-menu">
          <ul className="metismenu" id="menu-bar">
            <li className={clsx('side-nav-item')}>
              <Link to={'/administrativo'}>
                <FiHome />
                Início
              </Link>
            </li>
            <li className={clsx('side-nav-item')}>
              <Link to={'/administrativo/comunicados'}>
                <HiOutlineSpeakerphone />
                Comunicados
              </Link>
            </li>
            <li className={clsx('side-nav-item')}>
              <Link to={'/administrativo/cartorios'}>
                <FiUsers />
                Cartórios
              </Link>
            </li>
            <li className={clsx('side-nav-item')}>
              <Link to={'/administrativo'}>
                <FiDollarSign />
                Financeiro
              </Link>
            </li>
            {/* <li className={clsx('side-nav-item')}>
              <Link to={'/administrativo/contratos'}>
                <FiBookOpen />
                Contratos
              </Link>
            </li> */}
            {/* <li className={clsx('side-nav-item')}>
              <Link to={'/administrativo/usuarios'}>
                <FiUsers />
                Usuários
              </Link>
            </li>
            <li className={clsx('side-nav-item')}>
              <Link to={'/administrativo/papeis'}>
                <FiShield />
                Pápeis e Permissões
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MenuLateral;
