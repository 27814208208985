import Paginacao from 'src/entidades/Paginacao';
import { IResultadoPaginado } from 'src/entidades/ResultadoPaginado';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { FiltroRelatorioProcesso } from '../../entidades/FiltroRelatorioProcesso';
import { RelatorioProcesso } from '../../entidades/RelatorioProcesso';

const formatarDadosApi = (filtro?: FiltroRelatorioProcesso) => {
  if (!filtro) return {};
  return {
    etiquetas: filtro.idsEtiquetas ? filtro.idsEtiquetas.join(',') : null,
    etapas: filtro.fases ? filtro.fases.join(',') : null,
    prepostos: filtro.idsPrepostos ? filtro.idsPrepostos.join(',') : null,
    data_inicio: null,
    data_fim: null,
    prazo: null,
  };
};

export default async function pegarRelatorioProcessos(
  pagina: number = 1,
  filtro?: FiltroRelatorioProcesso
): Promise<IResultadoRequisicao<RelatorioProcesso[]>> {
  try {
    const resultado: IResultadoPaginado<RelatorioProcesso> = await api().get(
      'processos/relatorio/geral',
      {
        params: {
          page: pagina,
          ...formatarDadosApi(filtro),
        },
      }
    );
    const paginacao = new Paginacao(resultado);
    return { resultado: resultado.data, paginacao };
  } catch (error) {
    return { error };
  }
}
