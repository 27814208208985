export interface IPaginacaoApi {
  current_page?: number;
  first_page_url?: string;
  from?: number;
  last_page?: number;
  last_page_url?: string;
  next_page_url?: string;
  path?: string;
  per_page?: number;
  prev_page_url?: string | null;
  to?: number;
  total?: number;
}

export default class Paginacao {
  total: number;

  paginaAtual: number;

  totalPaginas: number;

  porPagina: number;

  constructor(dados: IPaginacaoApi) {
    this.total = dados.total || 0;
    this.paginaAtual = dados.current_page || 1;
    this.totalPaginas = dados.total || 0;
    this.porPagina = dados.per_page || 0;
  }

  public get listaPaginas(): number[] {
    return new Array(this.totalPaginas).fill(1).map((_, item) => item + 1);
  }

  ehUltimaPagina(): boolean {
    return this.paginaAtual === this.totalPaginas;
  }
}
