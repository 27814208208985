import api from 'src/modulos/aplicacao/api';
import { IResultadoRequisicao } from '../../entidades/IResultadoRequisicao';

type AtoParams = {
  selo: any;
  nome_livro: string;
  especialidade: string;
  modelo: string;
  substituicoes: any;
  protocolo_id: number;
  processo_id: number;
};

export default async function gerarAto(
  dados: Partial<AtoParams>
): Promise<IResultadoRequisicao<any>> {
  try {
    const resultado = await api().post('/v2/atos', dados);
    return { resultado };
  } catch (error) {
    return { error };
  }
}
