import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import { Etiqueta } from '../../entidades/Etiqueta';
import { Processo } from '../../entidades/Processo';

const adicionarEtiquetaProcesso = async (
  processo: Processo,
  etiqueta: Etiqueta
): Promise<IResultadoRequisicao<Andamento>> => {
  try {
    const resultado: Andamento = await api().post(
      `/processos/${processo.id}/etiqueta/${etiqueta.id}`
    );

    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default adicionarEtiquetaProcesso;
