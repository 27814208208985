import { useToggle } from 'ahooks';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { livros } from 'src/modulos/aplicacao/util/livros';

const LivrosRCPN: React.FC = () => {
  const { register } = useFormContext();
  const [naoPossuiLivroE, { toggle }] = useToggle();

  return (
    <Row>
      <Col md={12}>
        <h5>Termo Inicial dos Livros A, B, C, D e E</h5>
        <span className="text-danger d-block mb-2">
          <b>
            Obs.: O número a ser informado é o correspondente ao número do
            próximo ato a ser realizado.
          </b>
        </span>
        <h5>Livro de Protocolo</h5>
        <input
          type="hidden"
          value="Livro de Protocolo"
          {...register(`${livros.RCPN.LIVRO_PROTOCOLO}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="RCPN"
          {...register(`${livros.RCPN.LIVRO_PROTOCOLO}.especialidade`)}
        />
        <input
          type="hidden"
          value={300}
          {...register(`${livros.RCPN.LIVRO_PROTOCOLO}.folhas_por_livro`)}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_PROTOCOLO}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_PROTOCOLO}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último protocolo</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_PROTOCOLO}.numero_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="number"
                className="form-control"
                {...register(
                  `${livros.RCPN.LIVRO_PROTOCOLO}.registros_por_folha`
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>Livro A - Registro de Nascimento</h5>
        <input
          type="hidden"
          value="Livro A - Registro de Nascimento"
          {...register(`${livros.RCPN.LIVRO_A}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="RCPN"
          {...register(`${livros.RCPN.LIVRO_A}.especialidade`)}
        />
        <input
          type="hidden"
          value={300}
          {...register(`${livros.RCPN.LIVRO_A}.folhas_por_livro`)}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_A}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_A}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último registro</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_A}.numero_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_A}.registros_por_folha`)}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>Livro B - Registro de Casamento</h5>
        <input
          type="hidden"
          value="Livro B - Registro de Casamento"
          {...register(`${livros.RCPN.LIVRO_B}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="RCPN"
          {...register(`${livros.RCPN.LIVRO_B}.especialidade`)}
        />
        <input
          type="hidden"
          value={300}
          {...register(`${livros.RCPN.LIVRO_B}.folhas_por_livro`)}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_B}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_B}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último registro</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_B}.numero_inicial`)}
              />
            </FormGroup>
          </Col>

          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_B}.registros_por_folha`)}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>
          Livro B Auxiliar - Registro de casamento religioso para efeitos civis
        </h5>
        <input
          type="hidden"
          value="Livro B Auxiliar - Registro de casamento religioso para efeitos civis"
          {...register(`${livros.RCPN.LIVRO_B_AUXILIAR}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="RCPN"
          {...register(`${livros.RCPN.LIVRO_B_AUXILIAR}.especialidade`)}
        />
        <input
          type="hidden"
          value={300}
          {...register(`${livros.RCPN.LIVRO_B_AUXILIAR}.folhas_por_livro`)}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_B_AUXILIAR}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_B_AUXILIAR}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último registro</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_B_AUXILIAR}.numero_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="number"
                className="form-control"
                {...register(
                  `${livros.RCPN.LIVRO_B_AUXILIAR}.registros_por_folha`
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>Livro C - Registro de óbito</h5>
        <input
          type="hidden"
          value="Livro C - Registro de óbito"
          {...register(`${livros.RCPN.LIVRO_C}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="RCPN"
          {...register(`${livros.RCPN.LIVRO_C}.especialidade`)}
        />
        <input
          type="hidden"
          value={300}
          {...register(`${livros.RCPN.LIVRO_C}.folhas_por_livro`)}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_C}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_C}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último registro</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_C}.numero_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_C}.registros_por_folha`)}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>Livro C auxiliar - Registro de natimorto</h5>
        <input
          type="hidden"
          value="Livro C auxiliar - Registro de natimorto"
          {...register(`${livros.RCPN.LIVRO_C_AUXILIAR}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="RCPN"
          {...register(`${livros.RCPN.LIVRO_C_AUXILIAR}.especialidade`)}
        />
        <input
          type="hidden"
          value={300}
          {...register(`${livros.RCPN.LIVRO_C_AUXILIAR}.folhas_por_livro`)}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_C_AUXILIAR}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_C_AUXILIAR}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último registro</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_C_AUXILIAR}.numero_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="number"
                className="form-control"
                {...register(
                  `${livros.RCPN.LIVRO_C_AUXILIAR}.registros_por_folha`
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5>Livro D - Registro de proclamas</h5>
        <input
          type="hidden"
          value="Livro D - Registro de proclamas"
          {...register(`${livros.RCPN.LIVRO_D}.nome_exibicao`)}
        />
        <input
          type="hidden"
          value="RCPN"
          {...register(`${livros.RCPN.LIVRO_D}.especialidade`)}
        />
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_D}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_D}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último registro</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_D}.numero_inicial`)}
              />
            </FormGroup>
          </Col>

          <input
            type="hidden"
            value={300}
            {...register(`${livros.RCPN.LIVRO_D}.folhas_por_livro`)}
          />

          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="number"
                className="form-control"
                {...register(`${livros.RCPN.LIVRO_D}.registros_por_folha`)}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="d-flex">
          <h5>Livro E - Registros especiais</h5>
          <input
            type="hidden"
            value="Livro E - Registros especiais"
            {...register(`${livros.RCPN.LIVRO_E}.nome_exibicao`)}
          />
          <input
            type="hidden"
            value="RCPN"
            {...register(`${livros.RCPN.LIVRO_E}.especialidade`)}
          />
          <input
            type="hidden"
            value={300}
            disabled={naoPossuiLivroE}
            {...register(`${livros.RCPN.LIVRO_E}.folhas_por_livro`)}
          />
          <div className="checkbox checkbox-primary ml-3 mt-2 pt-1">
            <input
              type="checkbox"
              id="checkboxPossuiLivroE"
              value="true"
              onChange={toggle}
            />
            <label htmlFor="checkboxPossuiLivroE">Não possui este livro</label>
          </div>
        </div>
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label>Livro atual</Label>
              <input
                type="number"
                className="form-control"
                disabled={naoPossuiLivroE}
                {...register(`${livros.RCPN.LIVRO_E}.livro_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Folha atual</Label>
              <input
                type="number"
                className="form-control"
                disabled={naoPossuiLivroE}
                {...register(`${livros.RCPN.LIVRO_E}.folha_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Número último registro</Label>
              <input
                type="number"
                className="form-control"
                disabled={naoPossuiLivroE}
                {...register(`${livros.RCPN.LIVRO_E}.numero_inicial`)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Registros por folha</Label>
              <input
                type="number"
                className="form-control"
                disabled={naoPossuiLivroE}
                {...register(`${livros.RCPN.LIVRO_E}.registros_por_folha`)}
              />
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LivrosRCPN;
