import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

const Dashboard: React.FC = () => {
  return (
    <Row className="d-flex justify-content-center">
      <Col className="p-4" sm={8}>
        <b>
          <h4 className="mb-4">Administrativo</h4>
        </b>
        <Card style={{ height: 300 }}>
          <CardBody></CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Dashboard;
