import { Pessoa } from 'src/modulos/pessoas/entidades/Pessoa';

export interface PessoaProcesso extends Pessoa {
  pivot: {
    processo_id: number;
    pessoa_id: number;
    principal: number;
    created_at: Date;
    updated_at: Date;
  };
}

export const pessoaEhApresentante = (pessoa: PessoaProcesso): boolean => {
  return pessoa.pivot.principal === 1;
};
