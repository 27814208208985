import clsx from 'clsx';
import React, { useContext } from 'react';
import { FiCheckSquare } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Badge } from 'reactstrap';
import atualizarItemChecklist from 'src/modulos/processos/casos-de-uso/processo/atualizarItemChecklist';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import {
  ChecklistItemProcesso,
  itemChecklistEstahConcluido,
  pegarCorPrazoItemChecklist,
  pegarPrazoItemChecklist,
} from 'src/modulos/processos/entidades/ChecklistItemProcesso';
import { ChecklistProcesso } from 'src/modulos/processos/entidades/ChecklistProcesso';
import css from '../style.module.css';
import EditarPrazo from './EditarPrazo';
import ExcluirChecklist from './ExcluirChecklist';

const ListagemChecklist: React.FC = () => {
  const { processo, arquivado, onItemChecklistAtualizado } =
    useContext(ProcessoContext);

  const atualizarConclusaoItemChecklist = async (
    checklist: ChecklistProcesso,
    itemChecklist: ChecklistItemProcesso,
    concluido: boolean
  ) => {
    const { resultado, error } = await atualizarItemChecklist(
      processo,
      checklist.id,
      itemChecklist.id,
      concluido
    );
    if (resultado) {
      onItemChecklistAtualizado(checklist, itemChecklist, concluido);
      return;
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  if (processo.checklists.length === 0) {
    return null;
  }

  return (
    <>
      {processo.checklists.map((item) => {
        return (
          <div key={item.id}>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <h3 className={css.titulo}>
                <FiCheckSquare className={css['icone-titulo']} />
                <h5>{item.titulo}</h5>
              </h3>
              <div className="d-flex w-50 justify-content-end">
                <EditarPrazo checklist={item} />
                <ExcluirChecklist checklist={item} />
              </div>
            </div>
            <div className={css['espaco-lateral']}>
              {item.itens.map((checklistItem) => {
                return (
                  <div
                    className="d-flex flex-row align-items-center"
                    key={checklistItem.id}
                  >
                    <div className="checkbox checkbox-primary flex-fill">
                      <input
                        type="checkbox"
                        id={`checkboxChecklist_${item.id}_${checklistItem.id}`}
                        checked={itemChecklistEstahConcluido(checklistItem)}
                        disabled={arquivado}
                        onChange={(
                          evento: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          atualizarConclusaoItemChecklist(
                            item,
                            checklistItem,
                            evento.target.checked
                          );
                        }}
                      />
                      <label
                        htmlFor={`checkboxChecklist_${item.id}_${checklistItem.id}`}
                        className={clsx('w-100 cursor-pointer', {
                          [css['item-checklist-concluido']]:
                            itemChecklistEstahConcluido(checklistItem),
                        })}
                      >
                        {checklistItem.titulo}
                      </label>
                    </div>
                    <Badge
                      color={pegarCorPrazoItemChecklist(checklistItem)}
                      pill
                    >
                      {pegarPrazoItemChecklist(checklistItem)}
                    </Badge>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ListagemChecklist;
