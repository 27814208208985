import React from 'react';
import Select from 'react-select';
import { Row, Col, Label, FormGroup } from 'reactstrap';
import { IMaskInput } from 'react-imask';
import constantes from 'src/util/constantes';
import { useGerenciarPessoaContext } from '../GerenciarPessoaContext';
import BuscaCidade from 'src/components/Form/BuscaCidade';

const DadosDeContato: React.FC = () => {
  const { form } = useGerenciarPessoaContext();

  return (
    <>
      <Row form>
        <Col md={3}>
          <FormGroup>
            <Label for="ddi">DDI</Label>
            <Select options={[]} />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="">DDD + Whatsapp</Label>
            <Row className="align-items-center">
              <Col md={3} xs={8}>
                <IMaskInput
                  mask={constantes.MASCARA_CELULAR}
                  unmask
                  className="form-control"
                  {...form.register('whatsapp')}
                />
              </Col>
              <Col md={3} xs={4}>
                <div className="checkbox checkbox-primary ml-1">
                  <input
                    type="checkbox"
                    id="checkboxNaoPossuiWhatsApp"
                    {...form.register('nao_possui_whatsapp')}
                  />
                  <label htmlFor="checkboxNaoPossuiWhatsApp">
                    Não possui WhatsApp
                  </label>
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="telefone">DDD + Telefone fixo</Label>
            <IMaskInput
              mask={constantes.MASCARA_TELEFONE}
              unmask
              className="form-control"
              {...form.register('telefone_fixo')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup>
            <Label for="email">E-mail</Label>
            <input
              id="email"
              type="email"
              className="form-control"
              placeholder="ex.: exemplo@gmail.com"
              {...form.register('email')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="endereco_comercial">Endereço comercial</Label>
            <Row className="align-items-center">
              <Col xs={8}>
                <input
                  id="endereco_comercial"
                  type="text"
                  className="form-control"
                  placeholder="obs.: Exigência do provimento 88"
                  {...form.register('endereco_comercial')}
                />
              </Col>
              <Col xs={4}>
                <div className="checkbox checkbox-primary ml-1">
                  <input
                    type="checkbox"
                    id="checkboxEnderecoComercialNaoInformado"
                    {...form.register('endereco_comercial_nao_informado')}
                  />
                  <label htmlFor="checkboxEnderecoComercialNaoInformado">
                    Recusou-se a informar ou não possui
                  </label>
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="email_comercial">E-mail comercial</Label>
            <Row className="align-items-center">
              <Col xs={8}>
                <input
                  id="email_comercial"
                  type="email"
                  className="form-control"
                  placeholder="obs.: Exigência do provimento 88"
                  {...form.register('email_comercial')}
                />
              </Col>
              <Col>
                <div className="checkbox checkbox-primary ml-1">
                  <input
                    type="checkbox"
                    id="checkboxEmailComercialNaoInformado"
                    {...form.register('email_comercial_nao_informado')}
                  />
                  <label htmlFor="checkboxEmailComercialNaoInformado">
                    Recusou-se a informar ou não possui
                  </label>
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <FormGroup>
            <Label for="cep">CEP</Label>
            <IMaskInput
              mask={constantes.MASCARA_CEP}
              unmask
              className="form-control"
              {...form.register('cep')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label for="cidade_uf">Cidade, UF</Label>
            <BuscaCidade
              onCidadeSelecionada={(cidade) => {
                form.setValue('cidade_id', cidade.id);
                form.setValue('cidade_nome', cidade.nome);
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label for="bairro">Bairro</Label>
            <input
              id="bairro"
              type="text"
              className="form-control"
              placeholder="ex.: Jardim Aureny IV"
              {...form.register('bairro')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup>
            <Label for="endereco">Endereço</Label>
            <input
              id="endereco"
              type="text"
              className="form-control"
              placeholder="ex.: Rua 01, quadra 01, S/N"
              {...form.register('endereco')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup>
            <Label for="complemento">Complemento</Label>
            <input
              id="complemento"
              type="text"
              className="form-control"
              placeholder="obs.: Insira pontos de referência"
              {...form.register('complemento')}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default DadosDeContato;
