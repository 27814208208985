import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, CardBody, Button, Badge } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import { FiPlus } from 'react-icons/fi';

const linksBreadcrumb = [
  {
    url: '/administrativo',
    texto: 'Início ',
  },
  {
    texto: 'Comunicados',
  },
];

const Comunicados: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Row>
      <Col md={12}>
        <Breadcrumb links={linksBreadcrumb} />
        <Card>
          <CardBody>
            <Col className="justify-content-between">
              <Row className="justify-content-between">
                <b>
                  <h4 className="mb-4">Comunicados</h4>
                </b>
                <Button
                  color="primary"
                  className="h-75"
                  onClick={() => {
                    navigate('/administrativo/comunicados/adicionar');
                  }}
                >
                  <FiPlus className="mr-2" />
                  Adicionar comunicado
                </Button>
              </Row>
            </Col>

            <Col>
              <table className="table table-striped table-bordered rounded">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Assunto</th>
                    <th>Conteúdo</th>
                    <th>Data de Envio</th>
                    <th>Visualizações</th>
                    <th>Situação</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>234</td>
                    <td>Financeiro em Manutenção</td>
                    <td>O módulo financeiro...</td>
                    <td>20/04/2022 9h 00min</td>
                    <td>0 / 100</td>
                    <td>
                      <Badge color="soft-primary">AGENDADO</Badge>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Comunicados;
