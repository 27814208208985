import React, { useCallback, useEffect, useState } from 'react';
import { Table, Button, Col, Row } from 'reactstrap';
import '../../../assets/styles/processos.css';
import pegarRelatorioProcessos from 'src/modulos/processos/casos-de-uso/relatorios/pegarRelatorioProcessos';
import { RelatorioProcesso } from 'src/modulos/processos/entidades/RelatorioProcesso';
import Etiquetas from '../../Inicio/ListagemQuadro/Cartao/Etiquetas';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import Paginacao from 'src/entidades/Paginacao';
import Avatar from 'src/modulos/processos/components/Avatar';
import { Link } from 'react-router-dom';
import { FiEye } from 'react-icons/fi';
import Filtro from './Filtro';
import { FiltroRelatorioProcesso } from 'src/modulos/processos/entidades/FiltroRelatorioProcesso';
import { gerarUrlProcesso } from 'src/modulos/processos/entidades/Processo';

const Processos: React.FC = () => {
  const [processando, setProcessando] = useState(false);
  const [filtro, setFiltro] = useState<FiltroRelatorioProcesso>(
    {} as FiltroRelatorioProcesso
  );
  const [abertos, setAbertos] = useState<number[]>([]);
  const [processos, setProcessos] = useState<RelatorioProcesso[]>([]);
  const [paginacao, setPaginacao] = useState<Paginacao>(new Paginacao({}));

  const atualizarFiltro = (dados: Partial<FiltroRelatorioProcesso>) => {
    setFiltro((atual) => ({ ...atual, ...dados }));
  };

  const carregarRelatorio = useCallback(
    async (pagina = 1) => {
      setProcessando(true);
      pegarRelatorioProcessos(pagina, filtro).then(
        ({ resultado, paginacao: dadosPaginacao }) => {
          setProcessando(false);
          if (resultado) {
            setProcessos((atual) => {
              if (pagina === 1) {
                return resultado;
              }
              return [...atual, ...resultado];
            });
          }
          if (dadosPaginacao) {
            setPaginacao(dadosPaginacao);
          }
        }
      );
    },
    [filtro]
  );

  const estahAberto = (id: number) => abertos.includes(id);

  const registrarAbertura = (id: number) => {
    setAbertos((atual) => [...atual, id]);
  };

  useEffect(() => {
    carregarRelatorio(1);
  }, [carregarRelatorio, filtro]);

  return (
    <>
      <div className="d-flex justify-content-end mb-2">
        <Filtro filtro={filtro} atualizarFiltro={atualizarFiltro} />
      </div>
      <Row>
        <Col md={12}>
          <Table striped>
            <thead>
              <tr>
                <th>Nº Processo</th>
                <th>Etiquetas</th>
                <th>Prepostos</th>
                <th>Clientes</th>
                <th>Fase</th>
                <th>Prazo</th>
              </tr>
            </thead>
            <tbody>
              {processos.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <Link
                          to={gerarUrlProcesso(item)}
                          target={'_blank'}
                          onClick={() => registrarAbertura(item.id!)}
                        >
                          {item.numero}
                        </Link>
                        {estahAberto(item.id!) && (
                          <FiEye className="text-primary ml-1" />
                        )}
                      </div>
                    </td>
                    <td>
                      <Etiquetas etiquetas={item.etiquetas} />
                    </td>
                    <td>
                      <div className="d-flex">
                        {item.prepostos.map((item) => (
                          <Avatar key={item.id} url={''} />
                        ))}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        {/* {item.pessoas.map((item) => (
                          <Avatar key={item.id} url={item.pegarFoto()} />
                        ))} */}
                      </div>
                    </td>
                    <td>{item.etapa}</td>
                    <td>{item.data}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {processos.length > 0 && (
            <div className="d-flex justify-content-center">
              {!paginacao.ehUltimaPagina() &&
                paginacao.total > processos.length && (
                  <Button
                    color="primary"
                    disabled={processando}
                    onClick={() => carregarRelatorio(paginacao.paginaAtual + 1)}
                    className="px-5"
                  >
                    <CarregamentoConteudo carregando={processando}>
                      Ver mais
                    </CarregamentoConteudo>
                  </Button>
                )}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Processos;
