import { Route } from 'react-router-dom';
import { AutenticacaoProvider } from '../../../contexts/Autenticacao';
import Layout from '../layout';
import AdicionarPessoa from '../paginas/AdicionarPessoa';
// import Dashboard from '../paginas/Dashboard';
import Listagem from '../paginas/Listagem';
import VisualizarPessoa from '../paginas/VisualizarPessoa';

import AdicionarPessoaJuridica from '../paginas/AdicionarPessoaJuridica';
import VisualizarPessoaJuridica from '../paginas/VisualizarPessoaJuridica';
import Inicio from '../paginas/Inicio';

export default (
  <Route
    path="/pessoas"
    element={
      <AutenticacaoProvider>
        <Layout />
      </AutenticacaoProvider>
    }
  >
    <Route index element={<Inicio />} />
    <Route path="lista" element={<Listagem />} />
    <Route path="visualizar/:id" element={<VisualizarPessoa />} />
    <Route path="editar/:id" element={<AdicionarPessoa />} />
    <Route path="adicionar" element={<AdicionarPessoa />} />

    {/* cadastro de pessoa juridca, ajustar */}

    <Route path="juridica/adicionar" element={<AdicionarPessoaJuridica />} />
    <Route
      path="juridica/visualizar/:id"
      element={<VisualizarPessoaJuridica />}
    />
  </Route>
);
