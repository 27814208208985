import React, { useState } from 'react';
import {
  FiBarChart2,
  FiCalendar,
  FiFilter,
  FiTag,
  FiUser,
} from 'react-icons/fi';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';
import { FiltroRelatorioProcesso } from 'src/modulos/processos/entidades/FiltroRelatorioProcesso';
import Etiquetas from './Etiquetas';
import Fases from './Fases';

type Props = {
  filtro: FiltroRelatorioProcesso;
  atualizarFiltro(filtro: FiltroRelatorioProcesso): void;
};

const Filtro: React.FC<Props> = ({ filtro, atualizarFiltro }) => {
  const [exibirFiltro, setExibirFiltro] = useState(false);
  const [abaSelecionada, setAbaSelecionada] = useState<string | null>(null);
  const abrirFiltro = () => setExibirFiltro(true);
  const fecharFiltro = () => {
    setExibirFiltro(false);
    setAbaSelecionada(null);
  };

  const selecionarAba = (id: string) => setAbaSelecionada(id);

  const pegarTituloAba = () => {
    switch (abaSelecionada) {
      case 'fases':
        return 'Fases';
      case 'prepostos':
        return 'Prepostos';
      case 'etiquetas':
        return 'Etiquetas';
      case 'prazos':
        return 'Prazos';
      default:
        return '';
    }
  };

  return (
    <>
      <Button
        color="soft-primary"
        className="d-flex align-items-center px-3"
        onClick={abrirFiltro}
      >
        <FiFilter className="mr-2" />
        <span>Filtrar</span>
      </Button>
      <Dropdown
        mostrar={exibirFiltro}
        fechar={fecharFiltro}
        titulo={`Filtrar por ${pegarTituloAba()}`}
        voltar={
          abaSelecionada !== null
            ? () => {
                setAbaSelecionada(null);
              }
            : undefined
        }
      >
        {abaSelecionada === null && (
          <ListGroup>
            <ListGroupItem
              className="d-flex align-items-center border-0 cursor-pointer"
              onClick={() => selecionarAba('fases')}
            >
              <FiBarChart2 className="mr-2" />
              <span>Fases</span>
            </ListGroupItem>
            <ListGroupItem
              className="d-flex align-items-center border-0 cursor-pointer"
              onClick={() => selecionarAba('prepostos')}
            >
              <FiUser className="mr-2" />
              <span>Prepostos</span>
            </ListGroupItem>
            <ListGroupItem
              className="d-flex align-items-center border-0 cursor-pointer"
              onClick={() => selecionarAba('etiquetas')}
            >
              <FiTag className="mr-2" />
              <span>Etiquetas</span>
            </ListGroupItem>
            <ListGroupItem
              className="d-flex align-items-center border-0 cursor-pointer"
              onClick={() => selecionarAba('prazos')}
            >
              <FiCalendar className="mr-2" />
              <span>Prazos</span>
            </ListGroupItem>
          </ListGroup>
        )}
        {abaSelecionada === 'fases' && (
          <Fases
            onFasesAtualizadas={(fases: string[]) => {
              console.log('🚀 ~ file: index.tsx ~ line 92 ~ fases', fases);
              atualizarFiltro({
                fases,
              });
            }}
          />
        )}
        {abaSelecionada === 'prepostos' && <></>}
        {abaSelecionada === 'etiquetas' && (
          <Etiquetas
            onEtiquetasAtualizadas={(ids: number[]) => {
              atualizarFiltro({
                idsEtiquetas: ids,
              });
            }}
          />
        )}
      </Dropdown>
    </>
  );
};

export default Filtro;
