import React, { useContext, useEffect, useState } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import Dropdown from 'src/components/Dropdown';
import { FiCheckSquare } from 'react-icons/fi';
import listarChecklists from 'src/modulos/processos/casos-de-uso/configuracoes/checklists/listarChecklists';
import { Checklist } from 'src/modulos/processos/entidades/Checklist';
import adicionarChecklistProcesso from 'src/modulos/processos/casos-de-uso/processo/adicionarChecklistProcesso';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import { toast } from 'react-toastify';
import { checklistEstahNoProcesso } from 'src/modulos/processos/entidades/Processo';

const AdicionarChecklist: React.FC = () => {
  const { processo, arquivado, onChecklistAdicionado } =
    useContext(ProcessoContext);
  const [mostrarFormulario, setMostrarFormulario] = useState<boolean>(false);
  const [carregando] = useState(false);
  const [checklists, setChecklists] = useState<Checklist[]>([]);

  const pegarListaChecklists = () =>
    checklists
      .filter((item) => !checklistEstahNoProcesso(processo, item.id))
      .map((item) => ({
        label: item.titulo,
        value: item.id,
        checklist: item,
      }));

  const adicionarChecklist = async (checklist: Checklist) => {
    const { resultado, error } = await adicionarChecklistProcesso(
      processo,
      checklist
    );

    if (resultado) {
      setMostrarFormulario(false);
      onChecklistAdicionado(resultado.checklist, resultado.andamento);
      return;
    }

    if (error) {
      toast.error(error.mensagem);
    }
  };

  useEffect(() => {
    listarChecklists().then(({ resultado }) => {
      if (resultado) {
        setChecklists(resultado);
      }
    });
  }, []);

  return (
    <div className="relative">
      <Button
        color="soft-secondary"
        block
        className="d-flex align-items-center"
        disabled={arquivado}
        onClick={() => setMostrarFormulario((mostrar) => !mostrar)}
      >
        <FiCheckSquare className="mr-2" />
        <span>Checklists</span>
      </Button>
      <Dropdown
        mostrar={mostrarFormulario}
        fechar={() => setMostrarFormulario((mostrar) => !mostrar)}
        fecharAoClicarExterno={false}
        titulo="Adicionar checklist"
      >
        <FormGroup>
          <Label>Selecione ou pesquise pelo checklist</Label>
          <Select
            placeholder="Pesquise pelo nome"
            isLoading={carregando}
            onChange={(selecionado) => {
              if (selecionado) {
                adicionarChecklist(selecionado.checklist);
              }
            }}
            options={pegarListaChecklists()}
          />
        </FormGroup>
      </Dropdown>
    </div>
  );
};

export default AdicionarChecklist;
