import Breadcrumb from '../../../../components/Breadcrumb';
import { Link } from 'react-router-dom';

import { FiLock, FiChevronRight } from 'react-icons/fi';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const Seguranca: React.FC = () => {
  return (
    <>
      <Breadcrumb
        links={[{ url: '/', texto: 'Inicio ' }, { texto: ' Segurança' }]}
      />
      <Container>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <h3>Segurança</h3>
                <Link to={'/usuarios/seguranca/alterar-senha'}>
                  <div className="d-flex align-items-center py-2">
                    <FiLock />
                    <span className="block w-100 mx-4">Alterar senha</span>
                    <FiChevronRight />
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Seguranca;
