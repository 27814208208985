import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Badge,
  InputGroup,
  Input,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FiLock, FiUnlock, FiPlus } from 'react-icons/fi';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import React from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import { BsArrowDownUp } from 'react-icons/bs';
import avatar from 'src/assets/images/users/avatar-1.jpg';
import { HiOutlinePencilAlt } from 'react-icons/hi';

import '../../assets/styles/contratos.css';

const linksBreadcrumb = [
  {
    url: '/administrativo',
    texto: 'Início ',
  },
  {
    texto: 'Contratos',
  },
];

const Usuarios: React.FC = () => {
  const [seletorDePaginas, setValorSeletor] = React.useState(1);

  const novoValorSeletorDePaginas = (pagina: number) => {
    setValorSeletor(pagina);
  };

  const statusSeletorDePaginas = () => {
    if (seletorDePaginas === 1) return true;
    else return false;
  };

  return (
    <Row className="d-flex justify-content-center">
      <Col className="p-4" sm={12}>
        <Breadcrumb links={linksBreadcrumb} />
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between">
              <div>
                <b>
                  <h4 className="mb-4">Usuários</h4>
                </b>
              </div>

              <div className="d-flex">
                <Link to="/administrativo/usuarios/adicionar">
                  <Button color="primary" className="h-75">
                    <FiPlus className="mr-2" />
                    Adicionar Usuário
                  </Button>
                </Link>
              </div>
            </div>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>
                    <div className="d-flex justify-content-between">
                      Nome
                      <BsArrowDownUp />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex justify-content-between">CPF</div>
                  </th>
                  <th>
                    <div className="d-flex justify-content-between">E-mail</div>
                  </th>
                  <th>Função</th>
                  <th>Situação</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="d-flex justify-content-center align-items-center h-50 w-50">
                    <img
                      src={avatar}
                      alt="imagem de usuário"
                      className="mr-2 card-img h-25 w-25 img-rounded"
                    />
                    Fulana da silva
                  </td>
                  <td className="text-center">***.587.750-**</td>
                  <td className="text-center">fulana_silva@nato-digital.com</td>
                  <td className="text-center">Desenvolvimento</td>
                  <td>
                    <Badge color="soft-success">Ativo</Badge>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Link to="/administrativo/contratos/adicionar">
                        <HiOutlinePencilAlt
                          className="text-primary mr-2"
                          style={{ cursor: 'pointer' }}
                        />
                      </Link>

                      <FiLock
                        className="text-danger"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex justify-content-center align-items-center h-50 w-50">
                    <img
                      src={avatar}
                      alt="imagem de usuário"
                      className="mr-2 card-img h-25 w-25 img-rounded"
                    />
                    Fulana da silva
                  </td>
                  <td className="text-center">***.587.750-**</td>
                  <td className="text-center">fulana_silva@nato-digital.com</td>
                  <td className="text-center">Suporte</td>
                  <td>
                    <Badge color="soft-danger">Bloqueado</Badge>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Link to="/administrativo/contratos/adicionar">
                        <HiOutlinePencilAlt
                          className="text-primary mr-2"
                          style={{ cursor: 'pointer' }}
                        />
                      </Link>

                      <FiUnlock
                        className="text-success"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>

            <div className="d-flex justify-content-end">
              <button
                className="btn btn-soft-primary"
                onClick={() => {
                  if (seletorDePaginas > 1) {
                    novoValorSeletorDePaginas(seletorDePaginas - 1);
                  }
                }}
                disabled={statusSeletorDePaginas()}
              >
                <BsChevronLeft />
              </button>

              <InputGroup style={{ width: 80 }} className="ml-2 mr-2">
                <Input
                  value={seletorDePaginas}
                  type="text"
                  onChange={(e) => {
                    novoValorSeletorDePaginas(parseInt(e.target.value));
                  }}
                  className="text-center"
                />
              </InputGroup>

              <button
                className="btn btn-soft-primary"
                onClick={() => {
                  novoValorSeletorDePaginas(seletorDePaginas + 1);
                }}
              >
                <BsChevronRight />
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Usuarios;
