import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import {
  gerarUrlProcesso,
  pegarApresentante,
  Processo,
} from 'src/modulos/processos/entidades/Processo';
import Etiquetas from './Etiquetas';
import Pessoas from './Pessoas';
// import Prazo from './Prazo';
import Prepostos from './Prepostos';
import css from './style.module.css';

type Props = {
  processo: Processo;
};

const Cartao: React.FC<Props> = ({ processo }) => {
  return (
    <>
      <Link
        to={gerarUrlProcesso(processo)}
        className="text-body"
        target="_blank"
        id={`${processo.id}`}
      >
        <Card className={css.cartao}>
          <CardBody>
            {processo.etiquetas.length > 0 && (
              <Etiquetas etiquetas={processo.etiquetas} />
            )}
            <span className="mb-2 font-size-15 font-weight-bold">
              PROCESSO N° {processo.numero}
            </span>
            {pegarApresentante(processo) && (
              <Pessoas apresentante={pegarApresentante(processo)} />
            )}
            {/* {processo.prazos && processo.prazos.length > 0 && (
              <Prazo processo={processo} />
            )*/}
            {processo.prepostos && processo.prepostos.length > 0 && (
              <Prepostos prepostos={processo.prepostos} />
            )}
          </CardBody>
        </Card>
      </Link>
    </>
  );
};

export default Cartao;
