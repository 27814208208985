import React from 'react';
import formatarDatahora from 'src/casos-de-uso/formatacao/formatarDatahora';
import { Processo } from 'src/modulos/processos/entidades/Processo';

type Props = {
  processo: Processo;
};

const Prazo: React.FC<Props> = ({ processo }) => {
  const ultimoPrazo =
    processo.prazos && processo.prazos.length > 0
      ? processo.prazos[processo.prazos?.length - 1]
      : null;
  if (!ultimoPrazo) {
    return <></>;
  }
  return <span className="d-block">{formatarDatahora(ultimoPrazo.data!)}</span>;
};

export default Prazo;
