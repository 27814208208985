import { Modulo } from '../entidades/Modulo';
import IconeProcessos from 'src/modulos/processos/assets/icon-processos.png';
import IconeAcervo from 'src/modulos/acervo/assets/icon-acervo.png';
import IconeFinanceiro from 'src/modulos/financeiro/assets/icon-financeiro.png';
import IconePessoas from 'src/modulos/pessoas/assets/icon-pessoas.png';
import IconeMosaico from 'src/modulos/mosaico/assets/icon-processos.png';

export default function pegarIconeModulo(modulo: Modulo) {
  if (!modulo.nome) return '';

  const icones: { [key: string]: string } = {
    controle_processos: IconeProcessos,
    financeiro: IconeFinanceiro,
    acervo: IconeAcervo,
    clientes: IconePessoas,
    mosaico: IconeMosaico,
  };

  return icones[modulo.nome] || '';
}
