import React from 'react';
import formatarDatahora from 'src/casos-de-uso/formatacao/formatarDatahora';
import formatarTamanho from 'src/modulos/acervo/casos-de-uso/arquivos/formatarTamanho';
import IconeTipoArquivo from 'src/modulos/acervo/components/IconeTipoArquivo';
import { Acervo } from 'src/modulos/acervo/entidades/Acervo';

type Props = {
  acervo: Acervo;
};

const ArquivoItem: React.FC<Props> = ({ acervo }) => {
  return (
    <>
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <div className="mr-3">
              <IconeTipoArquivo extensao={acervo.extensao} />
            </div>
            <span className="d-block">
              <b>{acervo.nome}</b>
            </span>
          </div>
        </td>
        <td>
          <span>{formatarDatahora(acervo.created_at)}</span>
        </td>
        <td>{formatarTamanho(Number(acervo.tamanho))}</td>
        <td>-</td>
      </tr>
    </>
  );
};

export default ArquivoItem;
