import { InputGroup, Input, Row, Col, InputGroupText, Badge } from 'reactstrap';
import { FiCalendar } from 'react-icons/fi';

const DadosGerais: React.FC = () => {
  return (
    <Col>
      <Row className="mb-4">
        <Col xs={4} className="mb-2">
          <b>
            <span>Nome do Contrato</span>
          </b>

          <InputGroup className="mt-1">
            <Input />
          </InputGroup>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs={4}>
          <b>
            <span>Valor Base</span>
          </b>

          <InputGroup className="mt-1">
            <InputGroupText>R$</InputGroupText>
            <Input />
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={4}>
          <b>
            <span>Válido até</span>
          </b>

          <div>
            <Badge color="soft-primary" className="font-size-14 p-2 mt-1">
              <FiCalendar className="mr-2" />
              02 / 04 / 2023
            </Badge>
          </div>
        </Col>
      </Row>

      <hr />
    </Col>
  );
};

export default DadosGerais;
