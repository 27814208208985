import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Processo } from '../../entidades/Processo';

const buscarProcessos = async (
  termo: string
): Promise<IResultadoRequisicao<Processo[]>> => {
  try {
    const resultado: Processo[] = await api().get(`/processos/buscar/${termo}`);

    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default buscarProcessos;
