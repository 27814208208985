import { Row, Col, Card, CardBody, InputGroup, Input, Badge } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';

import { FiCalendar } from 'react-icons/fi';
import { MdOutlineWatchLater } from 'react-icons/md';

const EditarComunicado: React.FC = () => {
  const linksBreadcrumb = [
    {
      url: '/administrativo',
      texto: 'Início ',
    },
    {
      url: '/administrativo/comunicados',
      texto: 'Comunicados',
    },
    {
      texto: 'Editar Comunicado',
    },
  ];

  return (
    <Row className="d-flex justify-content-center">
      <Col className="p-4" sm={8}>
        <Breadcrumb links={linksBreadcrumb} />
        <Card>
          <CardBody>
            <Col>
              <b>
                <h4 className="mb-4">Editar Comunicado</h4>
              </b>
            </Col>

            <Col xs={6}>
              <h6>Assunto</h6>

              <InputGroup className="mt-2">
                <Input />
              </InputGroup>
            </Col>

            <Col className="mt-4">
              <h6>Data de envio</h6>
              <span>
                <i>
                  Obs.: O envio pode ser agendado ao selecionar uma data futura.
                </i>
              </span>

              <Row className="mt-2 align-items-center">
                <Col>
                  <Badge color="soft-primary" className="p-2 font-size-16 mr-3">
                    <FiCalendar className="mr-2" /> 11 / 04 / 2022
                  </Badge>
                  às
                  <Badge color="soft-primary" className="p-2 font-size-16 ml-3">
                    <MdOutlineWatchLater className="mr-2" /> 8:00 hrs
                  </Badge>
                </Col>
              </Row>
            </Col>

            <Col className="mt-4">
              <h6>Enviar...</h6>

              <Col className="ml-2 mb-2">
                <div className="d-flex">
                  <Input type="radio" value="" className="mr-2" />

                  <span>apenas para titulares e substitutos</span>
                </div>
              </Col>

              <Col className="ml-2">
                <div className="d-flex">
                  <Input type="radio" value="" className="mr-2" />

                  <span>para todos os usuários ativos</span>
                </div>
              </Col>
            </Col>

            <Col className="mt-4">
              <h6>Conteúdo</h6>

              <Input
                type="textarea"
                name="text"
                id="exampleText"
                style={{ overflow: 'scroll', minHeight: 200 }}
              />
            </Col>

            <hr />

            <div className="d-flex justify-content-end">
              <button type="button" className="btn-success mr-3 h-25">
                Cancelar
              </button>

              <button type="submit" className="btn btn-primary h-25">
                Adicionar Comunicado
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default EditarComunicado;
