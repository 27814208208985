import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Cartorio } from '../../entidades/Cartorio';

export default async function atualizarPerfilDaServentia(
  cartorio: Cartorio
): Promise<IResultadoRequisicao<any>> {
  try {
    const resultado: any = await api().put('serventia', cartorio);
    return { resultado };
  } catch (error) {
    return { error };
  }
}
