export interface IEmolumento {
  acrescimo_por_pagina: number;
  apelido: string;
  codigo: number;
  comeco_vigencia: string;
  created_at: string;
  deleted_at: string | null;
  emolumento: number;
  emolumento_acrescimo: number;
  faixa_final: number;
  faixa_inicial: number;
  fim_vigencia: string;
  funcivil: number;
  funcivil_acrescimo: number;
  id: number;
  identificador_faixa: number | null;
  limite_emolumento: number | null;
  limite_funcivil: number | null;
  limite_tfj: number | null;
  nome_ato: string;
  status: string;
  tfj: number;
  tfj_acrescimo: number;
  tipo: string;
  total: number;
  updated_at: string;
}

export class Emolumento {
  apelido: string;

  constructor(emolumento: IEmolumento) {
    this.apelido = emolumento.apelido;
  }
}
