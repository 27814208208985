import React, { useState } from 'react';
import criarProcesso from 'src/modulos/processos/casos-de-uso/processo/criarProcesso';
import { useNavigate } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FiPlus } from 'react-icons/fi';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import { toast } from 'react-toastify';
import { gerarUrlProcesso } from 'src/modulos/processos/entidades/Processo';

const AdicionarProcesso: React.FC = () => {
  const navigate = useNavigate();
  const [exibirCriacaoNovoProcesso, setExibirCriacaoNovoProcesso] =
    useState(false);
  const [processandoCriacao, setProcessandoCriacao] = useState(false);

  const abrirModalCriacaoProcesso = () => setExibirCriacaoNovoProcesso(true);
  const fecharModalCriacaoProcesso = () => setExibirCriacaoNovoProcesso(false);

  const criarNovoProcesso = async () => {
    setProcessandoCriacao(true);
    const { resultado, error } = await criarProcesso();
    setProcessandoCriacao(false);
    fecharModalCriacaoProcesso();

    if (resultado) {
      toast.success(`Processo ${resultado.numero} criado com sucesso`);
      navigate(gerarUrlProcesso(resultado));
      return;
    }

    if (error) {
      toast.error(error.mensagem);
    }
  };

  return (
    <>
      <Button
        color="soft-primary"
        onClick={abrirModalCriacaoProcesso}
        data-testid="btnAbrirModalCriarProcesso"
        className="d-flex align-items-center"
      >
        <FiPlus />
        <span className="ml-1">Adicionar Processo</span>
      </Button>
      <Modal isOpen={exibirCriacaoNovoProcesso}>
        <ModalHeader>Criação de processo</ModalHeader>
        <ModalBody>
          <p>Tem certeza que deseja criar um novo processo?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="soft-secondary" onClick={fecharModalCriacaoProcesso}>
            Cancelar
          </Button>

          <Button
            color="primary"
            className="px-4"
            onClick={criarNovoProcesso}
            disabled={processandoCriacao}
            data-testid="btnCriarProcesso"
          >
            <CarregamentoConteudo carregando={processandoCriacao}>
              Sim
            </CarregamentoConteudo>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AdicionarProcesso;
