import React from 'react';
import { Row, Col } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import css from '../../../../../assets/styles/configuracoes.module.css';

const Configuracoes: React.FC = () => {
  const [editando, setEditando] = React.useState(false);
  const [descricao, setDescricao] = React.useState('');
  const [editor, setEditor] = React.useState(EditorState.createEmpty());

  const setHtmlEditor = (html: string) => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditor(editorState);
    }
  };

  React.useEffect(() => {
    const html = 'teste';
    setHtmlEditor(html);
    setDescricao(html);
  }, []);

  const salvar = async () => {
    const novaDescricao = draftToHtml(convertToRaw(editor.getCurrentContent()));
    console.log(novaDescricao);
  };

  return (
    <Col>
      <Row className="mt-4">
        <Col className="mb-4">
          <b>
            <span>UFs Autorizadas</span>
          </b>
          <br />

          <i>
            <span>Pesquise pela sigla do estado, ex.: TO</span>
          </i>

          <Row>
            <Col xs={3} className="mt-2">
              <form action="">
                <div className="input-busca-div d-flex">
                  <div className="p-2">
                    <BsSearch className="font-size-16" />
                  </div>

                  <input type="text" className="form-control" />
                </div>
              </form>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <b>
            <span>Cláusulas do Contrato</span>
          </b>
          <br />

          <i>
            <span>
              Use {'{{'.toString()} para dados variáveis, então escolha uma
              opção de dado. Ex.: {'{{UF}}'.toString()}
            </span>
          </i>

          <div className={css['espaco-lateral']}>
            <div className="px-3 border mt-3">
              <Editor
                editorState={editor}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={setEditor}
              />
            </div>
          </div>
        </Col>
      </Row>

      <hr />
    </Col>
  );
};

export default Configuracoes;
