import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';

const Financeiro: React.FC = () => {
  return (
    <>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>Valor ISS</Label>
            <input type="text" className="form-control" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>Número inicial do orçamento</Label>
            <input type="text" className="form-control" />
          </FormGroup>
        </Col>
      </Row>
      <h5>Dados bancários</h5>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>Banco</Label>
            <input type="text" className="form-control" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>Agência</Label>
            <input type="text" className="form-control" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>Conta</Label>
            <input type="text" className="form-control" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>CNPJ ou CPF</Label>
            <input type="text" className="form-control" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>Favorecido</Label>
            <input type="text" className="form-control" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>Tipo chave PIX</Label>
            <input type="text" className="form-control" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>Chave PIX</Label>
            <input type="text" className="form-control" />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default Financeiro;
