import { SeloAnoregProcesso } from "./SeloAnoregProcesso";

export interface SeloProcesso {
  id: number;
  selo_id: null;
  processo_id: number;
  created_at: Date;
  updated_at: Date;
  selo_mongo_id: string;
  selo: any;
  selo_anoreg: SeloAnoregProcesso;
}

export const pegarCodigoSelo = (selo: SeloProcesso): string => {
  if (!selo.selo) return '';
  return `${selo.selo.codigo}-${selo.selo.codigo_validacao}`;
};

export const pegarUrlSelo = (selo: SeloProcesso): string => {
  return `/selos/visualizar/${selo.id}`;
};
