import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import recuperarSenhaUsuario from 'src/modulos/aplicacao/casos-de-uso/usuario/recuperarSenhaUsuario';
import constantes from 'src/util/constantes';

type CamposFormulario = {
  cpf: string;
};

const RecuperarSenha: React.FC = () => {
  const [processando, setProcessando] = useState(false);
  const [exibirModal, setExibirModal] = useState(false);
  const { register, handleSubmit } = useForm<CamposFormulario>({
    defaultValues: {
      cpf: '',
    },
  });

  const fecharModal = () => setExibirModal(false);
  const abrirModal = () => setExibirModal(true);

  const recuperarSenha = async ({ cpf }: CamposFormulario) => {
    setProcessando(true);
    const { resultado, error } = await recuperarSenhaUsuario(cpf);
    setProcessando(false);
    if (resultado) {
      toast.success(
        `Foi enviado um email de recuperação de senha para ${resultado}`
      );
      fecharModal();
      return;
    }
    if (error) {
      toast.error(error.mensagem);
      return;
    }
  };

  return (
    <>
      <span className="cursor-pointer" data-testid="linkRecuperarSenha" onClick={abrirModal}>
        Esqueceu sua senha?
      </span>
      <Modal isOpen={exibirModal} data-testid="modalRecuperarSenha">
        <ModalHeader>Recuperar senha</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(recuperarSenha)}>
            <FormGroup>
              <Label htmlFor="recuperarCpf">CPF</Label>
              <ReactInputMask
                id="recuperarCpf"
                data-testid="recuperarCpf"
                mask={constantes.MASCARA_CPF}
                className="form-control"
                maskPlaceholder={null}
                {...register('cpf', { required: 'Informe o CPF' })}
              />
            </FormGroup>
            <div className="d-flex justify-content-end">
              <Button
                color="soft-secondary"
                className="mr-2"
                onClick={fecharModal}
              >
                Cancelar
              </Button>
              <Button color="primary" type="submit" data-testid="btnRecuperar">
                <CarregamentoConteudo carregando={processando}>
                  Recuperar Senha
                </CarregamentoConteudo>
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RecuperarSenha;
