import React from 'react';
import { Processo } from 'src/modulos/processos/entidades/Processo';

type Props = {
  processo: Processo;
};

const ItemResultadoBusca: React.FC<Props> = () => {
  return (
    <>
    </>
  );
};

export default ItemResultadoBusca;
