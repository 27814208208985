import clsx from 'clsx';
import React from 'react';
import {
  BsFileEarmarkImage,
  BsFileEarmarkPdfFill,
  BsFileEarmarkWordFill,
} from 'react-icons/bs';
import { FiExternalLink } from 'react-icons/fi';
import css from './style.module.css';

type Props = {
  extensao: string;
};

const IconeTipoArquivo: React.FC<Props> = ({ extensao }) => {
  return (
    <div className={clsx(css[extensao], 'h3')}>
      {['pdf', 'application/pdf'].includes(extensao) && (
        <BsFileEarmarkPdfFill />
      )}
      {['doc', 'docx'].includes(extensao) && <BsFileEarmarkWordFill />}
      {['jpg', 'jpeg', 'png', 'gif'].includes(extensao) && (
        <BsFileEarmarkImage />
      )}
      {extensao === 'url' && <FiExternalLink />}
    </div>
  );
};

export default IconeTipoArquivo;
