import { RegraNegocioError } from '../../../../entidades/ErroRegraNegocio';
import { Usuario } from '../../entidades/Usuario';
import { Cartorio } from '../../entidades/Cartorio';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';

type Resultado = {
  usuario: Usuario;
  cartorio: Cartorio;
  cartorios: Cartorio[];
};

const pegarUsuarioAutenticado = async (): Promise<
  IResultadoRequisicao<Resultado>
> => {
  try {
    const usuario = localStorage.getItem('usuario');
    if (usuario) {
      const resultado = JSON.parse(usuario);
      return { resultado };
    } else {
      throw new RegraNegocioError('Usuário não autenticado.');
    }
  } catch (error: any) {
    return { error };
  }
};

export default pegarUsuarioAutenticado;
