import axios from 'axios';
import { ErroRegraNegocio } from 'src/entidades/ErroRegraNegocio';
import { get } from 'tiny-cookie';

const api = (): any => {
  const instancia = axios.create({
    baseURL: `${process.env.REACT_APP_CONTROLE_PROCESSOS_API}/`,
    headers: {
      ...(get('vc_token')
        ? { Authorization: `Bearer ${get('vc_token')}` }
        : {}),
    },
  });

  instancia.interceptors.response.use(
    (res) => {
      if (
        res.headers['content-type'] &&
        res.headers['content-type'].indexOf('application/json') >= 0
      ) {
        return res.data;
      }
      return res;
    },
    (err) => {
      console.log(err.message === 'Network Error');
      if ([401, 403].includes(err?.response?.status) || err.message === 'Network Error') {
        window.location.href = '/entrar';
        return Promise.reject(err);
      }
      if (err && err.response.data && err.response.data.erro) {
        const erro: ErroRegraNegocio = {
          mensagem: err.response.data.erro,
        };
        return Promise.reject(erro);
      }
      return Promise.reject(err);
    }
  );

  return instancia;
};

export default api;
