import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import listarModulosCartorio from 'src/modulos/aplicacao/casos-de-uso/cartorio/listarModulosCartorio';
import listarPrepostos from 'src/modulos/aplicacao/casos-de-uso/cartorio/listarPrepostos';
import trocarCartorio from 'src/modulos/aplicacao/casos-de-uso/cartorio/trocarCartorio';
import { Modulo } from 'src/modulos/aplicacao/entidades/Modulo';
import { Preposto } from 'src/modulos/processos/entidades/Preposto';
import pegarUsuarioAutenticado from '../modulos/aplicacao/casos-de-uso/usuario/pegarUsuarioAutenticado';
import { Cartorio } from '../modulos/aplicacao/entidades/Cartorio';
import { Usuario } from '../modulos/aplicacao/entidades/Usuario';

interface AutenticacaoContextProps {
  usuario: Usuario;
  cartorio: Cartorio;
  cartorios: Cartorio[];
  modulos: Modulo[];
  carregando: boolean;
  prepostos: Preposto[];
  temPermissao(permissao: string | string[]): boolean;
  temPermissaoAdmin(): boolean;
  temModulo(modulo: string): boolean;
  encontrarPreposto(termo: string): Preposto[];
  fazerTrocaCartorio(cartorio: Cartorio): void;
}

const AutenticacaoContext = React.createContext<AutenticacaoContextProps>(
  {} as AutenticacaoContextProps
);

export const AutenticacaoProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [carregando, setCarregando] = React.useState<boolean>(true);
  const [carregandoTrocaCartorio, setCarregandoTrocaCartorio] =
    React.useState<boolean>(false);
  const [usuario, setUsuario] = React.useState<Usuario>({} as Usuario);
  const [modulos, setModulos] = React.useState<Modulo[]>([]);
  const [cartorio, setCartorio] = React.useState<Cartorio>({} as Cartorio);
  const [cartorios, setCartorios] = React.useState<Cartorio[]>([]);
  const [prepostos, setPrepostos] = React.useState<Preposto[]>([]);

  const temPermissaoAdmin = () => {
    return usuario.admin === 'S';
  };

  const temPermissao = (permissao: string | string[]): boolean => {
    return false;
    // let permissoesLocal: string[] = [];

    // if (!Array.isArray(permissao)) {
    //   permissoesLocal = [permissao];
    // } else {
    //   permissoesLocal = permissao;
    // }

    // return (
    //   permissoes.filter((item) => {
    //     return permissoesLocal.indexOf(item) >= 0;
    //   }).length > 0
    // );
  };

  const temModulo = (modulo: string): boolean => {
    return modulos.findIndex((item) => item.nome === modulo) >= 0;
  };

  const encontrarPreposto = (termo: string) => {
    const resultadoBusca = prepostos.filter((item) => {
      const buscaCpf = termo.replace(/\D/g, '');
      if (buscaCpf && buscaCpf.length > 0) {
        return item.cpf!.indexOf(buscaCpf) >= 0;
      }
      return (
        item.nome!.toLowerCase().indexOf(termo.toLowerCase()) >= 0 ||
        (item.nome_usuario &&
          item.nome_usuario?.toLowerCase().indexOf(termo.toLowerCase()) >= 0)
      );
    });
    return resultadoBusca;
  };

  const carregarDadosCartorio = () => {
    pegarUsuarioAutenticado().then(({ resultado, error }) => {
      if (error) {
        navigate('/entrar');
        return;
      }
      if (resultado) {
        setUsuario(resultado.usuario);
        setCartorio(resultado.cartorio);
        setCartorios(resultado.cartorios);

        listarPrepostos().then(({ resultado }) => {
          if (resultado) {
            setPrepostos(resultado);
          }
        });
        listarModulosCartorio().then(({ resultado }) => {
          if (resultado) {
            setModulos(resultado);
          }
        });
      }
    });
  };

  const fazerTrocaCartorio = async (cartorio: Cartorio) => {
    setCarregandoTrocaCartorio(true);
    const { resultado, error } = await trocarCartorio(cartorio);
    setCarregandoTrocaCartorio(false);
    if (resultado) {
      navigate('/');
      carregarDadosCartorio();
      return;
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  React.useEffect(() => {}, [cartorio]);

  React.useEffect(() => {
    setCarregando(true);
    carregarDadosCartorio();
    setCarregando(false);
    // eslint-disable-next-line
  }, []);

  return (
    <AutenticacaoContext.Provider
      value={{
        carregando,
        usuario,
        cartorio,
        cartorios,
        modulos,
        prepostos,
        encontrarPreposto,
        temPermissao,
        temPermissaoAdmin,
        temModulo,
        fazerTrocaCartorio,
      }}
    >
      {/* <div
        id="aviso-ambiente-teste"
        className="bg-danger position-fixed w-100 d-flex justify-content-center align-items-center"
      >
        <span className="text-secondary py-2">
          <b>Você está em um ambiente de teste!</b>
        </span>
      </div> */}
      <CarregamentoConteudo carregando={carregandoTrocaCartorio || !usuario.id}>
        {children}
      </CarregamentoConteudo>
    </AutenticacaoContext.Provider>
  );
};

export default AutenticacaoContext;
