import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Modulo } from '../../entidades/Modulo';

export default async function listarModulosCartorio(): Promise<
  IResultadoRequisicao<Modulo[]>
> {
  try {
    const resultado: Modulo[] = await api().get('autenticacao/modulos');
    return { resultado };
  } catch (error) {
    return { error };
  }
}
