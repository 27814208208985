import { Route } from 'react-router-dom';
import { AutenticacaoProvider } from 'src/contexts/Autenticacao';
import DetalhesDoCartorio from '../paginas/DetalhesDoCartorio';
import EditarModulo from '../paginas/EditarModulo';
import Modulos from '../paginas/Modulos';
import Layout from '../layout';
import Cartorios from '../paginas/Cartorios';
import Dashboard from '../paginas/Dashboard';
import Comunicados from '../paginas/Comunicados';
import AdicionarComunicado from '../paginas/AdicionarComunicado';
import EditarComunicado from '../paginas/EditarComunicado';
import Contratos from '../paginas/Contratos';
import EditarAdicionarContrato from '../paginas/Contratos/EditarAdicionarContrato';
import Usuarios from '../paginas/Usuarios';
import AdicionarUsuario from '../paginas/Usuarios/AdicionarUsuario';
import PapeisEPermissoes from '../paginas/PapeisEPermissoes';

export default (
  <Route
    path="/administrativo"
    element={
      <AutenticacaoProvider>
        <Layout />
      </AutenticacaoProvider>
    }
  >
    <Route index element={<Dashboard />} />
    <Route path="cartorios">
      <Route index element={<Cartorios />} />
      <Route path=":id" element={<DetalhesDoCartorio />} />
    </Route>
    <Route path="modulos">
      <Route index element={<Modulos />} />
      <Route path=":id" element={<EditarModulo />} />
    </Route>
    <Route path="comunicados">
      <Route index element={<Comunicados />} />
      <Route path="adicionar" element={<AdicionarComunicado />} />
      <Route path="editar" element={<EditarComunicado />} />
    </Route>
    <Route path="contratos">
      <Route index element={<Contratos />} />
      <Route path="adicionar" element={<EditarAdicionarContrato />} />
    </Route>
    <Route path="usuarios">
      <Route index element={<Usuarios />} />
      <Route path="adicionar" element={<AdicionarUsuario />} />
    </Route>
    <Route path="papeis" element={<PapeisEPermissoes />} />
  </Route>
);
