import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import { Processo } from '../../entidades/Processo';

const adicionarComentarioProcesso = async (
  processo: Processo,
  comentario: string
): Promise<IResultadoRequisicao<Andamento>> => {
  try {
    const resultado: Andamento = await api().post(
      `processos/comentario/${processo.id}`,
      {
        comentario,
      }
    );
    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default adicionarComentarioProcesso;
