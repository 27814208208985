import React, { useContext } from 'react';
import { FiCalendar, FiFilter, FiTag, FiUser } from 'react-icons/fi';
import { Badge, Button, Col, Row } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';
import ListagemProcessosContext from 'src/modulos/processos/contexts/ListagemProcessosContext';
import Etiquetas from './Etiquetas';
import Prazos from './Prazos';
import Prepostos from './Prepostos';

const Filtro: React.FC = () => {
  const { filtro } = useContext(ListagemProcessosContext);
  const [exibirFiltro, setExibirFiltro] = React.useState(false);
  const [exibirOpcao, setExibirOpcao] = React.useState<string | null>(null);

  const abrirMenuFiltros = () => setExibirFiltro(true);
  const fecharMenuFiltros = () => {
    setExibirOpcao(null);
    setExibirFiltro(false);
  };

  const pegarQtdFiltrosListagem = () => {
    const qtd = Object.keys(filtro).reduce((prev, atual: string) => {
      if (filtro[atual] && filtro[atual].length > 0) {
        return prev + 1;
      }
      return prev;
    }, 0);
    return qtd;
  };

  const pegarTituloMenu = () => {
    if (exibirOpcao !== null) {
      return `Filtrar por ${exibirOpcao}`;
    }
    return 'Filtrar por...';
  };

  return (
    <div className="position-relative">
      <Button
        color="soft-primary"
        className="mx-2 d-flex align-items-center"
        onClick={() => {
          if (!exibirFiltro) {
            abrirMenuFiltros();
          } else {
            fecharMenuFiltros();
          }
        }}
      >
        <FiFilter />
        <span className="mx-2">Filtrar</span>
        {pegarQtdFiltrosListagem() > 0 && (
          <Badge color="primary" pill>
            {pegarQtdFiltrosListagem()}
          </Badge>
        )}
      </Button>
      <Dropdown
        mostrar={exibirFiltro}
        fechar={fecharMenuFiltros}
        titulo={pegarTituloMenu()}
        fecharAoClicarExterno={false}
        voltar={
          exibirOpcao
            ? () => {
                setExibirOpcao(null);
              }
            : undefined
        }
      >
        <div className="pt-2">
          {exibirOpcao === 'prepostos' && <Prepostos />}
          {exibirOpcao === 'etiquetas' && <Etiquetas />}
          {exibirOpcao === 'prazos' && <Prazos />}
          {exibirOpcao === null && (
            <Row>
              <Col md={12}>
                <ul className="list-unstyled">
                  <li
                    className="d-flex flex-row py-3 align-items-center cursor-pointer"
                    onClick={() => setExibirOpcao('prepostos')}
                  >
                    <FiUser />
                    <span className="flex-fill ml-3">Prepostos</span>
                  </li>
                  <li
                    className="d-flex flex-row py-3 align-items-center cursor-pointer"
                    onClick={() => setExibirOpcao('etiquetas')}
                  >
                    <FiTag />
                    <span className="flex-fill ml-3">Etiquetas</span>
                  </li>
                  <li
                    className="d-flex flex-row py-3 align-items-center cursor-pointer"
                    onClick={() => setExibirOpcao('prazos')}
                  >
                    <FiCalendar />
                    <span className="flex-fill ml-3">Prazos</span>
                  </li>
                </ul>
              </Col>
            </Row>
          )}
        </div>
      </Dropdown>
    </div>
  );
};

export default Filtro;
