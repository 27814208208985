import React from 'react';
import AsyncSelect from 'react-select/async';
import buscarCidade from 'src/modulos/aplicacao/casos-de-uso/cidade/buscarCidade';
import Cidade from 'src/modulos/aplicacao/entidades/Cidade';

type Props = {
  onCidadeSelecionada(cidade: Cidade): void;
  valorInicial?: {
    value?: string | number;
    label?: string;
    cidade?: any;
  };
};

const BuscaCidade: React.FC<Props> = ({
  onCidadeSelecionada,
  valorInicial = {},
}) => {
  const pesquisarCidade = async (valor: string) => {
    const { resultado } = await buscarCidade(valor);
    if (resultado) {
      return resultado.map((item) => ({
        value: item.id,
        label: item.nome,
        cidade: item,
      }));
    }
    return [];
  };
  return (
    <AsyncSelect
      loadOptions={pesquisarCidade}
      placeholder="Pesquisar cidade"
      defaultValue={valorInicial}
      onChange={(valor) => {
        if (valor) {
          onCidadeSelecionada(valor.cidade);
        }
      }}
    />
  );
};

export default BuscaCidade;
