import React from "react";
import { Outlet } from "react-router-dom";
import AutenticacaoContext from "../../../contexts/Autenticacao";
import Footer from "../../../layout/Footer";
import Header from "./Header";

const Layout: React.FC = () => {
  const { carregando } = React.useContext(AutenticacaoContext);

  if (carregando) {
    return <>Carregando...</>;
  }

  return (
    <div id="wrapper">
      <Header />
      <div className="container-fluid">
        <div className="content">
          <div className="container-fluid mt-4">
            <div className="row page-title">
              <div className="col-md-12"></div>
            </div>
          </div>
          <div className="my-4">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
