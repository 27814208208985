import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb';

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
} from 'reactstrap';

import Identificacao from './Janelas/Identificacao';
import Endereco from './Janelas/Endereco';
import Contato from './Janelas/Contato';
import { FormProvider, useForm } from 'react-hook-form';
import pegarPerfilDaServentia from '../../casos-de-uso/cartorio/pegarPerfilDaServentia';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import atualizarPerfilDaServentia from '../../casos-de-uso/cartorio/atualizarPerfilDaServentia';
import { toast } from 'react-toastify';
import { useRequest } from 'ahooks';
import { Cartorio } from 'src/modulos/aplicacao/entidades/Cartorio';
import Financeiro from './Janelas/Financeiro';
import ControleIp from './Janelas/ControleIp';

const abas = [
  {
    id: 'identificacao',
    titulo: 'Identificação',
    componente: <Identificacao />,
  },
  {
    id: 'endereco',
    titulo: 'Endereço',
    componente: <Endereco />,
  },
  {
    id: 'contato',
    titulo: 'Contato',
    componente: <Contato />,
  },
  {
    id: 'financeiro',
    titulo: 'Financeiro',
    componente: <Financeiro />,
  },
  {
    id: 'controle-ips',
    titulo: 'Controle de IP',
    componente: <ControleIp />,
  },
];

const PerfilDeServentia: React.FC = () => {
  const methods = useForm<Cartorio>();
  const { loading: processando } = useRequest(pegarPerfilDaServentia, {
    onSuccess(dados) {
      methods.reset(dados);
    },
  });
  const [abaSelecionada, setAbaSelecionada] = React.useState(abas[0].id);

  const abaEstahSelecionada = (id: string) => abaSelecionada === id;

  const salvarAlteracoes = async (dados: Cartorio) => {
    const { resultado, error } = await atualizarPerfilDaServentia(dados);
    if (resultado) {
      toast.success('Perfil da serventia atualizado.');
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  return (
    <>
      <Breadcrumb
        links={[
          { url: '/', texto: 'Inicio ' },
          { texto: ' Perfil de Serventia' },
        ]}
      />
      <Row>
        <Col md={12}>
          <CarregamentoConteudo carregando={processando}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(salvarAlteracoes)}>
                <Card>
                  <CardBody>
                    <h4>Perfil da serventia</h4>
                    <div className="mt-3">
                      <Nav tabs>
                        {abas.map((item) => {
                          return (
                            <NavItem key={item.id} className="cursor-pointer">
                              <NavLink
                                active={abaEstahSelecionada(item.id)}
                                onClick={() => {
                                  setAbaSelecionada(item.id);
                                }}
                              >
                                {item.titulo}
                              </NavLink>
                            </NavItem>
                          );
                        })}
                      </Nav>

                      <TabContent activeTab={abaSelecionada}>
                        {abas.map((item) => {
                          return (
                            <TabPane tabId={item.id} key={item.id}>
                              {item.componente}
                            </TabPane>
                          );
                        })}
                      </TabContent>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex justify-content-end">
                      <Button color="primary">Salvar alterações</Button>
                    </div>
                  </CardFooter>
                </Card>
              </form>
            </FormProvider>
          </CarregamentoConteudo>
        </Col>
      </Row>
    </>
  );
};

export default PerfilDeServentia;
