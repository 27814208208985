import React, { useCallback, useState } from 'react';
import { FiChevronLeft, FiChevronRight, FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import ResultadoPaginado from 'src/entidades/ResultadoPaginado';
import buscarPessoas from '../../casos-de-uso/pessoa/buscarPessoas';
import {
  pegarDenominacaoPessoa,
  pegarDocumentoPessoa,
  Pessoa,
} from '../../entidades/Pessoa';
import { PessoaFisica } from '../../entidades/PessoaFisica';
import { PessoaJuridica } from '../../entidades/PessoaJuridica';

const linksBreadcrumb = [
  {
    url: '/',
    texto: 'Início',
  },
  {
    texto: 'Clientes',
  },
];

const Inicio: React.FC = () => {
  const [listaPessoas, setListaPessoas] =
    useState<ResultadoPaginado<PessoaFisica | PessoaJuridica>>();
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [, setProcessando] = useState(false);

  const buscar = useCallback((pagina: number = 1) => {
    setProcessando(true);
    buscarPessoas('', pagina).then(({ resultado }) => {
      setProcessando(false);
      if (resultado) {
        setListaPessoas(resultado);
        return;
      }
    });
  }, []);

  const gerarLinkPessoa = (pessoa: Pessoa) => `/pessoas/editar/${pessoa.id}`;

  const ehUltimaPagina = () => {
    const total = listaPessoas?.total || 1;
    const ultima = total / 10;
    return Number(ultima.toFixed(0)) === paginaAtual;
  };

  const ehPrimeiraPagina = () => {
    return paginaAtual === 1;
  };

  const proximaPagina = () => {
    setPaginaAtual((atual) => atual + 1);
  };

  const voltarPagina = () => {
    setPaginaAtual((atual) => atual - 1);
  };

  React.useEffect(() => {
    buscar(paginaAtual);
  }, [buscar, paginaAtual]);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <Breadcrumb links={linksBreadcrumb} />
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Cadastros recentes</h5>
                  <Link to="/pessoas/adicionar">
                    <Button
                      color="primary"
                      className="d-flex align-items-center"
                    >
                      <FiPlus className="mr-2" />
                      <span>Adicionar</span>
                    </Button>
                  </Link>
                </div>
                <span className="pb-2 d-block">
                  {listaPessoas?.total} resultados
                </span>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Nome/Razão Social</th>
                      <th>CPF/CNPJ</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listaPessoas?.dados.map((item) => {
                      return (
                        <tr>
                          <td>
                            <Link to={gerarLinkPessoa(item)}>
                              {pegarDenominacaoPessoa(item)}
                            </Link>
                          </td>
                          <td>{pegarDocumentoPessoa(item, true)}</td>
                          <td>
                            <span className="badge badge-soft-warning"></span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    color="primary"
                    disabled={ehPrimeiraPagina()}
                    onClick={voltarPagina}
                  >
                    <FiChevronLeft />
                  </Button>
                  <input
                    type="number"
                    value={paginaAtual}
                    className="form-control col-1 text-center mx-1"
                    min={1}
                    onChange={(evento: React.ChangeEvent<HTMLInputElement>) => {
                      setPaginaAtual(Number(evento.target.value));
                    }}
                  />
                  <Button
                    color="primary"
                    disabled={ehUltimaPagina()}
                    onClick={proximaPagina}
                  >
                    <FiChevronRight />
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Inicio;
