import { Modulo } from '../entidades/Modulo';

export default function pegarUrlModulo(modulo: Modulo): string {
  if (!modulo.nome) return '';

  const urls: { [key: string]: string } = {
    controle_processos: '/',
    financeiro: '/financeiro',
    acervo: '/acervo',
    clientes: '/pessoas',
    mosaico: '/mosaico',
    comunicacoes: '/comunicacoes',
  };
  return urls[modulo.nome] || '';
}
