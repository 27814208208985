import React from "react";

// import { Container } from './styles';

const InfoMoney: React.FC = () => {
  return (
    <section>
      <div className="card">
        <div className="flex items-start">
          <div className="flex flex-col flex-1">
            <h1 className="titulo-pagina">InfoMoney</h1>
            <span>Todos os atos realizados entre 2019 e 2021</span>
          </div>
          <button className="btn-azul">
            <span>Filtros</span>
          </button>
        </div>
        <div className="mt-8 bg-gray-400 h-80"></div>
      </div>
    </section>
  );
};

export default InfoMoney;
