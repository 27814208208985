import { Route } from 'react-router-dom';
import { AutenticacaoProvider } from 'src/contexts/Autenticacao';
import Layout from '../layout';
import Configuracoes from '../paginas/Configuracoes';
import ListagemTaxas from '../paginas/ListagemTaxas';
import ListagemServicos from '../paginas/ListagemServicos';
import ListagemContas from '../paginas/ListagemContas';
import InfoMoney from '../paginas/InfoMoney';
import InformacoesDeRepasse from '../paginas/InformacoesDeRepasse';
import ListagemOrcamentos from '../paginas/ListagemOrcamentos';
import LivroDiarioAuxiliar from '../paginas/LivroDiarioAuxiliar';
import ResumoDeCaixa from '../paginas/ResumoDeCaixa';
import VisualizarOrcamento from '../paginas/VisualizarOrcamento';
import InicioFinanceiro from '../paginas';
import CaixaPorUsuario from '../paginas/ResumoDeCaixa/CaixaPorUsuario';
import Selos from '../paginas/Selos';

export default (
  <Route
    path="/financeiro"
    element={
      <AutenticacaoProvider>
        <Layout />
      </AutenticacaoProvider>
    }
  >
    <Route index element={<ResumoDeCaixa />} />
    <Route path="selos" element={<Selos />} />
    <Route path="caixa">
      {/* <Route index element={<ResumoDeCaixa />} /> */}
      <Route path="usuarios" element={<CaixaPorUsuario />} />
    </Route>
    <Route path="orcamentos" element={<ListagemOrcamentos />} />
    <Route path="orcamento/visualizar/:id" element={<VisualizarOrcamento />} />
    <Route path="livro-diario-auxiliar" element={<LivroDiarioAuxiliar />} />
    <Route path="informacoes-repasse" element={<InformacoesDeRepasse />} />
    <Route path="infomoney" element={<InfoMoney />} />
    {/* <Route path="selos" element={<Selos />} /> */}
    <Route path="configuracoes" element={<Configuracoes />} />
    <Route path="gerenciar-adicionais" element={<ListagemTaxas />} />
    <Route path="gerenciar-servicos" element={<ListagemServicos />} />
    <Route path="gerenciar-contas" element={<ListagemContas />} />
  </Route>
);
