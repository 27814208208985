import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { Button } from 'reactstrap';
import Dropdown from '../Dropdown';

interface Props {
  mostrar: boolean;
  fechar(): void;
  aoConfirmar(): void;
  titulo?: string;
  mensagem?: string;
  textoBotao?: string;
}

const PopupConfirmacao: React.FC<Props> = ({
  mostrar,
  fechar,
  aoConfirmar,
  titulo = 'Excluir',
  mensagem = 'Tem certeza? Essa ação é irreversível.',
  textoBotao = 'Excluir',
}) => {
  return (
    <Dropdown
      mostrar={mostrar}
      fechar={fechar}
      alinharEsquerda
      titulo={titulo}
      fecharAoClicarExterno
    >
      <span className="d-block py-2">{mensagem}</span>
      <div className="d-flex justify-content-end">
        <Button
          color="soft-danger"
          className="d-flex align-items-center"
          onClick={aoConfirmar}
        >
          <FiTrash2 />
          <span>{textoBotao}</span>
        </Button>
      </div>
    </Dropdown>
  );
};

export default PopupConfirmacao;
