import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import Cidade, { CidadeType } from '../../entidades/Cidade';

export default async function buscarCidade(
  termo: string
): Promise<IResultadoRequisicao<Cidade[]>> {
  try {
    const resultado: CidadeType[] = await api().get('localidade/cidade', {
      params: {
        busca: termo,
      },
    });
    return { resultado: resultado.map((item) => new Cidade(item)) };
  } catch (error) {
    return { error };
  }
}
