import { ErroRegraNegocio } from "src/entidades/ErroRegraNegocio";
import api from "../../api";
import { FinanceiroConfiguracao } from "../../entidades/FinanceiroConfiguracao";

const visualizarConfiguracao = async (): Promise<{
  resultado?: FinanceiroConfiguracao[];
  error?: ErroRegraNegocio;
}> => {
  try {
    const resultado: FinanceiroConfiguracao[] = await api().get(
      '/configuracao/visualizar'
    );
    return { resultado };
  } catch (error: ErroRegraNegocio | any) {
    return { error };
  }
};

export default visualizarConfiguracao;
