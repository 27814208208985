import React, { useContext, useRef, useState } from 'react';
import { FiList } from 'react-icons/fi';
import { BsEyeSlash } from 'react-icons/bs';
import css from '../style.module.css';
import clsx from 'clsx';
import formatarDatahora from 'src/casos-de-uso/formatacao/formatarDatahora';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import {
  Andamento,
  possuiComentarioOuInformacao,
} from 'src/modulos/processos/entidades/Andamento';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/i18n/pt-br';
import { Button } from 'reactstrap';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import adicionarComentarioProcesso from 'src/modulos/processos/casos-de-uso/processo/adicionarComentarioProcesso';
import { toast } from 'react-toastify';
import Avatar from 'src/modulos/processos/components/Avatar';
import pegarFotoPreposto from 'src/modulos/processos/casos-de-uso/preposto/pegarFotoPreposto';
// import AutenticacaoContext from 'src/contexts/Autenticacao';
// import { Preposto } from 'src/modulos/processos/entidades/Preposto';
import { Editor } from '@toast-ui/react-editor';
import MarkdownPreview from '@uiw/react-markdown-preview';
import editarComentarioProcesso from 'src/modulos/processos/casos-de-uso/processo/editarComentarioProcesso';

const ListagemAtividades: React.FC = () => {
  // const { usuario } = useContext(AutenticacaoContext);
  const { processo, arquivado, onComentarioAdicionado, onComentarioEditado } =
    useContext(ProcessoContext);
  const editorRef = useRef<Editor>(null);
  const [comentarioEditado, setComentarioEditado] = useState<Andamento | null>(
    null
  );
  const [exibirDetalhes, setExibirDetalhes] = useState<boolean>(false);
  const [exibirEditorComentario, setExibirEditorComentario] =
    useState<boolean>(false);
  const [processandoComentario, setProcessandoComentario] =
    useState<boolean>(false);

  const pegarConteudoAndamento = (andamento: Andamento) => {
    return (
      andamento.comentario || andamento.descricao || andamento.informacao || ''
    );
  };

  const listarAndamentosEComentarios = () => {
    if (processo.andamentos) {
      return processo.andamentos.filter((item) => {
        if (!exibirDetalhes) {
          return possuiComentarioOuInformacao(item);
        }
        return true;
      });
    }
    return [];
  };

  const atividadePossuiEdicao = (andamento: Andamento) => {
    return andamento.created_at !== andamento.updated_at;
  };

  const enviarComentario = async () => {
    setProcessandoComentario(true);
    const comentario = editorRef.current?.getInstance().getMarkdown() || '';
    let resultado, error;

    if (comentarioEditado) {
      const { resultado: resultadoEdicao, error: errorEdicao } =
        await editarComentarioProcesso(
          processo,
          comentarioEditado.id!,
          comentario
        );
      resultado = resultadoEdicao;
      error = errorEdicao;
    } else {
      const { resultado: resultadaoAdicao, error: errorAdicao } =
        await adicionarComentarioProcesso(processo, comentario);
      resultado = resultadaoAdicao;
      error = errorAdicao;
    }
    setProcessandoComentario(false);
    if (resultado) {
      setExibirEditorComentario(false);
      if (comentarioEditado) {
        onComentarioEditado(resultado);
      } else {
        onComentarioAdicionado(resultado);
      }
      setComentarioEditado(null);
      // toast.success('Comentário adicionado ao processo');
      return;
    }
    if (error) {
      toast.error(error.mensagem);
      return;
    }
  };

  const abrirEdicaoComentario = (comentario: Andamento) => {
    if (comentario.comentario) {
      setComentarioEditado(comentario);
      setExibirEditorComentario(true);
    }
  };

  const exibirBotaoEditarComentario = (comentario: Andamento) => {
    return comentario.comentario && comentario.comentario.length > 0;
  };
  const exibirBotaoExcluirComentario = (comentario: Andamento) => {
    return comentario.comentario && comentario.comentario.length > 0;
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className={css.titulo}>
          <FiList className={css['icone-titulo']} />
          <h5>Atividades</h5>
        </h3>
        <button
          className="btn btn-soft-primary"
          onClick={() => setExibirDetalhes((exibir) => !exibir)}
        >
          <BsEyeSlash />
          <span className="ml-2">
            {!exibirDetalhes ? 'Exibir' : 'Ocultar'} detalhes
          </span>
        </button>
      </div>
      <div className={clsx('mt-3', css['espaco-lateral'])}>
        <div className="d-flex flex-row align-items-center">
          <div className="mr-3">
            {/* <Avatar
              url={pegarFotoPreposto(usuario as Preposto)}
              tamanho="48px"
            /> */}
          </div>
          <div className="flex-fill">
            {!exibirEditorComentario && (
              <textarea
                className="w-100 form-control"
                placeholder="Escreva um comentário"
                disabled={arquivado}
                onFocus={() => setExibirEditorComentario(true)}
              />
            )}
            {exibirEditorComentario && (
              <>
                <div className="d-block mw-100">
                  <Editor
                    previewStyle="tab"
                    height="200px"
                    initialEditType="markdown"
                    placeholder="Escreva o comentário aqui..."
                    ref={editorRef}
                    initialValue={
                      comentarioEditado
                        ? comentarioEditado.comentario || ''
                        : ''
                    }
                    language="pt-BR"
                    toolbarItems={[
                      ['heading', 'bold', 'italic', 'strike'],
                      ['ol'],
                    ]}
                  />
                </div>
                <div className="d-flex justify-content-end mt-2">
                  <Button
                    color="soft-info"
                    className="mr-2"
                    onClick={() => {
                      setComentarioEditado(null);
                      setExibirEditorComentario(false);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    color="primary"
                    onClick={enviarComentario}
                    disabled={processandoComentario}
                    className="px-5"
                  >
                    <CarregamentoConteudo carregando={processandoComentario}>
                      Enviar
                    </CarregamentoConteudo>
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={clsx('mt-3', css['espaco-lateral'])}>
        {listarAndamentosEComentarios().map((item: any) => {
          return (
            <div
              className="d-flex align-items-start flex-row my-3"
              key={item.id}
            >
              <div className="mr-3">
                <Avatar url={pegarFotoPreposto(item.usuario)} tamanho="48px" />
              </div>
              <div className="d-flex flex-column flex-fill">
                <h5 className="p-0 m-0">
                  <b>{item.usuario.nome_usuario}</b>{' '}
                </h5>
                <div>
                  {<MarkdownPreview source={pegarConteudoAndamento(item)} />}
                </div>
                <div className="mt-2">
                  <span>
                    <b>Criado em: </b>
                    {formatarDatahora(item.created_at)}
                  </span>
                  {atividadePossuiEdicao(item) && (
                    <span className="ml-2">
                      - <b>Editado em: </b>
                      {formatarDatahora(item.updated_at)}
                    </span>
                  )}
                  {exibirBotaoEditarComentario(item) && (
                    <>
                      <span className="mx-1">-</span>
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => abrirEdicaoComentario(item)}
                      >
                        Editar
                      </span>
                    </>
                  )}
                  {exibirBotaoExcluirComentario(item) && (
                    <>
                      <span className="mx-1">-</span>
                      <span className="text-danger cursor-pointer">
                        Excluir
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListagemAtividades;
