import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Breadcrumb from '../../../../components/Breadcrumb';
import pegarOrcamentoCompleto from '../../casos-de-uso/orcamento/pegarOrcamentoCompleto';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { OrcamentoCompleto } from '../../entidades/OrcamentoCompleto';
import { formatarNumeroOrcamento } from '../../entidades/Orcamento';
import Apresentante from './Apresentante';
import DetalheServico from './DetalheServico';
import formatarDinheiro from 'src/util/formatarDinheiro';
import DetalheServicoExtra from './DetalheServicoExtra';
import DetalhePagamento from './DetalhePagamento';
import DetalheRecolhido from './DetalheRecolhido';
import formatarDatahora from 'src/casos-de-uso/formatacao/formatarDatahora';
import { formatarCPF } from 'src/util/formatarCPF';
import dayjs from 'dayjs';
import ptbr from 'dayjs/locale/pt-br';
import formatarPercentual from 'src/util/formatarPercentual';
import Processos from './Processos';

dayjs.locale('pt-br', ptbr);

const VisualizarOrcamento: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [expandirServicos, setExpandirServicos] = useState(false);
  const [orcamento, setOrcamento] = useState<OrcamentoCompleto>();

  const dataCriacao = orcamento?.created_at
    ? dayjs(orcamento.created_at).format('DD [de] MMMM [de] YYYY')
    : '';

  const alterarExpandirServicos = () => {
    setExpandirServicos((atual) => !atual);
  };

  useEffect(() => {
    pegarOrcamentoCompleto(Number(id)).then(({ resultado }) => {
      if (resultado) {
        setOrcamento(resultado);
      }
    });
  }, [id]);

  if (!orcamento) {
    return <></>;
  }

  return (
    <>
      <Breadcrumb
        links={[
          {
            url: '/financeiro',
            texto: 'Financeiro',
          },
          {
            url: '/financeiro/orcamentos',
            texto: 'Orçamentos',
          },
          {
            texto: `Orçamento Nº ${formatarNumeroOrcamento(orcamento)}`,
          },
        ]}
      />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between mb-3 align-items-center">
                <h4>Orçamento Nº {formatarNumeroOrcamento(orcamento)}</h4>
                <div>
                  <Button color="soft-primary" className="mx-2">
                    <AiOutlinePrinter className="mr-2" />
                    Imprimir
                  </Button>
                  <Button color="soft-primary" className="mx-2">
                    <HiOutlinePencilAlt className="mr-2" />
                    Editar orçamento
                  </Button>
                </div>
              </div>

              <hr />

              <Apresentante pessoa={orcamento.pessoa} />
              <Processos idOrcamento={orcamento.id} />

              <hr />

              <div className="d-flex justify-content-between mb-3 align-items-center">
                <b>
                  <span className="d-block">Detalhamento</span>
                </b>

                <div>
                  <Button
                    color="soft-primary"
                    className="mx-2"
                    onClick={alterarExpandirServicos}
                  >
                    <AiOutlineEye className="mr-2" />
                    {expandirServicos ? 'Recolher' : 'Expandir'} todos os
                    serviços
                  </Button>
                </div>
              </div>
              <Row>
                <Col md={12}>
                  <h6>Serviços</h6>
                  {orcamento.servicos_atos.map((item) => (
                    <DetalheServico
                      key={item.id}
                      servico={item}
                      aberturaExterna={expandirServicos}
                    />
                  ))}
                </Col>
                {orcamento.servicos_extras.length > 0 && (
                  <Col md={12} className="mt-2">
                    <h6>Serviços Extra Notarais/Registrais</h6>
                    {orcamento.servicos_extras.map((item) => (
                      <DetalheServicoExtra key={item.id} servico={item} />
                    ))}
                  </Col>
                )}
              </Row>

              <hr />

              <Row>
                <Col md={12}>
                  <div className="d-flex flex-column justify-content-end">
                    <b>
                      <span className="d-block text-right">Valor Total</span>
                    </b>
                    <h2 className="text-right">
                      {formatarDinheiro(orcamento.total)}
                    </h2>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={12}>
                  <b>
                    <span>Informações de Pagamento</span>
                  </b>
                  {orcamento.pagamentos.map((item) => (
                    <DetalhePagamento key={item.id} pagamento={item} />
                  ))}
                </Col>
                <Col md={12}>
                  {orcamento.recolhidos.map((item) => (
                    <DetalheRecolhido key={item.id} recolhido={item} />
                  ))}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={12}>
                  <b>
                    <span>Histórico de atualizações</span>
                  </b>
                  {orcamento.edicoes.map((item) => {
                    return (
                      <span className="d-block py-2" key={item.id}>
                        Editado por {item.usuario.pessoa.especializacao.nome} em{' '}
                        {formatarDatahora(item.created_at)}
                      </span>
                    );
                  })}
                  <span className="d-block py-2">
                    Criado por {orcamento.usuario.pessoa.especializacao.nome} em{' '}
                    {formatarDatahora(orcamento.created_at)}
                  </span>
                </Col>
              </Row>
              <hr />
              <Row className="justify-content-end">
                <Col md={3}>
                  <h6>Dados Bancários</h6>
                  <div className="border p-3 rounded">
                    <span className="d-block">{orcamento.cartorio.banco}</span>
                    <span className="d-block">
                      {orcamento.cartorio.favorecido}
                    </span>
                    <span className="d-block">
                      CPF/CNPJ:{' '}
                      {formatarCPF(orcamento.cartorio.favorecido_cnpj!)}
                    </span>
                    <span className="d-block">
                      <b>AG: {orcamento.cartorio.agencia}</b>
                      <b className="ml-4">C/C: {orcamento.cartorio.conta}</b>
                    </span>
                  </div>
                </Col>
                <Col md={3}>
                  <h6>Chave PIX:</h6>
                  <div className="border p-3 rounded">
                    <span className="d-block">
                      <b>
                        {orcamento.cartorio.tipo_pix}: {orcamento.cartorio.pix}
                      </b>
                    </span>
                    <span className="d-block">
                      Obs.: Ao realizar o pagamento, será exibido o nome do
                      Cartório ou do Tabelião/Registrador. Na dúvida, realize o
                      pagamento pelos meios convencionais.
                    </span>
                  </div>
                </Col>
              </Row>

              <div className="d-flex justify-content-end pt-3">
                <span>
                  {orcamento.cartorio.cidade}, {dataCriacao}.
                </span>
              </div>

              <hr />

              <Row>
                <Col md={7}>
                  <b>
                    <span>Observações</span>
                  </b>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col md={12}>
                  <ul className="pl-3 mb-3">
                    <li>
                      Já incluso o imposto municipal (ISS:{' '}
                      {formatarPercentual(Number(orcamento.cartorio.iss))})
                    </li>
                    <li>
                      Valor estimado, podendo sofrer alterações quando da
                      execução dos serviços.
                    </li>
                    <li>{orcamento.observacao_rodape}</li>
                  </ul>
                  <span className="mt-3">
                    <b>
                      Obs.: Comprovante de depósito/transferência vale como
                      recibo dos valores acima discriminados.
                    </b>
                  </span>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default VisualizarOrcamento;
