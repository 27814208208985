import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import formatarData from 'src/casos-de-uso/formatacao/formatarData';
import Dropdown from 'src/components/Dropdown';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import { ProtocoloProcesso } from 'src/modulos/processos/entidades/ProtocoloProcesso';
import { pegarCodigoSelo } from 'src/modulos/processos/entidades/SeloAnoregProcesso';
import CriarProtocolo from './CriarProtocolo';

const Protocolos: React.FC = () => {
  const { processo, arquivado } = useContext(ProcessoContext);
  const [exibirDetalhes, setExibirDetalhes] = useState<number | null>(null);

  return (
    <div className="py-3 position-relative">
      <h5 className="mb-2">Protocolos</h5>
      <div className="d-flex flex-wrap">
        {processo.protocolos.map((item: ProtocoloProcesso) => {
          return (
            <div className="position-relative mb-2">
              <Button
                color="soft-secondary"
                className="mr-2"
                onClick={() => setExibirDetalhes(item.id)}
              >
                <span>
                  {item.codigo} ({formatarData(item.data)})
                </span>
              </Button>
              <Dropdown
                mostrar={item.id === exibirDetalhes}
                fechar={() => setExibirDetalhes(null)}
                titulo="Informações"
                alinharEsquerda
              >
                <Table bordered>
                  <tbody>
                    <tr>
                      <td className="bg-light">
                        <b>Protocolo</b>
                      </td>
                      <td>{item.numero}</td>
                    </tr>
                    <tr>
                      <td className="bg-light">
                        <b>Data</b>
                      </td>
                      <td>{formatarData(item.data)}</td>
                    </tr>
                    <tr>
                      <td className="bg-light">
                        <b>Apresentante</b>
                      </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td className="bg-light">
                        <b>Natureza do título</b>
                      </td>
                      <td>{item.especialidade}</td>
                    </tr>
                    <tr>
                      <td className="bg-light">
                        <b>Selo</b>
                      </td>
                      <td>{pegarCodigoSelo(item.selo)}</td>
                    </tr>
                  </tbody>
                </Table>
                {!arquivado && item?.selo?.livro_registro_id && (
                  <Link
                    to={`/selos/${item?.selo?.livro_registro_id}?processo=${processo.id}`}
                    target="_blank"
                  >
                    <Button color="primary">Realizar ato</Button>
                  </Link>
                )}
              </Dropdown>
            </div>
          );
        })}
        <CriarProtocolo />
      </div>
    </div>
  );
};

export default Protocolos;
