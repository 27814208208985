import React, { useContext } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { ImStack } from 'react-icons/im';
import css from '../style.module.css';
import clsx from 'clsx';
import { gerarUrlProcesso } from 'src/modulos/processos/entidades/Processo';
import { Link } from 'react-router-dom';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import { ProcessoRelacionado } from 'src/modulos/processos/entidades/ProcessoRelacionado';
import desrelacionarProcessos from 'src/modulos/processos/casos-de-uso/processo/desrelacionarProcessos';
import { toast } from 'react-toastify';

const ListagemProcessosRelacionados: React.FC = () => {
  const { processo, arquivado, onProcessoDesrelacionado } =
    useContext(ProcessoContext);

  const excluirRelacionamento = async (
    processoRelacionado: ProcessoRelacionado
  ) => {
    const { resultado, error } = await desrelacionarProcessos(
      processo,
      processoRelacionado.id
    );
    if (resultado) {
      onProcessoDesrelacionado(processoRelacionado, resultado);
      return;
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  if (processo.processos_relacionados.length === 0) {
    return null;
  }
  return (
    <div className="mb-4">
      <div className="d-flex flex-row align-items-center">
        <h3 className={css.titulo}>
          <ImStack className={css['icone-titulo']} />
          <h5>Processos relacionados</h5>
        </h3>
      </div>
      <div className={clsx('d-flex flex-row flex-wrap', css['espaco-lateral'])}>
        {processo.processos_relacionados.map((item) => {
          return (
            <div
              className="d-flex align-items-center text-success bg-soft-success py-2 px-3 rounded mr-2 mt-2"
              key={item.id}
            >
              <Link
                to={gerarUrlProcesso(item)}
                className="flex-1"
                target="_blank"
              >
                PROCESSO Nº {item.numero}
              </Link>
              {!arquivado && (
                <MdOutlineClose
                  className="ml-3 font-size-16"
                  onClick={() => excluirRelacionamento(item)}
                />
              )}
            </div>
          );
        })}
        {/* <button className="btn btn-soft-primary py-2 px-3 ml-3">
          <FiPlus />
        </button> */}
      </div>
    </div>
  );
};

export default ListagemProcessosRelacionados;
