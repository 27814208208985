import clsx from 'clsx';
import React, { useRef } from 'react';
import { FiChevronLeft, FiX } from 'react-icons/fi';
import css from './style.module.css';

type Props = {
  mostrar: boolean;
  largura?: string;
  titulo?: string;
  fecharAoClicarExterno?: boolean;
  fechar(): void;
  alinharEsquerda?: boolean;
  alinharDireita?: boolean;
  voltar?(): void;
};

const Dropdown: React.FC<Props> = ({
  children,
  mostrar,
  fechar,
  voltar,
  titulo,
  fecharAoClicarExterno = true,
  largura = '400px',
  alinharEsquerda = false,
  alinharDireita = true,
}) => {
  const dropdownClick = useRef<HTMLDivElement>(null);

  const temFuncaoVoltar = typeof voltar === 'function';

  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        fecharAoClicarExterno &&
        dropdownClick.current &&
        !dropdownClick.current.contains(event.target)
      ) {
        fechar();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [fechar, fecharAoClicarExterno]);

  if (!mostrar) {
    return null;
  }

  return (
    <div
      ref={dropdownClick}
      className={clsx('dropdownClick', css['dropdown'], {
        [css['esquerda']]: alinharEsquerda,
        [css['direita']]: alinharDireita,
      })}
      style={{ width: largura }}
    >
      {titulo && (
        <div className="d-flex justify-content-between align-items-center border-bottom abc">
          <h5>
            {temFuncaoVoltar && (
              <FiChevronLeft
                className="font-size-18 cursor-pointer mr-2"
                onClick={voltar}
              />
            )}
            <span
              className={clsx({ [css['margem-titulo']]: !temFuncaoVoltar })}
            >
              {titulo}
            </span>
          </h5>
          <FiX onClick={fechar} className="font-size-18 cursor-pointer" />
        </div>
      )}
      <>{children}</>
    </div>
  );
};

export default Dropdown;
