import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import { FiCalendar, FiUsers } from 'react-icons/fi';
import { Chart } from 'react-google-charts';
import { Link } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import DatePicker from 'react-datepicker';

const ResumoDeCaixa: React.FC = () => {
  const [dropdownAberto, setDropdownAberto] = useState(false);
  const alternarEstadoDropdown = () =>
    setDropdownAberto((prevState) => !prevState);
  const [dataProtocolo, setDataProtocolo] = useState<Date | null>(null);

  const dadosReceitas = [
    ['Receitas', 'Porcentagem por especialidade'],
    ['Dinheiro', 11],
    ['Transf./Depósito', 2],
    ['Nota Promissória', 2],
    ['Cartão de Crédito', 2],
    ['Cartão de Débito', 7],
    ['Creditado pela Central de Serv. Eletrônicos', 2],
  ];

  const dadosDespesas = [
    ['Despesas', 'Porcentagem por especialidade'],
    ['Aluguel', 11],
    ['Copa / Cozinha', 2],
    ['Condomínio', 2],
    ['Abastecimento de Água', 2],
    ['Correio / Correspondências', 7],
    ['Outros', 2],
  ];

  return (
    <>
      <Breadcrumb
        links={[
          {
            url: '/financeiro',
            texto: 'Financeiro',
          },
          {
            texto: 'Resumo do Caixa',
          },
        ]}
      />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <b>
                        <h3>Resumo do Caixa</h3>
                      </b>
                      <p>De 11/02/2022 à 14/02/2022</p>
                    </div>

                    <div className="d-flex align-items-center">
                      <Dropdown
                        isOpen={dropdownAberto}
                        toggle={alternarEstadoDropdown}
                        direction={'down'}
                        className="mr-2"
                      >
                        <DropdownToggle caret color="soft-primary">
                          <FiCalendar className="mr-1" /> Filtrar por Intervalo
                          anual
                        </DropdownToggle>

                        <DropdownMenu>
                          <DropdownItem
                            header
                            className="d-flex align-items-center justify-content-between"
                          >
                            <h6>Filtrar por Intervalo Anual </h6>

                            <IoClose
                              className="ml-5 font-size-16"
                              style={{ cursor: 'pointer' }}
                              onClick={alternarEstadoDropdown}
                            />
                          </DropdownItem>
                          <hr />
                          <DropdownItem>
                            <DatePicker
                              selected={dataProtocolo}
                              onChange={(date: Date) => setDataProtocolo(date)}
                              inline
                              className="form-control"
                            />
                          </DropdownItem>
                          <hr />
                          <DropdownItem>
                            <div className="d-flex align-items-center justify-content-end">
                              <Button
                                color="soft-success"
                                className="w-75 mr-2"
                              >
                                Cancelar
                              </Button>
                              <Button color="primary" className="w-75">
                                Aplicar Filtro
                              </Button>
                            </div>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>

                      <Link to="usuarios">
                        <Button color="soft-primary">
                          <FiUsers className="mr-1" /> Visualizar Caixa por
                          Usuário
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="d-flex align-items-center">
                <Col>
                  <b>
                    <p>Receitas</p>
                  </b>
                  <b>
                    <h3 className="text-success">R$ 129,92</h3>
                  </b>

                  <div>
                    <Chart
                      chartType="PieChart"
                      data={dadosReceitas}
                      width={'100%'}
                      height={'400px'}
                    />
                  </div>
                </Col>

                <hr className="vertical-hr mr-1" />

                <Col>
                  <b>
                    <p>Despesas</p>
                  </b>
                  <b>
                    <h3 className="text-danger">- R$ 129,92</h3>
                  </b>

                  <div>
                    <Chart
                      chartType="PieChart"
                      data={dadosDespesas}
                      width={'100%'}
                      height={'400px'}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ResumoDeCaixa;
