import { Route } from 'react-router-dom';
import { AutenticacaoProvider } from 'src/contexts/Autenticacao';
import LayoutUsuarios from '../layout/Usuarios';
import AlterarSenha from '../paginas/AlterarSenha';
import Entrar from '../paginas/Entrar';
import GerenciarMembro from '../paginas/GerenciarMembro';
import Integracoes from '../paginas/Integracoes';
import MembrosDaEquipe from '../paginas/MembrosDaEquipe';
import MeuPerfil from '../paginas/MeuPerfil';
import NavegadoresConfirmados from '../paginas/NavegadoresConfirmados';
import PerfilDeServentia from '../paginas/PerfilDeServentia';
import Seguranca from '../paginas/Seguranca';
import TermoInicial from '../paginas/TermoInicial';

export default (
  <Route path="/">
    <Route path="entrar" element={<Entrar />} />
    <Route
      path="usuarios"
      element={
        <AutenticacaoProvider>
          <LayoutUsuarios />
        </AutenticacaoProvider>
      }
    >
      <Route path="perfil" element={<MeuPerfil />} />
      <Route path="serventia" element={<PerfilDeServentia />} />
      <Route path="integracoes" element={<Integracoes />} />
      <Route path="termo-inicial" element={<TermoInicial />} />
      <Route path="seguranca">
        <Route index element={<Seguranca />} />
        <Route path="alterar-senha" element={<AlterarSenha />} />
        <Route path="navegadores" element={<NavegadoresConfirmados />} />
      </Route>
      <Route path="membros" element={<MembrosDaEquipe />} />
      <Route path="membros/adicionar" element={<GerenciarMembro />} />
      <Route path="membros/editar/:id" element={<GerenciarMembro />} />
    </Route>
  </Route>
);
