import React, { useState } from 'react';
import {
  Breadcrumb,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import Anoreg from './Anoreg';
import Gise from './Gise';

const tabs = [
  {
    id: 'anoreg',
    titulo: 'ANOREG/TO',
    componente: <Anoreg />,
  },
  {
    id: 'gise',
    titulo: 'GISE',
    componente: <Gise />,
  },
];

const Integracoes: React.FC = () => {
  const [tabSelecionada, setTabSelecionada] = useState<string>('anoreg');

  return (
    <>
      <Breadcrumb
        links={[{ url: '/', texto: 'Inicio ' }, { texto: ' Integrações' }]}
      />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <h4>Integrações</h4>
              <div className="mt-3">
                <Nav tabs>
                  {tabs.map((item) => (
                    <NavItem
                      className="cursor-pointer"
                      key={item.id}
                      onClick={() => setTabSelecionada(item.id)}
                    >
                      <NavLink active={item.id === tabSelecionada}>
                        {item.titulo}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>

                <TabContent activeTab={tabSelecionada}>
                  {tabs.map((item) => (
                    <TabPane id={item.id} tabId={item.id}>
                      {item.componente}
                    </TabPane>
                  ))}
                </TabContent>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Integracoes;
