import React from 'react';
import { FiEdit, FiPlus, FiTrash } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import listarEtiquetas from 'src/modulos/processos/casos-de-uso/configuracoes/etiquetas/listarEtiquetas';
import {
  Etiqueta,
  pegarCorEtiqueta,
} from 'src/modulos/processos/entidades/Etiqueta';

const Etiquetas: React.FC = () => {
  const navigate = useNavigate();
  const [processando, setProcessando] = React.useState(true);
  const [etiquetas, setEtiquetas] = React.useState<Etiqueta[]>([]);

  React.useEffect(() => {
    setProcessando(true);
    listarEtiquetas().then(({ resultado }) => {
      setProcessando(false);
      if (resultado) {
        setEtiquetas(resultado);
      }
    });
  }, []);

  return (
    <Container>
      <Breadcrumb
        links={[
          {
            url: '/',
            texto: 'Controle de Processos',
          },
          {
            texto: 'Configuração de Etiquetas',
          },
        ]}
      />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CarregamentoConteudo carregando={processando}>
                <>
                  <div className="d-flex justify-content-between">
                    <h4>Etiquetas</h4>
                    <Button
                      color="primary"
                      onClick={() => {
                        navigate('/processos/configuracoes/etiqueta');
                      }}
                    >
                      <FiPlus />
                      <span>Adicionar</span>
                    </Button>
                  </div>
                  <div className="rounded-lg mt-4">
                    {etiquetas.map((item) => {
                      return (
                        <div
                          className="d-flex px-4 py-3"
                          key={item.id}
                          style={pegarCorEtiqueta(item)}
                        >
                          <div
                            className="d-flex align-items-center flex-fill"
                            onClick={() =>
                              navigate(
                                `/processos/configuracoes/etiqueta/${item.id}`
                              )
                            }
                          >
                            <FiEdit />
                            <span className="ml-2">{item.titulo}</span>
                          </div>
                          <div>
                            <FiTrash />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              </CarregamentoConteudo>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Etiquetas;
