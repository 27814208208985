import React, { useContext, useState } from 'react';
import { BsCalendar4 } from 'react-icons/bs';
import { Button, Row, Col } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import adicionarDataEntregaProcesso from 'src/modulos/processos/casos-de-uso/adicionarDataEntregaProcesso';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import Flatpickr from 'react-flatpickr';
import BlockUi from 'react-block-ui';

const DataEntrega: React.FC = () => {
  const { processo, arquivado, onAdicionadoDataEntrega } =
    useContext(ProcessoContext);
  const [processando, setProcessando] = useState(false);
  const [exibirPopup, setExibirPopup] = useState<boolean>(false);
  const [dataSelecionada, setDataSelecionada] = useState<Date>(new Date());
  const prazoProcesso = processo.prazos[0]?.data
    ? dayjs(processo.prazos[0]?.data).toDate()
    : new Date();

  const fecharPopup = () => setExibirPopup(false);
  const abrirPopup = () => setExibirPopup(true);

  const salvarDataEntrega = async () => {
    setProcessando(true);
    const { resultado, error } = await adicionarDataEntregaProcesso(
      processo,
      dayjs(dataSelecionada).format('YYYY-MM-DD')
    );
    setProcessando(false);

    if (resultado) {
      toast.success('Data de entrega adicionada ao processo');
      fecharPopup();
      onAdicionadoDataEntrega(resultado.prazo);
      return;
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  return (
    <>
      <Button
        color="soft-secondary"
        className="w-100 d-flex align-items-center"
        onClick={abrirPopup}
        disabled={arquivado}
      >
        <BsCalendar4 className="mr-2" />
        <span>Data de entrega</span>
      </Button>
      <Dropdown
        mostrar={exibirPopup}
        fechar={fecharPopup}
        titulo="Escolha uma data de entrega"
      >
        <BlockUi blocking={processando}>
          <Row>
            <Col
              md={12}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <Flatpickr
                options={{
                  minDate: new Date(),
                  inline: true,
                  locale: 'pt',
                  dateFormat: 'd/m/Y',
                  defaultDate: prazoProcesso,
                }}
                onChange={(datas: Date[]) => {
                  if (datas.length > 0) {
                    setDataSelecionada(datas[0]);
                  }
                }}
                className="d-none"
              />
            </Col>
            <Col md={6}>
              {/* <Button color="soft-danger" block>
              Remover data
            </Button> */}
            </Col>
            <Col md={6}>
              <Button
                color="primary"
                block
                onClick={salvarDataEntrega}
                className="mt-3"
              >
                <CarregamentoConteudo carregando={processando}>
                  Salvar alterações
                </CarregamentoConteudo>
              </Button>
            </Col>
          </Row>
        </BlockUi>
      </Dropdown>
    </>
  );
};

export default DataEntrega;
