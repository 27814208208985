export interface IPaisApi {
  id: number;
  nome: string;
}

export default class Pais {
  id?: number;
  nome?: string;

  constructor(dados?: Partial<IPaisApi>) {
    if (dados) {
      this.id = dados.id;
      this.nome = dados.nome;
    }
  }
}
