import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FiPlus, FiEdit, FiTrash, FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { Container, Card, CardBody, Table, ButtonGroup, Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, TabContent, TabPane, Nav, NavItem, NavLink, Collapse } from 'reactstrap';
import classnames from 'classnames';
import Breadcrumb from 'src/components/Breadcrumb';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import SemDadosParaExibir from 'src/components/SemDadosParaExibir';

import { Conta } from "src/modulos/financeiro/entidades/Conta";
import listarContas from 'src/modulos/financeiro/casos-de-uso/conta/listarContas';
import cadastrarConta from 'src/modulos/financeiro/casos-de-uso/conta/cadastrarConta';
import atualizarConta from 'src/modulos/financeiro/casos-de-uso/conta/atualizarConta';
import excluirConta from '../../casos-de-uso/conta/excluirConta';

import { Grupo } from "src/modulos/financeiro/entidades/Grupo";
import listarGrupos from 'src/modulos/financeiro/casos-de-uso/grupo/listarGrupos';
import cadastrarGrupo from 'src/modulos/financeiro/casos-de-uso/grupo/cadastrarGrupo';
import atualizarGrupo from 'src/modulos/financeiro/casos-de-uso/grupo/atualizarGrupo';
import excluirGrupo from '../../casos-de-uso/grupo/excluirGrupo';

const ListagemContas: React.FC = () => {
  const [carregando, setCarregando] = React.useState(false);
  const [atualizando, setAtualizando] = React.useState(false);
  const [mostrarModalFormulario, setMostrarModalFormulario] = React.useState(false);
  const [mostrarModalFormularioGrupo, setMostrarModalFormularioGrupo] = React.useState(false);
  const [mostrarModalExcluir, setMostrarModalExcluir] = React.useState(false);
  const [tipoModalExcluir, setTipoModalExcluir] = React.useState<'conta' | 'grupo' | '' >('');
  const [grupos, setGrupos] = React.useState<Grupo[]>([]);
  const [contas, setContas] = React.useState<Conta[]>([]);
  const [grupoExcluir, setGrupoExcluir] = React.useState<Grupo>();
  const [contaExcluir, setContaExcluir] = React.useState<Conta>();
  const [grupoExibirDetalhe, setGrupoExibirDetalhe] = React.useState<Grupo>();

  const [guiaAtiva, setGuiaAtiva] = React.useState('DEDUTIVEL');

  const {
    reset: resetGrupo,
    setValue: setValueGrupo,
    register: registerGrupo,
    handleSubmit: handleSubmitGrupo,
  } = useForm<Grupo>();

  const {
    reset,
    setValue,
    register,
    handleSubmit,
  } = useForm<Conta>();

  useEffect(() => {
    carregarInformacoes();
  }, []);

  const carregarInformacoes = async () => {
    setCarregando(true);
    await carregarGrupos();
    setCarregando(false);
  }

  const carregarGrupos = async () => {
    await listarGrupos().then(({ resultado }) => {
      if (resultado) {
        setGrupos(resultado.data);
      }
    });
  };

  const mostrarModalFormularioGrupoEditando = (data: Grupo) => {
    setValueGrupo('id', data.id);
    setValueGrupo('nome', data.nome);
    setAtualizando(true);
    setMostrarModalFormularioGrupo(true);
  };

  const ocultarModalFormularioGrupo = () => {
    setMostrarModalFormularioGrupo(false);
    setAtualizando(false);
    resetGrupo();
  };

  const confirmaSalvarGrupo: SubmitHandler<Grupo> = async (data, e) => {
    e?.preventDefault();

    const { resultado, erro } = atualizando ? await atualizarGrupo(data) : await cadastrarGrupo(data);

    if (resultado) {
      toast.success('Grupo salvo com sucesso!');
      ocultarModalFormularioGrupo();
      carregarInformacoes();
      recarregarGuiaAtivaGrupo();
    } else {
      toast.error(erro?.mensagem);
    }
  };

  const ocultarModalFormularioConta = () => {
    setMostrarModalFormulario(false);
    setAtualizando(false);
    reset();
  };

  const mostrarModalFormularioEditando = (data: Conta) => {
    setValue('id', data.id);
    setValue('tipo', data.tipo);
    setValue('nome', data.nome);
    setValue('grupo_id', data.grupo_id);
    setAtualizando(true);
    setMostrarModalFormulario(true);
  };

  const confirmaSalvarConta: SubmitHandler<Conta> = async (data, e) => {
    e?.preventDefault();

    const { resultado, erro } = atualizando ? await atualizarConta(data) : await cadastrarConta(data);

    if (resultado) {
      toast.success('Conta salva com sucesso!');
      ocultarModalFormularioConta();
      carregarInformacoes();
      recarregarGuiaAtivaGrupo();
    } else {
      toast.error(erro?.mensagem);
    }
  };

  const mostrarModalExcluirGrupo = async (data: Grupo) => {
    setGrupoExcluir(data);
    setTipoModalExcluir('grupo');
    setMostrarModalExcluir(true);
  };

  const ocultarModalExcluir = () => {
    setMostrarModalExcluir(false);
    setTipoModalExcluir('');
    setGrupoExcluir(undefined);
    setContaExcluir(undefined);
  };

  const confirmaExcluirGrupo = async () => {
    if (grupoExcluir) {
      const { resultado, erro } = await excluirGrupo(grupoExcluir);

      if (resultado) {
        toast.success('Grupo excluído com sucesso!');
        ocultarModalExcluir();
        carregarInformacoes();
      } else {
        toast.error(erro?.mensagem);
      }  
    }
    setGrupoExcluir(undefined);
    setMostrarModalExcluir(false);
  };

  const mostrarModalExcluirConta = async (data: Conta) => {
    setContaExcluir(data);
    setTipoModalExcluir('conta');
    setMostrarModalExcluir(true);
  };

  const confirmaExcluirConta = async () => {
    if (contaExcluir) {
      const { resultado, erro } = await excluirConta(contaExcluir);

      if (resultado) {
        toast.success('Conta excluída com sucesso!');
        ocultarModalExcluir();
        carregarInformacoes();
        recarregarGuiaAtivaGrupo();
      } else {
        toast.error(erro?.mensagem);
      }  
    }
    setContaExcluir(undefined);
    setMostrarModalExcluir(false);
  };

  const alterarGuiaAtiva = async (guia: string) => {
    if (guiaAtiva !== guia) {
      setGuiaAtiva(guia);
      setCarregando(true);
      await carregarContas(guia);
      setCarregando(false);
    }
  };

  const ListaDeGuias = [
    {
      id: 'DEDUTIVEL',
        titulo: 'Dedutíveis'
    },
    {
      id: 'NAO_DEDUTIVEL',
        titulo: 'Não Dedutíveis'
    }
  ];

  const exibirDetalheGrupo = async (grupo: Grupo) => {
    if (grupo.id == grupoExibirDetalhe?.id) {
      setGrupoExibirDetalhe(undefined);
    } else {
      setGrupoExibirDetalhe(grupo);
      setCarregando(true);
      await carregarContas(guiaAtiva, grupo);
      setCarregando(false);
    }
  }

  const recarregarGuiaAtivaGrupo = async () => {
    if (guiaAtiva && grupoExibirDetalhe) {
      setCarregando(true);
      await carregarContas(guiaAtiva);
      setCarregando(false);  
    }
  }

  const carregarContas = async (guiaTipo: string, grupo?: Grupo) => {
    const grupoId = grupo?.id || grupoExibirDetalhe?.id;
    if (grupoId){
      await listarContas({'tipo': guiaTipo, 'grupo_id': grupoId}).then(({ resultado }) => {
        if (resultado) {
          setContas(resultado.data);
          return;
        }
      });
    }
  };

  return (
    <>
      <Breadcrumb links={[{ url: '/financeiro', texto: 'Financeiro' }, { texto: 'Gerenciar Contas' }]}/>
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <h2 className="mb-3 mt-0">Gerenciar Contas</h2>
            </Col>
            <Col md={6}>
              <Button color='primary' className='float-right' onClick={() => { setMostrarModalFormulario(true); }}><FiPlus></FiPlus> Cadastrar Conta</Button>
              <Button color='soft-primary' className='float-right ml-2 mr-2' onClick={() => { setMostrarModalFormularioGrupo(true); }}><FiPlus></FiPlus> Cadastrar Grupo</Button>
            </Col>
          </Row>
          <CarregamentoConteudo carregando={carregando}></CarregamentoConteudo>
            <Nav tabs>
              {ListaDeGuias.map((guia, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink href="#" className={classnames({ active: guiaAtiva === guia.id })} onClick={() => { alterarGuiaAtiva(guia.id); }}>
                      <span className="d-none d-sm-block">{guia.titulo}</span>
                    </NavLink>
                  </NavItem>
                );
              })}
          </Nav>
          <Container>
            {grupos.map((grupo, index) => {
              return (
                <Row key={index}>
                  <Col sm="12">
                  <NavLink href="#" className='border-bottom'>
                    <Row>
                      <Col sm="11">
                        <h5 onClick={() => { exibirDetalheGrupo(grupo) }}>
                          {grupo.id == grupoExibirDetalhe?.id && <FiChevronUp></FiChevronUp>}
                          {grupo.id != grupoExibirDetalhe?.id && <FiChevronDown></FiChevronDown>}
                          {grupo.nome}
                        </h5>
                      </Col>
                      <Col sm="1">
                        <ButtonGroup className='float-right'>
                          <Button onClick={() => { mostrarModalFormularioGrupoEditando(grupo); }} color={'soft-light'} size={'lg'} className="pt-0 pb-0"><FiEdit className="text-primary" /></Button>
                          <Button onClick={() => { mostrarModalExcluirGrupo(grupo); }} color={'soft-light'} size={'lg'} className="pt-0 pb-0"><FiTrash className="text-danger" /></Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </NavLink>
                  <Collapse isOpen={grupo.id == grupoExibirDetalhe?.id}>
                    <CardBody>
                      <CarregamentoConteudo carregando={carregando}>
                        <SemDadosParaExibir semDadosParaExibir={contas.length <= 0}>
                          <Table className="mb-0" disabled={atualizando ? true : false} bordered striped>
                          <thead>
                            <tr>
                              <th className='text-left col-md-11'>Nome</th>
                              <th className='text-center col-md-1'>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                          {contas.map((conta, index) => {
                            return (
                              <tr key={index}>
                                <td className='text-left col-md-11'>{conta.nome}</td>
                                <td className='text-center col-md-1'>
                                  <ButtonGroup>
                                    <Button onClick={() => { mostrarModalFormularioEditando(conta); }} color={'soft-light'} size={'lg'} className="pt-0 pb-0"><FiEdit className="text-primary" /></Button>
                                    <Button onClick={() => { mostrarModalExcluirConta(conta); }} color={'soft-light'} size={'lg'} className="pt-0 pb-0"><FiTrash className="text-danger" /></Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            );
                          })}
                          </tbody>
                          </Table>
                        </SemDadosParaExibir>
                      </CarregamentoConteudo>
                    </CardBody>
                  </Collapse>
                  </Col>
                </Row>
              );
            })}
          </Container>
        </CardBody>
      </Card>
      <Modal
        className='modal-dialog-centered'
        isOpen={mostrarModalFormularioGrupo}>
        <form onSubmit={handleSubmitGrupo(confirmaSalvarGrupo)}>
          <ModalHeader toggle={ocultarModalFormularioGrupo}>{atualizando ? 'Editar' : 'Criar'} Grupo</ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for='nome'>
                    Nome
                  </Label>
                  <input
                    {...registerGrupo('nome')}
                    id="nome"
                    type="text"
                    className="form-control"
                    placeholder="ex.: Despesas"
                    aria-label="Nome do Grupo"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
              <Button color="soft-info" onClick={ocultarModalFormularioGrupo}>Cancelar</Button>
              <Button color="primary" type="submit">Cadastrar</Button>
          </ModalFooter>
        </form>
      </Modal>
      <Modal
        className='modal-dialog-centered'
        isOpen={mostrarModalExcluir && tipoModalExcluir == 'grupo'}>
        <form onSubmit={handleSubmitGrupo(confirmaExcluirGrupo)}>
          <ModalHeader toggle={ocultarModalExcluir}>Excluir Grupo</ModalHeader>
          <ModalBody>
              <h2>Confirma excluir {grupoExcluir?.nome}?</h2>
          </ModalBody>
          <ModalFooter>
              <Button color="soft-info" onClick={ocultarModalExcluir}>Cancelar</Button>
              <Button color="soft-danger" type="submit">Excluir</Button>
          </ModalFooter>
        </form>
      </Modal>
      <Modal
        className='modal-dialog-centered'
        isOpen={mostrarModalFormulario}>
        <form onSubmit={handleSubmit(confirmaSalvarConta)}>
          <ModalHeader toggle={ocultarModalFormularioConta}>{atualizando ? 'Editar' : 'Cadastrar'} Conta</ModalHeader>
          <ModalBody>
          <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label>
                    Tipo
                  </Label>
                  <div>
                    <div className="custom-control custom-radio">
                      <input type="radio" id="tipoDedutivel" value="DEDUTIVEL" {...register('tipo')} className="custom-control-input"/>
                      <Label className="custom-control-label" for="tipoDedutivel">Dedutível</Label>
                    </div>
                    <div className="custom-radio custom-control">
                      <input type="radio" id="tipoNaoDedutivel" value="NAO_DEDUTIVEL" {...register('tipo')} className="custom-control-input"/>
                      <Label className="custom-control-label" for="tipoNaoDedutivel">Não Dedutível</Label>
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for='nome'>
                    Nome
                  </Label>
                  <input
                    {...register('nome')}
                    id="nome"
                    type="text"
                    className="form-control"
                    placeholder="ex.: Energia do Cartório"
                    aria-label="Nome da Conta"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
              <FormGroup>
                  <Label for='grupo_id'>
                    Grupo
                  </Label>
                  <select
                    {...register('grupo_id')}
                    id="grupo_id"
                    className="form-control"
                    aria-label="Grupo"
                  >
                    <option value="">Selecione ou pesquise pelo nome</option>
                    {grupos.map((grupo, index) => <option key={index} value={grupo.id}>{grupo.nome}</option>)}
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
              <Button color="soft-info" onClick={ocultarModalFormularioConta}>Cancelar</Button>
              <Button color="primary" type="submit">Cadastrar</Button>
          </ModalFooter>
        </form>
      </Modal>
      <Modal
        className='modal-dialog-centered'
        isOpen={mostrarModalExcluir && tipoModalExcluir == 'conta'}>
        <form onSubmit={handleSubmit(confirmaExcluirConta)}>
          <ModalHeader toggle={ocultarModalExcluir}>Excluir Conta</ModalHeader>
          <ModalBody>
              <h2>Confirma excluir {contaExcluir?.nome}?</h2>
          </ModalBody>
          <ModalFooter>
              <Button color="soft-info" onClick={ocultarModalExcluir}>Cancelar</Button>
              <Button color="soft-danger" type="submit">Excluir</Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}

export default ListagemContas;