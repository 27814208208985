import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { Button, Collapse } from 'reactstrap';
import adicionarArquivoProcesso from 'src/modulos/processos/casos-de-uso/processo/adicionarArquivoProcesso';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';

type Props = {
  mostrar: boolean;
};

const AnexarDoComputador: React.FC<Props> = ({ mostrar }) => {
  const { processo, onArquivoAnexado } = useContext(ProcessoContext);
  const [processando, setProcessando] = React.useState(false);
  const abrirSeletorDeArquivo = (id: string) => {
    const input = document.getElementById(id) as HTMLInputElement;
    input.click();
  };

  const adicionarAnexoProcesso = async (
    evento: React.ChangeEvent<HTMLInputElement>,
    tipo: string
  ) => {
    setProcessando(true);

    if (evento.target.files && evento.target.files?.length > 0) {
      const arquivos = Array.from(evento.target.files);
      const promises = arquivos.map((item) => {
        const formData = new FormData();
        formData.append('tipo', tipo);
        formData.append('anexo', item);
        return adicionarArquivoProcesso(processo, formData).then(
          ({ resultado, error }) => {
            if (resultado) {
              toast.success(
                `Arquivo ${resultado.anexo.titulo} anexado ao processo.`
              );
              onArquivoAnexado(resultado.anexo, resultado.andamento);
              return;
            }
            if (error) {
              toast.error(error.mensagem);
            }
          }
        );
      });

      await Promise.all(promises);
    }
    setProcessando(false);
  };

  return (
    <Collapse isOpen={mostrar}>
      <input
        type="file"
        id="inputConteudoAto"
        className="d-none"
        multiple
        onChange={(evento: React.ChangeEvent<HTMLInputElement>) =>
          adicionarAnexoProcesso(evento, 'CONTEUDO_DO_ATO')
        }
      />
      <input
        type="file"
        id="inputDocumento"
        className="d-none"
        onChange={(evento: React.ChangeEvent<HTMLInputElement>) =>
          adicionarAnexoProcesso(evento, 'DOCUMENTOS_PROCESSO')
        }
      />
      <Button
        color="link"
        className="d-block"
        onClick={() => abrirSeletorDeArquivo('inputConteudoAto')}
      >
        Conteúdo do ato
      </Button>
      <Button
        color="link"
        className="d-block"
        onClick={() => abrirSeletorDeArquivo('inputDocumento')}
      >
        Documento do processo
      </Button>
    </Collapse>
  );
};

export default AnexarDoComputador;
