import React from 'react';

import { Chart } from 'react-google-charts';
import { Badge } from 'reactstrap';
import { BsChevronDown } from 'react-icons/bs';

export const data = [
  ['Processos', 'Entregues', 'Antecipados', 'No dia', 'Atrasados'],
  ['Jan', 65, 33, 0, 30],
  ['Fev', 70, 20, 0, 45],
  ['Mar', 42, 14, 0, 33],
  ['Abr', 94, 45, 0, 28],
  ['Mai', 100, 23, 0, 45],
  ['Jun', 12, 54, 0, 3],
  ['Jul', 65, 34, 0, 23],
];

const Individual: React.FC = () => {
  return (
    <div className="my-8">
      <div className="d-flex align-items-center">
        Processos de
        <Badge color="soft-primary" className="ml-2 p-2 mr-2">
          Janeiro / 2020
          <BsChevronDown className="ml-2" />
        </Badge>
        à
        <Badge color="soft-primary" className="ml-2 p-2">
          Julho / 2020
          <BsChevronDown className="ml-2" />
        </Badge>
      </div>

      <div className="mt-5">
        <Chart chartType="Bar" width="100%" height="400px" data={data} />
      </div>
    </div>
  );
};

export default Individual;
