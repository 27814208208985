import React from 'react';
import FeedbackErro from '../FeedbackErro';

type Props = {
  erro?: string;
};

const FormGroup: React.FC<Props> = ({ children, erro }) => {
  return (
    <div className="form-group">
      {children}
      <FeedbackErro>{erro}</FeedbackErro>
    </div>
  );
};

export default FormGroup;
