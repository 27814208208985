import { UsuarioCaixaType } from 'src/modulos/financeiro/entidades/UsuarioCaixa';

export default function mockUsuariosCaixa() {
  let mockListaPessoas: Array<UsuarioCaixaType> = [
    {
      nome: 'Liz Peixoto',
      valor: 15.75,
      detalhesExibidos: true,
      valores: [
        { nome: 'Dinheiro', valor: 2.25 },
        { nome: 'Transf. / Depósito', valor: 2.25 },
        { nome: 'Cheque', valor: 2.25 },
        { nome: 'Nota Promissória', valor: 2.25 },
        { nome: 'Cartão de Crédito', valor: 2.25 },
        { nome: 'Cartão de Débito', valor: 2.25 },
        { nome: 'Creditado pela Central de Serviços Eletrônicos', valor: 2.25 },
      ],
    },
    {
      nome: 'Emilly Porto',
      valor: 15.75,
      detalhesExibidos: true,
      valores: [
        { nome: 'Dinheiro', valor: 2.25 },
        { nome: 'Transf. / Depósito', valor: 2.25 },
        { nome: 'Cheque', valor: 2.25 },
        { nome: 'Nota Promissória', valor: 2.25 },
        { nome: 'Cartão de Crédito', valor: 2.25 },
        { nome: 'Cartão de Débito', valor: 2.25 },
        { nome: 'Creditado pela Central de Serviços Eletrônicos', valor: 2.25 },
      ],
    },
    {
      nome: 'Rayssa Gonçalves',
      valor: 0,
      detalhesExibidos: true,
      valores: [
        { nome: 'Dinheiro', valor: 2.25 },
        { nome: 'Transf. / Depósito', valor: 2.25 },
        { nome: 'Cheque', valor: 2.25 },
        { nome: 'Nota Promissória', valor: 2.25 },
        { nome: 'Cartão de Crédito', valor: 2.25 },
        { nome: 'Cartão de Débito', valor: 2.25 },
        { nome: 'Creditado pela Central de Serviços Eletrônicos', valor: 2.25 },
      ],
    },
    {
      nome: 'Sarah Gonçalves',
      valor: 0,
      detalhesExibidos: true,
      valores: [
        { nome: 'Dinheiro', valor: 2.25 },
        { nome: 'Transf. / Depósito', valor: 2.25 },
        { nome: 'Cheque', valor: 2.25 },
        { nome: 'Nota Promissória', valor: 2.25 },
        { nome: 'Cartão de Crédito', valor: 2.25 },
        { nome: 'Cartão de Débito', valor: 2.25 },
        { nome: 'Creditado pela Central de Serviços Eletrônicos', valor: 2.25 },
      ],
    },
    {
      nome: 'Gabrielly Ribeiro',
      valor: 15.75,
      detalhesExibidos: true,
      valores: [
        { nome: 'Dinheiro', valor: 2.25 },
        { nome: 'Transf. / Depósito', valor: 2.25 },
        { nome: 'Cheque', valor: 2.25 },
        { nome: 'Nota Promissória', valor: 2.25 },
        { nome: 'Cartão de Crédito', valor: 2.25 },
        { nome: 'Cartão de Débito', valor: 2.25 },
        { nome: 'Creditado pela Central de Serviços Eletrônicos', valor: 2.25 },
      ],
    },
    {
      nome: 'Raquel da Conceição',
      valor: 0,
      detalhesExibidos: true,
      valores: [
        { nome: 'Dinheiro', valor: 2.25 },
        { nome: 'Transf. / Depósito', valor: 2.25 },
        { nome: 'Cheque', valor: 2.25 },
        { nome: 'Nota Promissória', valor: 2.25 },
        { nome: 'Cartão de Crédito', valor: 2.25 },
        { nome: 'Cartão de Débito', valor: 2.25 },
        { nome: 'Creditado pela Central de Serviços Eletrônicos', valor: 2.25 },
      ],
    },
    {
      nome: 'Luís Viana',
      valor: 15.75,
      detalhesExibidos: true,
      valores: [
        { nome: 'Dinheiro', valor: 2.25 },
        { nome: 'Transf. / Depósito', valor: 2.25 },
        { nome: 'Cheque', valor: 2.25 },
        { nome: 'Nota Promissória', valor: 2.25 },
        { nome: 'Cartão de Crédito', valor: 2.25 },
        { nome: 'Cartão de Débito', valor: 2.25 },
        { nome: 'Creditado pela Central de Serviços Eletrônicos', valor: 2.25 },
      ],
    },
    {
      nome: 'Letícia Drummond',
      valor: 15.75,
      detalhesExibidos: true,
      valores: [
        { nome: 'Dinheiro', valor: 2.25 },
        { nome: 'Transf. / Depósito', valor: 2.25 },
        { nome: 'Cheque', valor: 2.25 },
        { nome: 'Nota Promissória', valor: 2.25 },
        { nome: 'Cartão de Crédito', valor: 2.25 },
        { nome: 'Cartão de Débito', valor: 2.25 },
        { nome: 'Creditado pela Central de Serviços Eletrônicos', valor: 2.25 },
      ],
    },
    {
      nome: 'Roberto da Mata',
      valor: 0,
      detalhesExibidos: true,
      valores: [
        { nome: 'Dinheiro', valor: 2.25 },
        { nome: 'Transf. / Depósito', valor: 2.25 },
        { nome: 'Cheque', valor: 2.25 },
        { nome: 'Nota Promissória', valor: 2.25 },
        { nome: 'Cartão de Crédito', valor: 2.25 },
        { nome: 'Cartão de Débito', valor: 2.25 },
        { nome: 'Creditado pela Central de Serviços Eletrônicos', valor: 2.25 },
      ],
    },
  ];

  return mockListaPessoas;
}
