import { IResultadoRequisicao } from '../../entidades/IResultadoRequisicao';

const listarEmolumentos = async (
  especialidade: string,
  data: string
): Promise<IResultadoRequisicao<any>> => {
  try {
    const { default: resultado }: { default: any[] } = await import(
      `./${especialidade}`
    );
    const emolumentos = resultado.map((item) => {
      const formulario = !item.combo
        ? `Emolumento${item.codigo}`
        : `Combo${item.codigo}`;
      return {
        ...item,
        formulario,
        titulo: `${item.codigo} - ${item.nome_ato}`,
      };
    });
    return { resultado: emolumentos };
  } catch (error) {
    return { error };
  }
};

export default listarEmolumentos;
