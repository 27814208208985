import React from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';

type props = {
  register: any
}

const ObservacoesDoOrcamento: React.FC<props> = ({ register }) => {
  return (
    <div>
      <Row form>
        <Col md={12}>
          <FormGroup>
            <Label for='orcamento_observacao'>
              Observações do Orçamento
            </Label>
            <textarea
              {...register('orcamento_observacao')}
              id="orcamento_observacao"
              className="form-control"
              aria-label="Observações do Orçamento"
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default ObservacoesDoOrcamento;