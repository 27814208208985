import { useBoolean, useRequest } from 'ahooks';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { pegarProtocolo } from 'src/modulos/aplicacao/casos-de-uso/protocolo';
import { Protocolo } from 'src/modulos/aplicacao/entidades/Protocolo';
import pegarProcesso from 'src/modulos/processos/casos-de-uso/processo/pegarProcesso';
import relacionarArquivoAcervoProcesso from 'src/modulos/processos/casos-de-uso/processo/relacionarArquivoAcervoProcesso';
import {
  gerarUrlProcesso,
  Processo,
} from 'src/modulos/processos/entidades/Processo';
import gerarAto from 'src/modulos/selos/casos-de-uso/ato/gerarAto';

interface GerarSeloContextProps {
  protocolo: Protocolo;
  processo: Processo;
  atoSelecionado: any;
  salvarAto(dados: any): void;
  setAtoSelecionado(ato: any): any;
  salvandoAto: boolean;
}

const GerarSeloContext = React.createContext<GerarSeloContextProps>(
  {} as GerarSeloContextProps
);

export const GerarSeloProvider: React.FC = ({ children }) => {
  const { idProtocolo } = useParams();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  const { data: protocolo, loading: carregandoProtocolo } = useRequest(() =>
    pegarProtocolo(Number(idProtocolo))
  );
  const { data: processo, loading: carregandoProcesso } = useRequest(() =>
    pegarProcesso(Number(queryParams.get('processo')))
  );
  const [atoSelecionado, setAtoSelecionado] = useState<any>(null);
  const [salvandoAto, { toggle: alternarSalvandoAto }] = useBoolean(false);

  const salvarAto = async (dados: any) => {
    alternarSalvandoAto();
    const { resultado, error } = await gerarAto(dados);
    alternarSalvandoAto();

    if (resultado) {
      toast.success(
        `Adicionando documento ao processo ${processo?.resultado?.numero}, aguarde`
      );
      alternarSalvandoAto();

      const { resultado: anexoProcesso, error: erroAnexoProcesso } =
        await relacionarArquivoAcervoProcesso(
          processo?.resultado?.id!,
          resultado.acervo.id
        );
      alternarSalvandoAto();

      if (anexoProcesso) {
        toast.success(
          `Documento anexado ao processo ${processo?.resultado?.numero}.`
        );
        navigate(gerarUrlProcesso(processo?.resultado!));
      } else {
        toast.error(erroAnexoProcesso.mensagem);
      }
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  if (carregandoProtocolo) {
    return <>Carregando protocolo...</>;
  }
  if (carregandoProcesso) {
    return <>Carregando processo...</>;
  }

  if (!protocolo) {
    return <>Protocolo não encontrado.</>;
  }

  return (
    <GerarSeloContext.Provider
      value={{
        protocolo,
        processo: processo?.resultado!,
        atoSelecionado,
        setAtoSelecionado,
        salvarAto,
        salvandoAto,
      }}
    >
      {children}
    </GerarSeloContext.Provider>
  );
};

export const useGerarSeloContext = (): GerarSeloContextProps => {
  return useContext(GerarSeloContext);
};

export default GerarSeloContext;
