import { ErroRegraNegocio } from "src/entidades/ErroRegraNegocio";
import api from "../../api";
import { Servico } from "../../entidades/Servico";

const excluirServico = async (
  servico: Partial<Servico>
): Promise<{
  resultado?: Servico;
  erro?: ErroRegraNegocio;
}> => {
  try {
    const resultado: Servico = await api().delete("/servico/excluir", { params: {id : servico.id}});
    return { resultado };
  } catch (error) {
    const erro = {
      mensagem: "Falha ao excluir serviço",
    };
    return { erro };
  }
};

export default excluirServico;
