import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import { set } from 'tiny-cookie';
import api from '../../api';
import { Cartorio } from '../../entidades/Cartorio';

type Autorizacao = {
  token: string;
};

const trocarCartorio = async (
  cartorio: Cartorio
): Promise<IResultadoRequisicao<boolean>> => {
  try {
    const resultado: Autorizacao = await api().get(
      `autenticacao/selecionar/${cartorio.id}`
    );
    set('vc_token', resultado.token);
    const usuario = localStorage.getItem('usuario');
    if (usuario) {
      const resultado = JSON.parse(usuario);
      resultado.cartorio = cartorio;
      localStorage.setItem('usuario', JSON.stringify(resultado));
    } else {
      throw new Error('Usuário não autenticado.');
    }
    return { resultado: true };
  } catch (error) {
    return { error };
  }
};

export default trocarCartorio;
