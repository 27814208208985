import { Preposto } from 'src/modulos/processos/entidades/Preposto';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';

const listarPrepostos = async (): Promise<IResultadoRequisicao<Preposto[]>> => {
  try {
    const resultado: { data: Preposto[] } = await api().get('gerencia/usuario');
    return { resultado: resultado.data };
  } catch (error) {
    return { error };
  }
};

export default listarPrepostos;
