import React, { useContext, useRef } from 'react';
import { BsTextLeft } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/i18n/pt-br';
import editarDescricaoProcesso from 'src/modulos/processos/casos-de-uso/processo/editarDescricaoProcesso';
import css from '../style.module.css';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { Button } from 'reactstrap';

const Descricao: React.FC = () => {
  const { processo, arquivado } = useContext(ProcessoContext);
  const [editando, setEditando] = React.useState(false);
  const [descricao, setDescricao] = React.useState('');
  const [processando, setProcessando] = React.useState(false);
  const editorRef = useRef<Editor>(null);

  const salvar = async () => {
    setProcessando(true);
    const novaDescricao = editorRef.current?.getInstance().getMarkdown() || '';
    const { resultado } = await editarDescricaoProcesso(
      processo,
      novaDescricao
    );
    setProcessando(false);
    if (resultado) {
      setDescricao(novaDescricao);
      setEditando(false);
    }
  };

  React.useEffect(() => {
    if (processo && processo.descricao) {
      const html = processo.descricao;
      setDescricao(html);
    }
  }, [processo]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className={css.titulo}>
          <BsTextLeft className={css['icone-titulo']} />
          <h5>Descrição</h5>
        </h3>
        {!editando && (
          <Button
            color="soft-primary"
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setEditando(true);
            }}
            disabled={arquivado}
          >
            <FiEdit className="mr-2" />
            <span>Editar</span>
          </Button>
        )}
      </div>
      {!descricao && !editando && (
        <div className={css['espaco-lateral']}>
          <span className="d-block">
            <i>Clique em "Editar" para atualizar a descrição do processo</i>
          </span>
        </div>
      )}
      {!editando && descricao && (
        <div className={css['espaco-lateral']}>
          {<MarkdownPreview source={descricao} />}
        </div>
      )}
      {editando && (
        <div className={css['espaco-lateral']}>
          <div className="px-3 border">
            <Editor
              previewStyle="tab"
              height="200px"
              initialEditType="markdown"
              placeholder="Escreva a descrição aqui..."
              ref={editorRef}
              language="pt-BR"
              initialValue={descricao}
            />
          </div>
          <div className="d-flex justify-content-end mt-3">
            <div>
              <button
                className="btn btn-soft-info mr-3"
                onClick={() => {
                  setEditando(false);
                }}
              >
                Cancelar
              </button>
              <button
                className="btn btn-primary"
                onClick={salvar}
                disabled={processando}
              >
                {processando ? 'Processando...' : 'Salvar alterações'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Descricao;
