import { Usuario } from 'src/modulos/aplicacao/entidades/Usuario';
import formatarDinheiro from 'src/util/formatarDinheiro';
import { OrcamentoCompleto } from './OrcamentoCompleto';
import { PessoaOrcamento } from './PessoaOrcamento';
import { Servico } from './Servico';

export interface Orcamento {
  id: number;
  cartorio_id: number;
  usuario_id: number;
  numero: number;
  ano: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: null;
  pessoa_id: number;
  telefone: string;
  descricao: string;
  observacao: string;
  tipo: string;
  observacao_rodape: string;
  recolhido: string;
  servicos: Servico[];
  usuario: Usuario;
  pessoa: PessoaOrcamento;
  classe: string;
}

export const formatarNumeroOrcamento = (
  orcamento: Orcamento | OrcamentoCompleto
): string => {
  return `${String(orcamento.numero).padStart(6, '0')}/${orcamento.ano}`;
};

export const pegarTotalServicos = (
  orcamento: Orcamento,
  formatar = false
): number | string => {
  const total = orcamento.servicos.reduce(
    (prev, atual) => prev + Number(atual.valor),
    0
  );
  if (formatar) {
    return formatarDinheiro(total);
  }
  return total;
};
