import { get } from 'tiny-cookie';
const URL_ARQUIVOS_ESTATICOS = process.env.REACT_APP_ARQUIVOS_ESTATICOS;

export default function pegarUrlImagem(url: string): string {
  if (url === 'default') {
    return '/icones/default-avatar.jpg';
  }
  if (url.indexOf('amazonaws') >= 0) {
    return url;
  }
  const urlImagem = `${URL_ARQUIVOS_ESTATICOS}${url}?token=${get('vc_token')}`;
  return urlImagem;
}
