import api from 'src/modulos/processos/api';
import { Modelo } from 'src/modulos/processos/entidades/Modelo';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';

export default async function atualizarModelo(
  id: number,
  dados: Modelo
): Promise<IResultadoRequisicao<Modelo>> {
  try {
    const resultado: Modelo = await api().put(`/modelos/${id}`, {
      modelo: dados,
    });
    return { resultado };
  } catch (error) {
    return { error };
  }
}
