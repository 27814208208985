import { Preposto } from '../../entidades/Preposto';

export default function pegarFotoPreposto(preposto: Preposto) {
  if (preposto.pessoa) {
    return preposto.pessoa?.fisica?.foto || 'default';
  }
  if (preposto.foto) {
    return preposto.foto;
  }
  return 'default';
}
