import React, { useContext } from 'react';
import { FiArchive } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import atualizarEtapaProcesso from 'src/modulos/processos/casos-de-uso/atualizarEtapaProcesso';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';

const Arquivar: React.FC = () => {
  const { processo, arquivado, onAtualizacaoEtapa } =
    useContext(ProcessoContext);

  const solicitarArquivamento = async () => {
    const { resultado, error } = await atualizarEtapaProcesso(
      processo,
      'FEITO: A SER ARQUIVADO'
    );
    if (resultado) {
      onAtualizacaoEtapa('FEITO: A SER ARQUIVADO', resultado);
      return;
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  const arquivarProcesso = async () => {
    const { resultado, error } = await atualizarEtapaProcesso(
      processo,
      'FINALIZADO'
    );
    if (resultado) {
      onAtualizacaoEtapa('FINALIZADO', resultado);
      return;
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  const desarquivarProcesso = async () => {
    const { resultado, error } = await atualizarEtapaProcesso(
      processo,
      'FEITO: A SER ENTREGUE'
    );
    if (resultado) {
      onAtualizacaoEtapa('FEITO: A SER ENTREGUE', resultado);
      return;
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  return (
    <>
      {!arquivado && processo.etapa !== 'FEITO: A SER ARQUIVADO' && (
        <div className="relative">
          <Button
            color="soft-secondary"
            block
            onClick={solicitarArquivamento}
            className="d-flex align-items-center"
          >
            <FiArchive className="mr-2" />
            <span>Solicitar Arquivamento</span>
          </Button>
        </div>
      )}
      {processo.etapa === 'FEITO: A SER ARQUIVADO' && (
        <div className="relative mt-2">
          <Button
            color="soft-danger"
            block
            onClick={arquivarProcesso}
            className="d-flex align-items-center"
          >
            <FiArchive className="mr-2" />
            <span>Arquivar processo</span>
          </Button>
        </div>
      )}
      {arquivado && (
        <div className="relative mt-2">
          <Button
            color="soft-danger"
            block
            onClick={desarquivarProcesso}
            className="d-flex align-items-center"
          >
            <FiArchive className="mr-2" />
            <span>Desarquivar processo</span>
          </Button>
        </div>
      )}
    </>
  );
};

export default Arquivar;
