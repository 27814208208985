import { Processo } from '../../entidades/Processo';

interface Etapa {
  [key: string]: string;
}

const formatarEtapaProcesso = (processo: Processo | string) => {
  const etapas: Etapa = {
    'NOVO/A SER DISTRIBUÍDO': 'NOVO/A SER DISTRIBUÍDO',
    'EM ANÁLISE': 'EM ANÁLISE',
    'PROVIDÊNCIAS DO CLIENTE': 'PROVIDÊNCIAS DO CLIENTE',
    'EM EXECUÇÃO': 'EM EXECUÇÃO',
    'FEITO: A SER ENTREGUE': 'FEITO: À SER ENTREGUE',
    'FEITO: A SER ARQUIVADO': 'FEITO: À SER ARQUIVADO',
    FINALIZADO: 'FINALIZADO',
  };

  if (typeof processo === 'string') {
    return etapas[processo];
  }
  if (processo.etapa && etapas[processo.etapa]) {
    return etapas[processo.etapa];
  }

  return 'Não identificado';
};

export default formatarEtapaProcesso;
