import { ErroRegraNegocio } from "src/entidades/ErroRegraNegocio";
import api from "../../api";
import { Grupo } from "../../entidades/Grupo";

const excluirGrupo = async (
  grupo: Partial<Grupo>
): Promise<{
  resultado?: Grupo;
  erro?: ErroRegraNegocio;
}> => {
  try {
    const resultado: Grupo = await api().delete("/grupo/excluir", { params: {id : grupo.id}});
    return { resultado };
  } catch (error) {
    const erro = {
      mensagem: "Falha ao excluir grupo",
    };
    return { erro };
  }
};

export default excluirGrupo;
