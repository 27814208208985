import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import moment from 'moment';

import { Pessoa } from 'src/modulos/pessoas/entidades/Pessoa';

type Props = {
  pessoa: Pessoa;
};

const PessoaFisica: React.FC<Props> = ({ pessoa }) => {
  // const { juridica } = pessoa;
  // if (juridica) {
  //   return (
  //     <Row className="mt-5">
  //       <Col xs={6}>
  //         <Table className="table table-bordered">
  //           <tbody>
  //             <tr>
  //               <td className="bg-light font-weight-bold">ID</td>
  //               <td>{pessoa.id}</td>
  //             </tr>
  //             <tr>
  //               <td className="bg-light font-weight-bold">Nome</td>
  //               <td>{juridica.razaoSocial}</td>
  //             </tr>
  //             <tr>
  //               <td className="bg-light font-weight-bold">CNPJ</td>
  //               <td>{juridica.cnpj}</td>
  //             </tr>
  //             <tr>
  //               <td className="bg-light font-weight-bold">Natureza Juridica</td>
  //               <td>{juridica.naturezaJuridica}</td>
  //             </tr>
  //             <tr>
  //               <td className="bg-light font-weight-bold">
  //                 Origem Capital Social
  //               </td>
  //               <td>{juridica.origemCapitalSocial}</td>
  //             </tr>
  //             <tr>
  //               <td className="bg-light font-weight-bold">Data Constituição</td>
  //               <td>
  //                 {moment(juridica.dataConstituicao).format('DD/MM/YYYY')}
  //               </td>
  //             </tr>
  //             <tr>
  //               <td className="bg-light font-weight-bold">
  //                 Data Registro Alteração
  //               </td>
  //               <td>
  //                 {moment(juridica.dataRegistroAlteracao).format('DD/MM/YYYY')}
  //               </td>
  //             </tr>
  //           </tbody>
  //         </Table>
  //       </Col>
  //       <Col xs={6}>
  //         <Table className="table table-bordered">
  //           <tbody>
  //             <tr>
  //               <td className="bg-light font-weight-bold">Cidade</td>
  //               <td className="text-warning">
  //                 {pessoa.cidadeNome} falta modelar
  //               </td>
  //             </tr>
  //             <tr>
  //               <td className="bg-light font-weight-bold">Endereço</td>
  //               <td>{pessoa.endereco}</td>
  //             </tr>
  //             <tr>
  //               <td className="bg-light font-weight-bold">Bairro</td>
  //               <td>{pessoa.bairro}</td>
  //             </tr>
  //             <tr>
  //               <td className="bg-light font-weight-bold">CEP</td>
  //               <td>{pessoa.cep}</td>
  //             </tr>
  //             <tr>
  //               <td className="bg-light font-weight-bold">E-mail</td>
  //               <td>{pessoa.email}</td>
  //             </tr>
  //             <tr>
  //               <td className="bg-light font-weight-bold">Telefone fixo</td>
  //               <td>{pessoa.telefoneFixo}</td>
  //             </tr>
  //             <tr>
  //               <td className="bg-light font-weight-bold">Whatsapp</td>
  //               <td>{pessoa.whatsapp}</td>
  //             </tr>
  //           </tbody>
  //         </Table>
  //       </Col>
  //     </Row>
  //   );
  // }
  return <></>;
};

export default PessoaFisica;
