import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import Pais, { IPaisApi } from '../../entidades/Pais';

export default async function buscarPais(
  termo: string
): Promise<IResultadoRequisicao<Pais[]>> {
  try {
    const resultado: IPaisApi[] = await api().get('localidade/pais', {
      params: {
        busca: termo,
      },
    });
    return { resultado: resultado.map((item) => new Pais(item)) };
  } catch (error) {
    return { error };
  }
}
