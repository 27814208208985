import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FiPlus, FiEdit, FiTrash } from 'react-icons/fi';
import { Card, CardBody, Table, ButtonGroup, Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label } from 'reactstrap';
import Breadcrumb from 'src/components/Breadcrumb';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';

import { Taxa } from "src/modulos/financeiro/entidades/Taxa";
import listarTaxas from 'src/modulos/financeiro/casos-de-uso/taxa/listarTaxas';
import cadastrarTaxa from 'src/modulos/financeiro/casos-de-uso/taxa/cadastrarTaxa';
import atualizarTaxa from 'src/modulos/financeiro/casos-de-uso/taxa/atualizarTaxa';
import excluirTaxa from '../../casos-de-uso/taxa/excluirTaxa';

const ListagemTaxas: React.FC = () => {
  const [carregando, setCarregando] = React.useState(false);
  const [atualizando, setAtualizando] = React.useState(false);
  const [mostrarModalFormulario, setMostrarModalFormulario] = React.useState(false);
  const [mostrarModalExcluir, setMostrarModalExcluir] = React.useState(false);
  const [taxas, setTaxas] = React.useState<Taxa[]>([]);
  const [taxaExcluir, setTaxaExcluir] = React.useState<Taxa>();

  const {
    reset,
    setValue,
    register,
    handleSubmit,
  } = useForm<Taxa>();

  useEffect(() => {
    carregarInformacoes();
  }, []);

  const carregarInformacoes = async () => {
    setCarregando(true);
    await carregarTaxas();
    setCarregando(false);
  }

  const carregarTaxas = async () => {
    await listarTaxas().then(({ resultado }) => {
      if (resultado) {
        setTaxas(resultado.data);
      }
    });
  };

  const ocultarModalFormulario = () => {
    setMostrarModalFormulario(false);
    setAtualizando(false);
    reset();
  };

  const mostrarModalFormularioEditando = (data: Taxa) => {
    setValue('id', data.id);
    setValue('nome', data.nome);
    setValue('repasse', data.repasse);
    setAtualizando(true);
    setMostrarModalFormulario(true);
  };

  const confirmaSalvarTaxa: SubmitHandler<Taxa> = async (data, e) => {
    e?.preventDefault();

    const { resultado, erro } = atualizando ? await atualizarTaxa(data) : await cadastrarTaxa(data);

    if (resultado) {
      toast.success('Taxa salva com sucesso!');
      ocultarModalFormulario();
      carregarInformacoes();
    } else {
      toast.error(erro?.mensagem);
    }
  };

  const ocultarModalExcluir = () => {
    setMostrarModalExcluir(false);
    setTaxaExcluir(undefined);
  };

  const mostrarModalExcluirTaxa = async (data: Taxa) => {
    setTaxaExcluir(data);
    setMostrarModalExcluir(true);
  };

  const confirmaExcluirTaxa = async () => {
    if (taxaExcluir) {
      const { resultado, erro } = await excluirTaxa(taxaExcluir);

      if (resultado) {
        toast.success('Taxa excluída com sucesso!');
        ocultarModalExcluir();
        carregarInformacoes();
      } else {
        toast.error(erro?.mensagem);
      }  
    }
    setTaxaExcluir(undefined);
    setMostrarModalExcluir(false);
  };

  return (
    <>
      <Breadcrumb links={[{ url: '/financeiro', texto: 'Financeiro' }, { texto: 'Gerenciar Adicionais' }]}/>
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <h2 className="mb-3 mt-0">Gerenciar Adicionais</h2>
            </Col>
            <Col md={6}>
              <Button color='primary' className='float-right' onClick={() => { setMostrarModalFormulario(true); }}><FiPlus></FiPlus> Cadastrar Adicional</Button>
            </Col>
          </Row>
          <CarregamentoConteudo carregando={carregando}></CarregamentoConteudo>
            <Table className="mb-0" disabled={atualizando ? true : false} bordered striped>
              <thead>
                <tr>
                  <th className='text-left col-md-11'>Nome</th>
                  <th className='text-center col-md-1'>Ações</th>
                </tr>
              </thead>
              <tbody>
              {taxas.map((taxa, index) => {
                return (
                  <tr key={index}>
                    <td className='text-left col-md-11'>{taxa.nome}</td>
                    <td className='text-center col-md-1'>
                      <ButtonGroup>
                        <Button onClick={() => { mostrarModalFormularioEditando(taxa); }} color={'soft-light'} size={'lg'} className="pt-0 pb-0"><FiEdit className="text-primary" /></Button>
                        <Button onClick={() => { mostrarModalExcluirTaxa(taxa); }} color={'soft-light'} size={'lg'} className="pt-0 pb-0"><FiTrash className="text-danger" /></Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </Table>
        </CardBody>
      </Card>
      <Modal
        className='modal-dialog-centered'
        isOpen={mostrarModalFormulario}>
        <form onSubmit={handleSubmit(confirmaSalvarTaxa)}>
          <ModalHeader toggle={ocultarModalFormulario}>{atualizando ? 'Editar' : 'Cadastrar'} Adicional</ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for='nome'>
                    Nome
                  </Label>
                  <input
                    {...register('nome')}
                    id="nome"
                    type="text"
                    className="form-control"
                    placeholder="ex.: Taxa de impressão"
                    aria-label="Nome da Taxa"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <div className="custom-control custom-checkbox pl-3">
                    <input type="checkbox" id="repasse" {...register('repasse')} className="custom-control-input"/>
                    <Label className="custom-control-label" for="repasse">É um Repasse</Label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
              <Button color="soft-info" onClick={ocultarModalFormulario}>Cancelar</Button>
              <Button color="primary" type="submit">Cadastrar</Button>
          </ModalFooter>
        </form>
      </Modal>
      <Modal
        className='modal-dialog-centered'
        isOpen={mostrarModalExcluir}>
        <form onSubmit={handleSubmit(confirmaExcluirTaxa)}>
          <ModalHeader toggle={ocultarModalExcluir}>Excluir Taxa</ModalHeader>
          <ModalBody>
              <h2>Confirma excluir {taxaExcluir?.nome}?</h2>
          </ModalBody>
          <ModalFooter>
              <Button color="soft-info" onClick={ocultarModalExcluir}>Cancelar</Button>
              <Button color="soft-danger" type="submit">Excluir</Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}

export default ListagemTaxas;