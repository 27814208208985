import api from 'src/modulos/processos/api';
import { Checklist } from 'src/modulos/processos/entidades/Checklist';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';

export default async function listarChecklists(): Promise<
  IResultadoRequisicao<Checklist[]>
> {
  try {
    const resultado: Checklist[] = await api().get('checklists');
    return { resultado };
  } catch (error) {
    return { error };
  }
}
