import React from 'react';
import AsyncSelect from 'react-select/async';
import buscarPais from 'src/modulos/aplicacao/casos-de-uso/pais/buscarPais';
import Pais from 'src/modulos/aplicacao/entidades/Pais';

type Props = {
  onPaisSelecionado(pais: Pais): void;
  valorInicial?: {
    value?: string | number;
    label?: string;
    pais?: any;
  };
};

const BuscaPais: React.FC<Props> = ({
  onPaisSelecionado,
  valorInicial = {},
}) => {
  const pesquisarPais = async (valor: string) => {
    const { resultado } = await buscarPais(valor);
    if (resultado) {
      return resultado.map((item) => ({
        value: item.id,
        label: item.nome,
        pais: item,
      }));
    }
    return [];
  };
  return (
    <AsyncSelect
      loadOptions={pesquisarPais}
      placeholder="Pesquisar país"
      defaultValue={valorInicial}
      onChange={(valor) => {
        if (valor) {
          onPaisSelecionado(valor.pais);
        }
      }}
    />
  );
};

export default BuscaPais;
