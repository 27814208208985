import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { IntegracaoAnoreg } from '../../entidades/IntegracaoAnoreg';

export const salvarCredenciaisAnoreg = async (
  dados: IntegracaoAnoreg
): Promise<IResultadoRequisicao<IntegracaoAnoreg>> => {
  try {
    const resultado: IntegracaoAnoreg = await api().post(
      'serventia/integracao',
      dados
    );
    return { resultado };
  } catch (error) {
    return { error };
  }
};
