import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import { Processo } from '../../entidades/Processo';

export default async function editarComentarioProcesso(
  processo: Processo,
  idComentario: number,
  comentario: string
): Promise<IResultadoRequisicao<Andamento>> {
  try {
    const resultado: Andamento = await api().put(
      `/processos/comentario/${processo.id}/${idComentario}`,
      {
        comentario,
      }
    );
    return { resultado };
  } catch (error) {
    return { error };
  }
}
