import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import { pegarCodigoSelo as pegarCodigoSeloAnoreg } from 'src/modulos/processos/entidades/SeloAnoregProcesso';
import {
  pegarCodigoSelo,
  pegarUrlSelo
} from 'src/modulos/processos/entidades/SeloProcesso';

const ListagemSelos: React.FC = () => {
  const { processo } = useContext(ProcessoContext);

  if (processo.selos.length === 0) {
    return <></>;
  }
  return (
    <div className="mt-3">
      <h5>Selos utilizados</h5>
      <ul className="list-unstyled">
        {processo.selos.map((item) => {
          if (item.selo_anoreg) {
            return (
              <li key={item.id} className="py-1">
                <Link to={pegarUrlSelo(item)} target="_blank">
                  {pegarCodigoSeloAnoreg(item.selo_anoreg)}
                </Link>
              </li>
            );
          }
          return (
            <li key={item.id} className="py-1">
              <Link to={pegarUrlSelo(item)} target="_blank">
                {pegarCodigoSelo(item)}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ListagemSelos;
