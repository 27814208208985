// import { RegraNegocioError } from 'src/entidades/ErroRegraNegocio';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import { Preposto } from '../../entidades/Preposto';
import { Processo } from '../../entidades/Processo';

const adicionarPrepostoProcesso = async (
  processo: Processo,
  preposto: Preposto
): Promise<IResultadoRequisicao<Andamento>> => {
  try {
    // if (preposto.status === 'BLOQUEADO') {
    //   throw new RegraNegocioError(
    //     'Não é possível adicionar um preposto bloqueado ao processo.'
    //   );
    // }
    // const prepostoExisteNoProcesso = processo.prepostos!.filter(
    //   (item) => item.id === preposto.id
    // );
    // if (prepostoExisteNoProcesso.length > 0) {
    //   throw new RegraNegocioError(
    //     'Este preposto já está adicionado ao processo'
    //   );
    // }

    const resultado: Andamento = await api().post(
      `/processos/preposto/${processo.id}/${preposto.id}`
    );

    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default adicionarPrepostoProcesso;
