import { pegarCorEtiqueta } from 'src/modulos/processos/entidades/Etiqueta';
import { EtiquetaProcesso } from 'src/modulos/processos/entidades/EtiquetaProcesso';
import css from './style.module.css';

type Props = {
  etiquetas?: EtiquetaProcesso[];
};

const Etiquetas: React.FC<Props> = ({ etiquetas }) => {
  return (
    <div className="w-100 d-block mb-2">
      {etiquetas?.map((item) => {
        return (
          <div
            className={css.etiqueta}
            style={pegarCorEtiqueta(item)}
            key={item.id}
          ></div>
        );
      })}
    </div>
  );
};

export default Etiquetas;
