import React from 'react';
import Select from 'react-select';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import BuscaCidade from 'src/components/Form/BuscaCidade';
import BuscaPais from 'src/components/Form/BuscaPais';
import Data from 'src/components/Form/Data';
import FeedbackErro from 'src/components/Form/FeedbackErro';
import { useGerenciarPessoaContext } from '../GerenciarPessoaContext';

const opcoesSexo = [
  {
    value: 'F',
    label: 'Feminino',
  },
  {
    value: 'M',
    label: 'Masculino',
  },
];

const opcoesEstadoCivil = [
  {
    value: 'SOLTEIRO',
    label: 'Solteiro',
  },
  {
    value: 'CASADO',
    label: 'Casado',
  },
  {
    value: 'SEPARADO',
    label: 'Separado',
  },
  {
    value: 'SEPARADO_JUDICIALMENTE',
    label: 'Separado Judicialmente',
  },
  {
    value: 'DESQUITADO',
    label: 'Desquitado',
  },
  {
    value: 'DIVORCIADO',
    label: 'Divorciado',
  },
  {
    value: 'VIUVO',
    label: 'Viúvo',
  },
  {
    value: 'UNIAO_ESTAVEL',
    label: 'União Estável',
  },
];

const DadosBasicos: React.FC = () => {
  const { form } = useGerenciarPessoaContext();

  const pegarValorSexo = (valor: string) =>
    opcoesSexo.find((item) => item.value === valor);

  const pegarValorEstadoCivil = (valor: string) =>
    opcoesEstadoCivil.find((item) => item.value === valor);

  const valorInicialNacionalidade = {
    value: form.getValues('nacionalidade_id'),
    label: form.getValues('nacionalidade_nome'),
  };

  const valorInicialNaturalidade = {
    value: form.getValues('naturalidade_id'),
    label: form.getValues('naturalidade_nome'),
  };

  return (
    <>
      <h4>Informações pessoais</h4>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Nome</Label>
            <input
              type="text"
              {...form.register('nome')}
              className="form-control"
            />
            {/* <FeedbackErro>{form.formState.errors.nome}</FeedbackErro> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Data de Nascimento</Label>
            <Data
              valorInicial={form.getValues('data_nascimento')}
              onDataAtualizada={(data) => {
                form.setValue('data_nascimento', data);
              }}
            />
            {/* <FeedbackErro>{errosValidacao.dataNascimento}</FeedbackErro> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Sexo</Label>
            <Select
              options={opcoesSexo}
              defaultValue={pegarValorSexo(form.getValues('sexo')!)}
              onChange={(valor) => {
                if (valor) {
                  form.setValue('sexo', String(valor.value));
                }
              }}
            />
            {/* <FeedbackErro>{errosValidacao.sexo}</FeedbackErro> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>CNH</Label>
            <input
              type="text"
              className="form-control"
              {...form.register('cnh')}
            />
            {/* <FeedbackErro>{errosValidacao.cnh}</FeedbackErro> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Órgão Emissor</Label>
            <input
              type="text"
              className="form-control"
              {...form.register('cnh_orgao_emissor')}
            />
            {/* <FeedbackErro>{errosValidacao.cnhOrgaoEmissor}</FeedbackErro> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Data de Emissão</Label>
            <Data
              valorInicial={form.getValues('cnh_data_emissao')}
              onDataAtualizada={(data) => {
                form.setValue('cnh_data_emissao', data);
              }}
            />
            {/* <FeedbackErro>{errosValidacao.cnhDataEmissao}</FeedbackErro> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Documento de Identidade Nº</Label>
            <input
              type="text"
              className="form-control"
              {...form.register('rg')}
            />
            {/* <FeedbackErro>{errosValidacao.rg}</FeedbackErro> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Órgão Emissor</Label>
            <input
              type="text"
              className="form-control"
              {...form.register('rg_orgao_emissor')}
            />
            {/* <FeedbackErro>{errosValidacao.rgOrgaoEmissor}</FeedbackErro> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Data de Emissão</Label>
            <Data
              valorInicial={form.getValues('rg_data_emissao')}
              onDataAtualizada={(data) => {
                form.setValue('rg_data_emissao', data);
              }}
            />
            {/* <FeedbackErro>{errosValidacao.rgDataEmissao}</FeedbackErro> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Nacionalidade (País de origem)</Label>
            <BuscaPais
              valorInicial={valorInicialNacionalidade}
              onPaisSelecionado={(pais) => {
                form.setValue('nacionalidade_nome', String(pais.nome));
                form.setValue('nacionalidade_id', Number(pais.id));
              }}
            />
            {/* <FeedbackErro>{errosValidacao.idNacionalidade}</FeedbackErro> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Naturalidade</Label>
            <BuscaCidade
              valorInicial={valorInicialNaturalidade}
              onCidadeSelecionada={(cidade) => {
                form.setValue('naturalidade_nome', String(cidade.nome));
                form.setValue('naturalidade_id', Number(cidade.id));
              }}
            />
            {/* <FeedbackErro>{errosValidacao.idNaturalidade}</FeedbackErro> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Profissão</Label>
            <input
              type="text"
              className="form-control"
              {...form.register('profissao')}
            />
            {/* <FeedbackErro>{errosValidacao.profissao}</FeedbackErro> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Estado Civil</Label>
            <Select
              options={opcoesEstadoCivil}
              defaultValue={pegarValorEstadoCivil(
                form.getValues('estado_civil')
              )}
              onChange={(valor) => {
                if (valor) {
                  form.setValue('estado_civil', String(valor.value));
                }
              }}
            />
            {/* <FeedbackErro>{errosValidacao.estadoCivil}</FeedbackErro> */}
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default DadosBasicos;
