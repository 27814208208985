import { formatarCNPJ } from 'src/util/formatarCNPJ';
import { formatarCPF } from 'src/util/formatarCPF';
import pegarUrlImagem from '../casos-de-uso/pessoa/pegarUrlImagem';
import { PessoaFisica } from './PessoaFisica';
import { PessoaJuridica } from './PessoaJuridica';

export interface Pessoa {
  id: number;
  created_at: string;
  updated_at: string;
  cartorio_id: number;
  endereco: string;
  complemento: null;
  bairro: string;
  cidade_id: number;
  cep: string;
  email: null;
  telefone_fixo: null;
  telefone_celular: null;
  operadora_celular: null;
  whatsapp: null;
  controle: number;
  motivo_exclusao: null;
  usuario_id: null;
  whatsapp_ddi: null;
  nao_possui_whatsapp: null;
  nao_possui_cpf_cnpj: string;
  usuario_editor_id: null;
  foto: string | null;
  criado_por: string;
  editado_por: string;
  tipo: string;
  documentos: any[];
  fisica?: PessoaFisica;
  juridica?: PessoaJuridica;
}

//   ehPessoaFisica(): boolean {
//     return !!this.cpf;
//   }

//   ehPessoaJuridica(): boolean {
//     return !!this.cnpj;
//   }

//   ehCadastroSimples(): boolean {
//     if (this.ehPessoaFisica()) {
//       return !this.nomeMae;
//     }
//     if (this.ehPessoaJuridica()) {
//       return !this.naturezaJuridica;
//     }
//     return false;
//   }

//   public get denominacaoPessoa(): string {
//     return this.nome || this.razaoSocial || '';
//   }

//   public get documentoPessoa(): string {
//     if (this.ehPessoaFisica()) {
//       return formatarCPF(this.cpf!);
//     }
//     if (this.ehPessoaJuridica()) {
//       return formatarCNPJ(this.cnpj!);
//     }
//     return '';
//   }

//   pegarFoto(): string {
//     let urlFoto = 'default';
//     if (this.documentos) {
//       const foto = this.documentos.filter(
//         (item) => item.tipo === 'OUTRO' && item.outro_tipo === 'FOTO'
//       );
//       if (foto.length > 0) {
//         urlFoto =
//           foto[foto.length - 1].arquivo !== 'padrao.jpg'
//             ? foto[foto.length - 1].arquivo
//             : 'default';
//       }
//     } else if (this.foto && this.foto !== '1') {
//       urlFoto = this.foto;
//     }
//     return pegarUrlImagem(urlFoto);
//   }

// }

export const ehPessoaFisica = (pessoa: Pessoa): boolean => {
  return pessoa.fisica !== undefined || 'cpf' in pessoa;
};

export const ehPessoaJuridica = (pessoa: Pessoa): boolean => {
  return pessoa.juridica !== undefined || 'cnpj' in pessoa;
};

export const pegarDenominacaoPessoa = (pessoa: Pessoa): string => {
  if (pessoa.fisica) {
    return pessoa.fisica.nome;
  }

  if (pessoa.juridica) {
    return pessoa.juridica.razao_social;
  }

  if (ehPessoaFisica(pessoa)) {
    const p = pessoa as PessoaFisica;
    return p.nome;
  }

  if (ehPessoaJuridica(pessoa)) {
    const p = pessoa as PessoaJuridica;
    return p.razao_social;
  }
  return '';
};

export const pegarDocumentoPessoa = (
  pessoa: Pessoa,
  formatar = false
): string => {
  if (pessoa.fisica) {
    return !formatar ? pessoa.fisica.cpf : formatarCPF(pessoa.fisica.cpf);
  }

  if (pessoa.juridica) {
    return !formatar
      ? pessoa.juridica.cnpj
      : formatarCNPJ(pessoa.juridica.cnpj);
  }

  if (ehPessoaFisica(pessoa)) {
    const p = pessoa as PessoaFisica;
    return !formatar ? p.cpf : formatarCPF(p.cpf);
  }

  if (ehPessoaJuridica(pessoa)) {
    const p = pessoa as PessoaJuridica;
    return !formatar ? p.cnpj : formatarCNPJ(p.cnpj);
  }
  return '';
};

export const pegarLogCadastroEdicao = (pessoa: Pessoa): string => {
  return `Cadastrado por ${pessoa.criado_por}, em ${pessoa.created_at};
  Editado, ${
    pessoa.editado_por ? `por ${pessoa.editado_por}, ` : ''
  }pela última vez, em
  ${pessoa.updated_at}`;
};
