import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FiBookOpen,
  FiUsers,
  FiTrash,
  FiDatabase,
  FiChevronRight,
  FiCloud,
  FiFolder,
} from 'react-icons/fi';
import { Pasta } from 'src/modulos/acervo/entidades/Pasta';
import listarPastasAcervo from 'src/modulos/acervo/casos-de-uso/listarPastasAcervo';
import { useRequest } from 'ahooks';
import { listarLivros } from 'src/modulos/aplicacao/casos-de-uso/livros';
import { groupBy } from 'lodash';

const MenuLateral: React.FC = () => {
  const [pastasAcervo, setPastasAcervo] = useState<Pasta[]>([]);
  const { data: livros } = useRequest(listarLivros);
  const [, setPaginaAtual] = useState('/acervo');
  const location = useLocation();

  const formatarLivros = () => {
    if (!livros) return {};
    const lista = Object.values(livros);

    return groupBy(lista, 'especialidade');
  };

  useEffect(() => {
    listarPastasAcervo('').then(({ resultado }) => {
      if (resultado) {
        setPastasAcervo(resultado);
      }
    });
  }, []);

  useEffect(() => {
    setPaginaAtual(location.pathname);
  }, [location.pathname]);

  return (
    <div className="left-side-menu" style={{ overflow: 'auto' }}>
      <div className="sidebar-content">
        <div id="sidebar-menu" className="slimscroll-menu">
          <ul className="metismenu" id="menu-bar">
            <li>
              <Link to={'/acervo'} className="d-flex align-items-center px-1">
                <FiBookOpen />
                <span className="flex-fill">Acervo</span>
                <FiChevronRight />
              </Link>
              <ul className="pl-4 pr-2">
                {pastasAcervo.map((item) => (
                  <li key={item.id}>
                    <Link
                      to={`/acervo/${item.uri}?incluir=subpastas,acervos`}
                      className="d-flex align-items-center py-2"
                    >
                      <FiFolder />
                      <span className="d-inline-block ml-1 text-truncate flex-fill">
                        {item.nome}
                      </span>
                      <FiChevronRight />
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <Link
                to={'/acervo/arquivos-processos'}
                className="d-flex align-items-center px-1"
              >
                <FiCloud />
                <span className="flex-fill"> Arquivos de Processo </span>
              </Link>
            </li>
            <li>
              <Link to={'/acervo/arquivos-pessoas'} className="d-flex align-items-center px-1">
                <FiUsers />
                <span className="flex-fill"> Arquivos de Pessoas </span>
              </Link>
            </li>
            <li>
              <Link to={'/acervo/nuvem'} className="d-flex align-items-center px-1">
                <FiCloud />
                <span className="flex-fill"> Nuvem </span>
              </Link>
            </li>
            <li>
              <Link to={'/acervo/lixeira'} className="d-flex align-items-center px-1">
                <FiTrash />
                <span className="flex-fill"> Lixeira </span>
                <FiChevronRight className="menu-arrow" />
              </Link>
            </li>
            <li>
              <Link to={'/acervo/armazenamento'} className="d-flex align-items-center px-1">
                <FiDatabase />
                <span className="flex-fill"> Armazenamento </span>
                <FiChevronRight className="menu-arrow" />
              </Link>
            </li>
          </ul>
          <ul className="metismenu" id="menu-bar">
            {Object.entries(formatarLivros()).map(
              ([especialidade, itens]: any) => {
                return (
                  <li>
                    <Link
                      to={'/acervo'}
                      className="d-flex align-items-center px-1"
                    >
                      <FiBookOpen />
                      <span className="flex-fill">{especialidade}</span>
                      <FiChevronRight />
                    </Link>

                    <ul className="pl-4 pr-2">
                      {itens.map((item: any) => {
                        return (
                          <li key={item.id}>
                            <Link
                              to={`/acervo/livro/${item.nome}`}
                              className="d-flex align-items-center py-2"
                            >
                              <FiFolder
                                style={{ minWidth: '16px', minHeight: '16px' }}
                              />
                              <span className="d-inline-block ml-1 text-truncate flex-fill">
                                {item.nome_exibicao}
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              }
            )}
          </ul>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
};

export default MenuLateral;
