import React from 'react';
import IconePessoas from '../../assets/icon-pessoas.png';
import Buscar from './Buscar';
import HeaderTema from 'src/layout/Header';

const Header: React.FC = () => {
  return (
    <HeaderTema
      icone={IconePessoas}
      urlIcone="/pessoas"
      componentBusca={<Buscar />}
    />
  );
};

export default Header;
