import dayjs from 'dayjs';
import React, { useState } from 'react';
import { IMaskInput } from 'react-imask';
import constantes from 'src/util/constantes';
import dataParser from 'dayjs/plugin/customParseFormat';

dayjs.extend(dataParser);

type Props = {
  valorInicial?: string;
  onDataAtualizada(data: string): void;
};

const Data: React.FC<Props> = ({ onDataAtualizada, valorInicial = '' }) => {
  const [dataInvalida, setDataInvalida] = useState(false);
  return (
    <>
      <IMaskInput
        mask={constantes.MASCARA_DATA}
        className="form-control"
        value={valorInicial}
        onAccept={(valor) => {
          setDataInvalida(false);
          if (
            valor &&
            String(valor).length === constantes.MASCARA_DATA.length
          ) {
            const data = dayjs(String(valor), 'DD/MM/YYYY', true).toDate();
            if (data.toDateString() !== 'Invalid Date') {
              onDataAtualizada(String(valor));
              setDataInvalida(false);
            } else {
              setDataInvalida(true);
            }
          }
        }}
      />
      {dataInvalida && <span className="text-danger">Data inválida</span>}
    </>
  );
};

export default Data;
