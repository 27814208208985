import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Usuario } from '../../entidades/Usuario';

const salvarPerfilUsuario = async (
  usuario: Partial<Usuario>
): Promise<IResultadoRequisicao<boolean>> => {
  try {
    const resultado: boolean = await api().put('perfil', usuario);
    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default salvarPerfilUsuario;
