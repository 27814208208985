import { useDynamicList } from 'ahooks';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { Button, Col, FormGroup, Label, Row, Table } from 'reactstrap';
import { Cartorio } from 'src/modulos/aplicacao/entidades/Cartorio';

const ControleIp: React.FC = () => {
  const { register, getValues, setValue } = useFormContext<Cartorio>();

  const { list, push, remove } = useDynamicList<string>([]);

  return (
    <>
      <Row>
        <Col md={3} className="d-flex align-items-center">
          <FormGroup className="flex-fill">
            <Label>Endereço de IP</Label>
            <input
              type="text"
              className="form-control"
              {...register('ip_cliente')}
            />
          </FormGroup>
          <Button
            color="primary"
            className="mt-3 ml-2"
            onClick={() => {
              push(String(getValues('ip_cliente')));
              setValue('ip_cliente', '');
            }}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Table>
            <thead>
              <tr>
                <th>IP</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, idx) => {
                return (
                  <tr>
                    <td className="w-100">{item}</td>
                    <td>
                      <Button
                        color="soft-danger"
                        size="sm"
                        type="button"
                        onClick={() => remove(idx)}
                      >
                        <FiTrash2 />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default ControleIp;
