import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';

export default async function relacionarArquivoAcervoProcesso(
  processoId: number,
  acervoId: number
): Promise<IResultadoRequisicao<any>> {
  try {
    const resultado = await api().post(
      `/processos/${processoId}/relacionar/${acervoId}`
    );
    return { resultado };
  } catch (error) {
    return { error };
  }
}
