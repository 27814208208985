import React from 'react';
import { pegarDenominacaoPessoa } from 'src/modulos/pessoas/entidades/Pessoa';
import { Processo } from 'src/modulos/processos/entidades/Processo';

type Props = {
  processo: Processo;
};

const Pessoas: React.FC<Props> = ({ processo }) => {
  return (
    <div>
      {processo.pessoas && processo.pessoas?.length > 0 && (
        <span>
          {pegarDenominacaoPessoa(processo.pessoas[0])}{' '}
          {processo.pessoas?.length > 1
            ? ` e +${processo.pessoas?.length - 1}`
            : ''}
        </span>
      )}
    </div>
  );
};

export default Pessoas;
