import React, { useContext, useState } from 'react';
import { FiTrash } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';
import excluirChecklistProcesso from 'src/modulos/processos/casos-de-uso/processo/excluirChecklistProcesso';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import { ChecklistProcesso } from 'src/modulos/processos/entidades/ChecklistProcesso';

type Props = {
  checklist: ChecklistProcesso;
};

const ExcluirChecklist: React.FC<Props> = ({ checklist }) => {
  const { processo, arquivado, onChecklistExcluida } = useContext(ProcessoContext);
  const [exibir, setExibir] = useState(false);

  const fechar = () => setExibir(false);

  const excluir = async () => {
    const { resultado, error } = await excluirChecklistProcesso(
      processo,
      checklist.id
    );

    if (resultado) {
      onChecklistExcluida(checklist, resultado.andamento);
    }
    if (error) {
      toast.error(error.mensagem);
    }
  };

  return (
    <div className="position-relative">
      <Button
        color="soft-danger"
        size="sm"
        className="d-flex align-items-center"
        disabled={arquivado}
        onClick={() => setExibir(true)}
      >
        <FiTrash />
        <span className="ml-1">Excluir lista</span>
      </Button>
      <Dropdown
        mostrar={exibir}
        fechar={fechar}
        titulo="Excluir essa checklist"
      >
        <span className="d-block py-2">
          Tem certeza? Essa ação é irreversível.
        </span>
        <div className="d-flex justify-content-end mt-2">
          <Button
            color="soft-danger"
            onClick={excluir}
            className="d-flex align-items-center"
          >
            <FiTrash />
            <span className="ml-1">Excluir</span>
          </Button>
        </div>
      </Dropdown>
    </div>
  );
};

export default ExcluirChecklist;
