import React from 'react';
import {
  FiFileText,
  FiHome,
  FiList,
  FiLock,
  FiShare2,
  FiUser,
  FiUsers,
} from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';

const MenuLateral: React.FC = () => {
  const location = useLocation();

  const pegarClasseUrlAtiva = (url: string | string[]) => {
    let links: string[] = [];
    if (!Array.isArray(url)) {
      links = [url];
    } else {
      links = url;
    }
    return links.includes(location.pathname) ? 'mm-active' : '';
  };

  return (
    <div className="left-side-menu">
      <div className="sidebar-content">
        <div id="sidebar-menu" className="slimscroll-menu">
          <ul className="metismenu" id="menu-bar">
            <li className={pegarClasseUrlAtiva('/usuarios/perfil')}>
              <Link to={'/usuarios/perfil'}>
                <FiUser />
                <span> Meu perfil </span>
              </Link>
            </li>
            <li
              className={pegarClasseUrlAtiva([
                '/usuarios/seguranca',
                '/usuarios/seguranca/alterar-senha',
              ])}
            >
              <Link to={'/usuarios/seguranca'}>
                <FiLock />
                <span> Segurança </span>
              </Link>
            </li>

            <hr />

            <li className="ml-4">
              <p>Cartório</p>
            </li>

            <li className={pegarClasseUrlAtiva('/usuarios/serventia')}>
              <Link to={'/usuarios/serventia'}>
                <FiFileText />
                <span> Perfil de Serventia </span>
              </Link>
            </li>
            <li className={pegarClasseUrlAtiva('/usuarios/termo-inicial')}>
              <Link to={'/usuarios/termo-inicial'}>
                <FiList />
                <span> Controle de Termo inicial (Livros) </span>
              </Link>
            </li>
            <li className={pegarClasseUrlAtiva('/usuarios/integracoes')}>
              <Link to={'/usuarios/integracoes'}>
                <FiShare2 />
                <span> Integrações </span>
              </Link>
            </li>
            <li className={pegarClasseUrlAtiva('/usuarios/membros')}>
              <Link to={'/usuarios/membros'}>
                <FiUsers />
                <span> Membros da Equipe </span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
};

export default MenuLateral;
