import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { CadastroPessoaSimplesForm } from '../../entidades/CadastroPessoaSimplesForm';
import { PessoaFisica } from '../../entidades/PessoaFisica';
import { PessoaJuridica } from '../../entidades/PessoaJuridica';
import visualizarPessoa from './visualizarPessoa';

export default async function cadastrarPessoaSimples(
  dados: CadastroPessoaSimplesForm
): Promise<IResultadoRequisicao<PessoaFisica | PessoaJuridica>> {
  try {
    let dadosForm = {};
    if (dados.tipoDocumento === 'CPF') {
      dadosForm = {
        cpf: dados.documento,
        cpf_cnpj: dados.documento,
        nome: dados.denominacao,
        sem_cpf_cnpj: null,
        tipo: 'pessoa_fisica',
        tipo_pessoa: 'PESSOA_FISICA',
        tipo_sem_cpf_cnpj: 'N',
      };
    }
    const cadastro: { id: number } = await api().post(
      '/gerencia/pessoa',
      dadosForm
    );
    const resultado = await visualizarPessoa(cadastro.id);
    return resultado;
  } catch (error) {
    return { error };
  }
}
