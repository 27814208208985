import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import { Processo } from '../../entidades/Processo';

const adicionarClienteNoProcesso = async (
  processo: Processo,
  pessoaId: number
): Promise<IResultadoRequisicao<Andamento>> => {
  try {
    const resultado: Andamento = await api().post(
      `/processos/cliente/${processo.id}/${pessoaId}`
    );

    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default adicionarClienteNoProcesso;
