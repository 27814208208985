import React, { useEffect, useState } from 'react';
import { FiCopy, FiTrash, FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import listarChecklists from 'src/modulos/processos/casos-de-uso/configuracoes/checklists/listarChecklists';
import { Checklist as EntidadeChecklist } from 'src/modulos/processos/entidades/Checklist';
import Breadcrumb from '../../../../../components/Breadcrumb';

const linksBreadcrumb = [
  {
    url: '/',
    texto: 'Controle de Processos',
  },
  {
    texto: 'Checklists',
  },
];

const Checklist: React.FC = () => {
  const [checklists, setChecklists] = useState<EntidadeChecklist[]>([]);
  const [processando, setProcessando] = useState(false);

  useEffect(() => {
    setProcessando(true);
    listarChecklists().then(({ resultado }) => {
      setProcessando(false);
      if (resultado) {
        setChecklists(resultado);
      }
    });
  }, []);

  return (
    <>
      <Breadcrumb links={linksBreadcrumb} />
      <Container>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center">
                  <h3>Checklists</h3>
                  <Link
                    to="/processos/configuracoes/checklists/adicionar"
                    className="btn btn-primary"
                  >
                    <FiPlus className="mr-2 font-size-18" />
                    <span>Adicionar</span>
                  </Link>
                </div>
                <CarregamentoConteudo carregando={processando}>
                  <Table striped className="mt-3">
                    <tbody>
                      {checklists.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <Link
                                to={`/processos/configuracoes/checklists/editar/${item.id}`}
                              >
                                {item.titulo}
                              </Link>
                            </td>
                            <td>
                              <Button color="soft-secondary" size="sm">
                                <FiCopy />
                              </Button>
                              <Button
                                color="soft-danger"
                                size="sm"
                                className="ml-2"
                              >
                                <FiTrash />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CarregamentoConteudo>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Checklist;
