import React from 'react';
import { FiLock, FiLogOut, FiUser, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { removeCookie } from 'tiny-cookie';
// import AutenticacaoContext from 'src/contexts/Autenticacao';
import Avatar from 'src/modulos/processos/components/Avatar';
import style from './style.module.css';

const Usuario: React.FC = () => {
  //   const { usuario } = React.useContext(AutenticacaoContext);

  const [mostrarOpcoesUsuario, setMostrarOpcoesUsuario] = React.useState(false);
  const alternarMostrarOpcoesUsuario = () => {
    setMostrarOpcoesUsuario((mostrar) => !mostrar);
  };

  const bloquearSessao = () => {};

  const sairDaSessao = () => {
    localStorage.clear();
    removeCookie('vc_token');
  };

  return (
    <Dropdown
      isOpen={mostrarOpcoesUsuario}
      toggle={alternarMostrarOpcoesUsuario}
      size="lg"
      className="nav-link right-bar-toggle"
      tag="li"
    >
      <DropdownToggle data-toggle="dropdown" tag="a">
        <Avatar />
      </DropdownToggle>
      <DropdownMenu right className={style['menu']}>
        <div className="d-flex justify-content-between px-3 align-items-center">
          <h4>Minha Conta</h4>
          <FiX onClick={alternarMostrarOpcoesUsuario} />
        </div>
        <div className={style['divisor']}></div>
        <div className="px-3 mt-2">
          <Link className="d-flex align-items-center py-3" to={'/usuarios/perfil'}>
            <FiUser />
            <span className="ml-3 flex-fill">Gerenciar minha conta</span>
          </Link>
        </div>
        <div className={style['divisor']}></div>
        <div className="px-3" onClick={bloquearSessao}>
          <span className="d-flex align-items-center py-3">
            <FiLock />
            <span className="ml-3 flex-fill">Bloquear sessão</span>
          </span>
        </div>
        <div className="px-3">
          <Link
            className="d-flex align-items-center py-3"
            to={'/entrar'}
            onClick={sairDaSessao}
          >
            <FiLogOut />
            <span className="ml-3 flex-fill">Sair</span>
          </Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Usuario;
