import { useBoolean } from 'ahooks';
import React, { useContext } from 'react';
import BlockUi from 'react-block-ui';
import { useForm } from 'react-hook-form';
import { Alert, Button, Label } from 'reactstrap';
import FormGroup from 'src/components/Form/FormGroup';
import { gerarProtocolo } from 'src/modulos/aplicacao/casos-de-uso/protocolo';
import { pegarDenominacaoPessoa } from 'src/modulos/pessoas/entidades/Pessoa';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import { pessoaEhApresentante } from 'src/modulos/processos/entidades/PessoaProcesso';

type Props = {
  onSalvar(): void;
  onCancelar(): void;
};

const FormularioNotas: React.FC<Props> = ({ onSalvar, onCancelar }) => {
  const { processo, onProtocoloAdicionado } = useContext(ProcessoContext);
  const [processando, { toggle }] = useBoolean(false);

  const { handleSubmit, getValues, setValue, register } = useForm();
  const apresentanteProcesso = processo.pessoas.find(pessoaEhApresentante);

  const salvar = handleSubmit(async () => {
    toggle();
    const dados = getValues();
    const { resultado } = await gerarProtocolo({
      especialidade: 'NOTAS',
      pessoa_id: apresentanteProcesso?.id,
      ...dados,
    });
    toggle();
  });

  return (
    <div className="py-2">
      <BlockUi blocking={processando}>
        <FormGroup>
          <div className="checkbox checkbox-primary ml-1 mt-2">
            <input type="checkbox" id="checkboxReurb" {...register('reurb')} />
            <label htmlFor="checkboxReurb">Reurb</label>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="checkbox checkbox-primary ml-1 mt-2">
            <input
              type="checkbox"
              id="checkboxConvalidacao"
              {...register('convalidacao')}
            />
            <label htmlFor="checkboxConvalidacao">Convalidação</label>
          </div>
        </FormGroup>
        {apresentanteProcesso && (
          <FormGroup>
            <Label>Apresentante</Label>
            <span className="d-block">
              {pegarDenominacaoPessoa(apresentanteProcesso)}
            </span>
          </FormGroup>
        )}
        {!apresentanteProcesso && (
          <Alert color="warning">
            Adicione o apresentante para gerar o protocolo.
          </Alert>
        )}
        <div className="d-flex justify-content-end">
          <Button color="soft-success" className="mr-2" onClick={onCancelar}>
            Cancelar
          </Button>
          <Button
            color="primary"
            className="px-3"
            onClick={salvar}
            disabled={!apresentanteProcesso}
          >
            Gerar
          </Button>
        </div>
      </BlockUi>
    </div>
  );
};

export default FormularioNotas;
