import React, { useContext } from 'react';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Button, ButtonGroup } from 'reactstrap';
import atualizarEtapaProcesso from 'src/modulos/processos/casos-de-uso/atualizarEtapaProcesso';
import formatarEtapaProcesso from 'src/modulos/processos/casos-de-uso/processo/formatarEtapaProcesso';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';

const ordemEtapas = [
  'NOVO/A SER DISTRIBUÍDO',
  'EM ANÁLISE',
  'PROVIDÊNCIAS DO CLIENTE',
  'EM EXECUÇÃO',
  'FEITO: A SER ENTREGUE',
  'FEITO: A SER ARQUIVADO',
];

const Etapas: React.FC = () => {
  const { processo, onAtualizacaoEtapa } = useContext(ProcessoContext);

  const atualizarEtapa = async (etapa: string) => {
    const { resultado, error } = await atualizarEtapaProcesso(processo, etapa);
    if (resultado) {
      onAtualizacaoEtapa(etapa, resultado);
      return;
    }
    if (error) {
      toast.error(error.mensagem);
      return;
    }
  };

  const listarEtapas = () => {
    const ordemEtapaAtual = ordemEtapas.findIndex(
      (item) => item === processo.etapa
    );
    if (ordemEtapaAtual === 0) {
      return ordemEtapas.slice(0, 2);
    }

    return ordemEtapas.slice(ordemEtapaAtual - 1, ordemEtapaAtual + 2);
  };

  return (
    <div className="d-flex align-items-center justify-content-center py-3">
      <ButtonGroup>
        {listarEtapas().map((item, index, lista) => {
          return (
            <Button
              color={processo.etapa === item ? 'primary' : 'soft-info'}
              onClick={() => atualizarEtapa(item)}
              key={item}
              className="d-flex align-items-center"
            >
              {index === 0 && lista.length > 2 && (
                <BsChevronLeft className="mr-2" />
              )}
              {formatarEtapaProcesso(item)}
              {index === lista.length - 1 && (
                <BsChevronRight className="ml-2" />
              )}
            </Button>
          );
        })}
      </ButtonGroup>
    </div>
  );
};

export default Etapas;
