import React from 'react';
import { Modelo } from 'src/modulos/processos/entidades/Modelo';
import { FiEdit, FiFileText, FiTrash } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import formatarDatahora from 'src/casos-de-uso/formatacao/formatarDatahora';

type Props = {
  modelo: Modelo;
};
const CardModelo: React.FC<Props> = ({ modelo }) => {
  return (
    <div className="d-flex align-items-start py-3 border-bottom">
      <div className="p-4 bg-primary rounded mr-3 mt-2">
        <FiFileText className="h1 text-white" />
      </div>
      <div>
        <h5>
          {modelo.titulo} - Emolumento: {modelo.emolumento_codigo}
        </h5>
        <span>
          Adicionado em {formatarDatahora(modelo.created_at)} | Atualizado em{' '}
          {formatarDatahora(modelo.updated_at)}
        </span>
        <div className="d-flex mt-2">
          <Link to={`/processos/configuracoes/modelos/editar/${modelo.id}`}>
            <Button color="soft-primary" className="mr-2">
              <FiEdit />
              <span className="ml-2">Editar</span>
            </Button>
          </Link>
          <Button color="soft-danger">
            <FiTrash />
            <span className="ml-2">Excluir</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CardModelo;
