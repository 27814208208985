import { Usuario } from 'src/modulos/aplicacao/entidades/Usuario';

export interface Andamento {
  comentario?: string;
  created_at?: Date;
  descricao?: string;
  id?: number;
  informacao?: string;
  processo_id?: number;
  reacoes?: [];
  reacoes_total?: false;
  updated_at?: Date;
  usuario?: Usuario;
  usuario_id?: number;
}

export const possuiComentarioOuInformacao = (andamento: Andamento) => {
  return (
    (andamento.comentario && andamento.comentario.length > 0) ||
    (andamento.informacao && andamento.informacao.length > 0)
  );
};

export const pegarConteudoAndamento = (andamento: Andamento) => {
  return (
    andamento.comentario || andamento.descricao || andamento.informacao || ''
  );
};
