import React, { useContext } from 'react';
import AutenticacaoContext from 'src/contexts/Autenticacao';

type Props = {
  modulo: string;
};

const TemModulo: React.FC<Props> = ({ children, modulo }) => {
  const { temModulo } = useContext(AutenticacaoContext);
  if (!temModulo(modulo)) {
    return null;
  }
  return <>{children}</>;
};

export default TemModulo;
