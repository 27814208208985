import React from 'react';
import { InputGroup, Input, Row, Col, InputGroupText, Badge } from 'reactstrap';
import { AiOutlineEye } from 'react-icons/ai';

const DadosGerais: React.FC = () => {
  const [CPFEscondido, setCPFEscondido] = React.useState(false);
  const [CPF, setCPF] = React.useState('');
  const [nomeDoContato, setNomeDoContrato] = React.useState('');
  const [email, setEmail] = React.useState('');

  const alternarEstadoCPF = () => {
    if (!CPFEscondido) setCPFEscondido(true);
    else setCPFEscondido(false);
  };

  return (
    <Col>
      <Row className="mb-4">
        <Col xs={6} className="mb-2">
          <b>
            <span>Nome do Contrato</span>
          </b>

          <div>
            <i>
              <span>Obs.: Nome completo, sem abreviações.</span>
            </i>
          </div>

          <InputGroup className="mt-1">
            <Input
              onChange={(e) => {
                setNomeDoContrato(e.target.value);
              }}
              value={nomeDoContato}
            />
          </InputGroup>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs={4}>
          <b>
            <span>CPF</span>
          </b>

          <form action="">
            <div className="input-busca-div d-flex">
              <Input
                type={CPFEscondido ? 'text' : 'password'}
                className="form-control"
                onChange={(e) => {
                  setCPF(e.target.value);
                }}
                value={CPF}
              />

              <div className="p-2">
                <b>
                  <AiOutlineEye
                    className="font-size-18"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      alternarEstadoCPF();
                    }}
                  />
                </b>
              </div>
            </div>
          </form>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs={6} className="mb-2">
          <b>
            <span>E-mail</span>
          </b>

          <div>
            <i>
              <span>ex.: meu_email@gmail.com</span>
            </i>
          </div>

          <InputGroup className="mt-1">
            <Input
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
          </InputGroup>
        </Col>
      </Row>

      <hr />
    </Col>
  );
};

export default DadosGerais;
