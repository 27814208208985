import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import ClipboardJS from 'clipboard';

import { FiEdit } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { Pessoa } from '../../entidades/Pessoa';
import CarregamentoConteudo from '../../../../components/CarregamentoConteudo';
import Breadcrumb from 'src/components/Breadcrumb';

import PessoaJuridica from './PessoaJuridica';

const VisualizarPessoa: React.FC = () => {
  const params = useParams();
  const [carregando, setCarregando] = React.useState(true);
  const [pessoa, setPessoa] = React.useState<Pessoa>({} as Pessoa);
  new ClipboardJS('.button');
  React.useEffect(() => {
    setCarregando(true);
  }, [params.id]);

  return (
    <section className="container">
      <Breadcrumb
        links={[
          {
            url: '/pessoas',
            texto: 'Pessoas',
          },
          {
            texto: ``,
          },
        ]}
      />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CarregamentoConteudo carregando={carregando}>
                <Row>
                  <Col xs={3}>
                    <img
                      src="https://source.unsplash.com/random/600x400/?img=1"
                      alt=""
                      className="w-100"
                    />
                  </Col>
                  <Col xs={9}>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h3></h3>
                        <span></span>
                      </div>
                      <div>
                        <button className="btn btn-soft-primary">
                          <FiEdit />
                          <span className="ml-3">Editar dados</span>
                        </button>
                      </div>
                    </div>
                    <div className="d-flex align-items-center bg-light rounded p-1 pr-3 pl-3  mt-3">
                      <div className="flex-fill">
                        <h5>Modelo comum</h5>
                        <p id="modelo-comum"></p>
                      </div>
                      <div className="ml-3">
                        <button
                          data-clipboard-action="copy"
                          data-clipboard-target="#modelo-comum"
                          className="btn btn-soft-primary button"
                        >
                          COPIAR
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex bg-light rounded p-4 mt-3">
                      <div className="flex-fill">
                        <h5>Modelo completo</h5>
                        <p id="modelo-completo">-</p>
                      </div>
                      <div className="ml-3">
                        <button
                          data-clipboard-action="copy"
                          data-clipboard-target="#modelo-completo"
                          className="btn btn-soft-primary button"
                        >
                          COPIAR
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <PessoaJuridica pessoa={pessoa} />
              </CarregamentoConteudo>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export default VisualizarPessoa;
