import React from "react";

// import { Container } from './styles';

const InformacoesDeRepasse: React.FC = () => {
  return (
    <section>
      <div className="card">
        <div className="flex flex-row">
          <h1 className="titulo-pagina flex-1">Informações de Repasses</h1>
          <div className="flex space-x-5">
            <button className="btn-azul">
              <span>Exportar CSV</span>
            </button>
            <button className="btn-azul">
              <span>Filtrar</span>
            </button>
          </div>
        </div>
        <div className="mt-8">
          <div className="tabela fundo-alternado">
            <table>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Ato</th>
                  <th>QTD</th>
                  <th>Emolumentos</th>
                  <th>TFJ</th>
                  <th>Funcivil</th>
                  <th>ISS</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Notas</td>
                  <td>1234</td>
                  <td>1</td>
                  <td>R$150,00</td>
                  <td>R$2,00</td>
                  <td>R$15,00</td>
                  <td>R$6,00</td>
                  <td>R$173,00</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td>236</td>
                  <td>R$150,00</td>
                  <td>R$2,00</td>
                  <td>R$15,00</td>
                  <td>R$6,00</td>
                  <td>R$173,00</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="flex justify-end space-x-3 mt-4">
            <button className="btn-azul">&gt;</button>
            <input
              type="text"
              className="border rounded w-16 text-center"
              value={1}
            />
            <button className="btn-azul">&gt;</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InformacoesDeRepasse;
