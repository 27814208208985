import React from 'react';
import AdicionarChecklist from './AdicionarChecklist';
import AdicionarEtiqueta from './AdicionarEtiqueta';
import AdicionarOrcamento from './AdicionarOrcamento';
import AnexarDocumento from './AnexarDocumento';
import Clientes from './Clientes';
import DataEntrega from './AdicionarDataEntrega';
import Descricao from './Descricao';
import Etapas from './Etapas';
import ListagemAnexos from './ListagemAnexos';
import ListagemAtividades from './ListagemAtividades';
import ListagemDataEntrega from './ListagemDataEntrega';
import ListagemEtiquetas from './ListagemEtiquetas';
import ListagemProcessosRelacionados from './ListagemProcessosRelacionados';
import Prepostos from './Prepostos';
import Protocolos from './Protocolos';
import RelacionarProcessos from './RelacionarProcessos';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import css from './style.module.css';
import ListagemOrcamentos from './ListagemOrcamentos';
import ListagemSelos from './ListagemSelos';
import AdicionarPontuacaoPrepostos from './AdicionarPontuacaoPrepostos';
import { ProcessoProvider } from '../../contexts/ProcessoContext';
import ListagemChecklist from './ListagemChecklist';
import Imprimir from './Imprimir';
import Arquivar from './Arquivar';
import Titulo from './Titulo';
import Breadcrumb from './Breadcrumb';
import Arquivado from './Arquivado';

const Processo: React.FC = () => {
  return (
    <ProcessoProvider>
      <Container className="min-vh">
        <Breadcrumb />
        <Row>
          <Col md={12}>
            <Arquivado />
            <Card>
              <CardBody>
                <Row>
                  <Col md={9}>
                    <Titulo />
                    <Etapas />
                    <Row className={css['espaco-lateral']}>
                      <Col md={6}>
                        <Prepostos />
                      </Col>
                      <Col md={6}>
                        <Clientes />
                      </Col>
                    </Row>
                    <Row className={css['espaco-lateral']}>
                      <Col>
                        <Protocolos />
                      </Col>
                    </Row>
                    <Row className={css['espaco-lateral']}>
                      <Col>
                        <ListagemDataEntrega />
                      </Col>
                    </Row>
                    <Row className={css['espaco-lateral']}>
                      <Col>
                        <ListagemEtiquetas />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Descricao />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListagemProcessosRelacionados />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListagemAnexos />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListagemChecklist />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListagemAtividades />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={3}>
                    <div className="mb-2">
                      <DataEntrega />
                    </div>
                    <div className="mb-2">
                      <AnexarDocumento />
                    </div>
                    <div className="mb-2">
                      <AdicionarChecklist />
                    </div>
                    <div className="mb-2">
                      <AdicionarEtiqueta />
                    </div>
                    <div className="mb-2">
                      <AdicionarOrcamento />
                    </div>
                    <div className="mb-2">
                      <RelacionarProcessos />
                    </div>
                    <div className="mb-2">
                      <AdicionarPontuacaoPrepostos />
                    </div>
                    <h4>Ações</h4>
                    <div className="mb-2">
                      <Imprimir />
                    </div>
                    <div className="mb-2">
                      <Arquivar />
                    </div>
                    <div className="mb-2">
                      <ListagemSelos />
                    </div>
                    <div className="mb-2">
                      <ListagemOrcamentos />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </ProcessoProvider>
  );
};

export default Processo;
