import React, { useContext } from 'react';
import { FiChevronDown, FiChevronUp, FiMessageSquare } from 'react-icons/fi';
import { Button, Collapse } from 'reactstrap';
import formatarDatahora from 'src/casos-de-uso/formatacao/formatarDatahora';
import pegarTipoArquivo from 'src/modulos/acervo/casos-de-uso/arquivos/pegarTipoArquivo';
import { pegarDenominacaoPessoa, pegarDocumentoPessoa } from 'src/modulos/pessoas/entidades/Pessoa';
import IconeTipoArquivo from 'src/modulos/processos/components/IconeTipoArquivo';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';

const DocumentosCadastrais: React.FC = () => {
  const { processo } = useContext(ProcessoContext);
  const [expandido, setExpandido] = React.useState<boolean[]>([]);

  const listarPessoasComDocumentos = () => {
    if (!processo.pessoas) {
      return [];
    }

    return processo.pessoas.filter((item) => {
      return item.documentos && item.documentos.length > 0;
    });
  };

  return (
    <div>
      {listarPessoasComDocumentos().map((item, index) => {
        return (
          <div className="border rounded p-3 my-3">
            <div
              className="d-flex flex-row cursor-pointer"
              onClick={() =>
                setExpandido((atual) => {
                  const copia = [...atual];
                  copia[index] = !copia[index];
                  return copia;
                })
              }
            >
              <span className="flex-fill">
                Documentos cadastrais {pegarDocumentoPessoa(item)} ({item.documentos.length}{' '}
                anexos) <br />
                <small>{pegarDenominacaoPessoa(item)}</small>
              </span>
              {expandido && <FiChevronDown />}
              {!expandido && <FiChevronUp />}
            </div>
            <Collapse isOpen={expandido[index]}>
              {item.documentos?.map((documento: any) => {
                return (
                  <div className="d-flex flex-row my-3" key={documento.id}>
                    <IconeTipoArquivo
                      tipo={pegarTipoArquivo(documento.arquivo)}
                    />
                    <div className="flex-fill d-flex flex-column px-3">
                      <h5>{documento.outro_tipo || documento.tipo}</h5>
                      <span className="d-block font-size-14">
                        Adicionado em {formatarDatahora(documento.created_at)} |
                        Atualizado em {formatarDatahora(documento.updated_at)}
                      </span>
                      <div className="d-flex flex-row mt-3">
                        <Button color="soft-primary" className="mr-2">
                          <FiMessageSquare />
                          <span className="ml-2">Comentar</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};

export default DocumentosCadastrais;
