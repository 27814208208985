import React from 'react';
import InputMask from 'react-input-mask';
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Input,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { FiHelpCircle } from 'react-icons/fi';
import Tooltip from 'src/components/Tooltip';

import Select from 'react-select';

type Props = {
  register: any;
};

const PessoaFisica: React.FC<Props> = ({ register }) => {
  return (
    <>
      <Row form>
        <Col>
          <FormGroup>
            <Label className="font-weight-bold" for="nome">
              Razão soial{' '}
              <span className="font-weight-normal text-danger">
                (obrigatório *)
              </span>
            </Label>
            <input
              type="text"
              className="form-control"
              id="razao_social"
              placeholder="obs.: Insira sua razão social e evite abreviaturas"
              {...register('razao_social')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={3}>
          <FormGroup>
            <Label className="font-weight-bold" for="numero_registro">
              Documento de Registro Nº
            </Label>
            <input
              type="text"
              className="form-control"
              id="numero_registro"
              placeholder="ex.: 000.000"
              {...register('numero_registro')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={2}>
          <FormGroup>
            <Label className="font-weight-bold" for="orgao_emissor">
              Órgão Emissor, UF
            </Label>
            <input
              type="text"
              className="form-control"
              id="orgao_emissor"
              placeholder="ex.: SSP, TO"
              {...register('orgao_emissor')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={3}>
          <FormGroup>
            <Label className="font-weight-bold" for="natureza_juridica">
              Natureza Juridica
            </Label>
            <input
              type="text"
              className="form-control"
              id="natureza_juridica"
              placeholder=""
              {...register('natureza_juridica')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={3}>
          <FormGroup>
            <Label className="font-weight-bold" for="origem_capital_social">
              Origem Capital Social
            </Label>
            <input
              type="text"
              className="form-control"
              id="origem_capital_social"
              placeholder=""
              {...register('origem_capital_social')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={3}>
          <FormGroup>
            <Label className="font-weight-bold" for="data_constituicao">
              Data Constituição
            </Label>
            <input
              type="date"
              className="form-control"
              id="data_constituicao"
              placeholder=""
              {...register('data_constituicao')}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={3}>
          <FormGroup>
            <Label className="font-weight-bold" for="data_registro_alteracao">
              Data alteração do registro
            </Label>
            <input
              type="date"
              className="form-control"
              id="data_registro_alteracao"
              placeholder=""
              {...register('data_registro_alteracao')}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default PessoaFisica;
