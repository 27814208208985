import { ErroRegraNegocio } from "src/entidades/ErroRegraNegocio";
import api from "../../api";
import { Conta } from "../../entidades/Conta";

const atualizarConta = async (
  params: Partial<Conta>
): Promise<{
  resultado?: Conta;
  erro?: ErroRegraNegocio;
}> => {
  try {
    const resultado: Conta = await api().put("/conta/atualizar", params);
    return { resultado };
  } catch (error) {
    const erro = {
      mensagem: "Falha ao atualizar conta",
    };
    return { erro };
  }
};

export default atualizarConta;
