import dayjs from 'dayjs';
import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { LivroDiarioTotais } from '../../entidades/LivroDiarioTotais';
import { TransacaoLivroDiario } from '../../entidades/TransacaoLivroDiario';

type ResultadoApi = {
  transacoes: TransacaoLivroDiario[];
  totais: LivroDiarioTotais;
};

export default async function listarTransacoesLivroDiario(
  competencia: string | Date,
  tipo: string
): Promise<IResultadoRequisicao<ResultadoApi>> {
  try {
    const data = dayjs(competencia).format('YYYY/MM');
    const resultado: ResultadoApi = await api().get(
      `gerencia/livro-diario-auxiliar/transacoes/${data}/${tipo}`
    );
    return { resultado };
  } catch (error) {
    return { error };
  }
}
