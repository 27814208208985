import React, { useContext } from 'react';
import { FiX } from 'react-icons/fi';
import { Orcamento } from 'src/modulos/financeiro/entidades/Orcamento';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';

const ListagemOrcamentos: React.FC = () => {
  const { processo } = useContext(ProcessoContext);

  const pegarNumeroOrcamento = (item: { orcamento: Orcamento }): string => {
    return `${String(item.orcamento.numero).padStart(6, '0')}/${
      item.orcamento.ano
    }`;
  };

  if (processo.orcamentos.length === 0) {
    return null;
  }

  return (
    <div>
      <h5>Orçamentos</h5>
      {processo.orcamentos?.map((item) => {
        return (
          <div
            className="d-flex align-items-center border rounded p-2"
            key={item.id}
          >
            <div className="flex-fill">
              <span className="d-block">{pegarNumeroOrcamento(item)}</span>
              <span className="d-block">{item.orcamento.recolhido}</span>
            </div>
            <FiX />
          </div>
        );
      })}
    </div>
  );
};

export default ListagemOrcamentos;
