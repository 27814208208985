import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../../api';
import { Andamento } from '../../entidades/Andamento';
import { Processo } from '../../entidades/Processo';

const desrelacionarProcessos = async (
  processo: Processo,
  processoRelacionadoId: number
): Promise<IResultadoRequisicao<Andamento>> => {
  try {
    const resultado: Andamento = await api().delete(
      `/processos/${processo.id}/desrelaciona/${processoRelacionadoId}`
    );

    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default desrelacionarProcessos;
