import { Usuario } from 'src/modulos/aplicacao/entidades/Usuario';
import { Pessoa } from '../../entidades/Pessoa';
import pegarUrlImagem from './pegarUrlImagem';

export default function pegarFotoPessoa(pessoa: Pessoa | Usuario) {
  let urlFoto = 'default';
  let pessoaAux = pessoa;
  // tipo usuario e preposto
  if ('pessoa' in pessoa) {
    pessoaAux = pessoa.pessoa!;
  }
  if ('documentos' in pessoaAux) {
    if (pessoaAux.documentos) {
      const foto = pessoaAux.documentos.filter(
        (item) => item.tipo === 'OUTRO' && item.outro_tipo === 'FOTO'
      );
      if (foto.length > 0) {
        urlFoto =
          foto[foto.length - 1].arquivo !== 'padrao.jpg'
            ? foto[foto.length - 1].arquivo
            : 'default';
      }
    }
  } else if (pessoaAux.foto && pessoaAux.foto !== '1') {
    urlFoto = pessoaAux.foto;
  } else if ('fisica' in pessoaAux) {
    const p = pessoaAux as Pessoa;
    urlFoto = p.fisica?.foto || 'default';
  }
  return pegarUrlImagem(urlFoto);
}
