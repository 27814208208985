import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Button, FormGroup, Label } from 'reactstrap';
import Dropdown from 'src/components/Dropdown';
import CpfCnpjInput from 'src/components/Form/CpfCnpjInput';
import pegarFotoPessoa from 'src/modulos/pessoas/casos-de-uso/pessoa/pegarFotoPessoa';
import {
  pegarDenominacaoPessoa,
  pegarDocumentoPessoa,
  Pessoa,
} from 'src/modulos/pessoas/entidades/Pessoa';
import adicionarClienteNoProcesso from 'src/modulos/processos/casos-de-uso/processo/adicionarClienteNoProcesso';
import marcarApresentanteProcesso from 'src/modulos/processos/casos-de-uso/processo/marcarApresentanteProcesso';
import Avatar from 'src/modulos/processos/components/Avatar';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';
import { pegarApresentante } from 'src/modulos/processos/entidades/Processo';
import css from './style.module.css';

const AdicionarCliente: React.FC = () => {
  const { processo, arquivado, onClienteAdicionado } =
    useContext(ProcessoContext);
  const [exibirPopupAdicao, setExibirPopupAdicao] = useState<boolean>(false);
  const [pessoaEncontrada, setPessoaEncontrada] = useState<Pessoa | null>(null);
  const [semCpfCnpj, setSemCpfCnpj] = useState(false);
  const [fecharAoClicarExterno, setFecharAoClicarExterno] = useState(true);

  const adicionarAoProcesso = async (pessoa: Pessoa) => {
    const { resultado, error } = await adicionarClienteNoProcesso(
      processo,
      pessoa.id!
    );

    if (resultado) {
      setExibirPopupAdicao(false);
      let principal = false;
      if (pegarApresentante(processo) === null) {
        await marcarApresentanteProcesso(processo, pessoa);
        principal = true;
      }
      onClienteAdicionado(pessoa, principal, resultado);
      setPessoaEncontrada(null);
      return;
    }
    if (error) {
      toast.error(error.mensagem);
      return;
    }
  };

  return (
    <div className="col-4">
      <Button
        color="soft-primary"
        className={clsx('rounded-circle', css['btn-add-cliente'])}
        disabled={arquivado}
        onClick={() => {
          setExibirPopupAdicao((exibir) => !exibir);
          setPessoaEncontrada(null);
        }}
      >
        <FiPlus />
      </Button>
      <Dropdown
        mostrar={exibirPopupAdicao}
        fecharAoClicarExterno={fecharAoClicarExterno}
        fechar={() => {
          setExibirPopupAdicao((exibir) => !exibir);
          setPessoaEncontrada(null);
        }}
        alinharEsquerda
      >
        <div className="position-relative">
          <div className="d-flex flex-row align-items-center">
            {!semCpfCnpj && (
              <FormGroup className="flex-fill mr-3">
                <CpfCnpjInput
                  onCampoLimpo={() => {
                    setPessoaEncontrada(null);
                  }}
                  onPessoaEncontrada={(pessoa: Pessoa | null) => {
                    setPessoaEncontrada(pessoa);
                  }}
                  habilitarCadastroSimples
                  onAberturaCadastroCliente={(situacao) => {
                    setFecharAoClicarExterno(!situacao);
                  }}
                />
              </FormGroup>
            )}
            {semCpfCnpj && (
              <FormGroup className="flex-fill mr-3">
                <Label>Nome ou razão social</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquise pelo nome ou razão social"
                />
              </FormGroup>
            )}
            <div className="checkbox checkbox-primary mt-2">
              <input
                type="checkbox"
                name=""
                id="checkboxSemCpfCnpj"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSemCpfCnpj(event.target.checked);
                }}
              />
              <label htmlFor="checkboxSemCpfCnpj">Sem CPF/CNPJ</label>
            </div>
          </div>
          <div className="mt-3">
            {pessoaEncontrada && (
              <div
                className="d-flex align-items-center p-2 cursor-pointer"
                onClick={() => adicionarAoProcesso(pessoaEncontrada)}
              >
                <Avatar url={pegarFotoPessoa(pessoaEncontrada)} />
                <div>
                  <span className="d-inline-block ml-3">
                    {pegarDenominacaoPessoa(pessoaEncontrada)}
                  </span>
                  <small className="d-block ml-3">
                    {pegarDocumentoPessoa(pessoaEncontrada, true)}
                  </small>
                </div>
              </div>
            )}
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default AdicionarCliente;
