import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Card, CardBody, Badge, Collapse, Row, Button } from 'reactstrap';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { MdOutlineDisabledByDefault } from 'react-icons/md';

import iconProcessos from '../../../../processos/assets/icon-processos.png';

type botaoDeEdicaoProps = {
  nomeDoModulo: string;
};

type props = {
  nome?: string;
  descricao?: string;
  imagem?: string;
  ativo?: boolean;
};

type propsBadgeStatus = {
  status?: boolean;
};

const estados: string[] = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

const retornarBadgeEstados = () => {
  return estados.map((estado) => (
    <div className="col">
      <Badge color="success" className="flex-fill">
        {estado}
      </Badge>
    </div>
  ));
};

const RetornarBadgeDeStatus: React.FC<propsBadgeStatus> = ({ status }) => {
  if (!status) {
    return (
      <Badge className="badge-warning p-2" style={{ width: 60 }}>
        Inativo
      </Badge>
    );
  }

  return (
    <Badge className="badge-success p-2" style={{ width: 60 }}>
      Ativo
    </Badge>
  );
};

const BlocoDoModulo: React.FC<props> = ({ nome, descricao, imagem, ativo }) => {
  const [estadoSeta, setEstadoSeta] = useState(false);
  const [estadoCollapse, setEstadoCollapse] = useState(false);

  let navigate = useNavigate();
  const navegarParaEdicao = (nomeDoModulo: string) => {
    navigate(`/administrativo/modulos/${nomeDoModulo}`);
  };

  const BotoesDeEdicaoDoModulo: React.FC<botaoDeEdicaoProps> = ({
    nomeDoModulo,
  }) => {
    return (
      <div className="container">
        <Row>
          <Col>
            <Button
              color="soft-primary"
              className="w-100"
              onClick={() => {
                navegarParaEdicao(nomeDoModulo);
              }}
            >
              <HiOutlinePencilAlt className="mr-1" />
              Editar Módulo
            </Button>
          </Col>

          <Col>
            <Button color="soft-warning" className="w-100">
              <MdOutlineDisabledByDefault className="mr-1" />
              Desativar
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const abrirCollapse = () => {
    setEstadoCollapse(!estadoCollapse);
  };

  const mudarEstadoSeta = () => {
    setEstadoSeta(!estadoSeta);
  };

  const retornarSeta = (estadoSeta: boolean) => {
    if (!estadoSeta) {
      return <BsChevronDown />;
    }

    return <BsChevronUp />;
  };

  return (
    <Col sm={6}>
      <Card className="border" style={{ cursor: 'pointer' }}>
        <CardBody
          onClick={() => {
            abrirCollapse();
            mudarEstadoSeta();
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div>
                <img
                  src={imagem ? imagem : iconProcessos}
                  alt="Icone do modulo de processos"
                />
              </div>

              <div className="ml-3 d-flex flex-column">
                <b>
                  <span>{nome ? nome : 'Modulo'}</span>
                </b>

                <RetornarBadgeDeStatus status={ativo} />
              </div>
            </div>

            {retornarSeta(estadoSeta)}
          </div>
        </CardBody>

        <Collapse isOpen={estadoCollapse}>
          <Card>
            <CardBody>
              <b>
                <p>
                  {descricao
                    ? descricao
                    : '[ Descrição do módulo aqui Descrição do módulo aqui Descrição do módulo aqui Descrição do módulo aqui]'}
                </p>
              </b>

              <span>UFs em que o módulo pode ser ativado:</span>

              <div className="container">
                <div className="row">{retornarBadgeEstados()}</div>
              </div>

              <div className="mt-4">
                <span>Atualizado por Ludimilla em 22/03/2022 às 19:35</span>

                <hr />

                <BotoesDeEdicaoDoModulo nomeDoModulo="processos" />
              </div>
            </CardBody>
          </Card>
        </Collapse>
      </Card>
    </Col>
  );
};

export default BlocoDoModulo;
