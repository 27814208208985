import React from 'react';
import { Row, Col, Card, CardBody, Container, Button } from 'reactstrap';
import ClipboardJS from 'clipboard';

import { FiEdit } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  ehPessoaFisica,
  pegarDenominacaoPessoa,
  pegarDocumentoPessoa,
  pegarLogCadastroEdicao,
  Pessoa,
} from '../../entidades/Pessoa';
import {
  pegarModeloCompleto,
  PessoaFisica as EntidadePessoaFisica,
} from '../../entidades/PessoaFisica';
import CarregamentoConteudo from '../../../../components/CarregamentoConteudo';
import visualizarPessoa from '../../casos-de-uso/pessoa/visualizarPessoa';
import Breadcrumb from 'src/components/Breadcrumb';
import PessoaFisica from './PessoaFisica';
import { pegarModeloComum } from '../../entidades/PessoaFisica';
import pegarFotoPessoa from '../../casos-de-uso/pessoa/pegarFotoPessoa';

const VisualizarPessoa: React.FC = () => {
  const params = useParams();
  const [carregando, setCarregando] = React.useState(true);
  const [pessoa, setPessoa] = React.useState<Pessoa | null>(null);
  new ClipboardJS('.button');

  React.useEffect(() => {
    setCarregando(true);
    visualizarPessoa(Number(params.id)).then(({ resultado, error }) => {
      if (resultado) {
        setPessoa(resultado);
        setCarregando(false);
      }
    });
  }, [params.id]);

  if (!pessoa) {
    return <></>;
  }

  return (
    <Container>
      <Breadcrumb
        links={[
          {
            url: '/pessoas',
            texto: 'Pessoas',
          },
          {
            texto: `${pegarDocumentoPessoa(pessoa)} (${pegarDenominacaoPessoa(
              pessoa
            )})`,
          },
        ]}
      />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CarregamentoConteudo carregando={carregando}>
                <Row>
                  <Col xs={3}>
                    <img
                      src={pegarFotoPessoa(pessoa)}
                      alt={pegarDenominacaoPessoa(pessoa)}
                      className="w-100"
                      style={{
                        maxHeight: '220px',
                        objectPosition: 'top center',
                        objectFit: 'cover',
                      }}
                    />
                  </Col>
                  <Col xs={9}>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h3>{pegarDenominacaoPessoa(pessoa)}</h3>
                        <span>{pegarDocumentoPessoa(pessoa)}</span>
                      </div>
                      <div>
                        <Link to={`/pessoas/editar/${pessoa.id}`}>
                          <Button
                            color="soft-primary"
                            className="d-flex align-items-center"
                          >
                            <FiEdit />
                            <span className="ml-2">Editar dados</span>
                          </Button>
                        </Link>
                      </div>
                    </div>
                    {ehPessoaFisica(pessoa) && (
                      <div className="d-flex align-items-center bg-light rounded p-1 pr-3 pl-3  mt-3">
                        <div className="flex-fill">
                          <h5>Modelo comum</h5>
                          <p id="modelo-comum">
                            {pegarModeloComum(pessoa as EntidadePessoaFisica)}
                          </p>
                        </div>
                        <div className="ml-3">
                          <button
                            data-clipboard-action="copy"
                            data-clipboard-target="#modelo-comum"
                            className="btn btn-soft-primary button"
                          >
                            COPIAR
                          </button>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                {ehPessoaFisica(pessoa) && (
                  <Row>
                    <Col>
                      <div className="d-flex bg-light rounded p-4 mt-3">
                        <div className="flex-fill">
                          <h5>Modelo completo</h5>
                          <p id="modelo-completo">
                            {pegarModeloCompleto(
                              pessoa as EntidadePessoaFisica
                            )}
                          </p>
                        </div>
                        <div className="ml-3">
                          <button
                            data-clipboard-action="copy"
                            data-clipboard-target="#modelo-completo"
                            className="btn btn-soft-primary button"
                          >
                            COPIAR
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                {ehPessoaFisica(pessoa) && (
                  <PessoaFisica pessoa={pessoa as EntidadePessoaFisica} />
                )}
                {/*<div className="row">
                  <div className="col-12">
                    <DocumentosPessoais />
                    <Processos />
                    <Orcamentos />
                    <Selos />
                    <HistoricoPep />
                  </div>
                </div>
                */}
                <div>
                  <p className="mt-6">{pegarLogCadastroEdicao(pessoa)}</p>
                </div>
              </CarregamentoConteudo>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default VisualizarPessoa;
