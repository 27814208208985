import { IResultadoRequisicao } from 'src/modulos/selos/entidades/IResultadoRequisicao';
import api from '../api';
import { Andamento } from '../entidades/Andamento';
import { Prazo } from '../entidades/Prazo';
import { Processo } from '../entidades/Processo';

const adicionarDataEntregaProcesso = async (
  processo: Processo,
  data: string
): Promise<IResultadoRequisicao<{ andamento: Andamento; prazo: Prazo }>> => {
  try {
    const resultado: { andamento: Andamento; prazo: Prazo } = await api().post(
      `/processos/${processo.id}/prazo`,
      {
        prazo: {
          titulo: 'PREVISÃO DE FINALIZAÇÃO',
          data,
        },
      }
    );

    return { resultado };
  } catch (error) {
    return { error };
  }
};

export default adicionarDataEntregaProcesso;
