import React, { useEffect } from 'react';
import Dropdown from 'src/components/Dropdown';
import buscarProcessos from 'src/modulos/processos/casos-de-uso/processos/buscarProcessos';
import { Processo } from 'src/modulos/processos/entidades/Processo';
import ItemResultadoBusca from './ItemResultadoBusca';
import css from './style.module.css';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';

const Buscar: React.FC = () => {
  const [, setProcessando] = React.useState(false);
  const [resultadoBusca, setResultadoBusca] = React.useState<Processo[]>([]);
  const location = useLocation();

  const pesquisarProcessos = async (
    evento: React.ChangeEvent<HTMLInputElement>
  ) => {
    const termo: string = evento.target.value;
    if (termo.length === 0) {
      setResultadoBusca([]);
      return;
    }
    setProcessando(true);
    const { resultado } = await buscarProcessos(termo);
    if (resultado) {
      setResultadoBusca(resultado);
    }
    setProcessando(false);
  };

  const debouncePesquisa = debounce(pesquisarProcessos, 500);

  const fecharBusca = () => {
    const input = document.getElementById(
      'inputPesquisarProcesso'
    ) as HTMLInputElement;
    input.value = '';
    setResultadoBusca([]);
  };

  useEffect(() => {
    fecharBusca();
  }, [location.pathname]);

  return (
    <div className="ml-3 w-50 position-relative">
      <div className="input-group">
        <input
          id="inputPesquisarProcesso"
          type="text"
          className="form-control w-100"
          placeholder="Pesquisar por processo"
          onChange={debouncePesquisa}
          autoComplete="off"
        />
      </div>
      <Dropdown
        mostrar={resultadoBusca.length > 0}
        fechar={fecharBusca}
        largura={'100%'}
      >
        <div className={css['busca']}>
          {resultadoBusca.map((item) => (
            <ItemResultadoBusca processo={item} key={item.id} />
          ))}
        </div>
      </Dropdown>
    </div>
  );
};

export default Buscar;
