import React, { useContext } from 'react';
import {
  FiChevronDown,
  FiChevronUp,
  FiEdit,
  FiMessageSquare,
  FiTrash,
} from 'react-icons/fi';
import { Button, Collapse } from 'reactstrap';
import formatarDatahora from 'src/casos-de-uso/formatacao/formatarDatahora';
import visualizarArquivoProcesso from 'src/modulos/processos/casos-de-uso/processo/visualizarArquivoProcesso';
import IconeTipoArquivo from 'src/modulos/processos/components/IconeTipoArquivo';
import ProcessoContext from 'src/modulos/processos/contexts/ProcessoContext';

const DocumentosDoProcesso: React.FC = () => {
  const { processo } = useContext(ProcessoContext);
  const [expandido, setExpandido] = React.useState(false);

  const pegarListaDocumentos = () => {
    if (!processo.anexos) {
      return [];
    }
    return processo.anexos?.filter(
      (item) => item.tipo === 'DOCUMENTOS_PROCESSO'
    );
  };

  if (pegarListaDocumentos().length === 0) {
    return <></>;
  }

  return (
    <div className="border rounded p-3">
      <div
        className="d-flex flex-row cursor-pointer"
        onClick={() => setExpandido((atual) => !atual)}
      >
        <span className="flex-fill">
          Documentos do Processo ({pegarListaDocumentos().length} anexos)
        </span>
        {expandido && <FiChevronDown />}
        {!expandido && <FiChevronUp />}
      </div>
      <Collapse isOpen={expandido}>
        {pegarListaDocumentos().map((item) => {
          return (
            <div className="d-flex flex-row my-3" key={item.id}>
              <div
                className="w-25"
                onClick={() => visualizarArquivoProcesso(item.acervoId!)}
              >
                <IconeTipoArquivo tipo="pdf" />
              </div>
              <div className="w-75 d-flex flex-column px-3">
                <h5
                  className="cursor-pointer"
                  onClick={() => visualizarArquivoProcesso(item.acervoId!)}
                >
                  {item.titulo}
                </h5>
                <span className="d-block font-size-14">
                  Adicionado em {formatarDatahora(item.criadoEm!)} | Atualizado
                  em {formatarDatahora(item.atualizadoEm!)}
                </span>
                <div className="d-flex flex-row mt-3">
                  <Button color="soft-primary" className="mr-2" disabled>
                    <FiMessageSquare />
                    <span className="ml-2">Comentar</span>
                  </Button>
                  <Button color="soft-primary" className="mr-2" disabled>
                    <FiEdit />
                    <span className="ml-2">Editar</span>
                  </Button>
                  <Button color="soft-danger" className="mr-2" disabled>
                    <FiTrash />
                    <span className="ml-2">Excluir</span>
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </Collapse>
    </div>
  );
};

export default DocumentosDoProcesso;
