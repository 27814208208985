import React from 'react';
import { BsChatRightText } from 'react-icons/bs';
import { IoFlashOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import IconeProcessos from '../../assets/icon-processos.png';
import Buscar from './Buscar';
import Usuario from './Usuario';
import Modulos from './Modulos';
import Configuracoes from './Configuracoes';
import SelecionarCartorio from './SelecionarCartorio';

const Header: React.FC = () => {
  return (
    <div className="navbar navbar-expand flex-column flex-md-row navbar-custom">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand mr-0 mr-md-2 logo">
          <span className="logo-lg">
            <img src={IconeProcessos} alt="" className="w-100" />
          </span>
        </Link>
        <div className="d-flex align-items-center h-100 flex-fill mx-4">
          <SelecionarCartorio />
          <Buscar />
        </div>

        <ul className="navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0 align-items-center">
          <li
            className="dropdown notification-list"
            data-toggle="tooltip"
            data-placement="left"
            title="Settings"
          >
            <span className="nav-link right-bar-toggle">
              <IoFlashOutline />
            </span>
          </li>
          <Configuracoes />
          <Modulos />
          <li
            className="dropdown"
            data-toggle="tooltip"
            data-placement="left"
            title="Settings"
          >
            <span className="nav-link right-bar-toggle">
              <BsChatRightText />
            </span>
          </li>
          <Usuario />
        </ul>
      </div>
    </div>
  );
};

export default Header;
