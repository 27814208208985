import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CarregamentoConteudo from 'src/components/CarregamentoConteudo';
import { Pessoa } from 'src/modulos/pessoas/entidades/Pessoa';
import pegarProcesso from '../casos-de-uso/processo/pegarProcesso';
import { Andamento } from '../entidades/Andamento';
import AnexoProcesso from '../entidades/AnexoProcesso';
import { Checklist } from '../entidades/Checklist';
import {
  ChecklistItemProcesso,
  itemChecklistEstahConcluido,
} from '../entidades/ChecklistItemProcesso';
import {
  AcaoPrazoChecklist,
  ChecklistProcesso,
} from '../entidades/ChecklistProcesso';
import { Etiqueta } from '../entidades/Etiqueta';
import { EtiquetaProcesso } from '../entidades/EtiquetaProcesso';
import { PessoaProcesso } from '../entidades/PessoaProcesso';
import { Prazo } from '../entidades/Prazo';
import { Preposto } from '../entidades/Preposto';
import { PrepostoProcesso } from '../entidades/PrepostoProcesso';
import { Processo } from '../entidades/Processo';
import { ProcessoRelacionado } from '../entidades/ProcessoRelacionado';
import { ProtocoloProcesso } from '../entidades/ProtocoloProcesso';

interface ProcessoContextProps {
  carregando: boolean;
  processo: Processo;
  arquivado: boolean;
  onAtualizacaoEtapa(etapa: string, andamento: Andamento): void;
  onPrepostoAdicionadoNoProcesso(
    preposto: Preposto,
    andamento: Andamento,
    principal: number
  ): void;
  onPrepostoRemovidoDoProcesso(preposto: Preposto, andamento: Andamento): void;
  onPrepostoExecutorAtualizado(preposto: Preposto, andamento: Andamento): void;
  onClienteAdicionado(
    cliente: Pessoa,
    principal: boolean,
    andamento: Andamento
  ): void;
  onClienteRemovido(cliente: Pessoa, andamento: Andamento): void;
  onClienteMarcadoComoApresentante(cliente: Pessoa, andamento: Andamento): void;
  onAdicionadoDataEntrega(prazo: Prazo): void;
  onExclusaoEtiqueta(etiqueta: Etiqueta, andamento: Andamento): void;
  onAdicaoEtiqueta(etiqueta: Etiqueta, andamento: Andamento): void;
  onProcessoRelacionado(
    processoRelacionado: Processo,
    andamento: Andamento
  ): void;
  onProcessoDesrelacionado(
    processoRelacionado: Processo,
    andamento: Andamento
  ): void;
  onComentarioAdicionado(andamento: Andamento): void;
  onComentarioEditado(andamento: Andamento): void;
  onItemChecklistAtualizado(
    checklist: ChecklistProcesso,
    itemChecklist: ChecklistItemProcesso,
    concluido: boolean
  ): void;
  onPrazoChecklistAtualizado(
    checklist: ChecklistProcesso,
    acao: AcaoPrazoChecklist,
    dias: number
  ): void;
  onChecklistAdicionado(
    checklist: ChecklistProcesso,
    andamento: Andamento
  ): void;
  onChecklistExcluida(checklist: Checklist, andamento: Andamento): void;
  onArquivoAnexado(anexo: AnexoProcesso, andamento: Andamento): void;
  onArquivoExcluido(anexo: AnexoProcesso, andamento: Andamento): void;
  onProtocoloAdicionado(
    protocolo: ProtocoloProcesso,
    andamento: Andamento
  ): void;
}

const ProcessoContext = React.createContext<ProcessoContextProps>(
  {} as ProcessoContextProps
);

export const ProcessoProvider: React.FC = ({ children }) => {
  const { id } = useParams();
  const [carregando, setCarregando] = useState<boolean>(true);
  const [processo, setProcesso] = useState<Processo>({} as Processo);
  const [arquivado, setArquivado] = useState(false);

  const atualizarAndamentos = (atual: Processo, andamento: Andamento) => {
    const listaAndamentos = atual.andamentos || [];
    listaAndamentos.unshift(andamento);
    return listaAndamentos;
  };

  const onAtualizacaoEtapa = (etapa: string, andamento: Andamento) => {
    setProcesso((atual) => {
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual, etapa };
    });
    setArquivado(etapa === 'FINALIZADO');
  };

  const onPrepostoAdicionadoNoProcesso = (
    preposto: Preposto,
    andamento: Andamento,
    principal: number
  ) => {
    setProcesso((atual) => {
      const prepostoProcesso: PrepostoProcesso = {
        ...preposto,
        pivot: {
          processo_id: processo.id,
          usuario_id: preposto.id,
          pontuacao: 0,
          pontuacao_final: 0,
          principal,
          created_at: andamento.created_at!,
          updated_at: andamento.updated_at!,
          data_arquivamento: null,
          justificativa: null,
        },
      };
      const prepostos = atual.prepostos || [];
      atual.prepostos = [...prepostos, prepostoProcesso];
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  const onPrepostoRemovidoDoProcesso = (
    preposto: Preposto,
    andamento: Andamento
  ) => {
    setProcesso((atual) => {
      atual.prepostos = [
        ...(atual.prepostos || []).filter((item) => {
          return item.id !== preposto.id;
        }),
      ];
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  const onPrepostoExecutorAtualizado = (
    preposto: Preposto,
    andamento: Andamento
  ) => {
    setProcesso((atual) => {
      const prepostos = atual.prepostos || [];
      const prepostoAtualizadoIdx = prepostos.findIndex(
        (item) => item.id === preposto.id
      );
      if (prepostoAtualizadoIdx >= 0) {
        const atualizado = prepostos[prepostoAtualizadoIdx];
        if (atualizado.pivot) {
          atualizado.pivot.principal = 1;
        }
        prepostos.splice(prepostoAtualizadoIdx, 1, atualizado);
        atual.prepostos = [...prepostos];
      }
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  const onClienteAdicionado = (
    cliente: Pessoa,
    principal: boolean,
    andamento: Andamento
  ) => {
    setProcesso((atual) => {
      const pessoas = atual.pessoas || [];
      const pessoa: PessoaProcesso = {
        ...cliente,
        pivot: {
          created_at: andamento.created_at!,
          updated_at: andamento.updated_at!,
          pessoa_id: cliente.id,
          principal: principal ? 1 : 0,
          processo_id: processo.id,
        },
      };
      atual.pessoas = [...pessoas, pessoa];
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  const onClienteRemovido = (cliente: PessoaProcesso, andamento: Andamento) => {
    setProcesso((atual) => {
      const pessoas = atual.pessoas || [];
      atual.pessoas = pessoas.filter((item) => item.id !== cliente.id);
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  const onClienteMarcadoComoApresentante = (
    cliente: PessoaProcesso,
    andamento: Andamento
  ) => {
    setProcesso((atual) => {
      const pessoas = atual.pessoas || [];
      pessoas.forEach((item) => {
        item.pivot.principal = 0;
      });
      const pessoaIdx = pessoas.findIndex((item) => item.id === cliente.id);
      if (pessoaIdx >= 0) {
        const pessoa = pessoas[pessoaIdx];
        pessoa.pivot.principal = 1;
        pessoas.splice(pessoaIdx, 1, pessoa);
      }
      atual.pessoas = [...pessoas];
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  const onAdicionadoDataEntrega = (prazo: Prazo) => {
    setProcesso((atual) => {
      atual.prazos = [prazo];
      return { ...atual };
    });
  };

  const onExclusaoEtiqueta = (etiqueta: Etiqueta, andamento: Andamento) => {
    setProcesso((atual) => {
      const etiquetas = atual.etiquetas || [];
      atual.etiquetas = [
        ...etiquetas.filter((item) => item.id !== etiqueta.id),
      ];
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  const onAdicaoEtiqueta = (etiqueta: Etiqueta, andamento: Andamento) => {
    setProcesso((atual) => {
      const etiquetas = atual.etiquetas || [];
      const novaEtiqueta: EtiquetaProcesso = {
        ...etiqueta,
        pivot: {
          created_at: andamento.created_at!,
          updated_at: andamento.updated_at!,
          etiqueta_id: etiqueta.id,
          processo_id: processo.id,
        },
      };
      etiquetas.push(novaEtiqueta);
      atual.etiquetas = [...etiquetas];
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  const onProcessoRelacionado = (
    processoRelacionado: Processo,
    andamento: Andamento
  ) => {
    setProcesso((atual) => {
      const relacionados = atual.processos_relacionados || [];
      const novoProcesso: ProcessoRelacionado = {
        ...processoRelacionado,
        pivot: {
          processo_id: processo.id,
          processo_relacionado_id: processoRelacionado.id,
        },
      };
      atual.processos_relacionados = [...relacionados, novoProcesso];
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  const onProcessoDesrelacionado = (
    processoRelacionado: Processo,
    andamento: Andamento
  ) => {
    setProcesso((atual) => {
      const relacionados = atual.processos_relacionados || [];
      atual.processos_relacionados = relacionados.filter(
        (item) => item.id !== processoRelacionado.id
      );
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  const onComentarioAdicionado = (andamento: Andamento) => {
    setProcesso((atual) => {
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  const onComentarioEditado = (andamento: Andamento) => {
    setProcesso((atual) => {
      const copia = [...(atual.andamentos || [])];
      const idx = copia.findIndex((item) => item.id === andamento.id);
      if (idx >= 0) {
        copia.splice(idx, 1, andamento);
      }
      atual.andamentos = [...copia];
      return { ...atual };
    });
  };

  const onItemChecklistAtualizado = (
    checklist: ChecklistProcesso,
    itemChecklist: ChecklistItemProcesso,
    concluido: boolean
  ) => {
    setProcesso((atual) => {
      const checklists = atual.checklists.map((item) => {
        if (item.id !== checklist.id) {
          return item;
        }
        if (item.itens) {
          const idx = item.itens.findIndex(
            (itemChecklistTemp) => itemChecklistTemp.id === itemChecklist.id
          );
          if (idx >= 0) {
            const copia = [...item.itens];
            copia[idx].processos[0].pivot.concluido = concluido ? 1 : 0;
          }
        }
        return item;
      });
      atual.checklists = [...(checklists || [])];
      return { ...atual };
    });
  };

  const onArquivoAnexado = (anexo: AnexoProcesso, andamento: Andamento) => {
    // setProcesso((atual) => {
    //   const anexos = atual.anexos || [];
    //   anexos.push(anexo);
    //   atual.anexos = anexos;
    //   atual.andamentos = atualizarAndamentos(atual, andamento);
    //   return { ...atual };
    // });
  };

  const onArquivoExcluido = (arquivo: AnexoProcesso, andamento: Andamento) => {
    // setProcesso((atual) => {
    //   const anexos = atual.anexos || [];
    //   atual.anexos = anexos.filter((item) => item.id !== arquivo.id);
    //   atual.andamentos = atualizarAndamentos(atual, andamento);
    //   return { ...atual };
    // });
  };

  const onChecklistAdicionado = (
    checklist: ChecklistProcesso,
    andamento: Andamento
  ) => {
    setProcesso((atual) => {
      const checklists = [...(atual.checklists || [])];
      checklists.push(checklist);
      atual.checklists = checklists;
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  const onChecklistExcluida = (checklist: Checklist, andamento: Andamento) => {
    setProcesso((atual) => {
      atual.checklists = atual.checklists.filter(
        (item) => item.id !== checklist.id
      );
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  const onPrazoChecklistAtualizado = (
    checklist: ChecklistProcesso,
    acao: AcaoPrazoChecklist,
    dias: number
  ) => {
    setProcesso((atual) => {
      const idxChecklist = atual.checklists.findIndex(
        (item) => item.id === checklist.id
      );
      let copiaChecklists = [...atual.checklists];
      if (idxChecklist >= 0) {
        const copia = atual.checklists[idxChecklist];
        copia.itens.forEach((item) => {
          if (!itemChecklistEstahConcluido(item)) {
            const novoPrazo =
              acao === 'prorrogar'
                ? dayjs(item.processos[0].pivot.prazo)
                    .add(dias, 'days')
                    .format('YYYY-MM-DD')
                : dayjs(item.processos[0].pivot.prazo)
                    .subtract(dias, 'days')
                    .format('YYYY-MM-DD');
            item.processos[0].pivot.prazo = novoPrazo;
          }
        });
        copiaChecklists.splice(idxChecklist, 1, copia);
      }
      atual.checklists = [...copiaChecklists];
      return { ...atual };
    });
  };

  const onProtocoloAdicionado = (
    protocolo: ProtocoloProcesso,
    andamento: Andamento
  ) => {
    setProcesso((atual) => {
      atual.protocolos = [...atual.protocolos, protocolo];
      atual.andamentos = atualizarAndamentos(atual, andamento);
      return { ...atual };
    });
  };

  useEffect(() => {
    const pegarDados = async () => {
      setCarregando(true);
      const { resultado } = await pegarProcesso(Number(id));

      if (resultado) {
        setProcesso(resultado);
        setArquivado(resultado.etapa === 'FINALIZADO');
      }
      setCarregando(false);
    };
    pegarDados();
  }, [id]);

  return (
    <ProcessoContext.Provider
      value={{
        carregando,
        processo,
        arquivado,
        onAtualizacaoEtapa,
        onPrepostoAdicionadoNoProcesso,
        onPrepostoRemovidoDoProcesso,
        onPrepostoExecutorAtualizado,
        onClienteAdicionado,
        onClienteRemovido,
        onClienteMarcadoComoApresentante,
        onAdicionadoDataEntrega,
        onExclusaoEtiqueta,
        onAdicaoEtiqueta,
        onProcessoRelacionado,
        onProcessoDesrelacionado,
        onComentarioAdicionado,
        onComentarioEditado,
        onItemChecklistAtualizado,
        onPrazoChecklistAtualizado,
        onArquivoAnexado,
        onArquivoExcluido,
        onChecklistAdicionado,
        onChecklistExcluida,
        onProtocoloAdicionado,
      }}
    >
      <CarregamentoConteudo carregando={carregando}>
        {children}
      </CarregamentoConteudo>
    </ProcessoContext.Provider>
  );
};

export default ProcessoContext;
