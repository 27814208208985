import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import DadosBasicos from '../DadosBasicos';
import DadosPLDFT from '../DadosPLDFT';
import DadosDeContato from '../DadosDeContato';
import DadosBiometricos from '../DadosBiometricos';
import VerificacaoCadastro from '../VerificacaoCadastro';
import { useGerenciarPessoaContext } from '../GerenciarPessoaContext';

const menuItens = [
  {
    numero: '02',
    id: 'dados-basicos',
    nome: 'Dados Básicos',
    component: DadosBasicos,
  },
  {
    numero: '03',
    id: 'dados-pldft',
    nome: 'Dados PLDFT',
    component: DadosPLDFT,
  },
  {
    numero: '04',
    id: 'dados-de-contratos',
    nome: 'Dados de Contatos',
    component: DadosDeContato,
  },
  {
    numero: '05',
    id: 'dados-biometricos',
    nome: 'Dados biométricos',
    component: DadosBiometricos,
  },
];
const Etapas: React.FC = () => {
  const [abaAtual, setAbaAtual] = useState(menuItens[0].id);
  const { exibirEtapas, salvar } = useGerenciarPessoaContext();

  return (
    <>
      <Card className="rounded">
        <CardBody>
          <VerificacaoCadastro />
        </CardBody>
      </Card>
      {exibirEtapas && (
        <Card className="rounded">
          <CardBody>
            <Nav tabs>
              {menuItens.map((item) => (
                <NavItem>
                  <NavLink
                    className={item.id === abaAtual ? 'active' : ''}
                    onClick={() => setAbaAtual(item.id)}
                  >
                    {item.nome}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={abaAtual}>
              {menuItens.map((item) => {
                const Componente = item.component;
                return (
                  <TabPane key={item.id} tabId={item.id}>
                    <Componente />
                  </TabPane>
                );
              })}
            </TabContent>
            <hr />
            <div className="d-flex justify-content-end py-2">
              <Button color="primary" className="px-4" onClick={salvar}>
                Salvar
              </Button>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default Etapas;
